import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import correspondenceStyles from 'app/views/correspondence/components/correspondenceListItem/correspondenceListItem.module.scss';
import {ICorrespondence} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';
import {getFormattedCorrespondenceDate} from '../../../../utils/convertDate';

export const Correspondence: FC<ISuggestionComponent<ICorrespondence>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {
        id,
        subject,
        fromStructure,
        searchString,
        status,
        reference,
        historyDate,
        hasAttachments,
        date,
    } = item;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_correspondence, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.info}>
                <span className={styles.status}>{status}</span>
                <span className={styles.reference}>{reference}</span>
                <div className={`${styles.subTitle} ${styles.darkSubTitle}`}>{fromStructure}</div>
                <div className={styles.title}>{subject}</div>

                <div className={correspondenceStyles.extraInfo}>
                    <span className={correspondenceStyles.date}>
                        {getFormattedCorrespondenceDate(date)}
                    </span>
                    <div className={correspondenceStyles.icons}>
                        {hasAttachments && <span className={correspondenceStyles.hasAttachments} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
