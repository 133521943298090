import {EntityType} from '../common.types';

export enum AppMode {
    Widget = 'widget',
    FullScreen = 'fullscreen',
    WebView = 'web-view', // for mobile and iframe
}

export interface IAppContext {
    mode: AppMode;
    query?: string;
    entityType?: EntityType;
    entityID?: string;
    mobileParams: MobileParams;
    updateToken: (token: string, reloadCallback: () => void) => void;
}

export interface MobileParams {
    mobile: string | null;
}
