import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {LineChart} from './lineChart';
import styles from './trend.module.scss';
import {m$FormatterShort} from '../../../../../utils';
import {useMediaQuery} from '@material-ui/core';
import scssVariables from 'app/variables.module.scss';

export const BasketSizeTrend = ({
    data,
    className,
    labels,
}: {
    data: any[];
    labels: string[];
    width: number;
    className?: string;
}) => {
    const {t} = useLocalizationContext();

    const chartData = [
        {
            label: t('distribution.preview.basketSize'),
            values: data.map(d => d.basketSizeForRange / 1000000),
        },
    ];

    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`);
    const isExtraSmall = useMediaQuery(`(${scssVariables.mediaExtraSmall.slice(1, -1)})`);
    let height = isSmall ? 260 : 256;

    return (
        <div className={className}>
            <div className={styles.trendHeader}>
                <h3 className={styles.trendTitle}>{t('distribution.preview.basketSize')}</h3>
            </div>
            <LineChart
                labels={labels}
                formatter={m$FormatterShort}
                height={height}
                dataLabelsVisible={false}
                data={chartData}
            />
        </div>
    );
};
