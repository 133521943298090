import React from 'react';
import {Bar} from 'react-chartjs-2';

import styles from './sCurveChart.module.scss';

const accumulate = (arr: number[]) =>
    arr.reduce((ac: number[], cv, i) => [...ac, (ac[i - 1] ? ac[i - 1] : 0) + cv], []);

const clearTicks = (axis: any, ticks: number[]) => {
    let t = [...ticks];

    const d = Math.abs(t[1] - t[2]);
    if (Math.abs(t[0] - t[1]) < d / 2) {
        t.shift();
    }

    const l = t.length;
    if (Math.abs(t[l - 1] - t[l - 2]) < d / 2) {
        t.pop();
    }
    return t;
};

export type chartDataProps = {
    labels: string[];
    actualProgress: number[];
    plannedProgress: number[];
    revisedProgress: number[];
};

export type sCurveProps = {
    chartData: chartDataProps;
    axisLabel: string;
    axisLabelCumulative: string;
    axisFormatter: (v: number) => string;
    labelFormatter: (v: number) => string;
    height: number;
};

export const SCurveChart = (props: sCurveProps) => {
    const {
        chartData: {plannedProgress, revisedProgress, actualProgress, labels},
        axisLabel,
        axisLabelCumulative,
        axisFormatter,
        labelFormatter,
        height,
    } = props;

    const accumulatedPlannedProgress = accumulate(plannedProgress);
    const accumulatedActualProgress = accumulate(actualProgress);
    const accumulatedRevisedProgress = accumulate(revisedProgress);

    const data = {
        labels,
        datasets: [
            {
                label: 'Cumulative Planned',
                type: 'line',
                data: accumulatedPlannedProgress,
                fill: false,
                backgroundColor: '#0075C9',
                borderColor: '#0075C9',
                borderWidth: 1,
                pointRadius: 0,
                hoverRadius: 3,
                yAxisID: 'y-axis-2',
            },
            {
                label: 'Cumulative Actual',
                type: 'line',
                data: accumulatedActualProgress,
                fill: false,
                backgroundColor: '#00754A',
                borderColor: '#00754A',
                borderWidth: 1,
                pointRadius: 0,
                hoverRadius: 3,
                yAxisID: 'y-axis-2',
            },
            {
                label: 'Cumulative Revised',
                type: 'line',
                data: accumulatedRevisedProgress,
                fill: false,
                backgroundColor: '#f19923',
                borderColor: '#f19923',
                borderWidth: 1,
                pointRadius: 0,
                hoverRadius: 3,
                yAxisID: 'y-axis-2',
            },
            {
                type: 'bar',
                label: 'Planned',
                data: plannedProgress,
                fill: false,
                backgroundColor: '#089BD7',
                yAxisID: 'y-axis-1',
            },
            {
                type: 'bar',
                label: 'Actual',
                data: actualProgress,
                fill: false,
                backgroundColor: '#109647',
                yAxisID: 'y-axis-1',
            },
            {
                type: 'bar',
                label: 'Revised',
                data: revisedProgress,
                fill: false,
                backgroundColor: '#FAA22C',
                yAxisID: 'y-axis-1',
            },
        ],
    };

    const barChartMin = Math.min(...actualProgress, ...revisedProgress, ...plannedProgress);
    const barChartMax = Math.max(...actualProgress, ...revisedProgress, ...plannedProgress);
    const lineChartMin = Math.min(
        ...accumulatedPlannedProgress,
        ...accumulatedRevisedProgress,
        ...accumulatedActualProgress
    );
    const lineChartMax = Math.max(
        ...accumulatedPlannedProgress,
        ...accumulatedRevisedProgress,
        ...accumulatedActualProgress
    );

    const arB = barChartMin / barChartMax;
    const arL = lineChartMin / lineChartMax;

    let bMax = barChartMax;
    let bMin = barChartMin;
    let lMax = lineChartMax;
    let lMin = lineChartMin;

    if (Math.abs(arB) > Math.abs(arL)) {
        lMin = arB * lMax;
    }

    if (Math.abs(arL) > Math.abs(arB)) {
        bMin = arL * bMax;
    }

    if (bMin > 0) {
        bMin = 0;
    }

    if (lMin > 0) {
        lMin = 0;
    }

    const options = {
        legend: {
            display: false,
        },
        tooltips: {
            mode: 'label',
            callbacks: {
                label: function ({index, datasetIndex}: any, {datasets}: any) {
                    const dataset = datasets[datasetIndex];
                    const label = dataset.label;
                    const value = dataset.data[index];

                    return `${label}: ${labelFormatter(value)}`;
                },
            },
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    gridLines: {
                        display: false,
                    },
                },
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: axisLabel,
                    },
                    ticks: {
                        callback: axisFormatter,
                        min: bMin,
                        max: bMax,
                    },
                    afterBuildTicks: clearTicks,
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: axisLabelCumulative,
                    },
                    ticks: {
                        callback: axisFormatter,
                        min: lMin,
                        max: lMax,
                    },
                    afterBuildTicks: clearTicks,
                },
            ],
        },
    };

    return (
        <div className={styles.chart} key={height}>
            <Bar data={data} options={options} width={100} height={height} />
        </div>
    );
};
