import React, {FC, useEffect, useRef} from 'react';
import classnames from 'classnames';
import styles from './Map.module.scss';
import {MapProps} from './Map.types';

import {ReactComponent} from 'app/static/simplemap/world.svg';

export const Map: FC<MapProps> = ({wrapperClassName, countryIsoCode, children}) => {
    const mapWrapperRef = useRef<any>();
    const mapRef = useRef<any>();

    useEffect(() => {
        if (!countryIsoCode) return;

        if (mapRef?.current && mapWrapperRef?.current) {
            const countrySvgAreaElements: SVGSVGElement[] = Array.from(
                /**
                 * get svg-elements using country ISO-code as className
                 * for example: <path class="JP" ... /> it is Japan on map
                 */
                mapRef.current.querySelectorAll(`.${countryIsoCode}`)
            );

            if (countrySvgAreaElements.length === 0) return;

            // fill selected country
            countrySvgAreaElements.forEach(domElement => {
                domElement.classList.add(styles.selectedCountry);
            });

            // min and max coordinates to map visualisation
            let x1 = -1,
                x2 = -1,
                y1 = -1,
                y2 = -1;

            countrySvgAreaElements.forEach(domElement => {
                const rect: DOMRect = domElement.getBBox();

                if ([x1, x2, y1, y2].some(c => c === -1)) {
                    x1 = rect.x;
                    x2 = rect.x + rect.width;
                    y1 = rect.y;
                    y2 = rect.y + rect.height;
                    return;
                }

                if (x1 > rect.x) x1 = rect.x;
                if (y1 > rect.y) y1 = rect.y;

                if (x2 < rect.x + rect.width) x2 = rect.x + rect.width;
                if (y2 < rect.y + rect.height) y2 = rect.y + rect.height;
            });

            const wrapperWidth = mapWrapperRef.current.clientWidth;
            const wrapperHeight = mapWrapperRef.current.clientHeight;

            const rightSidePosition = -mapRef.current.clientWidth + x2;
            const rightOffset = (wrapperWidth / 2 - (x2 - x1)) / 2;

            const topSidePosition = -y1;
            const topOffset = (wrapperHeight - (y2 - y1)) / 2;

            mapRef.current.style.right = `${rightSidePosition + rightOffset}px`;
            mapRef.current.style.top = `${topSidePosition + topOffset}px`;
        }
    }, [countryIsoCode]);

    return (
        <div className={classnames(styles.wrapper, wrapperClassName)}>
            <div className={styles.mapWrapper} ref={mapWrapperRef}>
                <ReactComponent className={styles.map} ref={mapRef} />
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
