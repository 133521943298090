import {get} from 'lodash';
import {actions, toastType} from 'react-redux-toastr';
import React, {Component} from 'react';
import MobileDetect from 'mobile-detect';
import {AppMode} from 'app/contexts';
import {generateUniqueString} from 'app/utils/generateUniqueString';
import {ToastrIcon} from './toastrIcon';
import classNames from 'classnames';

export type ToastrBaseType = {
    id: string;
    destroy: (param?: {force: boolean}) => void | undefined;
};

const maxTimeOut = 6000;
const minTimeOut = 3000;

const isToastrsFeature = get(window, 'SEARCH_APP.feature.searchToastrs', false);
const toastrOptions = (params = {}) => ({
    timeOut: isToastrsFeature.skey_64 || maxTimeOut,
    showCloseButton: true,
    removeOnHover: false,
    ...params,
});

export const ToastrBaseComponent = (
    dispatch: any,
    {
        type,
        mode,
        className,
        iconSrc,
        component,
        params,
    }: {
        type: toastType;
        mode: string;
        className?: string;
        iconSrc: string;
        component?: Component | JSX.Element | undefined;
        params?: {};
    }
): ToastrBaseType => {
    // TODO Remove this condition after disable show toast in OneHub side
    const md = new MobileDetect(window.navigator.userAgent);
    const mobile = md.mobile();

    if (!isToastrsFeature || !isToastrsFeature.skey_66 || mobile) {
        return {
            id: '',
            destroy: () => undefined,
        };
    }

    const isMobile = mode !== AppMode.FullScreen;
    const isWebView = mode === AppMode.WebView;
    const id = generateUniqueString();

    const action = actions.add({
        id,
        type,
        options: {
            ...toastrOptions(params),
            icon: React.createElement(ToastrIcon, {iconSrc}, null),
            showCloseButton: true,
            className: classNames(isMobile && 'mobile', isWebView && 'webView', className),
            component,
        },
    });
    dispatch(action);

    return {
        id,
        destroy: param => {
            setTimeout(
                () => dispatch(actions.remove(id)),
                param?.force ? 0 : isToastrsFeature.skey_65 || minTimeOut
            );
        },
    };
};
