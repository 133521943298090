export enum FilterType {
    Checkbox = 'checkbox',
    Radio = 'radio',
    SingleCheckbox = 'singleCheckbox',
    DateRange = 'dateRange',
    TextField = 'textField',
    BooleanOption = 'booleanOption',
    SearchField = 'searchField',
}

export interface IFilterPanelProps {
    filterProps: IFilterRefiner[];
    onApplyFilters?: (filters: IAllFilters[]) => void;
    onCancel: () => void;
    isLoading?: boolean;
    mobileLabel: string;
    filterLabelMapper?: (i18nProperty: string) => string;
    onHideFiltersClick: () => void;
}

export interface IFiltersMapProps {
    filters: IFilterRefiner[];
    filterLabelMapper?: (i18nProperty: string) => string;
    toggle: (filterName: string) => void;
    checkFilterIsActiveToggle: (filterName: string) => boolean;
    changeFilterIsCheckedValue: (
        refinementName: string,
        filterName: string,
        shouldMoveToTop?: boolean
    ) => void;
    changeSingleFilterValue: (
        refinementName: string,
        filterName: string,
        shouldMoveToTop?: boolean
    ) => void;
    getFilterIsCheckedValue: (filterName: string) => boolean;
    findCheckedFilters: (filters: IAllFilters[], filterName?: string) => IAllFilters[];
    allFilters: IAllFilters[];
    setAllFilters: (arr: IAllFilters[]) => void;
    setFilterDateValue: (filter: IFilterRefiner, entry: IFilter, date: number | null) => void;
    getFilterDateValue: (filterName: string, entryName: string) => number | null;
    setTextFilterValue: (filterName: string, entryName: string, value: string) => void;

    onSubmit(): void;
}

export interface IFilterRefiner {
    Name: string;
    type?: FilterType;
    Entries: IFilter[];
}

export interface IFilter {
    RefinementName: string;
    RefinementValue: string;
    RefinementCount?: string;
    RefinementToken?: string;
    isChecked?: boolean;
    value?: any;
}

export interface IAllFilters {
    filter: string;
    refinementName: string;
    refinementValue: string | number;
    isChecked: boolean;
    value?: any;
}

export interface IFiltersToggle {
    filterName: string;
    isActiveToggle: boolean;
}

export interface IELearningSelectedFilters {
    [key: string]: string[];
}
