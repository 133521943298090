import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import 'reflect-metadata';
import {StylesProvider} from '@material-ui/core/styles';
import 'index.scss';

import store, {history} from 'app/state/store';
import 'app/i18n';
import {AppProvider, ChannelProvider, LocalizationProvider} from 'app/contexts';
import {AuthorizationGuard} from 'app/guards';
import App from 'app/app';
import './wdyr.ts';
import {FallbackLayout} from 'app/layouts/fallbackLayout/fallbackLayout';
import ReduxToastr from 'react-redux-toastr';
import {appInsights} from 'app/infrastructure/logging/AppInsights';
import {appConfig} from 'app/config';

if (appConfig.keys.skey_48) {
    appInsights.loadAppInsights();

    const telemetryInitializer = (envelope: {tags: {[x: string]: string}}) => {
        envelope.tags['ai.cloud.role'] = 'SEARCH-WEB';
        envelope.tags['ai.cloud.roleInstance'] = process.env.NODE_ENV;
        envelope.tags['env'] = process.env.NODE_ENV;
    };

    // @ts-ignore
    appInsights.addTelemetryInitializer(telemetryInitializer);
    appInsights.trackPageView();
}

const MOUNT_POINT = document.getElementById('root');
const APP = (
    <Provider store={store}>
        <ConnectedRouter history={history} noInitialPop>
            <StylesProvider injectFirst>
                <Suspense fallback={<FallbackLayout />}>
                    <AppProvider>
                        <AuthorizationGuard>
                            <ChannelProvider>
                                <LocalizationProvider>
                                    <App />
                                </LocalizationProvider>
                            </ChannelProvider>
                        </AuthorizationGuard>
                    </AppProvider>
                </Suspense>
            </StylesProvider>
        </ConnectedRouter>
        <ReduxToastr
            newestOnTop={false}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={false}
            closeOnToastrClick={false}
        />
    </Provider>
);

if (process.env.NODE_ENV === 'development') {
    ReactDOM.render(<React.StrictMode>{APP}</React.StrictMode>, MOUNT_POINT);
} else {
    ReactDOM.render(<React.Fragment>{APP}</React.Fragment>, MOUNT_POINT);
}
