import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import styles from './userInfoPopup.module.scss';
import {useOnClickOutside} from 'app/hooks/useOnClickOutside';
import {TextAvatar} from 'app/views/components/textAvatar';
import phoneIcon from 'app/static/images/contactPhone.svg';
import mailIcon from 'app/static/images/contactMail.svg';
import teamsIcon from 'app/static/images/contactTeams.svg';

export const UserInfoPopup = (props: any) => {
    const {buttonClass, children, user} = props;

    const {
        avatarInBase64,
        displayName,
        jobTitle,
        department,
        companyName,
        mail,
        mobilePhone,
        businessPhone,
    } = user;

    const phone = mobilePhone || businessPhone;

    const ref = useRef<HTMLDivElement | null>(null);
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    useOnClickOutside(ref, () => setShow(false));

    return (
        <div className={styles.container} ref={ref}>
            <button
                type="button"
                onClick={toggleShow}
                className={classNames(styles.button, buttonClass)}
            >
                {children}
            </button>
            <div
                className={classNames(styles.popup, {
                    [styles.show]: show,
                })}
            >
                <div className={styles.background} />
                <TextAvatar
                    title={displayName}
                    avatarProps={{
                        src: avatarInBase64
                            ? `data:image/jpeg;base64,${avatarInBase64}`
                            : undefined,
                        className: styles.avatar,
                    }}
                />
                <div className={styles.name}>{displayName}</div>
                <div className={styles.jobInfo}>
                    {jobTitle}
                    {jobTitle && department ? ', ' : null}
                    {department}
                </div>
                {companyName && <div className={styles.companyName}>{companyName}</div>}
                <div className={styles.contact}>
                    {phone && (
                        <a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
                            <img src={phoneIcon} alt="phone" />
                        </a>
                    )}
                    {mail && (
                        <>
                            <a href={`mailto:${mail}`} target="_blank" rel="noopener noreferrer">
                                <img src={mailIcon} alt="mail" />
                            </a>
                            <a
                                href={`https://teams.microsoft.com/l/chat/0/0?users=${mail}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={teamsIcon} alt="teams" />
                            </a>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
