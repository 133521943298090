import accxIcon from 'app/static/images/docs/accx.svg';
import aspxIcon from 'app/static/images/docs/aspx.svg';
import csvIcon from 'app/static/images/docs/csv.svg';
import docxIcon from 'app/static/images/docs/docx.svg';
import emlIcon from 'app/static/images/docs/eml.svg';
import htmIcon from 'app/static/images/docs/htm.svg';
import htmlIcon from 'app/static/images/docs/html.svg';
import jspIcon from 'app/static/images/docs/jsp.svg';
import mhtIcon from 'app/static/images/docs/mht.svg';
import msgIcon from 'app/static/images/docs/msg.svg';
import odsIcon from 'app/static/images/docs/ods.svg';
import odpIcon from 'app/static/images/docs/odp.svg';
import oneIcon from 'app/static/images/docs/one.svg';
import pdfIcon from 'app/static/images/docs/pdf.svg';
import phpIcon from 'app/static/images/docs/php.svg';
import pptxIcon from 'app/static/images/docs/pptx.svg';
import ppubIcon from 'app/static/images/docs/ppub.svg';
import pubIcon from 'app/static/images/docs/pub.svg';
import txtIcon from 'app/static/images/docs/txt.svg';
import urlIcon from 'app/static/images/docs/url.svg';
import vpxIcon from 'app/static/images/docs/vpx.svg';
import vsdIcon from 'app/static/images/docs/vsd.svg';
import vssIcon from 'app/static/images/docs/vss.svg';
import xlsIcon from 'app/static/images/docs/xls.svg';
import xmlIcon from 'app/static/images/docs/xml.svg';
import zipIcon from 'app/static/images/docs/zip.svg';
import imageIcon from 'app/static/images/docs/image.svg';

import unknownIcon from 'app/static/images/docs/unknown.svg';

export enum DocumentExtension {
    ACCX = 'ACCX',
    ASPX = 'ASPX',
    CSV = 'CSV',
    DOC = 'DOC',
    DOCX = 'DOCX',
    DOCM = 'DOCM',
    DOT = 'DOT',
    DOTX = 'DOTX',
    EML = 'EML',
    HTM = 'HTM',
    HTML = 'HTML',
    JSP = 'JSP',
    MHT = 'MHT',
    MSG = 'MSG',
    ODS = 'ODS',
    ODP = 'ODP',
    ONE = 'ONE',
    PDF = 'PDF',
    PHP = 'PHP',
    PPT = 'PPT',
    PPTS = 'PPTS',
    PPTX = 'PPTX',
    PPSX = 'PPSX',
    PPTM = 'PPTM',
    PPUB = 'PPUB',
    PUB = 'PUB',
    TXT = 'TXT',
    VPX = 'VPX',
    VSD = 'VSD',
    VSDX = 'VSDX',
    VSS = 'VSS',
    XLS = 'XLS',
    XLSX = 'XLSX',
    XLSB = 'XLSB',
    XLSM = 'XLSM',
    XML = 'XML',
    ZIP = 'ZIP',
    URL = 'URL',
    PNG = 'PNG',
    JPEG = 'JPEG',
    JPG = 'JPG',
    ETC = 'ETC',
    GIF = 'GIF',
    BMP = 'BMP',
    SVG = 'SVG',
}

const getDocumentIcon = (extension: string) => {
    const preparedExtention: string = extension.toUpperCase();
    let icon: string;

    switch (preparedExtention) {
        case DocumentExtension.PDF:
            icon = pdfIcon;
            break;
        case DocumentExtension.DOC:
        case DocumentExtension.DOCX:
        case DocumentExtension.DOCM:
        case DocumentExtension.DOT:
        case DocumentExtension.DOTX:
            icon = docxIcon;
            break;
        case DocumentExtension.PPT:
        case DocumentExtension.PPTS:
        case DocumentExtension.PPTX:
        case DocumentExtension.PPSX:
        case DocumentExtension.PPTM:
            icon = pptxIcon;
            break;
        case DocumentExtension.XLS:
        case DocumentExtension.XLSX:
        case DocumentExtension.XLSB:
        case DocumentExtension.XLSM:
            icon = xlsIcon;
            break;
        case DocumentExtension.CSV:
            icon = csvIcon;
            break;
        case DocumentExtension.VSD:
        case DocumentExtension.VSDX:
            icon = vsdIcon;
            break;
        case DocumentExtension.PPUB:
            icon = ppubIcon;
            break;
        case DocumentExtension.PUB:
            icon = pubIcon;
            break;
        case DocumentExtension.MSG:
            icon = msgIcon;
            break;
        case DocumentExtension.ACCX:
            icon = accxIcon;
            break;
        case DocumentExtension.ASPX:
            icon = aspxIcon;
            break;
        case DocumentExtension.EML:
            icon = emlIcon;
            break;
        case DocumentExtension.HTM:
            icon = htmIcon;
            break;
        case DocumentExtension.HTML:
            icon = htmlIcon;
            break;
        case DocumentExtension.JSP:
            icon = jspIcon;
            break;
        case DocumentExtension.MHT:
            icon = mhtIcon;
            break;
        case DocumentExtension.ODS:
            icon = odsIcon;
            break;
        case DocumentExtension.ODP:
            icon = odpIcon;
            break;
        case DocumentExtension.ONE:
            icon = oneIcon;
            break;
        case DocumentExtension.PHP:
            icon = phpIcon;
            break;
        case DocumentExtension.TXT:
            icon = txtIcon;
            break;
        case DocumentExtension.VPX:
            icon = vpxIcon;
            break;
        case DocumentExtension.VSS:
            icon = vssIcon;
            break;
        case DocumentExtension.XML:
            icon = xmlIcon;
            break;
        case DocumentExtension.ZIP:
            icon = zipIcon;
            break;
        case DocumentExtension.URL:
            icon = urlIcon;
            break;
        case DocumentExtension.PNG:
        case DocumentExtension.JPEG:
        case DocumentExtension.JPG:
        case DocumentExtension.ETC:
        case DocumentExtension.GIF:
        case DocumentExtension.BMP:
        case DocumentExtension.SVG:
            icon = imageIcon;
            break;
        default:
            icon = unknownIcon;
    }

    return icon;
};

export {getDocumentIcon};
