import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {appConfig} from 'app/config';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {ISearchServiceMails} from './searchService.mails.types';
import {IMailBody, ISearchMailOptions} from 'app/api/searchAPI/mails/searchAPI.mails.types';
import {SearchAPICallerMails} from 'app/api/searchAPI/mails/searchAPICaller.mails';
import {ISearchMailResponse} from '../searchService.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {ResponseError} from 'app/utils/responseError';

@injectable()
export class SearchServiceMails implements ISearchServiceMails {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchMail(options: ISearchMailOptions): Promise<ISearchMailResponse> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerMails.searchMail(
                    options,
                    appConfig.searchAPIURL,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse({
                        areMoreResultsAvailable: false,
                        items: [],
                    }) as ISearchMailResponse;
                }
            }
        );
    }

    public async getMailBody(emailId: string): Promise<IMailBody> {
        console.log('internetMessageId', emailId);
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerMails.getMailBody(
                    emailId,
                    appConfig.searchAPIURL,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse();
            }
        );
    }

    /**
     * Download Mail Attachment
     * @param internetMessageId
     * @param attachmentId
     */
    public async getMailAttachmentDownload(internetMessageId: string, attachmentId: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return await SearchAPICallerMails.getMailAttachmentDownload(
                    internetMessageId,
                    attachmentId,
                    appConfig.searchAPIURL,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse();
            }
        );
    }

    /**
     * Get Link for mail attachment DOWNLOAD (will be using for mobile)
     * @param internetMessageId
     * @param attachmentId
     */
    public async getMailAttachmentLinkDownload(
        internetMessageId: string,
        attachmentId: string
    ): Promise<object> {
        const url = await SearchAPICallerMails.getMailAttachmentLinkDownload(
            internetMessageId,
            attachmentId,
            appConfig.searchAPIURL
        );

        return {url};
    }

    /**
     * Get Link for mail attachment PREVIEW (will be using for mobile)
     * @param internetMessageId
     * @param attachmentId
     */
    public async getMailAttachmentLinkPreview(
        internetMessageId: string,
        attachmentId: string
    ): Promise<object> {
        const url = await SearchAPICallerMails.getMailAttachmentLinkPreview(
            internetMessageId,
            attachmentId,
            appConfig.searchAPIURL
        );

        return {url};
    }

    /**
     * DEPRECATED
     * @param internetMessageId
     * @param attachmentId
     * @param name
     * @param fileFormat
     */
    public async getMailAttachmentLink(
        internetMessageId: string,
        attachmentId: string,
        name: string,
        fileFormat?: string
    ): Promise<object> {
        const accessToken = await this.authProvider.getAccessToken();
        const url = await SearchAPICallerMails.getMailAttachmentLink(
            internetMessageId,
            attachmentId,
            appConfig.searchAPIURL,
            accessToken,
            name,
            fileFormat
        );
        const authorizedUrl = new URL(url);

        // authorizedUrl.searchParams.append('fileFormat', 'optimized');
        authorizedUrl.searchParams.append('token', accessToken);

        return {
            url: url,
            authorizedUrl,
        };
    }

    public async getMailAttachments(internetMessageId: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return (
                    await SearchAPICallerMails.getMailAttachments(
                        internetMessageId,
                        appConfig.searchAPIURL,
                        accessToken
                    )
                ).attachments;
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse();
            }
        );
    }
}
