import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

import styles from './schedule.module.scss';
import commonStyles from '../projectsPreviewPanel.module.scss';
import {IProjectDetails} from 'app/state/projects/projects.types';
import {ColorLabel, colors} from '../../../../components/colorLabel/colorLabel';

export const ScheduleInfo = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {
            scheduleInfo: {
                actualProgress,
                plannedProgress,
                progressVariance,
                minorDelay,
                mayorDelay,
                onSchedule,
                onBehindSchedule,
            },
        },
        isSmall,
    } = props;

    const scheduleLabels: {
        active: boolean;
        color: colors;
        label: string;
    }[] = [
        {
            active: minorDelay === 1,
            color: 'orange',
            label: t('projects.preview.minorDelay'),
        },
        {
            active: mayorDelay === 1,
            color: 'brightRed',
            label: t('projects.preview.majorDelay'),
        },
        {
            active: onSchedule === 1,
            color: 'blue',
            label: t('projects.preview.onSchedule'),
        },
        {
            active: onBehindSchedule === 1,
            color: 'red',
            label: t('projects.preview.behindCompletion'),
        },
    ];

    const label = scheduleLabels.find(({active}) => active);
    const labelColor = label?.color;
    const labelTitle = label?.label || '';
    let progressLabel = '';

    if (plannedProgress === actualProgress) {
        progressLabel = t('projects.preview.onScheduleInfo');
    }
    if (plannedProgress > actualProgress) {
        progressLabel = `${Math.abs(progressVariance)}% ${t(
            'projects.preview.behindScheduleInfo'
        )}`;
    }
    if (plannedProgress < actualProgress) {
        progressLabel = `${Math.abs(progressVariance)}% ${t('projects.preview.overScheduleInfo')}`;
    }

    return (
        <div className={commonStyles.itemContainer}>
            <div className={styles.title}>
                <h3>{t('projects.preview.schedule')}</h3>
                <ColorLabel color={labelColor}>{labelTitle}</ColorLabel>
            </div>
            <div
                className={classNames(styles.container, {
                    [styles.small]: isSmall,
                })}
            >
                <div>
                    <div className={styles.value}>{actualProgress}%</div>
                    <div
                        className={classNames(styles.label, {
                            [styles.hide]: isSmall,
                        })}
                    >
                        Actual
                    </div>
                </div>
                <div>
                    <div className={styles.progress}>
                        <div
                            className={classNames(styles.actual, {
                                [styles.blue]: labelColor === 'blue',
                                [styles.orange]: labelColor === 'orange',
                                [styles.brightRed]: labelColor === 'brightRed',
                                [styles.red]: labelColor === 'red',
                            })}
                            style={{width: `${actualProgress}%`}}
                        />
                        <div className={styles.planned} style={{left: `${plannedProgress}%`}}>
                            <span
                                className={classNames({
                                    [styles.min]: plannedProgress < 5,
                                    [styles.max]: plannedProgress > 90,
                                })}
                            >
                                {plannedProgress}%
                            </span>
                        </div>
                    </div>
                    <div className={styles.label}>{progressLabel}</div>
                </div>
            </div>
        </div>
    );
};
