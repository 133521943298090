import {handleBlob} from 'app/utils';
import {toastrDownloadError, toastrDownloadInfo} from 'app/views/components/toastr/toastrDownload';
import {ToastrBaseType} from '../../../components/toastr/toastrBaseComponent';

interface IGetDocument {
    arrayBuffer: ArrayBuffer | string;
    hasErrors?: boolean;
}

const getCorrespondenceDocument = (
    id: string,
    shouldDownload: boolean,
    mode: string,
    dispatch: any,
    getCorrespondenceDocumentFromProps: (id: string, preview?: boolean) => Promise<IGetDocument>
) => {
    let toastr: ToastrBaseType = {
        id: '',
        destroy: () => {},
    };
    if (shouldDownload) {
        toastr = toastrDownloadInfo(dispatch, {documentName: `${id}.pdf`, mode});
    }

    return getCorrespondenceDocumentFromProps(id, !shouldDownload)
        .then(data => {
            if (data.hasErrors) {
                toastr.destroy({force: true});
                toastrDownloadError(dispatch, {mode});
                return;
            }

            if (shouldDownload) {
                handleBlob(data.arrayBuffer, 'application/pdf', true, `${id}.pdf`);
                toastr.destroy();
            }

            return data;
        })
        .catch(() => {});
};

export default getCorrespondenceDocument;
