import {EntityType} from 'app/contexts';
import {RootState} from 'app/state/store';

export const isMailLoadingSelector = (state: RootState) => state.mail.isLoading;
export const mailAreMoreResultsAvailableSelector = ({mail: {areMoreResultsAvailable}}: RootState) =>
    areMoreResultsAvailable;
export const mailItemsSelector = ({mail: {items}}: RootState) => items;
export const mailErrorsSelector = (state: RootState) => state.mail.hasErrors;
export const mailErrorCodeSelector = ({mail: {hasErrorCode}}: RootState) => hasErrorCode;
export const filtersSelector = ({filter: {repository}}: RootState) =>
    repository[EntityType.Email].filters;
