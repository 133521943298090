import {createActions} from 'redux-actions';

export const {
    searchServices,
    searchServicesSuccess,
    searchServicesFailure,
    resetServicesState,
    resetServicesItems,
    applyServiceFilters,
    resetServiceNowTab,
    getServiceDetails,
    getServiceDetailsSuccess,
    getServiceDetailsFailure,
} = createActions({
    SEARCH_SERVICES: (query: string) => query,
    SEARCH_SERVICES_SUCCESS: servicesResponse => servicesResponse,
    SEARCH_SERVICES_FAILURE: (error: any) => error,
    RESET_SERVICES_STATE: () => {},
    RESET_SERVICES_ITEMS: () => {},
    APPLY_SERVICE_FILTERS: () => {},
    RESET_SERVICE_NOW_TAB: () => {},
    GET_SERVICE_DETAILS: (payload: any) => payload,
    GET_SERVICE_DETAILS_SUCCESS: (payload: any) => payload,
    GET_SERVICE_DETAILS_FAILURE: (error: any) => error,
});
