export const downloadFileErrorMessage = (exceptionType: number) => {
    switch (exceptionType) {
        case 0:
            return `The file can't be downloaded. Please, try again later.`;
        case 1:
            return 'Protection for provided file type is not supported. Allowed file types are .txt, .xml, .jpg, .jpeg, .png, .tif, .tiff, .bmp, .gif, .giff, .jpe, .jfif, .jif, .jfi, .msg, .doc, .docm, .docx, .dot, .dotm, .dotx, .potm, .potx, .pps, .ppsm, .ppsx, .ppt, .pptm, .pptx, .vsdm, .vsdx, .vssm, .vssx, .vstm, .vstx, .xla, .xlam, .xls, .xlsb, .xlt, .xlsm, .xlsx, .xltm, .xltx, .xps, .pdf';
        default:
            return 'An error occurred during the file processing. Try to download the file later.';
    }
};
