import React, {FC} from 'react';
import classnames from 'classnames';

import {CardButtonGroupProps} from './CardButtonGroup.types';
import styles from './CardButtonGroup.module.scss';

export const CardButtonGroup: FC<CardButtonGroupProps> = ({
    rootProps = {},
    buttons,
    activeIds,
    onSelect,
}) => {
    const rootClasses = classnames(styles.root, rootProps.className);

    return (
        <div {...rootProps} className={rootClasses}>
            {buttons.map(btn => (
                <button
                    key={btn.id}
                    className={classnames(styles.btn, {
                        [styles.active]: activeIds.includes(btn.id),
                    })}
                    onClick={() => onSelect(btn.id)}
                >
                    {btn.title}
                </button>
            ))}
        </div>
    );
};
