import React, {FC, HTMLAttributes} from 'react';
import classnames from 'classnames';

import styles from './InfoCard.module.scss';

export const InfoCard: FC<{
    rootProps?: HTMLAttributes<HTMLDivElement>;
    containerProps?: HTMLAttributes<HTMLDivElement>;
}> = ({rootProps = {}, containerProps = {}, children}) => {
    const rootClassName = classnames(styles.infoCard, rootProps.className);
    const containerClassName = classnames(styles.infoCardWrapper, containerProps.className);

    return (
        <div {...rootProps} className={rootClassName}>
            <div {...containerProps} className={containerClassName}>
                {children}
            </div>
        </div>
    );
};
