import React from 'react';
import {RectSkeleton} from 'app/views/components/skeleton';
import styles from './newsListItemSkeleton.module.scss';

export const NewsListItemSkeleton = () => (
    <div className={styles.item}>
        <RectSkeleton width={40} height={40} />
        <div className={styles.info}>
            <RectSkeleton width={144} />
            <RectSkeleton width={80} />
            <RectSkeleton width={200} />
        </div>
    </div>
);
