import {createActions} from 'redux-actions';

export const {
    searchNews,
    searchNewsSuccess,
    searchNewsFailure,
    resetNewsState,
    resetNewsItems,
    resetNewsCurrentItem,
    resetNewsTab,
    applyNewsFilters,
    getNewsDetails,
    getNewsDetailsSuccess,
    getNewsDetailsFailure,
} = createActions({
    SEARCH_NEWS: (query: string) => query,
    SEARCH_NEWS_SUCCESS: (data: {news: any; filters: any}) => data,
    SEARCH_NEWS_FAILURE: (error: any) => error,
    RESET_NEWS_STATE: () => {},
    RESET_NEWS_ITEMS: () => {},
    RESET_NEWS_CURRENT_ITEM: () => {},
    RESET_NEWS_TAB: () => {},
    APPLY_NEWS_FILTERS: () => {},
    GET_NEWS_DETAILS: (id: string) => id,
    GET_NEWS_DETAILS_SUCCESS: (data: string) => data,
    GET_NEWS_DETAILS_FAILURE: (error: any) => error,
});
