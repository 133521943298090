import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {DetailsList} from '../detailsList';
import {CommonDetails} from '../commonDetails';

import styles from '../../servicePreviewPanel.module.scss';
import {PriorityLabel} from '../../../label/label';
import {userInfoRenderer} from '../userInfoRenderer';

export const Incident = ({data}: any) => {
    const {t} = useLocalizationContext();

    return (
        <>
            <div className={styles.twoColumnContainer}>
                <DetailsList
                    data={data}
                    fields={[
                        'number',
                        {
                            name: 'priority',
                            renderer: (value: string) =>
                                value ? (
                                    <PriorityLabel>{value}</PriorityLabel>
                                ) : (
                                    t('serviceNow.preview.NA')
                                ),
                        },
                    ]}
                />
                <DetailsList
                    data={data}
                    fields={[
                        {
                            name: 'reportedBy',
                            renderer: userInfoRenderer,
                        },
                        {
                            name: 'reportedOnBehalfOf',
                            renderer: userInfoRenderer,
                        },
                    ]}
                />
            </div>
            <hr />
            <div className={styles.twoColumnContainer}>
                <CommonDetails data={data} />
                <DetailsList
                    data={data}
                    fields={[
                        {
                            name: 'assignedTo',
                            renderer: userInfoRenderer,
                        },
                        'businessService',
                        'location',
                        'contactType',
                        'cause',
                    ]}
                />
            </div>
        </>
    );
};
