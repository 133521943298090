import {ISendNotificationRequest} from './searchAPI.history.types';
import {EntityType} from 'app/contexts';
import {fetchData} from 'app/utils';
import {HistoryRoutes} from './routes.history';

export abstract class SearchAPICallerHistory {
    public static async sendNotification(request: ISendNotificationRequest, entity: EntityType) {
        const notification = request.data;
        const options = request.options;
        return fetchData(
            HistoryRoutes.history(options.url)(entity),
            options.accessToken,
            'POST',
            notification,
            false,
            () => Promise.resolve(true)
        );
    }
}
