import {IFilterRefiner} from '../filterPanel.types';

export enum FilterSearchPosition {
    Top,
    Bottom,
}

export interface IFilterSearchProps {
    changeFilterIsCheckedValue: (refinementName: string) => void;
    getFilterIsCheckedValue: (filterName: string) => boolean;
    filter: IFilterRefiner;
    visibleFiltersBeforeSearch: number;
}
