import {fetchData} from 'app/utils';

const routes = {
    lightApps: (origin: string) => `${origin}/api/lightapps/v0`,
    lightAppsByCategories: (origin: string) => `${origin}/api/lightapps/v0/allowed`,
    myProfile: (origin: string) => `${origin}/api/proxy/v0/profile/proxy/graph/me`,
};

export abstract class SearchAPICaller {
    //#region LIGHT_APPS
    public static async apiGetLightApps(options: any) {
        return fetchData(
            routes.lightAppsByCategories(options.apiURL),
            options.accessToken,
            'GET',
            null,
            null,
            null
        );
    }

    //#endregion LIGHT_APPS

    //#region MY_PROFILE
    public static async apiGetMyProfile(options: any) {
        return fetchData(
            routes.myProfile(options.apiURL),
            options.accessToken,
            'GET',
            null,
            null,
            null
        );
    }

    //#endregion MY_PROFILE
}
