import React, {FC} from 'react';
import {RectSkeleton} from 'app/views/components/skeleton';
import {IPortalSkeletonProps} from './portal.types';
import styles from './styles/portalContent.module.scss';

export const PortalSkeleton: FC<IPortalSkeletonProps> = ({children}) => {
    return (
        <>
            <div className={styles.header}>
                <RectSkeleton width={143} />
                <RectSkeleton width={74} />
            </div>
            {children}
        </>
    );
};
