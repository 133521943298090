import React from 'react';
import {AppMode, IAppContext} from './appContext.types';
import parse, {IInitParams} from './appContext.parser';
import {container, DIContainerConfig, IDIContainer, IoC, Scope} from 'app/ioc';
import {ISearchService, SearchService} from 'app/infrastructure/search';
import {ISearchServiceDocuments, SearchServiceDocuments} from 'app/infrastructure/search/documents';
import {ISearchTrainings, SearchServiceELearning} from 'app/infrastructure/search/eLearning';
import {ISearchServiceMails, SearchServiceMails} from 'app/infrastructure/search/mails';
import {
    ISearchServiceDistributions,
    SearchServiceDistributions,
} from 'app/infrastructure/search/distributions';
import {ISearchServiceEmployee, SearchServiceEmployee} from 'app/infrastructure/search/employee';
import {
    ISearchServiceCorrespondence,
    SearchServiceCorrespondence,
} from 'app/infrastructure/search/correspondence';
import {ISearchServiceNews, SearchServiceNews} from 'app/infrastructure/search/news';
import {ISearchServiceTenders, SearchServiceTenders} from 'app/infrastructure/search/tenders';
import {ISearchServiceProjects, SearchServiceProjects} from 'app/infrastructure/search/projects';
import {ISearchServiceSuppliers, SearchServiceSuppliers} from 'app/infrastructure/search/suppliers';
import {ISearchServiceCustomers, SearchServiceCustomers} from 'app/infrastructure/search/customers';
import {ISearchServiceServices, SearchServiceServices} from 'app/infrastructure/search/services';
import {ISuggestionsService, SuggestionsService} from 'app/infrastructure/suggestions';
import {HistoryService, IHistoryService} from 'app/infrastructure/history';
import {AuthProvider, DummyAuthProvider, IAuthProvider} from 'app/infrastructure/auth';
import {ConsoleLogger, ILogger} from 'app/infrastructure/logging';

const initDependencies = (initParams: IInitParams) => {
    const config = new DIContainerConfig((container: IDIContainer) => {
        container.bind<ISearchService>(IoC.SearchService, SearchService, Scope.Singleton);
        container.bind<ISearchTrainings>(
            IoC.SearchServiceELearning,
            SearchServiceELearning,
            Scope.Singleton
        );
        container.bind<ISearchServiceDocuments>(
            IoC.SearchServiceDocuments,
            SearchServiceDocuments,
            Scope.Singleton
        );
        container.bind<ISearchServiceMails>(
            IoC.SearchServiceMails,
            SearchServiceMails,
            Scope.Singleton
        );
        container.bind<ISearchServiceCorrespondence>(
            IoC.SearchServiceCorrespondence,
            SearchServiceCorrespondence,
            Scope.Singleton
        );
        container.bind<ISearchServiceDistributions>(
            IoC.SearchServiceDistributions,
            SearchServiceDistributions,
            Scope.Singleton
        );
        container.bind<ISearchServiceEmployee>(
            IoC.SearchServiceEmployee,
            SearchServiceEmployee,
            Scope.Singleton
        );
        container.bind<ISearchServiceNews>(
            IoC.SearchServiceNews,
            SearchServiceNews,
            Scope.Singleton
        );
        container.bind<ISearchServiceTenders>(
            IoC.SearchServiceTenders,
            SearchServiceTenders,
            Scope.Singleton
        );
        container.bind<ISearchServiceProjects>(
            IoC.SearchServiceProjects,
            SearchServiceProjects,
            Scope.Singleton
        );
        container.bind<ISearchServiceSuppliers>(
            IoC.SearchServiceSuppliers,
            SearchServiceSuppliers,
            Scope.Singleton
        );
        container.bind<ISearchServiceCustomers>(
            IoC.SearchServiceCustomers,
            SearchServiceCustomers,
            Scope.Singleton
        );
        container.bind<ISearchServiceServices>(
            IoC.SearchServiceServices,
            SearchServiceServices,
            Scope.Singleton
        );
        container.bind<ISuggestionsService>(
            IoC.SuggestionsService,
            SuggestionsService,
            Scope.Singleton
        );
        if (initParams.mode !== AppMode.FullScreen) {
            container.bindToValue<IAuthProvider>(
                IoC.AuthProvider,
                new DummyAuthProvider(initParams.token!, new ConsoleLogger())
            );
        } else {
            container.bind<IAuthProvider>(IoC.AuthProvider, AuthProvider, Scope.Singleton);
        }
        container.bind<ILogger>(IoC.Logger, ConsoleLogger, Scope.Singleton);
        container.bind<IHistoryService>(IoC.HistoryService, HistoryService, Scope.Singleton);
    });

    container.init(config);
};

export const AppContext = React.createContext<IAppContext>({
    mode: AppMode.FullScreen,
    mobileParams: {
        mobile: null,
    },
    updateToken: () => {},
});

export const Provider = (props: any) => {
    const initParams = parse(window.location.search);
    const context = {
        ...initParams,
        updateToken: (token: string, reloadCallback: () => void) => {
            const _DummyAuthProvider = container.get<IAuthProvider>(IoC.AuthProvider);

            _DummyAuthProvider.setAccessToken(token);
            reloadCallback();
        },
    } as IAppContext;

    initDependencies(initParams);

    return <AppContext.Provider value={context}>{props?.children}</AppContext.Provider>;
};
