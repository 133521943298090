import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useLocalizationContext} from 'app/hooks';
import styles from './filterSearch.module.scss';
import {FilterSearchEntry} from '../filterSearchEntry';
import {FilterSearchPosition, IFilterSearchProps} from './filterSearch.types';
import classNames from 'classnames';

const searchHeightPx = 200;

export const FilterSearch = ({
    getFilterIsCheckedValue,
    changeFilterIsCheckedValue,
    filter,
    visibleFiltersBeforeSearch,
}: IFilterSearchProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [position, setPosition] = useState<FilterSearchPosition>(FilterSearchPosition.Top);
    const {t} = useLocalizationContext();
    const componentRef = useRef<null | HTMLDivElement>(null);
    const inputRef = useRef<null | HTMLInputElement>(null);

    const entries = filter.Entries.slice(visibleFiltersBeforeSearch).filter(
        ({RefinementName}) => !search || RefinementName.toLowerCase().includes(search.toLowerCase())
    );

    const focusInput = () => inputRef.current?.focus();

    const onMenuButtonClick = () => {
        const {bottom} = componentRef.current!.getBoundingClientRect();
        const openPosition =
            document.documentElement.clientHeight - bottom > searchHeightPx
                ? FilterSearchPosition.Bottom
                : FilterSearchPosition.Top;

        setPosition(openPosition);
        setIsOpen(true);
    };

    const onDocumentClick = useCallback(
        event => {
            const isTargetInside: boolean = Boolean(componentRef.current!.contains(event.target));

            if (isOpen && !isTargetInside) {
                setIsOpen(false);
            }
        },
        [isOpen]
    );

    const onClearSearchButtonClick = () => {
        setSearch('');
    };

    const onFilterChange = (value: string) => {
        changeFilterIsCheckedValue(value);
    };

    useEffect(() => {
        document.addEventListener('click', onDocumentClick);
        return () => {
            document.removeEventListener('click', onDocumentClick);
        };
    }, [onDocumentClick]);

    useLayoutEffect(() => {
        if (isOpen) {
            focusInput();
        }
    }, [isOpen]);

    const optionsMenuClasses = classNames(
        {
            [styles.optionsMenuTop]: position === FilterSearchPosition.Top,
            [styles.optionsMenuBottom]: position === FilterSearchPosition.Bottom,
        },
        styles.optionsMenu
    );

    return (
        <div className={styles.search} ref={componentRef}>
            {isOpen ? (
                <>
                    <span className={styles.searchInputIcon} />
                    <input
                        type="text"
                        className={styles.searchInput}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        ref={inputRef}
                    />
                    <button
                        className={styles.searchClearButton}
                        onClick={onClearSearchButtonClick}
                    />
                    <ul className={optionsMenuClasses}>
                        {entries.map((entry, index) => (
                            <FilterSearchEntry
                                key={index}
                                entry={entry}
                                onChange={onFilterChange}
                                getFilterIsCheckedValue={getFilterIsCheckedValue}
                            />
                        ))}
                    </ul>
                </>
            ) : (
                <button className={styles.searchMenuButton} onClick={onMenuButtonClick}>
                    <span className={styles.searchMenuButtonIcon} />
                    <span className={styles.searchMenuButtonText}>
                        {t('components.filterSearch.searchButton')}
                    </span>
                </button>
            )}
        </div>
    );
};
