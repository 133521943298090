import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {TextAvatar} from 'app/views/components/textAvatar';
import {IEmail} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';
import {useUserAvatar} from 'app/hooks/useUserAvatar';
import {getDate} from '../../../../utils';

export const Email: FC<ISuggestionComponent<IEmail>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {
        id,
        name,
        trailer,
        subject,
        searchString,
        historyDate,
        emailAddress,
        hasAttachments,
        date,
    } = item;
    const avatar = useUserAvatar(emailAddress);

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_email, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <TextAvatar title={name} avatarProps={{className: styles.mailIcon, src: avatar}} />
            <div className={styles.info}>
                <div className={styles.title}>{name}</div>
                <div className={`${styles.subTitle} ${styles.darkSubTitle}`}>{trailer}</div>
                <div className={styles.subTitle}>{subject}</div>
            </div>
            <div className={styles.subInfo}>
                {!!date && <div className={styles.date}>{getDate(date)}</div>}
                {hasAttachments && <div className={styles.hasAttachments} />}
            </div>
        </div>
    );
};
