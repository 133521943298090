import {RootState} from 'app/state';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {ISearchNewsOptions} from 'app/api/searchAPI/news/searchAPICaller.news.types';
import {NewsFiltersOptions} from 'app/containers/news/news.types';

export const getSearchNewsOptions = (
    keyword: string,
    state: RootState,
    filters: IFilterRefiner[]
): ISearchNewsOptions => {
    const pagesize = DEFAULT_PAGE_SIZE;
    const nextPage = Math.floor((state.news.items.length + pagesize) / pagesize);

    const filterParams: Partial<ISearchNewsOptions> = {};

    const dateFilter = filters.find(f => f.Name === NewsFiltersOptions.Date);
    if (dateFilter) {
        const [dateFromEntrie, dateToEntrie] = dateFilter.Entries;
        if (dateFromEntrie.value) filterParams.dateFrom = dateFromEntrie.value;
        if (dateToEntrie.value) filterParams.dateTo = dateToEntrie.value;
    }

    const companyFilter = filters.find(f => f.Name === NewsFiltersOptions.Company);
    if (companyFilter) {
        const selectedCompanyIds: string[] = companyFilter.Entries.filter(
            company => company.isChecked === true
        ).map(company => company.RefinementValue);

        if (selectedCompanyIds.length > 0) filterParams.companyIds = selectedCompanyIds;
    }

    return {
        keyword,
        pagesize,
        pageNumber: nextPage,
        ...filterParams,
    };
};
