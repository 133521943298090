import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {DetailsList} from '../detailsList';

import styles from '../../servicePreviewPanel.module.scss';

export const KnowledgeArticle = ({data}: any) => {
    const {t} = useLocalizationContext();
    return (
        <div className={styles.twoColumnContainer}>
            <DetailsList
                data={data}
                fields={[
                    'number',
                    'category',
                    {
                        name: 'link',
                        renderer: (value: string) =>
                            value ? (
                                <a
                                    className={styles.link}
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {value}
                                </a>
                            ) : (
                                t('NA')
                            ),
                    },
                ]}
            />
            <DetailsList data={data} fields={['author', 'knowledgeBase']} />
        </div>
    );
};
