import {all, call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {
    searchHistory as searchHistoryAction,
    searchHistoryFailure,
    searchHistorySuccess,
    searchSuggestions as searchSuggestionsAction,
    searchSuggestionsFailure,
    searchSuggestionsSuccess,
    searchSuggestionsWithLightApps as searchSuggestionsWithLightAppsAction,
} from './suggestions.actions';
import {getLightApps} from '../lightApps/lightApps.sagas';
import {lightAppsListSelector} from '../lightApps/lightApps.selectors';

export function* searchSuggestionsWithLightApps(
    suggestionsArgs: ReturnType<typeof searchSuggestionsAction>
) {
    const {categories, hasErrors} = yield select(lightAppsListSelector);
    if (!categories.length && !hasErrors) {
        yield call(getLightApps);
    }
    yield call(searchSuggestions, suggestionsArgs);
}

export function* searchSuggestions({payload}: ReturnType<typeof searchSuggestionsAction>) {
    const logger = yield getContext('logger');
    try {
        const suggestionsService = yield getContext('suggestionsService');
        const data = yield suggestionsService.get().searchSuggestions(payload);
        yield put(searchSuggestionsSuccess(data));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchSuggestionsFailure({}));
    }
}

export function* searchHistory() {
    const logger = yield getContext('logger');
    try {
        const suggestionsService = yield getContext('suggestionsService');
        const data = yield suggestionsService.get().searchHistory();
        yield put(searchHistorySuccess(data));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchHistoryFailure({}));
    }
}

export function* suggestionsSaga() {
    yield all([yield takeLatest(String(searchSuggestionsAction), searchSuggestions)]);
    yield all([
        yield takeLatest(
            String(searchSuggestionsWithLightAppsAction),
            searchSuggestionsWithLightApps
        ),
    ]);
    yield all([yield takeLatest(String(searchHistoryAction), searchHistory)]);
}
