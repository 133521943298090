import React, {FC} from 'react';
import styles from './previewLayout.module.scss';

export interface INoResultsChartsProps {
    noResultImageUrl: string;
    noResultsTitle: string;
    noResultsDescription: string;
}

export const NoResultsCharts: FC<INoResultsChartsProps> = ({
    noResultImageUrl,
    noResultsTitle,
    noResultsDescription,
}) => {
    return (
        <>
            <img src={noResultImageUrl} alt="No data available" className={styles.image} />
            <div className={styles.promptText}>{noResultsTitle}</div>
            <div className={styles.helperText}>{noResultsDescription}</div>
        </>
    );
};
