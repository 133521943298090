import React from 'react';
import {Rating} from '@material-ui/lab';
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import {withStyles} from '@material-ui/core/styles';

const StyledRating = withStyles({
    root: {
        display: 'flex',
        height: 16,
        alignItems: 'center',
        marginLeft: -2,
    },
    decimal: {
        marginLeft: -4,
        marginRight: -4,
    },
})(Rating);

const svgStyle = {
    fontSize: 28,
};

export const StationRating = ({
    rating = 0,
    className,
    showOnlyFilledStars,
}: {
    rating: number;
    className?: string;
    showOnlyFilledStars?: boolean;
}) => {
    const maxRating = 5;

    return (
        <div className={className}>
            <StyledRating
                name="station-rating"
                value={rating}
                precision={0.5}
                max={maxRating}
                readOnly={true}
                icon={<StarRateRoundedIcon style={svgStyle} />}
                emptyIcon={showOnlyFilledStars ? <svg style={{display: 'none'}} /> : null}
            />
        </div>
    );
};
