import React, {useEffect, useRef} from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import {IIframeViewerProps} from './iframeViewer.types';
import styles from './iframeViewer.module.scss';
import {addAnchorAttributesInHTML} from 'app/utils';

export const IframeViewer = ({
    webLink,
    styleClasses,
    onLoad,
    body,
    onSelect,
}: IIframeViewerProps) => {
    const {t} = useLocalizationContext();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const classes = classNames(styleClasses, styles.iframeViewer);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (body && iframe?.contentDocument) {
            const htmlElement = iframe.contentDocument.body.parentElement as any;

            htmlElement.innerHTML = addAnchorAttributesInHTML(body || '');
            htmlElement.addEventListener('touchstart', onSelect);
            htmlElement.addEventListener('touchmove', onSelect);
        }

        return () => {
            const htmlElement = iframe?.contentDocument?.body.parentElement as any;

            if (htmlElement) {
                htmlElement.removeEventListener('touchstart', onSelect);
                htmlElement.removeEventListener('touchmove', onSelect);
            }
        };
    }, [body, onSelect]);

    return (
        <div
            className={classes}
            onClickCapture={onSelect}
            onTouchStart={onSelect}
            onScrollCapture={onSelect}
        >
            {body ? (
                <iframe
                    title={t('components.iframeViewer.title')}
                    onLoad={onLoad as any}
                    ref={iframeRef}
                />
            ) : (
                <iframe
                    title={t('components.iframeViewer.title')}
                    src={webLink}
                    onLoad={onLoad as any}
                />
            )}
        </div>
    );
};
