import React, {useState} from 'react';
import classNames from 'classnames';
import {PieChart} from '../charts/pieChart';
import {BarChart} from '../charts/barChart';
import {useLocalizationContext} from 'app/hooks';
import customerStyles from '../../customersPreviewPanel.module.scss';
import {TabButtons} from '../tabButtons';
import {m$Formatter} from 'app/utils';
import styles from '../../customersPreviewPanel.module.scss';

export const SalesShares = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();

    const {
        details: {productGroupShares = [], customers = []},
    } = data;

    const productsChartData = productGroupShares.map(
        ({materialGroupName, amount}: {materialGroupName: string; amount: number}) => ({
            label: materialGroupName,
            value: amount,
        })
    );

    const customersChartData = customers.map(
        ({customerName, amount}: {customerName: string; amount: number}) => ({
            label: customerName,
            value: amount,
        })
    );

    const tabs = [
        {
            title: t('customers.preview.products'),
            name: 'products',
            content: (
                <div className={styles.containerBody}>
                    <PieChart data={productsChartData} />
                </div>
            ),
        },
        {
            title: t('customers.preview.customers'),
            name: 'totalSales',
            content: <BarChart data={customersChartData} formatter={m$Formatter} />,
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);
    return (
        <div className={classNames(className)}>
            <div className={customerStyles.containerHeader}>
                <h3>{t('customers.preview.salesValueDetails')}</h3>
                <TabButtons
                    tabs={tabs.map(tab => ({
                        ...tab,
                        active: selectedTab === tab.name,
                    }))}
                    onSelect={setSelectedTab}
                />
            </div>
            {tabs.find(({name}) => name === selectedTab)?.content}
        </div>
    );
};
