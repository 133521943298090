import React from 'react';
import classNames from 'classnames';
import {IPathArrayProps, IItemProps} from './pathArray.types';
import styles from './pathArray.module.scss';

const SpanItem = ({item, index, styleClasses}: IItemProps) => {
    const fullPathClasses = classNames(styles.fullPath, styleClasses?.fullPath);
    const pathChevronClasses = classNames(styles.pathChevron, styleClasses?.pathChevron);
    const pathClasses = classNames(styles.path, styleClasses?.path);

    if (index > 0) {
        return (
            <span className={fullPathClasses}>
                <span className={pathChevronClasses}>&rsaquo;</span>
                <span className={pathClasses}>{item}</span>
            </span>
        );
    }
    return <span className={pathClasses}>{item}</span>;
};

const PathArray = ({fullPath, pathArray, styleClasses}: IPathArrayProps) => {
    if (!pathArray.length) return null;

    const pathClasses = classNames(styles.pathArray, styleClasses?.pathArrayClasses);
    const pathSpanItemClasses = styleClasses?.pathSpanItemClasses;

    return (
        <div className={pathClasses}>
            {pathArray.map((item, index) => (
                <SpanItem
                    key={index}
                    index={index}
                    item={item}
                    styleClasses={pathSpanItemClasses}
                />
            ))}
        </div>
    );
};

export {PathArray};
