import type {IGMapInitalize} from '../distributionMay.types';
import {Map} from './index';
import {FindByAddress} from './findByAddress';
import {FindByPlaceId} from './findByPlaceId';

export const GMapInitalize = (
    mapRef: {current: HTMLDivElement},
    errorHandler: (data: any) => void
): null | IGMapInitalize => {
    if (!mapRef) return null;

    const map = Map(mapRef, errorHandler);

    return {
        map,
        codeAddress: FindByAddress,
        placeIdAddress: FindByPlaceId,
    };
};
