import React, {MouseEvent, useCallback, useState} from 'react';
import {IMailInfoProps, IMailRecipient} from './mailInfo.types';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import {useMediaQuery} from '@material-ui/core';

import {useAppContext, useChannelContext, useLocalizationContext} from 'app/hooks';
import {MailAttachments} from 'app/views/mail/components/mailAttachments';
import {format, parseISO} from 'date-fns';
import {IMailAttachment} from 'app/state/mail/mail.types';
import {OutlookLink} from 'app/views/mail/components/outlookLink';

import styles from './mailInfo.module.scss';
import {TextAvatar} from 'app/views/components/textAvatar';
import {AppMode} from 'app/contexts';
import scssVariables from 'app/variables.module.scss';
import {useUserAvatar} from 'app/hooks/useUserAvatar';

export const MailInfo = ({
    mail,
    downloadAttachment,
    showDocumentPreview,
    onSelect,
    shouldShowAttachments,
    shouldShowSubject,
}: IMailInfoProps) => {
    const {
        attachments,
        subject,
        // avatarSrc,
        dateSent,
        senderName,
        replyTo,
        ccRecipients,
        webLink,
        address,
    } = mail;
    const {t} = useLocalizationContext();
    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`, {noSsr: true});
    const {mode} = useAppContext();
    const {onOpenLink} = useChannelContext();
    const [isToShown, setIsToShown] = useState<boolean>(false);
    const [isCcShown, setIsCcShown] = useState<boolean>(false);
    const avatar = useUserAvatar(address);

    const shownContactsCount = isSmall ? 2 : 5;

    const toggleIsToShown = () => {
        setIsToShown(!isToShown);
    };
    const toggleIsCcShown = () => {
        setIsCcShown(!isCcShown);
    };

    const concatRecipients = useCallback(
        (recipients: IMailRecipient[], showAll: boolean) =>
            recipients
                .map(({emailAddress: {name, address}}) =>
                    t('mail.mailPreviewPanel.recipient', {name: name || address || ''})
                )
                .slice(0, showAll ? recipients.length : shownContactsCount)
                .join('')
                .slice(0, -2),
        [t, shownContactsCount]
    );

    const to = !!replyTo?.length && concatRecipients(replyTo, isToShown);
    const cc = !!ccRecipients?.length && concatRecipients(ccRecipients, isCcShown);
    const toTitle = !!replyTo?.length && concatRecipients(replyTo, true);
    const ccTitle = !!ccRecipients?.length && concatRecipients(ccRecipients, true);

    const handleOutlookLinkOnWebView = useCallback(
        (e: MouseEvent): void => {
            e.preventDefault();
            onOpenLink({absoluteURL: webLink});
        },
        [onOpenLink, webLink]
    );

    const setEmailLink = (content: JSX.Element = <div></div>) => (
        <div className={styles.linkWrapper}>
            {content}
            <div className={styles.link}>
                {mode !== AppMode.WebView ? (
                    <OutlookLink linkProps={{href: webLink}}>
                        {t('mail.mailPreviewPanel.singlePreview')}
                    </OutlookLink>
                ) : (
                    <OutlookLink
                        linkProps={{href: '#', onClick: handleOutlookLinkOnWebView}}
                        linkTextHidden={true}
                    />
                )}
            </div>
        </div>
    );

    const getToSectionContent = () => (
        <div className={styles.toSection}>
            <span className={styles.toHeading}>{t('mail.mailPreviewPanel.to')}</span>
            <span className={styles.to} title={toTitle || ''}>
                {to}
                {replyTo && replyTo.length > shownContactsCount && (
                    <div
                        className={classNames({
                            [styles.more]: !isToShown,
                            [styles.less]: isToShown,
                        })}
                        onClick={toggleIsToShown}
                    />
                )}
            </span>
        </div>
    );
    const getCcSectionContent = () => (
        <div className={styles.ccSection}>
            <span className={styles.ccHeading}>{t('mail.mailPreviewPanel.cc')}</span>
            <span className={styles.cc} title={ccTitle || ''}>
                {cc}
                {ccRecipients && ccRecipients.length > shownContactsCount && (
                    <div
                        className={classNames({
                            [styles.more]: !isCcShown,
                            [styles.less]: isCcShown,
                        })}
                        onClick={toggleIsCcShown}
                    />
                )}
            </span>
        </div>
    );

    return (
        <>
            {subject && (
                <Collapse
                    in={shouldShowSubject}
                    classes={{
                        container: styles.collapsedSubject,
                    }}
                >
                    <div className={styles.subject}>
                        <span className={styles.subjectText} title={subject}>
                            {subject}
                        </span>
                    </div>
                </Collapse>
            )}
            <div className={styles.info} onClick={onSelect}>
                <div className={styles.mainInfo}>
                    <TextAvatar
                        title={senderName}
                        avatarProps={{src: avatar, className: styles.mailInfoAvatar}}
                    />
                    <div className={styles.allInfo}>
                        <div className={styles.mailInfo}>
                            <span className={styles.senderName}>{senderName}</span>
                            <span className={styles.dateSent}>
                                {format(parseISO(dateSent || ''), 'dd.MM.Y')}
                            </span>
                        </div>
                        {to && setEmailLink(getToSectionContent())}
                        {cc && (to ? getCcSectionContent() : setEmailLink(getCcSectionContent()))}
                        {!cc && !to && setEmailLink()}
                    </div>
                </div>
                {Boolean(attachments?.length) && (
                    <Collapse
                        in={shouldShowAttachments}
                        collapsedHeight={'1px'}
                        classes={{container: styles.collapsedInfo}}
                    >
                        <MailAttachments
                            attachments={attachments as IMailAttachment[]}
                            onDownloadClick={downloadAttachment}
                            onViewClick={showDocumentPreview}
                        />
                    </Collapse>
                )}
            </div>
        </>
    );
};
