import React, {FC} from 'react';
import {DistributionListItemSkeleton} from '../distributionListItemSkeleton/distributionListItemSkeleton';
import {DistributionListItem} from '../distributionListItem/distributionListItem';
import {List} from 'app/views/components/list';
import {IDistributionListProps} from './distributionList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const DistributionList: FC<IDistributionListProps> = ({
    items,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: IDistributionListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    key="skeleton"
                    itemComponentRender={DistributionListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    key="list"
                    itemComponentRender={DistributionListItem}
                    itemsData={items}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
