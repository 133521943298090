import React, {FC, MouseEvent} from 'react';
import {useLocalizationContext, useCurrency} from 'app/hooks';
import {Currency} from 'app/contexts';
import {getDocumentIcon, toMoneyFormat} from 'app/utils';

import {DefaultPreviewPanelProps} from './previewPanel.types';
import styles from './previewPanel.module.scss';

import {useCompanyFilter} from 'app/containers/suppliers';
import {Select} from 'app/views/components';

import {Commitments} from './components/Commitments';
import {Map} from './components/Map';
import {Table} from 'app/views/components/table';
import {useMediaQuery} from '@material-ui/core';
import scssVariables from 'app/variables.module.scss';
import {useShareFilter} from 'app/containers/suppliers/detailsShareFilterContext';
import {routeParams, routes} from 'app/routes';
import {useDispatch, useSelector} from 'react-redux';
import {locationSelector} from 'app/state/router';
import {parse, stringify} from 'querystring';
import {useCallback} from 'react';
import {push} from 'connected-react-router';
import {DocumentExtension} from '../../../../utils/getDocumentIcon';

export const CountryPreview: FC<DefaultPreviewPanelProps> = ({
    data: {details},
    id,
    onPreviewDocument,
}) => {
    const {fromYear, toYear} = details;
    const {t} = useLocalizationContext();
    const [companies, toggleCompany] = useCompanyFilter();
    const [shares, toggleShare] = useShareFilter();
    const [selectedCurrency, setSelectedCurrency] = useCurrency();
    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`, {noSsr: true});
    const location = useSelector(locationSelector);
    const dispatch = useDispatch();

    const estCost =
        selectedCurrency === 'usd'
            ? details.softCommitment?.estCost || 0
            : details.softCommitment?.estCostAed || 0;
    const totalCommit =
        selectedCurrency === 'usd'
            ? details.totalCommitment?.totalCommit || 0
            : details.totalCommitment?.totalCommitAed || 0;
    const invAmountSpent =
        selectedCurrency === 'usd'
            ? details.totalSpent?.invAmountSpent || 0
            : details.totalSpent?.invAmountSpentAed || 0;
    const commitments = [
        {
            name: t('suppliers.previewPanel.commitments.soft'),
            value: toMoneyFormat(estCost),
        },
        {
            name: t('suppliers.previewPanel.commitments.total'),
            value: toMoneyFormat(totalCommit),
        },
        {
            name: t('suppliers.previewPanel.commitments.totalSpend'),
            value: toMoneyFormat(invAmountSpent),
        },
    ];
    const selectedCompany = companies.find(i => i.active);
    const currencySelectItems: Array<{name: string; value: Currency}> = [
        {name: 'USD', value: 'usd'},
        {name: 'AED', value: 'aed'},
    ];

    const r = (value: any) => (value ? value : t('suppliers.previewPanel.NA'));

    const InfoTable = () => (
        <div>
            <h2 className={styles.contentTitle}>{t('suppliers.previewPanel.countryInfo.title')}</h2>
            <div className={styles.infoTable}>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.capital')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.countryCapital)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.region')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.countryRegion)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.subregion')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.countrySubregion)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.population')}
                </span>
                <span className={styles.infoTableValue}>
                    {toMoneyFormat(details?.countryInfo?.countryPopulation)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.language')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.firstCountryLanguage)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.timezone')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.countryTimezone)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.currency')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.countryCurrencyName)}
                </span>
                <span className={styles.infoTableTitle}>
                    {t('suppliers.previewPanel.countryInfo.block')}
                </span>
                <span className={styles.infoTableValue}>
                    {r(details?.countryInfo?.firstCountryBlockName)}
                </span>
            </div>
        </div>
    );

    const suppliersTableTitles = [
        t('suppliers.previewPanel.suppliersTable.col1'),
        t('suppliers.previewPanel.suppliersTable.col2'),
        t('suppliers.previewPanel.suppliersTable.col3'),
        t('suppliers.previewPanel.suppliersTable.col4'),
        t('suppliers.previewPanel.suppliersTable.col5'),
        t('suppliers.previewPanel.suppliersTable.col6'),
    ];

    const getSupplierLink = (id: string): string => {
        const query = {
            ...parse(location.search.substring(1)),
            [routeParams.QUERY]: id,
            [routeParams.SELECTED_ID]: id,
        };
        return routes.search_suppliers + '?' + stringify(query);
    };
    const handleSupplierPush = useCallback(
        (e: MouseEvent, link: string): void => {
            e.preventDefault();
            dispatch(push(link));
        },
        [dispatch]
    );
    const suppliersTableValues = (details?.supplierCountryItems || []).map((item: any) => [
        item.supplierId || '-',
        item.supplierName ? (
            <a
                className={styles.supplierLink}
                href={getSupplierLink(item.supplierId)}
                onClick={e => handleSupplierPush(e, getSupplierLink(item.supplierId))}
            >
                {item.supplierName}
            </a>
        ) : (
            '-'
        ),
        item.supplierFunction || '-',
        selectedCurrency === 'usd'
            ? `$ ${toMoneyFormat(item.softCommitment)}`
            : `${toMoneyFormat(item.softCommitmentAed)} AED`,
        selectedCurrency === 'usd'
            ? `$ ${toMoneyFormat(item.totalCommitment)}`
            : `${toMoneyFormat(item.totalCommitmentAed)} AED`,
        selectedCurrency === 'usd'
            ? `$ ${toMoneyFormat(item.totalSpent)}`
            : `${toMoneyFormat(item.totalSpentAed)} AED`,
    ]);

    const selectedShare = shares.find(i => i.active);

    return (
        <div>
            <div className={styles.title}>
                <span className={styles.titleName}>
                    <span>
                        {details?.countryInfo?.countryName} -{' '}
                        {details?.countryInfo?.countryLongName}
                    </span>
                    <img
                        onClick={onPreviewDocument}
                        className={styles.docIcon}
                        src={getDocumentIcon(DocumentExtension.PDF)}
                        alt="Doc icon"
                    />
                </span>

                {!!companies.length && (
                    <Select
                        value={selectedCompany?.id}
                        onChange={toggleCompany}
                        items={companies.map(c => ({name: c.title, value: c.id}))}
                    />
                )}
                {!!shares.length && (
                    <Select
                        value={selectedShare?.id}
                        onChange={toggleShare}
                        items={shares.map(c => ({name: c.title, value: c.id}))}
                    />
                )}
                <Select
                    value={selectedCurrency}
                    onChange={setSelectedCurrency}
                    items={currencySelectItems}
                />
            </div>

            <div className={styles.contentWrapper}>
                <Commitments items={commitments} yearFrom={fromYear} yearTo={toYear} />
            </div>

            {isSmall ? (
                <div className={styles.contentWrapper}>
                    <InfoTable />
                    <Map
                        countryIsoCode={details?.countryInfo?.countryShortIso}
                        key={details?.countryInfo?.countryLongName}
                    />
                </div>
            ) : (
                <Map
                    wrapperClassName={styles.contentWrapper}
                    countryIsoCode={details?.countryInfo?.countryShortIso}
                    key={details?.countryInfo?.countryLongName}
                >
                    <div className={`${styles.contentWrapper} ${styles.mapBlockContent}`}>
                        <InfoTable />
                    </div>
                </Map>
            )}
            {suppliersTableValues.length > 0 && (
                <div className={styles.contentWrapper}>
                    <h2 className={styles.contentTitle}>
                        {t('suppliers.previewPanel.suppliersTable.title')}
                    </h2>
                    <Table titles={suppliersTableTitles} values={suppliersTableValues} />
                </div>
            )}
        </div>
    );
};
