import {all, getContext, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    getSuppliersDetails as getSuppliersDetailsAction,
    getSuppliersDetailsFailure,
    getSuppliersDetailsSuccess,
    resetSuppliersDetails,
    resetSuppliersState,
    resetSuppliersTab as resetSuppliersTabAction,
    searchSuppliers as searchSuppliersAction,
    searchSuppliersFailure,
    searchSuppliersSuccess,
    setSuppliersDetailsFilters as setSuppliersDetailsFiltersAction,
} from './suppliers.actions';
import {searchQuerySelector} from '../router';
import {detailsFiltersSelector, filtersSelector} from './suppliers.selectors';
import {isFilterSelected} from 'app/utils/filterUtils';
import {createShareDetailsFilters, mapCompaniesToDetailFilters} from './suppliers.utils';

function* searchSuppliers({payload}: ReturnType<typeof searchSuppliersAction>): any {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');

        let detailsFilters = yield select(detailsFiltersSelector);
        if (detailsFilters.length === 0) {
            const companies = yield searchService.suppliers.get().searchSuppliersCompanies();
            detailsFilters = [mapCompaniesToDetailFilters(companies), createShareDetailsFilters()];
        }

        const suppliers = yield searchService.suppliers.get().searchSuppliers({
            searchString: payload,
        });
        yield put(
            searchSuppliersSuccess({
                suppliers: suppliers.rows,
                filters: [],
                detailsFilters,
            })
        );
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchSuppliersFailure(error));
    }
}

export function* getSuppliersDetails({
    payload: {supplier, companyId, selectedShareId},
}: ReturnType<typeof getSuppliersDetailsAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const details = yield searchService.suppliers
            .get()
            .getSuppliersDetails(supplier, companyId, selectedShareId);
        yield put(getSuppliersDetailsSuccess({supplierId: supplier.id, details}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getSuppliersDetailsFailure(error));
    }
}

function* setSuppliersDetailsFilters(): any {
    const logger = yield getContext('logger');
    try {
        yield put(resetSuppliersDetails());
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetSuppliersTab() {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(filtersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetSuppliersState());
            const query = yield select(searchQuerySelector);
            yield put(searchSuppliersAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* suppliersSaga() {
    yield all([
        yield takeLatest(String(searchSuppliersAction), searchSuppliers),
        yield takeLatest(String(setSuppliersDetailsFiltersAction), setSuppliersDetailsFilters),
        yield takeEvery(String(getSuppliersDetailsAction), getSuppliersDetails),
        yield takeLatest(String(resetSuppliersTabAction), resetSuppliersTab),
    ]);
}
