import React from 'react';
import styles from './loader.module.scss';

export const Loader = () => {
    return (
        <div className={styles.loader}>
            <svg
                className="oh-rotating"
                width="40"
                height="40"
                viewBox="0 0 44 44"
                color="#0047BA"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <linearGradient id="spinner-2of3">
                        <stop offset="0" stopOpacity="0.7" stopColor="currentColor" />
                        <stop offset="100%" stopOpacity="0.45" stopColor="currentColor" />
                    </linearGradient>
                    <linearGradient id="spinner-1of3">
                        <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
                        <stop offset="100%" stopOpacity="0.7" stopColor="currentColor" />
                    </linearGradient>
                    <linearGradient id="spinner-3of3">
                        <stop offset="15%" stopOpacity="0" stopColor="currentColor" />
                        <stop offset="100%" stopOpacity="0.45" stopColor="currentColor" />
                    </linearGradient>
                </defs>

                <g strokeWidth="3">
                    <path stroke="url(#spinner-1of3)" d="M 22 42 A 20 20 90 0 1 2 22" />
                    <path stroke="url(#spinner-2of3)" d=" M 42 22 A 20 20 0 0 1 22 42" />
                    <path stroke="url(#spinner-3of3)" d=" M 22 2 A 20 20 270 0 1 42 22" />
                </g>
            </svg>
        </div>
    );
};
