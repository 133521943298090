import {RootState} from 'app/state/store';

export const suppliersSelector = ({suppliers: {items}}: RootState) => items;
export const areSuppliersLoadingSelector = ({suppliers: {isLoading}}: RootState) => isLoading;
export const areMoreSuppliersAvailableSelector = ({
    suppliers: {areMoreResultsAvailable},
}: RootState) => areMoreResultsAvailable;
export const filtersSelector = ({suppliers: {filters}}: RootState) => filters;
export const detailsFiltersSelector = ({suppliers: {detailsFilters}}: RootState) => detailsFilters;
export const suppliersErrorCodeSelector = ({suppliers: {hasErrorCode}}: RootState) => hasErrorCode;
