import React, {FC} from 'react';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {ColorLabel} from '../../colorLabel/colorLabel';
import {IProject} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import {routes} from 'app/routes';
import classNames from 'classnames';

export const Project: FC<ISuggestionComponent<IProject>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, searchString, projectName, projectCompanyName, projectCode, historyDate} = item;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_projects, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.info}>
                <div className={styles.justifyContentBetween}>
                    <span className={styles.subTitle}>{projectCompanyName}</span>
                    {projectCode && (
                        <ColorLabel color="gray">{projectCode.split(' | ')[0]}</ColorLabel>
                    )}
                </div>
                <div className={styles.title}>{projectName}</div>
            </div>
        </div>
    );
};
