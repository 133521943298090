import React from 'react';
import {useLocalizationContext} from 'app/hooks';

import styles from '../projectsPreviewPanel.module.scss';
import ptStyles from './productionTarget.module.scss';
import {IProjectDetails} from 'app/state/projects/projects.types';
import {DetailsList} from 'app/views/components/detailsList/detailsList';
import {Table} from 'app/views/components/table';
import {ColorLabel, colors} from 'app/views/components/colorLabel/colorLabel';
import {IfDataAvailable} from './ifDataAvailable';

export const ProductionTarget = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {productionTarget},
        isSmall,
    } = props;

    const stateColors: {[key: string]: colors} = {
        'On-Track': 'green',
        Watchlist: 'gray',
        Delayed: 'orange',
    };

    const objectiveStateRender = (state: string) =>
        state ? <ColorLabel color={stateColors[state]}>{state}</ColorLabel> : t('NA');

    return (
        <div className={styles.itemContainer}>
            <h3>{t('projects.preview.productionTarget')}</h3>
            <IfDataAvailable
                isData={!!productionTarget.length}
                name={t('projects.preview.productionTarget')}
            >
                {!isSmall ? (
                    <Table
                        titles={[
                            t('projects.preview.pt.product'),
                            t('projects.preview.pt.targetIncreaseYear'),
                            t('projects.preview.pt.volumeIncrease'),
                            t('projects.preview.pt.objectiveState'),
                        ]}
                        values={productionTarget.map(row => [
                            row.product,
                            row.targetIncreaseYear,
                            row.volumeIncrease,
                            objectiveStateRender(row.objectiveState),
                        ])}
                    />
                ) : (
                    <div>
                        {productionTarget.map((item, index) => (
                            <div className={ptStyles.item} key={index}>
                                <DetailsList
                                    data={item}
                                    fields={[
                                        'product',
                                        'targetIncreaseYear',
                                        'volumeIncrease',
                                        {
                                            name: 'objectiveState',
                                            renderer: objectiveStateRender,
                                        },
                                    ]}
                                    localizationPath={'projects.preview.pt'}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </IfDataAvailable>
        </div>
    );
};
