import React, {useState} from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

import styles from '../projectsPreviewPanel.module.scss';
import risksStyles from './risks.module.scss';
import {getDate} from 'app/utils';

import {IProjectDetails} from 'app/state/projects/projects.types';
import {ColorLabel, colors} from '../../../../components/colorLabel/colorLabel';
import {TabButtons} from './tabButtons';
import {IfDataAvailable} from './ifDataAvailable';

const priorityColors: {[key: string]: colors} = {
    Low: 'green',
    Medium: 'orange',
    High: 'red',
};

const consequencesColors: {[key: string]: colors} = {
    Notable: 'green',
    Minor: 'brightOrange',
    Serious: 'brightRed',
    Major: 'brightRed',
    Catastrophic: 'red',
    Disastrous: 'brown',
};

const typeColors: {[key: string]: colors} = {
    Low: 'green',
    Medium: 'brightOrange',
    'High-Medium': 'orange',
    High: 'red',
};

const typeMatrix: any = {
    Notable: {
        Rare: 'Low',
        Unlikely: 'Low',
        Possible: 'Low',
        Likely: 'Low',
        'Very Likely': 'Medium',
        'Almost Certain': 'Medium',
    },
    Minor: {
        Rare: 'Low',
        Unlikely: 'Low',
        Possible: 'Medium',
        Likely: 'Medium',
        'Very Likely': 'Medium',
        'Almost Certain': 'High-Medium',
    },
    Serious: {
        Rare: 'Low',
        Unlikely: 'Medium',
        Possible: 'Medium',
        Likely: 'High-Medium',
        'Very Likely': 'High-Medium',
        'Almost Certain': 'High',
    },
    Major: {
        Rare: 'Medium',
        Unlikely: 'Medium',
        Possible: 'High-Medium',
        Likely: 'High',
        'Very Likely': 'High',
        'Almost Certain': 'High',
    },
    Catastrophic: {
        Rare: 'Medium',
        Unlikely: 'High-Medium',
        Possible: 'High',
        Likely: 'High',
        'Very Likely': 'High',
        'Almost Certain': 'High',
    },
    Disastrous: {
        Rare: 'High-Medium',
        Unlikely: 'High',
        Possible: 'High',
        Likely: 'High',
        'Very Likely': 'High',
        'Almost Certain': 'High',
    },
};

export const Risks = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {riskInfo},
        isSmall,
    } = props;

    const renderRisk = (risk: any) => {
        const type = typeMatrix[risk.riskConsequences][risk.riskProbability];
        const riskPriority =
            !risk.riskPriority || risk.riskPriority === '-' ? t('NA') : risk.riskPriority;
        return (
            <div key={risk.riskDescription} className={risksStyles.riskItem}>
                <div className={risksStyles.title}>{risk.riskDescription}</div>
                <div className={risksStyles.detailsContainer}>
                    <div className={risksStyles.details}>
                        <div className={risksStyles.label}>
                            {t('projects.preview.indetificaitonDate')}:
                        </div>
                        <div className={risksStyles.value}>{getDate(risk.identificationDate)}</div>
                    </div>
                    <div className={risksStyles.details}>
                        <div className={risksStyles.label}>
                            {risk.riskStatus === 'Open'
                                ? t('projects.preview.plannedCompletionDate')
                                : t('projects.preview.completionDate')}
                            :
                        </div>
                        <div className={risksStyles.value}>
                            {getDate(risk.plannedCompletionDate)}
                        </div>
                    </div>
                </div>
                <div>
                    <ColorLabel color={priorityColors[riskPriority]}>
                        {t('projects.preview.priority')}: {riskPriority}
                    </ColorLabel>
                    <ColorLabel color={'gray'}>
                        {t('projects.preview.probability')}: {risk.riskProbability}
                    </ColorLabel>
                    <ColorLabel color={consequencesColors[risk.riskConsequences]}>
                        {t('projects.preview.consequences')}: {risk.riskConsequences}
                    </ColorLabel>
                    <ColorLabel color={typeColors[type]}>
                        {t('projects.preview.type')}: {type}
                    </ColorLabel>
                </div>
            </div>
        );
    };

    const openRisks = riskInfo
        .filter(({riskStatus}) => riskStatus === 'Open')
        .sort(function (a, b) {
            return b.identificationDate && a.identificationDate
                ? new Date(b.identificationDate).getTime() -
                      new Date(a.identificationDate).getTime()
                : 1;
        });

    const closedRisks = riskInfo
        .filter(({riskStatus}) => riskStatus !== 'Open')
        .sort(function (a, b) {
            return b.plannedCompletionDate && a.plannedCompletionDate
                ? new Date(b.plannedCompletionDate).getTime() -
                      new Date(a.plannedCompletionDate).getTime()
                : 1;
        });

    const tabs = [
        {
            title: t('projects.preview.open'),
            name: 'open',
            content: (
                <IfDataAvailable isData={!!openRisks.length} name={t('projects.preview.openRisks')}>
                    {openRisks.map(renderRisk)}
                </IfDataAvailable>
            ),
        },
        {
            title: t('projects.preview.closed'),
            name: 'closed',
            content: (
                <IfDataAvailable
                    isData={!!closedRisks.length}
                    name={t('projects.preview.closedRisks')}
                >
                    {closedRisks.map(renderRisk)}
                </IfDataAvailable>
            ),
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);

    return (
        <div
            className={classNames(styles.itemContainer, {
                [risksStyles.small]: isSmall,
            })}
        >
            <div className={styles.containerTitle}>
                <h3>{t('projects.preview.projectRisks')}</h3>
                <div className={styles.tabsContainer}>
                    <TabButtons
                        tabs={tabs.map(tab => ({
                            ...tab,
                            active: selectedTab === tab.name,
                        }))}
                        onSelect={setSelectedTab}
                    />
                </div>
            </div>

            {tabs.find(({name}) => name === selectedTab)?.content}
        </div>
    );
};
