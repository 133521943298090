export enum EntityType {
    Employee = 'employee',
    Document = 'document',
    Correspondence = 'correspondence',
    Email = 'email',
    Training = 'training',
    News = 'news',
    Tender = 'tender',
    Projects = 'projects',
    Service = 'service',
    Suppliers = 'suppliers',
    Customers = 'customers',
    Distribution = 'distribution',
}
