import React from 'react';
import {DocViewer} from 'app/views/components/DocViewer/DocViewer';
import styles from './documentPreview.module.scss';
import CannotBePreviewed from 'app/static/images/cannotBePreviewed.svg';
import {useInjection, useLocalizationContext} from 'app/hooks';
import {DocViewerError} from 'app/views/components/DocViewer/DocViewerError';
import classNames from 'classnames';
import {Loader} from 'app/views/components/loader';
import {ISearchServiceDocuments} from 'app/infrastructure/search/documents';
import {IoC} from 'app/ioc';

type PreviewProps = {
    documentURL: string;
    documentSiteName: string;
    documentName: string;
    documentType: string;
    onFullScreenClose: () => void;
    isFullScreen: boolean;
    docLoading: boolean;
    setDocLoading: (p: boolean) => void;
    docError: string | undefined;
    setDocError: (p: string | undefined) => void;
    isSupportedFileType: boolean;
    onDownloadClick: () => void;
};

export const DocumentPreview = ({
    documentURL,
    documentSiteName,
    documentName,
    onFullScreenClose,
    isFullScreen,
    docLoading,
    setDocLoading,
    docError,
    setDocError,
    isSupportedFileType,
    onDownloadClick,
}: PreviewProps) => {
    const {t} = useLocalizationContext();
    const SearchServiceDocuments = useInjection<ISearchServiceDocuments>(
        IoC.SearchServiceDocuments
    );
    const previewUrl = SearchServiceDocuments.previewDocumentLink(
        documentURL,
        documentSiteName
    ) as string;

    return isSupportedFileType && !docError ? (
        <div className={styles.previewContainer}>
            <div
                className={classNames(styles.previewDocContainer, {
                    [styles.fs]: isFullScreen,
                })}
            >
                <DocViewer
                    className={styles.preview}
                    fileName={documentName}
                    // url={
                    //     (getDocumentBlobUrl(documentURL, documentSiteName) as {previewUrl: string})
                    //         .previewUrl
                    // }
                    url={previewUrl}
                    onError={setDocError}
                    onLoad={setDocLoading}
                    onDownload={onDownloadClick}
                    onClose={onFullScreenClose}
                    isFullScreen={isFullScreen}
                />
            </div>
            {docLoading && (
                <div className={styles.loading}>
                    <Loader />
                </div>
            )}
        </div>
    ) : (
        <DocViewerError
            onClose={onFullScreenClose}
            onDownload={onDownloadClick}
            isHideHeader={!isSupportedFileType || !!docError}
            title={
                docError
                    ? t('documents.documentPreview.documentLoadError')
                    : t('documents.documentPreview.documentCannotBePreviewed')
            }
            description={
                docError
                    ? t('documents.documentPreview.pleaseDownloadToView')
                    : t('documents.documentPreview.pleaseDownloadDocument')
            }
            imageSrc={CannotBePreviewed}
            imageClasses={styles.noResultsIcon}
            noResultsContainerClasses={styles.noResultsContainer}
        />
    );
};
