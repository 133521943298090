import {handleActions} from 'redux-actions';
import {IDistributionItem, IDistributionState} from './distribution.types';
import {DistributionsSortOptions} from '../../containers/distribution/distribution.types';

import {
    getDistributionDetails,
    getDistributionDetailsFailure,
    getDistributionDetailsSuccess,
    resetDistributionDetails,
    resetDistributionItems,
    resetDistributionsState,
    searchDistributions,
    searchDistributionsFailure,
    searchDistributionsSuccess,
    setDistributionsSortBy,
} from './distribution.actions';
import {DEFAULT_PAGE_SIZE} from 'app/constants';

const initialState: IDistributionState = {
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    isLoadingDetails: false,
    hasErrors: false,
    hasErrorCode: undefined,
    sortBy: DistributionsSortOptions.nameAZ,
    totalCount: 0,
};

export default handleActions(
    {
        [String(searchDistributions)]: state => ({...state, isLoading: true}),
        [String(searchDistributionsSuccess)]: (
            state,
            {
                payload,
            }: {
                payload: {
                    distributions: IDistributionItem[];
                    totalCount: number;
                };
            }
        ) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload.distributions],
            areMoreResultsAvailable: payload.distributions.length === DEFAULT_PAGE_SIZE,
            totalCount: payload.totalCount,
        }),
        [String(searchDistributionsFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetDistributionsState)]: () => initialState,
        [String(resetDistributionItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
            totalCount: 0,
        }),
        [String(getDistributionDetails)]: state => ({
            ...state,
            hasErrors: false,
            hasErrorCode: undefined,
            isLoading: true,
        }),
        [String(getDistributionDetailsSuccess)]: (
            state,
            {payload: {distributionId, details}}: any
        ) => ({
            ...state,
            items: state.items.map(item => {
                if (item.id === distributionId) return {...item, details};
                return item;
            }),
            isLoading: false,
            hasErrorCode: undefined,
            hasErrors: false,
        }),
        [String(getDistributionDetailsFailure)]: (state, {payload}: any) => {
            return {
                ...state,
                hasErrorCode: payload.responseStatus,
                // hasErrors: payload.hasErrors,
                isLoading: false,
            };
        },
        [String(resetDistributionDetails)]: state => ({
            ...state,
            items: state.items.map(item => ({
                ...item,
                details: null,
            })),
            isLoading: true,
        }),
        [String(setDistributionsSortBy)]: (state, {payload}: any) => ({
            ...state,
            sortBy: payload as DistributionsSortOptions,
        }),
    },
    initialState
);
