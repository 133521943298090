import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';

import {useLocalizationContext, useChannelContext} from 'app/hooks';
import {EntityType} from 'app/contexts';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {
    projectsSelector,
    areProjectsLoadingSelector,
    areMoreProjectsAvailableSelector,
    searchProjects,
    projectFiltersSelector,
    projectsErrorCodeSelector,
    applyProjectsFilters,
    IProjectItem,
    resetProjectsState,
    resetProjectsTab,
    getProjectDetails,
    projectsTotalCount,
    projectsSortSelector,
    setProjectsSortBy,
} from 'app/state/projects';

import {changeSingleFilter} from 'app/state/filter';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {ProjectsListItem} from 'app/views/projects/components/projectsListItem';
import {ProjectsListItemSkeleton} from 'app/views/projects/components/projectsListItemSkeleton';
import {ProjectsPreviewPanel} from 'app/views/projects/components/projectsPreviewPanel/projectsPreviewPanel';
import newsChoose from 'app/static/images/news.svg';
import noResultsNews from 'app/static/images/noResultsNews.svg';
import {ProjectsSortOptions} from './projects.types';
import {ISortOption} from 'app/views/components/sortPanel/sortPanel.types';
import {FilterType} from 'app/views/components/filterPanel/filterPanel.types';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const projectsSortOptionsI18n = {
    [ProjectsSortOptions.none]: 'projects.sort.none',
    [ProjectsSortOptions.NameAZ]: 'projects.sort.NameAZ',
    [ProjectsSortOptions.NameZA]: 'projects.sort.NameZA',
    [ProjectsSortOptions.companyAZ]: 'projects.sort.companyAZ',
    [ProjectsSortOptions.companyZA]: 'projects.sort.companyZA',
    [ProjectsSortOptions.projectStageASC]: 'projects.sort.projectStageASC',
    [ProjectsSortOptions.projectStageDESC]: 'projects.sort.projectStageDESC',
};

const sortList: ISortOption[] = Object.values(ProjectsSortOptions).map(
    (option: ProjectsSortOptions): ISortOption => ({
        name: projectsSortOptionsI18n[option],
        value: option,
    })
);

export const ProjectsContainer = () => {
    const dispatch = useDispatch();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector, shallowEqual);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(projectsSelector, shallowEqual);
    const areProjectsLoading = useSelector(areProjectsLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(areMoreProjectsAvailableSelector, shallowEqual);
    const filters = useSelector(projectFiltersSelector, shallowEqual);
    const totalCount = useSelector(projectsTotalCount);
    const currentSort = useSelector(projectsSortSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(projectsErrorCodeSelector);
    const {t} = useLocalizationContext();

    const selectedItem: IProjectItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetProjectsState());
        dispatch(searchProjects(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetProjectsTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Projects,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!areProjectsLoading && areMoreResultsAvailable) {
            dispatch(searchProjects(searchQuery));
        }
    }, [areMoreResultsAvailable, areProjectsLoading, dispatch, searchQuery]);

    useEffect(() => {
        if (selectedItem && !selectedItem.details) {
            dispatch(getProjectDetails(selectedItem.id));
        }
    }, [dispatch, selectedItem]);

    const onSelect = useCallback(
        (project: IProjectItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: project.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onSortChange = useCallback(
        sortBy => {
            dispatch(setProjectsSortBy(sortBy));
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const list = {
        itemComponentRender: ProjectsListItem,
        itemSkeletonRender: ProjectsListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    const onApplyFilters = useCallback(
        selectedFilters => {
            const searchFilter = selectedFilters.find((f: any) => f.filter === 'projectSearch');
            const searchString = searchFilter ? searchFilter.value : '';
            dispatch(
                changeSingleFilter({
                    key: EntityType.Projects,
                    filters: selectedFilters,
                })
            );
            if (searchQuery !== searchString) {
                const query = {
                    ...parse(location.search.substring(1)),
                    [routeParams.QUERY]: searchString,
                };
                delete query[routeParams.SELECTED_ID];
                dispatch(push(location.pathname + '?' + stringify(query)));
            } else {
                dispatch(applyProjectsFilters());
                dispatch(clearPreviousSelectedItem());
            }
        },
        [dispatch, searchQuery, location]
    );

    const filterLabelMapper = (filter: string): string => {
        return t(`projects.projectsFilter.${filter}`);
    };

    const filter = {
        filterProps: [
            {
                Name: 'projectSearch',
                Entries: [
                    {
                        RefinementName: 'projectSearch',
                        RefinementValue: searchQuery,
                        isChecked: !!searchQuery,
                        value: searchQuery,
                    },
                ],
                type: FilterType.SearchField,
            },
            ...filters,
        ],
        onApplyFilters,
        filterLabelMapper,
    };

    const sorting = {
        sortList,
        onSortChange,
        value: currentSort,
    };

    return (
        <SearchResultLayout
            isLoading={areProjectsLoading}
            isPreviewLoading={selectedItem && !selectedItem.details}
            list={list}
            totalItemsCount={totalCount}
            sorting={sorting}
            selectedItem={selectedItem}
            previewComponent={ProjectsPreviewPanel}
            filter={filter}
            noResultImageUrl={noResultsNews}
            chooseItemImageUrl={newsChoose}
            translateRoot={'projects'}
        />
    );
};
