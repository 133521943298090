import {RootState} from 'app/state';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {ProjectsFiltersOptions} from 'app/containers/projects';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {IProject} from 'app/api/searchAPI/projects/searchAPICaller.projects.types';
import {IProjectItem} from './projects.types';
import {ProjectsSortOptions} from 'app/containers/projects/projects.types';

export const getSort = (sortField: string): {orderBy: string; order: string} | {} => {
    switch (sortField) {
        case ProjectsSortOptions.NameAZ:
            return {
                orderBy: 'Project Name',
                order: 'asc',
            };
        case ProjectsSortOptions.NameZA:
            return {
                orderBy: 'Project Name',
                order: 'desc',
            };
        case ProjectsSortOptions.companyAZ:
            return {
                orderBy: 'Project Company Name',
                order: 'asc',
            };
        case ProjectsSortOptions.companyZA:
            return {
                orderBy: 'Project Company Name',
                order: 'desc',
            };
        case ProjectsSortOptions.projectStageASC:
            return {
                orderBy: 'Project Stage',
                order: 'asc',
            };
        case ProjectsSortOptions.projectStageDESC:
            return {
                orderBy: 'Project Stage',
                order: 'desc',
            };
        case ProjectsSortOptions.none:
        default:
            return {};
    }
};

export const getSearchProjectsOptions = (
    searchText: string,
    state: RootState,
    filters: IFilterRefiner[],
    sort: ProjectsSortOptions
) => {
    const pageSize = DEFAULT_PAGE_SIZE;
    const nextPage = Math.floor((state.projects.items.length + pageSize) / pageSize);
    const filter: any = {};
    filters.forEach(f => {
        switch (f.Name) {
            case ProjectsFiltersOptions.projectSearch:
                break;
            default:
                const checked = f.Entries.filter(e => e.isChecked);
                if (checked.length) {
                    filter[f.Name] = checked.map(({RefinementValue}) => RefinementValue);
                }
        }
    });
    return {
        keyWord: searchText,
        pageSize: pageSize,
        page: nextPage,
        ...getSort(sort),
        ...filter,
    };
};

export const transformProjectItems = (response: IProject[]): IProjectItem[] => {
    return response.map(project => ({
        ...project,
        id: project.projectId,
    }));
};
