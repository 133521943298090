import {all, getContext, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {searchQuerySelector} from 'app/state/router';
import {
    applyNewsFilters,
    getNewsDetails as getNewsDetailsAction,
    getNewsDetailsFailure,
    getNewsDetailsSuccess,
    resetNewsItems,
    resetNewsState,
    resetNewsTab as resetNewsTabAction,
    searchNews as searchNewsAction,
    searchNewsFailure,
    searchNewsSuccess,
} from 'app/state/news/news.actions';
import {ISearchNewsOptions} from 'app/api/searchAPI/news/searchAPICaller.news.types';
import {getSearchNewsOptions} from 'app/state/news/news.operations';
import {RootState} from 'app/state';
import {filtersSelector} from './news.selectors';
import {isFilterSelected} from 'app/utils/filterUtils';
import {resetSingleFilter} from '../filter';
import {EntityType} from 'app/contexts';

export function* searchNews({payload}: ReturnType<typeof searchNewsAction>): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const state: RootState = yield select();
        const filters = yield select(filtersSelector);
        const options: ISearchNewsOptions = getSearchNewsOptions(payload, state, filters);
        const news = yield searchService.news.get().searchNews(options);
        yield put(searchNewsSuccess({news}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchNewsFailure(error));
    }
}

export function* getNewsDetails({payload: id}: ReturnType<typeof getNewsDetailsAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const data = yield searchService.news.get().getNewsDetails(id);
        yield put(getNewsDetailsSuccess({data}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getNewsDetailsFailure(error));
    }
}

function* onApplyFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetNewsItems());
        yield put(searchNewsAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetNewsTab(): any {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(filtersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.News}));
            yield put(resetNewsState());
            const query = yield select(searchQuerySelector);
            yield put(searchNewsAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* newsSaga() {
    yield all([
        yield takeLatest(String(searchNewsAction), searchNews),
        yield takeLatest(String(resetNewsTabAction), resetNewsTab),
        yield takeEvery(String(getNewsDetailsAction), getNewsDetails),
        yield takeLatest(String(applyNewsFilters), onApplyFilters),
    ]);
}
