import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {execute, hasErrorsResponse} from 'app/utils';
import {
    searchPeopleRequestTransformer,
    searchPeopleResultsTransformer,
} from '../searchService.transformer';
import {SearchAPICallerEmployee} from 'app/api/searchAPI/employee/searchAPICaller.employee';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {ISearchServiceEmployee, ITransformedPeopleResponse} from './searchService.employee.types';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {PeopleFiltersOptions} from 'app/containers/people';
import {createPeopleFilter} from 'app/state/people/people.operations';
import {IEmployeeProfile} from 'app/state/people';
import {OneHubAPICaller} from 'app/api/oneHubAPI/oneHubAPICaller';

@injectable()
export class SearchServiceEmployee implements ISearchServiceEmployee {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchPeople(
        query: string,
        skipAmount: number,
        companies: string[] = [],
        jobTitles: string[] = [],
        exactMatch: boolean = false
    ) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const body = searchPeopleRequestTransformer(
                    query,
                    skipAmount,
                    companies,
                    jobTitles,
                    exactMatch
                );
                const data = await SearchAPICallerEmployee.searchPeople(
                    body,
                    accessToken,
                    appConfig.skey_38
                );
                return searchPeopleResultsTransformer(data, skipAmount);
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                }

                this.logger.error(e);
                return hasErrorsResponse({
                    skipAmount,
                    areMoreResultsAvailable: false,
                    items: [],
                }) as ITransformedPeopleResponse;
            }
        );
    }

    public async searchPeopleFilters(): Promise<IFilterRefiner[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                const exactMatch: [
                    {
                        RefinementName: PeopleFiltersOptions;
                        RefinementValue: PeopleFiltersOptions;
                        isChecked: boolean;
                    }
                ] = [
                    {
                        RefinementName: PeopleFiltersOptions.ExactMatch,
                        RefinementValue: PeopleFiltersOptions.ExactMatch,
                        isChecked: false,
                    },
                ];
                let filterJob = [];
                let companies = [];

                try {
                    filterJob = await SearchAPICallerEmployee.searchPeopleJobs(
                        accessToken,
                        appConfig.skey_38
                    );
                } catch (e) {
                    this.logger.log(e);
                }
                try {
                    companies = await SearchAPICallerEmployee.searchPeopleCompanies(
                        accessToken,
                        appConfig.skey_38
                    );
                } catch (e) {
                    this.logger.log(e);
                }
                return createPeopleFilter(exactMatch, companies, filterJob);
            },
            (e: any) => {
                this.logger.error(e);
                return [];
            }
        );
    }

    public async getEmployeeProfile(employeeEmail: string): Promise<IEmployeeProfile> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const params = {
                    apiURL: appConfig.skey_43,
                    accessToken,
                    employeeEmail,
                };
                return OneHubAPICaller.getEmployeeProfile(params);
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse({
                    employeeInformation: {},
                    previousEmployment: [],
                    personalInformation: {},
                    myTeam: [],
                    profileHeader: {email: ''},
                    employeeNumber: 0,
                }) as IEmployeeProfile;
            }
        );
    }

    public async getEmployeeProfilePdf(employeeEmail: string): Promise<any> {
        const accessToken = await this.authProvider.getAccessToken();
        const params = {
            apiURL: appConfig.skey_43,
            accessToken,
            employeeEmail,
        };
        return OneHubAPICaller.getEmployeeProfilePdf(params);
    }

    public async getTeamBalanceFile(employeeEmail: string): Promise<any> {
        const accessToken = await this.authProvider.getAccessToken();
        const params = {
            apiURL: appConfig.skey_43,
            accessToken,
            employeeEmail,
        };
        return OneHubAPICaller.getTeamBalanceFile(params);
    }

    public async getEmployeePresenceStatus(employeeEmails: string[]) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const params = {
                    apiURL: appConfig.skey_43,
                    accessToken,
                    employeeEmails,
                };
                return OneHubAPICaller.getEmployeePresence(params);
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse();
            }
        );
    }

    public async getEmployeeActivity() {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return await SearchAPICallerEmployee.apiGetEmployeeActivity({
                    apiURL: appConfig.skey_43,
                    accessToken,
                });
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse();
                }
            }
        );
    }
}
