import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {ISearchServiceSuppliers} from './searchService.suppliers.types';
import {
    IGetSuppliersSubItems,
    ISearchSuppliersOptions,
    ISupplier,
} from 'app/api/searchAPI/suppliers/searchAPICallers.suppliers.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {ISupplierItem} from 'app/state/suppliers';
import {SearchAPICallerSuppliers} from 'app/api/searchAPI/suppliers/searchAPICaller.suppliers';

@injectable()
export class SearchServiceSuppliers implements ISearchServiceSuppliers {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchSuppliers(options: ISearchSuppliersOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerSuppliers.searchSuppliers(
                    options,
                    appConfig.searchAPIURL,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse([]) as ISupplier[];
                }
            }
        );
    }

    public async searchSuppliersCompanies(): Promise<any[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const companies = await SearchAPICallerSuppliers.searchSuppliersCompanies(
                    appConfig.searchAPIURL,
                    accessToken
                );
                return companies.rows;
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return [];
                }
            }
        );
    }

    public async getSuppliersDetails(supplier: ISupplierItem, companyId: string, share: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                if (supplier.entityLabel === 'COUNTRY') {
                    return await SearchAPICallerSuppliers.getSuppliersCountryDetails(
                        {
                            country: supplier.id.split('|')[1],
                            share,
                            companyId,
                        },
                        appConfig.searchAPIURL,
                        accessToken
                    );
                }

                return await SearchAPICallerSuppliers.getSuppliersDetails(
                    {
                        supplierId: supplier.id,
                        companyId,
                    },
                    appConfig.searchAPIURL,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse();
            }
        );
    }

    public async getSuppliersRankDetails({pgCode, topGuyCode, companyId}: IGetSuppliersSubItems) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return await SearchAPICallerSuppliers.getSuppliersRankDetails(
                    {
                        pgCode,
                        topGuyCode,
                        companyId,
                    },
                    appConfig.searchAPIURL,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse();
            }
        );
    }

    public async downloadDocument(id: string) {
        const accessToken = await this.authProvider.getAccessToken();
        const url = appConfig.searchAPIURL;

        return SearchAPICallerSuppliers.getDocumentBlob(url, accessToken, id);
    }

    public getPreviewDocumentUrl(id: string) {
        const url = appConfig.searchAPIURL;
        return SearchAPICallerSuppliers.getPreviewDocumentUrl(url, id);
    }

    public getDownloadDocumentUrl(id: string) {
        const url = appConfig.searchAPIURL;
        return SearchAPICallerSuppliers.getDownloadDocumentUrl(url, id);
    }
}
