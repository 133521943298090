import React from 'react';
import {RectSkeleton} from 'app/views/components/skeleton';
import styles from './documentListItemSkeleton.module.scss';

export const DocumentListItemSkeleton = () => (
    <div className={styles.skeleton}>
        <div className={styles.thumbnail}>
            <RectSkeleton width={40} height={40} />
        </div>
        <div className={styles.info}>
            <div className={styles.textInfo}>
                <RectSkeleton width={144} />
                <RectSkeleton width={70} />
            </div>
            <div className={styles.textInfo}>
                <RectSkeleton width={102} />
            </div>
            <div className={styles.textInfo}>
                <RectSkeleton width={207} />
            </div>
        </div>
    </div>
);
