import {EntityType} from 'app/contexts';
import {RootState} from 'app/state/store';

export const projectsSelector = ({projects: {items}}: RootState) => items;
export const areProjectsLoadingSelector = ({projects: {isLoading}}: RootState) => isLoading;
export const areMoreProjectsAvailableSelector = ({
    projects: {areMoreResultsAvailable},
}: RootState) => areMoreResultsAvailable;
export const projectFiltersSelector = ({filter}: RootState) =>
    filter.repository[EntityType.Projects].filters;
export const projectsTotalCount = ({projects: {totalCount}}: RootState) => totalCount;
export const projectsSortSelector = (state: RootState) => state.projects.sortBy;
export const projectsErrorCodeSelector = ({projects: {hasErrorCode}}: RootState) => hasErrorCode;
