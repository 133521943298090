import {createActions} from 'redux-actions';
import {ISearchPeopleActionPayload, IEmployeeProfile} from 'app/state/people/people.types';
import {IActivity} from '@one-hub/onehub-web-lib';

export const {
    searchPeople,
    searchPeopleSuccess,
    searchPeopleFailure,
    resetPeopleState,
    resetPeopleItems,
    loadEmployeeProfile,
    loadEmployeeProfileSuccess,
    loadEmployeeProfileFailure,
    loadEmployeeActivity,
    loadEmployeeActivitySuccess,
    loadEmployeeActivityFailure,
    applyEmployeeFilters,
    clearEmployeeActivity,
    resetEmployeeTab,
} = createActions({
    SEARCH_PEOPLE: (query: string) => query,
    SEARCH_PEOPLE_SUCCESS: (payload: ISearchPeopleActionPayload) => payload,
    SEARCH_PEOPLE_FAILURE: (error: any) => error,
    RESET_PEOPLE_STATE: () => {},
    RESET_PEOPLE_ITEMS: () => {},
    LOAD_EMPLOYEE_PROFILE: (payload: {id: string; email: string}) => payload,
    LOAD_EMPLOYEE_PROFILE_SUCCESS: (profile: IEmployeeProfile) => profile,
    LOAD_EMPLOYEE_PROFILE_FAILURE: () => {},
    LOAD_EMPLOYEE_ACTIVITY: () => {},
    LOAD_EMPLOYEE_ACTIVITY_SUCCESS: (activity: IActivity) => activity,
    LOAD_EMPLOYEE_ACTIVITY_FAILURE: () => {},
    APPLY_EMPLOYEE_FILTERS: () => {},
    CLEAR_EMPLOYEE_ACTIVITY: () => {},
    RESET_EMPLOYEE_TAB: () => {},
});
