import React, {FC, useMemo} from 'react';
import {Avatar} from '@material-ui/core';
import classNames from 'classnames';

import {Props} from './TextAvatar.types';
import styles from './TextAvatar.module.scss';

const colorThemes = [
    styles.colorType1,
    styles.colorType2,
    styles.colorType3,
    styles.colorType4,
    styles.colorType5,
];

const generateAvatarName = (title: string = ''): string => {
    const t = title.trim();

    if (t.indexOf(' ') > 0) {
        return t
            .split(' ')
            .filter(word => word.length)
            .slice(0, 2)
            .map(word => word[0].toUpperCase())
            .join('');
    }
    return t.substring(0, 2).toUpperCase();
};

function getColorThemeNumber(name: string, max: number): number {
    const sumOfUnicodeNumbers = name.split('').reduce((acc, cur) => (acc += +cur.charCodeAt(0)), 0);
    return sumOfUnicodeNumbers % max || 0;
}

export const TextAvatar: FC<Props> = ({title, colorThemeNumber, avatarProps}) => {
    const avatarName = useMemo<string>(() => generateAvatarName(title), [title]);
    const colorNumber = useMemo<number>(() => {
        const max = colorThemes.length;
        if (
            typeof colorThemeNumber === 'number' &&
            colorThemeNumber >= 0 &&
            colorThemeNumber < max
        ) {
            return colorThemeNumber;
        }
        return getColorThemeNumber(title, max);
    }, [title, colorThemeNumber]);

    const avatarClassName = classNames(
        styles.avatar,
        colorThemes[colorNumber],
        avatarProps?.className
    );

    return (
        <Avatar
            variant="circle"
            {...avatarProps}
            className={avatarClassName}
            imgProps={{style: {objectFit: 'contain', backgroundColor: 'white'}}}
        >
            <span className={styles.avatarName}>{avatarName}</span>
        </Avatar>
    );
};
