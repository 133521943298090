import React, {useCallback} from 'react';
import Button from '@material-ui/core/Button';
import styles from './backButton.module.scss';

interface IBackButton {
    onClick: Function;
}

const BackButton = ({onClick}: IBackButton) => {
    const _onClick = useCallback(() => onClick(), [onClick]);
    return <Button className={styles.backButton} onClick={_onClick} />;
};

export {BackButton};
