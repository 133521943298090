import {AbortControllerTenders, ISearchTendersOptions} from './searchAPICaller.tenders.types';
import {fetchData} from 'app/utils';
import {TendersRoutes} from './routes.tenders';

export const abortControllers: AbortControllerTenders = {
    TENDERS: null,
};

export abstract class SearchAPICallerTenders {
    public static async searchTenders(
        body: ISearchTendersOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.TENDERS?.abort();
        abortControllers.TENDERS = new AbortController();

        return fetchData(
            TendersRoutes.tenders(searchAPIURL),
            accessToken,
            'POST',
            body,
            null,
            null,
            abortControllers.TENDERS.signal
        );
    }

    public static async getTenderDetails(id: string, searchAPIURL: string, accessToken: string) {
        const url = new URL(`${TendersRoutes.tenders(searchAPIURL)}/${id}`);

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }

    public static async searchTenderFilters(searchAPIURL: string, accessToken: string) {
        return fetchData(
            TendersRoutes.tendersFilters(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }
}
