import {IAuthProvider} from 'app/infrastructure/auth';
import {IoC} from 'app/ioc';
import {inject, injectable} from 'inversify';
import {SuggestionsAPICaller} from 'app/api/suggestionsAPI/suggestionsAPICaller';
import {ISuggestionCategory, ISuggestionsService} from './suggestionsService.types';
import {historyTransformer, suggestionsTransformer} from './suggestionsService.transformer';
import {appConfig} from 'app/config';

const CATEGORIES_NUMBER = 2;
const SUGGESTIONS_NUMBER = 2;

@injectable()
export class SuggestionsService implements ISuggestionsService {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;

    public async searchSuggestions(query: string): Promise<ISuggestionCategory[]> {
        const accessToken = await this.authProvider.getAccessToken();
        return SuggestionsAPICaller.getSuggestions({
            apiURL: appConfig.skey_38,
            responsesNumber: CATEGORIES_NUMBER,
            accessToken,
            query,
        }).then(response =>
            suggestionsTransformer(response, CATEGORIES_NUMBER, SUGGESTIONS_NUMBER)
        );
    }

    public async searchHistory(): Promise<ISuggestionCategory[]> {
        const accessToken = await this.authProvider.getAccessToken();
        return SuggestionsAPICaller.getHistory({
            apiURL: appConfig.skey_38,
            accessToken,
        }).then(response => historyTransformer(response, CATEGORIES_NUMBER, SUGGESTIONS_NUMBER));
    }
}
