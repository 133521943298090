import React, {EffectCallback, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './peoplePreviewPanel.module.scss';
import {useAppContext, useChannelContext, useInjection} from 'app/hooks';
import {
    IMyTeamMember,
    IProfileData,
    IProfileHandlers,
    IProfileHeader,
    Profile,
} from '@one-hub/onehub-web-lib';
import {AppMode} from 'app/contexts';
import {appConfig} from 'app/config';
import {selectMyProfileUserPrincipalName} from 'app/state/myProfile/myProfile.selectors';
import {ISearchService} from 'app/infrastructure/search';
import {ISearchServiceEmployee} from 'app/infrastructure/search/employee/searchService.employee.types';
import {IoC} from 'app/ioc';
import {handleBlob} from 'app/utils';
import {ILogger} from 'app/infrastructure/logging';
import {IAuthProvider} from 'app/infrastructure/auth';
import {downloadFileErrorMessage} from 'app/utils/downloadFileErrorMessage';
import {toastrDownloadError, toastrDownloadInfo} from 'app/views/components/toastr/toastrDownload';
import {DocumentPreview} from '../../../components/documentPreview/documentPreview';
import {ONE_HUB_PROFILE_PROXY_URL} from 'app/api/oneHubAPI/oneHubAPICaller';
import {generateOneHubServicesInnerPageUrl} from 'app/utils/urlUtils';
import {ToastrBaseType} from '../../../components/toastr/toastrBaseComponent';

export interface IPeoplePreviewPanelProps {
    data: IProfileData | null;
}

enum UrlParts {
    PROFILE = 'profile',
    ORG_CHART = 'orgchart',
}

export const PeoplePreviewPanel = ({data: profile}: IPeoplePreviewPanelProps) => {
    const searchServiceEmployee = useInjection<ISearchServiceEmployee>(IoC.SearchServiceEmployee);
    const searchService = useInjection<ISearchService>(IoC.SearchService);
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const authProvider = useInjection<IAuthProvider>(IoC.AuthProvider);
    const userPrincipalName = useSelector(selectMyProfileUserPrincipalName);
    const {skey_44, skey_43} = appConfig;

    const profileURL = `${skey_43}/${ONE_HUB_PROFILE_PROXY_URL}`;
    const profilePreviewURL = `${profileURL}/download/preview`;
    const profileDownloadURL = `${profileURL}/download`;
    const teamBalancePreviewURL = `${profileURL}/team-balance/download/preview`;
    const teamBalanceDownloadURL = `${profileURL}/team-balance/download`;

    const {mode} = useAppContext();
    const {
        onOpenProfile,
        onOpenLink,
        onOpenOrgChart,
        onMobileMailClientOpen,
        onDownloadLink,
        onDocumentPreview,
        onGoToEServiceProfile,
    } = useChannelContext();
    const dispatch = useDispatch();
    const [documentPreview, setDocumentPreview] = useState<string>('');
    const [documentLoading, setDocumentLoading] = useState<boolean>(false);
    const [documentError, setDocumentError] = useState<string | undefined>();

    const onGoToEServiceClick = (data: {requestUrl: string; sectionName: string}): void => {
        if (!data?.requestUrl) {
            return;
        }
        if (mode !== AppMode.FullScreen) {
            onGoToEServiceProfile({url: data?.requestUrl});
            return;
        }
        authProvider.getAccessToken().then(token => {
            const eServiceUrl = `${generateOneHubServicesInnerPageUrl(
                data?.requestUrl,
                'eservices',
                token
            )}`;
            window.open(`${skey_44}/${eServiceUrl}`, '_blank', 'noopener,noreferrer');
        });
    };

    const goToOneHub = useCallback(
        (email: string | void, urlPart: string, postMessageFunction: any) => {
            if (!email) return;

            const url = `${skey_44}/${urlPart ? `${urlPart}/` : ''}${email}`;

            switch (mode) {
                case AppMode.FullScreen:
                    window.open(url, '_blank');
                    break;
                default:
                    postMessageFunction({email});
                    break;
            }
        },
        [mode, skey_44]
    );
    const openProfile = useCallback(
        (email: string) => {
            goToOneHub(email, UrlParts.PROFILE, onOpenProfile);
        },
        [goToOneHub, onOpenProfile]
    );
    const openOrgChart = useCallback(() => {
        goToOneHub(profile!.profileHeader.email, UrlParts.ORG_CHART, onOpenOrgChart);
    }, [goToOneHub, onOpenOrgChart, profile]);

    const onLinkClick = useCallback(
        (url: string) => {
            if (!url) return;

            switch (mode) {
                case AppMode.FullScreen:
                    window.open(url, '_blank');
                    break;
                default:
                    onOpenLink({absoluteURL: url});
                    break;
            }
        },
        [mode, onOpenLink]
    );

    const onTeamMemberClick = useCallback(
        (member: IMyTeamMember) => {
            openProfile(member?.email);
        },
        [openProfile]
    );
    const onReportingToMemberClick = useCallback(
        (profile: IProfileHeader) => {
            openProfile(profile?.email);
        },
        [openProfile]
    );

    const onUserProfileClick = useCallback(
        (profile: IProfileHeader) => {
            openProfile(profile?.email);
        },
        [openProfile]
    );

    const onMailClick = (email: string) => {
        if (mode === AppMode.WebView) {
            return onMobileMailClientOpen({email});
        }

        window.open(`mailto:${email}`);
    };

    useEffect((): ReturnType<EffectCallback> => {
        setDocumentError(undefined);
        setDocumentLoading(true);
        setDocumentPreview('');
    }, [dispatch, profile]);

    const onExportPdfButtonClick: () => void = useCallback(() => {
        if (mode === AppMode.WebView) {
            onDocumentPreview({
                downloadUrl: profileDownloadURL,
                previewUrl: `${profilePreviewURL}/${profile?.profileHeader?.email}`,
            });
        } else {
            setDocumentPreview('profile');
        }
    }, [mode, onDocumentPreview, setDocumentPreview, profileDownloadURL, profilePreviewURL]);

    const onTeamBalanceDownloadClick: () => void = useCallback(() => {
        if (mode === AppMode.WebView) {
            onDocumentPreview({
                downloadUrl: teamBalanceDownloadURL,
                previewUrl: teamBalancePreviewURL,
            });
        } else {
            setDocumentPreview('team-balance');
        }
    }, [
        mode,
        onDocumentPreview,
        setDocumentPreview,
        teamBalanceDownloadURL,
        teamBalancePreviewURL,
    ]);

    const handleDownloadDocument = async (type: any) => {
        try {
            let response;
            let toastr: ToastrBaseType = {
                id: '',
                destroy: () => {},
            };
            if (type === 'profile') {
                if (mode === AppMode.WebView) {
                    onDownloadLink({absoluteURL: profileDownloadURL});
                } else {
                    toastr = toastrDownloadInfo(dispatch, {documentName: type, mode});
                    response = await searchServiceEmployee.getEmployeeProfilePdf(
                        profile?.profileHeader?.email as string
                    );
                }
            } else {
                if (mode === AppMode.WebView) {
                    onDownloadLink({absoluteURL: teamBalanceDownloadURL});
                } else {
                    toastr = toastrDownloadInfo(dispatch, {documentName: type, mode});
                    response = await searchServiceEmployee.getTeamBalanceFile(
                        profile?.profileHeader?.email as string
                    );
                }
            }

            if (response.status === 200) {
                const arrayBuffer = await response.arrayBuffer();
                const fileName = response.headers
                    .get('content-disposition')
                    ?.match(/filename=(.*);/)[1]
                    ?.replace(/"/g, '');
                debugger;
                handleBlob(arrayBuffer, 'octet/stream', true, fileName || `${type}.pdf`);
                toastr.destroy();
            } else {
                let data;
                try {
                    data = await response.json();
                } catch (e) {}

                const text = downloadFileErrorMessage(data?.ExceptionType);
                toastr.destroy({force: true});
                toastrDownloadError(dispatch, {text, mode});
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const handlers: IProfileHandlers = {
        onUserProfileClick,
        onReportingToMemberClick,
        onTeamMemberClick,
        onOneErpClick: onLinkClick,
        onTeamsClick: onLinkClick,
        onOrgChartClick: openOrgChart,
        onMailClick,
        onExportPdfButtonClick,
        onTeamBalanceDownloadClick,
    };

    const getAccessTokenCallback = async () => {
        return await searchService.getOneHubAuthToken();
    };

    const documentPreviewURL =
        documentPreview === 'profile'
            ? `${profilePreviewURL}/${profile?.profileHeader?.email}`
            : teamBalancePreviewURL;
    const documentName = documentPreview === 'profile' ? 'Profile' : 'Team Leave Balance';

    return (
        <div className={styles.preview}>
            {/*
             // @ts-ignore */}
            <Profile
                {...handlers}
                {...(profile as IProfileData)}
                mode={mode}
                shouldShowExportPdfButton={Boolean(profile?.profileDownloadUrl)}
                getAccessTokenCallback={getAccessTokenCallback}
                proxyUrl={`${appConfig.skey_43}/api/proxy/v0`}
                lightAppsUrl={`${appConfig.skey_43}/api/lightapps/v0`}
                myProfileMail={userPrincipalName}
                user={profile?.profileHeader?.email}
                onError={(e: string, e2: any) => console.log(e2)}
                onGoToEServiceClick={onGoToEServiceClick}
            />
            {documentPreview && (
                <DocumentPreview
                    documentURL={documentPreviewURL}
                    documentName={documentName}
                    onFullScreenClose={() => setDocumentPreview('')}
                    isFullScreen={true}
                    docLoading={documentLoading}
                    setDocLoading={setDocumentLoading}
                    docError={documentError}
                    setDocError={setDocumentError}
                    fileType="pdf"
                    onDownloadClick={() => handleDownloadDocument(documentPreview)}
                />
            )}
        </div>
    );
};
