import {format} from 'date-fns';

import {RootState} from 'app/state';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {ISearchTendersOptions} from 'app/api/searchAPI/tenders/searchAPICaller.tenders.types';
import {
    TendersFiltersOptions,
    TendersSortOptions,
} from 'app/containers/tenderBoard/tenderBoard.types';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';

const getFilters = (filters: IFilterRefiner[]): {field: string; values: any[]}[] => {
    const listFilters = [...filters];

    const dateFilter = listFilters.find(f => f.Name === TendersFiltersOptions.Date);
    const dateOptions: {field: string; values: string[]}[] = [];
    if (dateFilter) {
        listFilters.splice(listFilters.indexOf(dateFilter), 1);
        dateFilter.Entries.filter(entry => entry.isChecked).forEach(entry => {
            dateOptions.push({
                field: entry.RefinementValue,
                values: [format(entry.value, 'yyyy-MM-dd')],
            });
        });
    }

    return listFilters
        .map(f => ({
            field: f.Name,
            values: f.Entries.filter(entry => entry.isChecked).map(entry => entry.RefinementValue),
        }))
        .concat(dateOptions)
        .filter(f => f.values.length > 0);
};

export const getSort = (sortField: string): {orderBy: string; order: string} | {} => {
    switch (sortField) {
        case TendersSortOptions.nameAZ:
            return {
                orderBy: 'Name',
                order: 'asc',
            };
        case TendersSortOptions.nameZA:
            return {
                orderBy: 'Name',
                order: 'desc',
            };
        case TendersSortOptions.companyAZ:
            return {
                orderBy: 'Company',
                order: 'asc',
            };
        case TendersSortOptions.companyZA:
            return {
                orderBy: 'Company',
                order: 'desc',
            };
        case TendersSortOptions.newToOld:
            return {
                orderBy: 'Date',
                order: 'desc',
            };
        case TendersSortOptions.oldToNew:
            return {
                orderBy: 'Date',
                order: 'asc',
            };
        case TendersSortOptions.none:
        default:
            return {};
    }
};

export const getSearchTendersOptions = (
    searchString: string,
    state: RootState,
    sort: TendersSortOptions,
    filters: IFilterRefiner[]
): ISearchTendersOptions => {
    const perPage = DEFAULT_PAGE_SIZE;
    const nextPage = Math.floor((state.tenders.items.length + perPage) / perPage);

    return {
        searchString,
        perPage,
        page: nextPage,
        filters: getFilters(filters),
        ...getSort(sort),
    };
};
