export const routes = {
    welcome: '/',

    search: '/search-results',
    search_employee: '/search-results/employee',
    search_document: '/search-results/document',
    search_correspondence: '/search-results/correspondence',
    search_email: '/search-results/email',
    search_training: '/search-results/training',
    search_news: '/search-results/news',
    search_tender: '/search-results/tender',
    search_projects: '/search-results/projects',
    search_service: '/search-results/service',
    search_suppliers: '/search-results/suppliers',
    search_customers: '/search-results/customers',
    search_distribution: '/search-results/distribution',
};

export const routeParams = {
    QUERY: 'q',
    SELECTED_ID: 'id',
    MODE: 'mode',
    TOKEN: 'token',
    DISABLE_HISTORY_ONCE: 'h',
};

export enum HistoryDisableValues {
    disable = 'disable',
    enable = '',
}
