import React, {FC} from 'react';
import styles from './serviceListItemSkeleton.module.scss';
import {RectSkeleton} from 'app/views/components/skeleton';

export const ServiceListItemSkeleton: FC = () => (
    <div className={styles.item}>
        <div className={styles.itemHeader}>
            <RectSkeleton width={150} height={20} />
            <RectSkeleton width={50} height={20} />
        </div>
        <div className={styles.title}>
            <RectSkeleton width={235} height={20} />
        </div>
        <RectSkeleton width={250} height={40} />
    </div>
);
