import {IAuthProvider} from './authProvider.types';
import {injectable} from 'inversify';
import {parseJWT} from 'app/utils/parseJWT';
import {ILogger} from 'app/infrastructure/logging';

@injectable()
class DummyAuthProvider implements IAuthProvider {
    private accessToken: string;
    private readonly logger: ILogger;

    constructor(accessToken: string, logger: ILogger) {
        this.accessToken = accessToken;
        this.logger = logger;
    }

    init(): Promise<any> {
        return Promise.resolve();
    }

    public isLoggedIn() {
        return true;
    }

    getAccessToken(): Promise<string> {
        try {
            const email = parseJWT(this.accessToken)?.email;
            if (email) {
                this.logger.setUserEmail(email);
            }
        } catch (e) {
            console.error(e);
        }

        return Promise.resolve(this.accessToken);
    }

    setAccessToken(token: string) {
        this.accessToken = token;
    }

    loginForIdToken() {
        return Promise.resolve();
    }
}

export default DummyAuthProvider;
