import React, {useEffect, useState} from 'react';

type DelayedWrapperProps = {
    children: React.ReactElement;
    waitBeforeShow?: number;
};

const DelayedWrapper = ({children, waitBeforeShow = 500}: DelayedWrapperProps) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    return isShown ? children : null;
};

export default DelayedWrapper;
