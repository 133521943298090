import React from 'react';
import {get} from 'lodash';
import {useLocalizationContext} from 'app/hooks';
import styles from './distributionMapDetails.module.scss';
import Link from '@material-ui/core/Link/Link';
import phoneIcon from 'app/static/images/contactPhone.svg';
import mail from 'app/static/images/contactMail.svg';
import teams from 'app/static/images/contactTeams.svg';
import {ColorLabel} from '../../../../components/colorLabel/colorLabel';
import {Avatar} from '@material-ui/core';
import classNames from 'classnames';
import {toMillions} from 'app/utils';
import {StationRating} from './stationRating';

export const DistributionMapDetails = ({
    className,
    data,
    gData,
}: {
    className: string;
    data: any;
    gData: any;
}) => {
    const {t} = useLocalizationContext();
    const {
        name,
        status,
        placeId,
        rating,
        id,
        cityName,
        totalRevenue,
        totalStaff,
        managerName,
        managerEmailAddress,
        phoneNumber,
    } = data;

    const labelClass = styles[status.toLowerCase()];
    const mapLink = get(
        gData,
        'url',
        `https://www.google.com/maps/place/?q=${
            placeId ? `place_id:${placeId}` : `${cityName}&z=10`
        }`
    );

    return (
        <div className={className}>
            <div className={styles.header}>
                <div className={styles.title}>{name}</div>
                <Link
                    className={styles.link}
                    href={mapLink}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('distribution.preview.viewDetails')}
                </Link>
            </div>
            <div className={styles.detailsContent}>
                <div className={styles.row}>
                    <div className={styles.label}>{t('distribution.preview.status')}:</div>
                    <div className={styles.value}>
                        <ColorLabel className={classNames(styles.statusLabel, labelClass)}>
                            {status}
                        </ColorLabel>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>{t('distribution.preview.address')}:</div>
                    <div className={styles.value}>
                        {id} {cityName}
                    </div>
                </div>
                <div className={classNames(styles.row, styles.rating)}>
                    <div className={styles.label}>{t('distribution.preview.rating')}:</div>
                    <StationRating className={styles.value} rating={rating} />
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>{t('distribution.preview.totalRevenue')}:</div>
                    <div className={styles.value}>
                        {!!totalRevenue ? `${toMillions(totalRevenue)}M` : ''}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>{t('distribution.preview.totalStaff')}:</div>
                    <div className={styles.value}>{totalStaff}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>{t('distribution.preview.manager')}:</div>
                    <div className={styles.value}>
                        {!!managerName && (
                            <div className={styles.managerInfo}>
                                <Avatar
                                    variant="rounded"
                                    alt=""
                                    // src={managerIconUrl}
                                    className={styles.managerAvatar}
                                />
                                <Link>{managerName}</Link>
                            </div>
                        )}
                        <div className={styles.managerContacts}>
                            {phoneNumber && (
                                <a
                                    href={`tel:${phoneNumber}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className={classNames(
                                            styles.contactsIcon,
                                            styles.phoneIcon
                                        )}
                                        src={phoneIcon}
                                        alt="phone"
                                    />
                                </a>
                            )}
                            {managerEmailAddress && (
                                <>
                                    <a
                                        href={`mailto:${managerEmailAddress}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            className={styles.contactsIcon}
                                            src={mail}
                                            alt="mail"
                                        />
                                    </a>
                                    <a
                                        href={`https://teams.microsoft.com/l/chat/0/0?users=${managerEmailAddress}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            className={styles.contactsIcon}
                                            src={teams}
                                            alt="teams"
                                        />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
