import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getMyProfile} from 'app/state/myProfile';
import {ErrorBoundaryGlobal} from 'app/views/components';
import {RouterView} from 'app/routerView';

import styles from './app.module.scss';

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyProfile());
    }, [dispatch]);

    return (
        <div className={styles.app}>
            <div className={styles.appLayout}>
                <ErrorBoundaryGlobal>
                    <RouterView />
                </ErrorBoundaryGlobal>
            </div>
        </div>
    );
};

export default App;
