import React from 'react';
import {PeopleListItem} from 'app/views/people';
import {PeopleListItemSkeleton} from '../peopleListItemSkeleton/peopleListItemSkeleton';
import {List} from 'app/views/components/list';
import {IPeopleListProps} from './peopleList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const PeopleList = ({
    items,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: IPeopleListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    itemComponentRender={PeopleListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    itemComponentRender={PeopleListItem}
                    itemsData={items}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
