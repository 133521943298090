import React, {ReactChild} from 'react';
import classNames from 'classnames';
import styles from './noResults.module.scss';

export interface INoResultsProps {
    title: string;
    imageSrc: string;
    description?: string;
    imageClasses?: string;
    noResultsContainerClasses?: string;
    children?: string | string[] | ReactChild | ReactChild[];
}

export const NoResults = ({
    title,
    description,
    imageSrc,
    imageClasses,
    noResultsContainerClasses,
    children,
}: INoResultsProps) => {
    const containerClassNames = classNames([styles.noResults], noResultsContainerClasses);
    const imageClassNames = classNames([styles.image, imageClasses]);

    return (
        <div className={containerClassNames}>
            <div style={{backgroundImage: `url(${imageSrc})`}} className={imageClassNames} />
            <div className={styles.promptText}>{title}</div>
            {description && <div className={styles.helperText}>{description}</div>}
            {children}
        </div>
    );
};
