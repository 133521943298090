import React, {HTMLAttributes, forwardRef} from 'react';
import classNames from 'classnames';
import styles from './oneColumnLayout.module.scss';

export const OneColumnLayout = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({children, className, ...props}, ref) => {
        const layoutClasses = classNames(styles.oneColumnLayout, className);
        return (
            <div {...props} className={layoutClasses} ref={ref}>
                <div className={styles.content}>{children}</div>
            </div>
        );
    }
);
