import {handleActions} from 'redux-actions';
import {
    searchCorrespondence,
    searchCorrespondenceSuccess,
    searchCorrespondenceFailure,
    setCorrespondenceFilters,
    resetCorrespondenceState,
    resetCorrespondenceItems,
    getCorrespondenceAttachments,
    getCorrespondenceAttachmentsSuccess,
    getCorrespondenceAttachmentsFailure,
    getCorrespondenceTracking,
    getCorrespondenceTrackingSuccess,
    getCorrespondenceTrackingFailure,
} from './correspondence.actions';
import {ICorrespondenceState} from 'app/state/correspondence/correspondence.types';

const initialState: ICorrespondenceState = {
    items: [],
    filters: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorsAttachments: false,
    hasErrorsTracking: false,
    hasErrorCode: undefined,
    totalCount: 0,
};

export default handleActions(
    {
        [String(searchCorrespondence)]: state => ({
            ...state,
            isLoading: true,
            hasErrors: false,
        }),
        [String(searchCorrespondenceSuccess)]: (
            state,
            {payload: {areMoreResultsAvailable, items, totalCount}}: {payload: any}
        ) => ({
            ...state,
            totalCount,
            isLoading: false,
            items: [...state.items, ...items],
            areMoreResultsAvailable,
            hasErrors: false,
        }),
        [String(searchCorrespondenceFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrors: true,
            totalCount: 0,
            hasErrorCode: payload.responseStatus,
        }),
        [String(setCorrespondenceFilters)]: (state, {payload}: any) => ({
            ...state,
            filters: payload,
        }),
        [String(resetCorrespondenceState)]: state => ({
            ...initialState,
            filters: state.filters.map(filter => ({
                ...filter,
                Entries: filter.Entries.map(entry => ({...entry, isChecked: false})),
            })),
            totalCount: 0,
        }),
        [String(resetCorrespondenceItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
            totalCount: 0,
        }),
        [String(getCorrespondenceAttachments)]: state => ({
            ...state,
            hasErrorsAttachments: false,
        }),
        [String(getCorrespondenceAttachmentsSuccess)]: (state, {payload}: any) => ({
            ...state,
            items: state.items.map(item =>
                item.id === payload.id ? {...item, attachments: payload.attachments} : item
            ),
            hasErrorsAttachments: false,
        }),
        [String(getCorrespondenceAttachmentsFailure)]: state => ({
            ...state,
            hasErrorsAttachments: true,
        }),
        [String(getCorrespondenceTracking)]: state => ({
            ...state,
            hasErrorsTracking: false,
        }),
        [String(getCorrespondenceTrackingSuccess)]: (state, {payload}: any) => ({
            ...state,
            items: state.items.map(item =>
                item.id === payload.id ? {...item, tracking: payload.tracking} : item
            ),
            hasErrorsTracking: false,
        }),
        [String(getCorrespondenceTrackingFailure)]: state => ({
            ...state,
            hasErrorsTracking: true,
        }),
    },
    initialState
);
