import React, {useCallback, useContext, useEffect, useState} from 'react';
import styles from './searchResultLayout.module.scss';
import pStyles from './previewLayout.module.scss';
import classNames from 'classnames';
import {SearchPageContext} from 'app/pages/searchPage/searchPage';
import {isFilterSelected as isSelected} from 'app/utils/filterUtils';

import {ResultList} from './resultList';
import {FiltersLayout} from './filtersLayout';
import {useMediaQuery} from '@material-ui/core';
import scssVariables from 'app/variables.module.scss';
import {PreviewLayout} from './previewLayout';
import {useChannelContext, useLocalizationContext} from 'app/hooks';
import {PopupContext} from './PopupContext';
import DelayedWrapper from 'app/views/components/delayedComponent';
import {Loader} from '../../views/components';
import {Error as ErrorScreen} from '../../views/components/errors/error';
// import {FallbackLayout} from "../fallbackLayout/fallbackLayout";
import {IChannelContext} from '../../contexts';
import {useSelector} from 'react-redux';
import {locationSelector} from '../../state/router';
import {HTTPS_STATUS_CODES} from '../../constants';

export const SearchResultLayout = (props: any) => {
    const {
        isLoading,
        filter,
        list,
        totalItemsCount,
        sorting,
        selectedItem,
        previewComponent,
        isPreviewLoading = false,
        noResultImageUrl,
        chooseItemImageUrl,
        translateRoot,
        previewKey,
    } = props;
    const {t} = useLocalizationContext();

    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`);
    const {setTabNavigationVisibility} = useContext(SearchPageContext);
    const [areFiltersExpanded, setFiltersExpanded] = useState<boolean>(true);
    const [areMobileFiltersShown, setMobileFiltersShown] = useState<boolean>(false);
    const [popup, setPopup] = useState<{
        title?: string;
        text?: string;
    }>({});

    const channelContext: IChannelContext = useChannelContext();
    const location = useSelector(locationSelector);

    useEffect(() => {
        setPopup({});
    }, [selectedItem]);

    const handleCancelClick = () => {
        setMobileFiltersShown(false);
        setTabNavigationVisibility(true);
    };
    const handleShowMobileFilterClick = useCallback(() => {
        setMobileFiltersShown(true);
        setTabNavigationVisibility(false);
    }, [setTabNavigationVisibility]);
    const handleHideFiltersClick = () => {
        setFiltersExpanded(false);
    };
    const handleShowFilterClick = useCallback(() => {
        setFiltersExpanded(true);
    }, []);

    const itemsLength = list.itemsData.length;
    const isInitialLoading = (!itemsLength && isLoading) as boolean;
    const hasNoResults = (!itemsLength && !isInitialLoading) as boolean;
    const hasFilter = !!filter && !!filter.filterProps?.length;
    const isFilterSelected = hasFilter ? isSelected(filter.filterProps) : false;
    const isItemSelected = !!selectedItem;

    const isFilterVisible =
        hasFilter &&
        (!hasNoResults || (hasNoResults && isFilterSelected)) &&
        ((isSmall && areMobileFiltersShown) || !isSmall);
    const isResultListVisible = !isSmall || (!isItemSelected && !areMobileFiltersShown);
    const isPreviewVisible =
        (isSmall && isItemSelected && !areMobileFiltersShown) ||
        !isSmall ||
        (hasNoResults && !areMobileFiltersShown);

    useEffect(() => {
        if (list.errorCode === HTTPS_STATUS_CODES.UNAUTHORIZED) {
            channelContext.onUpdateTokenFromParent();
        }
    }, [channelContext, list.errorCode]);

    return (
        <DelayedWrapper>
            <div
                className={classNames(styles.root, {
                    [styles.hideFilter]: !areFiltersExpanded,
                })}
            >
                {isFilterVisible && (
                    <div className={classNames(styles.filter)}>
                        <FiltersLayout
                            {...filter}
                            onCancel={handleCancelClick}
                            isLoading={isInitialLoading}
                            onHideFiltersClick={handleHideFiltersClick}
                            mobileLabel={t(`${translateRoot}.mobileFilterLabel`)}
                        />
                    </div>
                )}
                {!hasNoResults && (
                    <div
                        className={classNames(styles.list, {
                            [styles.hidden]: !isResultListVisible,
                        })}
                    >
                        <ResultList
                            isLoading={isInitialLoading}
                            list={list}
                            sorting={sorting}
                            totalItemsCount={totalItemsCount}
                            hasFilter={hasFilter}
                            areFilterShow={areFiltersExpanded}
                            onShowFilterClick={handleShowFilterClick}
                            onShowMobileFilterClick={handleShowMobileFilterClick}
                        />
                    </div>
                )}
                {isPreviewVisible && (
                    <div className={classNames(styles.fluid, styles.preview)}>
                        {isInitialLoading || isPreviewLoading ? (
                            <div className={pStyles.noResults}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {list.errorCode ? (
                                    <ErrorScreen
                                        fetchErrors={true}
                                        errorCode={list.errorCode}
                                        attempts={+location.query.attempts}
                                    />
                                ) : (
                                    <>
                                        <PopupContext.Provider value={{popup, setPopup}}>
                                            <PreviewLayout
                                                selectedItem={selectedItem}
                                                noResults={hasNoResults}
                                                isFilterSelected={isFilterSelected}
                                                previewComponent={previewComponent}
                                                areFilterShow={areFiltersExpanded}
                                                onShowFilterClick={handleShowFilterClick}
                                                onShowMobileFilterClick={
                                                    handleShowMobileFilterClick
                                                }
                                                noResultImageUrl={noResultImageUrl}
                                                chooseItemImageUrl={chooseItemImageUrl}
                                                translateRoot={translateRoot}
                                                previewKey={previewKey}
                                            />
                                        </PopupContext.Provider>
                                        {popup.text && (
                                            <div className={styles.previewPopupBack}>
                                                <div className={styles.previewPopup}>
                                                    <button
                                                        type="button"
                                                        className={styles.close}
                                                        onClick={() => {
                                                            setPopup({});
                                                        }}
                                                    />
                                                    <h3>{popup.title}</h3>
                                                    <p>{popup.text}</p>
                                                    <button
                                                        type="button"
                                                        className={styles.button}
                                                        onClick={() => {
                                                            setPopup({});
                                                        }}
                                                    >
                                                        OK
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </DelayedWrapper>
    );
};
