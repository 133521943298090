import React, {FC} from 'react';
import styles from './InfoTable.module.scss';
import {InfoTableProps} from './InfoTable.types';

export const InfoTable: FC<InfoTableProps> = ({column1, column2}) => {
    return (
        <div className={styles.table}>
            {[column1, column2].map((column, columnIndex) => (
                <div className={styles.tableColumn} key={columnIndex}>
                    {column.map((col, colIndex) => (
                        <div className={styles.tableRow} key={colIndex}>
                            <div className={styles.tableCell}>{col.title}</div>
                            <div className={styles.tableCell}>{col.value}</div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
