import React, {useEffect, useRef} from 'react';
import {useLocalizationContext} from 'app/hooks';
import useDimensions from 'react-cool-dimensions';
import classNames from 'classnames';

// import {ISearchService} from 'app/infrastructure/search';
// import {IoC} from 'app/ioc';
import {IProjectItem} from 'app/state/projects/projects.types';
import styles from './projectsPreviewPanel.module.scss';
import calendar from 'app/static/images/calendar.svg';
import {getMonthYear} from 'app/utils';
import {SCurve} from './components/sCurve';
import {UserInfo} from './components/userInfo';
import {ScheduleInfo} from './components/scheduleInfo';
import {ProjectInfo} from './components/projectInfo';
import {ProductionTarget} from './components/productionTarget';
import {Contractor} from './components/contractor';
import {Milestones} from './components/milestones';
import {Risks} from './components/risks';
import {CostsInfo} from './components/costsInfo';
import {IfDataAvailable} from './components/ifDataAvailable';

export const ProjectsPreviewPanel = ({data}: {data: IProjectItem}) => {
    const {t} = useLocalizationContext();
    // const searchService = useInjection<ISearchService>(IoC.SearchService);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const {width} = useDimensions({ref: contentRef});
    const isSmall = width < 640;
    const {details} = data;

    // const [avatars, setAvatars] = useState({});
    useEffect(() => {
        const {managerInfo, gateKeeperInfo} = details || {};
        let emails = [];
        if (gateKeeperInfo?.email) emails.push(gateKeeperInfo.email);
        if (managerInfo) {
            managerInfo.forEach(({email}) => {
                emails.push(email);
            });
        }
        // if (emails.length) {
        //     searchService.searchEmployeesPhotos(emails).then(data => {
        //         setAvatars(data);
        //     });
        // }
    }, [details]);

    if (!details || details?.hasErrors) return null;

    const {managerInfo, gateKeeperInfo} = details;

    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.content, {
                    [styles.small]: isSmall,
                })}
                ref={contentRef}
            >
                <div className={styles.title}>
                    <h2>{details.projectName}</h2>
                    <div className={styles.date}>
                        {getMonthYear(details.reportingDate)}
                        <img src={calendar} alt="calendar" />
                    </div>
                </div>

                <ProjectInfo data={details} isSmall={isSmall} />
                <ScheduleInfo data={details} isSmall={isSmall} />
                <CostsInfo data={details} isSmall={isSmall} />

                <div className={classNames({[styles.twoColumns]: !isSmall})}>
                    {managerInfo &&
                        managerInfo.map((manager, index) => (
                            <div className={styles.itemContainer} key={index}>
                                <h3>{t('projects.preview.projectManager')}</h3>
                                <IfDataAvailable
                                    isData={!!manager?.name}
                                    name={t('projects.preview.projectManager')}
                                >
                                    <UserInfo
                                        info={manager}
                                        // avatars={avatars}
                                    />
                                </IfDataAvailable>
                            </div>
                        ))}
                    <div className={styles.itemContainer}>
                        <h3>{t('projects.preview.gatekeeper')}</h3>
                        <IfDataAvailable
                            isData={!!gateKeeperInfo?.name}
                            name={t('projects.preview.gatekeeper')}
                        >
                            <UserInfo
                                info={gateKeeperInfo}
                                // avatars={avatars}
                            />
                        </IfDataAvailable>
                    </div>
                </div>

                <div className={styles.itemContainer}>
                    <h3>{t('projects.preview.businessCase')}</h3>
                    <IfDataAvailable
                        isData={!!details.businessCase}
                        name={t('projects.preview.businessCase')}
                    >
                        <p>{details.businessCase}</p>
                    </IfDataAvailable>
                </div>

                <div className={styles.itemContainer}>
                    <h3>{t('projects.preview.scope')}</h3>
                    <IfDataAvailable isData={!!details.scope} name={t('projects.preview.scope')}>
                        <p>{details.scope}</p>
                    </IfDataAvailable>
                </div>

                <ProductionTarget data={details} isSmall={isSmall} />
                <SCurve data={details} isSmall={isSmall} />
                <Milestones data={details} isSmall={isSmall} />
                <Risks data={details} isSmall={isSmall} />
                <Contractor data={details} isSmall={isSmall} />
            </div>
        </div>
    );
};
