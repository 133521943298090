import React from 'react';
import {DocViewer} from 'app/views/components/DocViewer/DocViewer';
import styles from './documentPreview.module.scss';
import CannotBePreviewed from 'app/static/images/cannotBePreviewed.svg';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';
import {Loader} from 'app/views/components/loader';
import {DocViewerError} from 'app/views/components/DocViewer/DocViewerError';

// TODO Check and replace by constant SUPPORTED_DOCUMENTS_FILE_TYPES
export const supportedFileTypes = ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt'];

type PreviewProps = {
    documentURL: string;
    documentName: string;
    documentType?: string;
    onFullScreenClose: () => void;
    isFullScreen: boolean;
    docLoading: boolean;
    setDocLoading: (p: boolean) => void;
    docError: string | undefined;
    setDocError: (p: string | undefined) => void;
    fileType: string;
    onDownloadClick: () => void;
};

export const DocumentPreview = ({
    documentURL,
    documentName,
    onFullScreenClose,
    isFullScreen,
    docLoading,
    setDocLoading,
    docError,
    setDocError,
    fileType,
    onDownloadClick,
}: PreviewProps) => {
    const {t} = useLocalizationContext();

    const handleCloseDocViewEmulator = () => {
        onFullScreenClose();
        // Reset error if attachments more than 1, and one of there is broken
        setDocError(undefined);
    };

    return (
        <div className={styles.previewContainer}>
            <div
                className={classNames(styles.previewDocContainer, {
                    [styles.fs]: isFullScreen,
                })}
            >
                {docError ? (
                    <DocViewerError
                        onClose={handleCloseDocViewEmulator}
                        onDownload={onDownloadClick}
                        title={t('documents.documentPreview.documentCannotBePreviewed')}
                        description={t('documents.documentPreview.pleaseDownloadDocument')}
                        imageSrc={CannotBePreviewed}
                        imageClasses={styles.noResultsIcon}
                        noResultsContainerClasses={styles.noResultsContainer}
                    />
                ) : (
                    <DocViewer
                        className={styles.preview}
                        fileName={documentName}
                        url={documentURL}
                        onError={setDocError}
                        onLoad={setDocLoading}
                        onDownload={onDownloadClick}
                        showOnlyDownloadOnError={true}
                        onClose={onFullScreenClose}
                        isFullScreen={isFullScreen}
                    />
                )}
                {docLoading && (
                    <div className={styles.loading}>
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};
