import {RootState} from 'app/state/store';

export const correspondenceFiltersSelector = ({correspondence: {filters}}: RootState) => filters;
export const correspondenceItemsSelector = ({correspondence: {items}}: RootState) => items;
export const correspondenceErrorsSelector = ({correspondence: {hasErrors}}: RootState) => hasErrors;
export const correspondenceAttachmentsErrorsSelector = ({
    correspondence: {hasErrorsAttachments},
}: RootState) => hasErrorsAttachments;
export const correspondenceTrackingErrorsSelector = ({
    correspondence: {hasErrorsTracking},
}: RootState) => hasErrorsTracking;
export const correspondenceErrorCodeSelector = ({correspondence: {hasErrorCode}}: RootState) => hasErrorCode;
export const isCorrespondenceLoadingSelector = ({correspondence: {isLoading}}: RootState) =>
    isLoading;
export const isMoreCorrespondenceAvailableSelector = ({
    correspondence: {areMoreResultsAvailable},
}: RootState) => areMoreResultsAvailable;
export const correspondenceTotalCountSelector = ({correspondence: {totalCount}}: RootState) =>
    totalCount;
