import React, {FC, useCallback, useMemo} from 'react';
import range from 'lodash/range';

import {useLocalizationContext, useCurrency} from 'app/hooks';

import {InfoCard} from './components/InfoCard';
import {CardTable, CardTableRow} from './components/CardTable';
import {CardButtonGroup, ButtonProps} from './components/CardButtonGroup';

import {CardsProps} from './Cards.types';
import {DataRow, HandleDataRow} from './Cards.types';
import styles from './Cards.module.scss';

import {useCardState} from './useCardsState';

import {mapInvoices, mapMaterials, mapServices, mapTendering} from './Cards.transformer';

const getEntityId = (st: any): string => `${st?.year || ''}`;

const EntityCard: FC<{
    entities: any[];
    mapEntity: HandleDataRow<any>;
    selectedYears: string[];
    toggleYear(year: string): void;
}> = ({entities, mapEntity, selectedYears, toggleYear, children}) => {
    const {t} = useLocalizationContext();
    const [selectedCurrency] = useCurrency();

    const buttons: ButtonProps[] = useMemo(() => {
        return entities.map(e => ({
            id: getEntityId(e),
            title: e.year,
        }));
    }, [entities]);

    const selectedEntities = useMemo(
        () => entities.filter(e => selectedYears.includes(getEntityId(e))),
        [selectedYears, entities]
    );

    const withPriceLabel = useCallback(
        (price: string) => (selectedCurrency === 'usd' ? `$ ${price}` : `${price} AED`),
        [selectedCurrency]
    );

    const cardTableData = useMemo<CardTableRow[]>(
        () =>
            mapEntity.map(handle => {
                // get sum of values for all selected years
                const dataRow: DataRow = selectedEntities.reduce((acc, cur) => {
                    const result = handle(cur);
                    result.val1 += acc.val1;
                    result.val2.usd += acc.val2.usd;
                    result.val2.aed += acc.val2.aed;
                    return result;
                }, handle({}));

                const cardData: CardTableRow = {
                    title: t(dataRow.title),
                    value1: Math.floor(dataRow.val1).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                    }),
                    value2: withPriceLabel(
                        Math.floor(dataRow.val2[selectedCurrency]).toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                        })
                    ),
                };

                return cardData;
            }),
        [mapEntity, selectedEntities, t, withPriceLabel, selectedCurrency]
    );

    return (
        <InfoCard>
            <div className={styles.cardBody}>
                <h3 className={styles.headline}>{children}</h3>
                <div className={styles.content}>
                    <CardTable data={cardTableData} />
                </div>
                <CardButtonGroup
                    rootProps={{className: styles.btnGroup}}
                    activeIds={selectedYears}
                    buttons={buttons}
                    onSelect={toggleYear}
                />
            </div>
        </InfoCard>
    );
};

export const Cards: FC<CardsProps> = ({
    suppliersTenderings,
    suppliersMaterials,
    suppliersServices,
    suppliersInvoices,
    yearFrom,
    yearTo,
}) => {
    const {t} = useLocalizationContext();

    const [selectedYears, toggleYear] = useCardState(
        range(yearFrom, yearTo + 1).map(item => `${item}`)
    );

    return (
        <div className={styles.cards}>
            <div className={styles.cardsGrid}>
                <EntityCard
                    entities={suppliersTenderings}
                    mapEntity={mapTendering}
                    selectedYears={selectedYears}
                    toggleYear={toggleYear}
                >
                    {t('suppliers.previewPanel.cards.trendingCard.title')}&nbsp;
                    <span>{t('suppliers.previewPanel.cards.trendingCard.subtitle')}</span>
                </EntityCard>
                <EntityCard
                    entities={suppliersMaterials}
                    mapEntity={mapMaterials}
                    selectedYears={selectedYears}
                    toggleYear={toggleYear}
                >
                    {t('suppliers.previewPanel.cards.materialsCard.title')}&nbsp;
                    <span>{t('suppliers.previewPanel.cards.materialsCard.subtitle')}</span>
                </EntityCard>
                <EntityCard
                    entities={suppliersServices}
                    mapEntity={mapServices}
                    selectedYears={selectedYears}
                    toggleYear={toggleYear}
                >
                    {t('suppliers.previewPanel.cards.servicesCard.title')}&nbsp;
                    <span>{t('suppliers.previewPanel.cards.servicesCard.subtitle')}</span>
                </EntityCard>
                <EntityCard
                    entities={suppliersInvoices}
                    mapEntity={mapInvoices}
                    selectedYears={selectedYears}
                    toggleYear={toggleYear}
                >
                    {t('suppliers.previewPanel.cards.invoicesCard.title')}
                </EntityCard>
            </div>
        </div>
    );
};
