import React, {FormEvent} from 'react';
import styles from './filterSearchField.module.scss';
import {useLocalizationContext} from 'app/hooks';
import {ITextFieldFilterProps} from './filterSearchField.types';

export const FilterSearchField = ({
    filter,
    allFilters,
    setValue,
    onSubmit,
}: ITextFieldFilterProps) => {
    const {t} = useLocalizationContext();

    const getValue = (refinementValue: string): string => {
        const currentFilter = allFilters.find(
            f => f.filter === filter.Name && f.refinementValue === refinementValue
        );
        return currentFilter?.value || '';
    };

    const submitHandler = (e: FormEvent): void => {
        e.preventDefault();
        onSubmit();
    };

    const entry = filter.Entries[0];

    return (
        <div className={styles.textFieldFilter}>
            <div key={entry.RefinementName} className={styles.textFieldEntry}>
                <div className={styles.fieldWrapper}>
                    <form onSubmit={submitHandler}>
                        <input
                            value={getValue(entry.RefinementValue)}
                            onChange={e =>
                                setValue(filter.Name, entry.RefinementValue, e.target.value)
                            }
                            type="text"
                            className={styles.field}
                            maxLength={120}
                            placeholder={t('pageContent.searchBar.placeholder')}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
