import React from 'react';
import {NewsListItem} from 'app/views/news/components/newsListItem';
import {NewsListItemSkeleton} from 'app/views/news/components/newsListItemSkeleton';
import {List} from 'app/views/components/list';
import {INewsListProps} from 'app/views/news/components/newsList/newsList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const NewsList = ({
    news,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: INewsListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    itemComponentRender={NewsListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    itemComponentRender={NewsListItem}
                    itemsData={news}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    loadMoreHandler={loadMoreHandler}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
