import {routeParams} from 'app/routes';
import {RootState} from '../store';
import {escape} from 'lodash';

export const locationSelector = ({router}: RootState) => router.location;

export const searchQuerySelector = ({router}: RootState) =>
    decodeURIComponent(router.location.query[routeParams.QUERY] || '');
export const searchWordsSelector = (state: RootState) =>
    searchQuerySelector(state)
        .split(' ')
        .map(word => escape(word));

export const selectedItemIdSelector = ({router}: RootState) => {
    const value = router.location.query[routeParams.SELECTED_ID];

    // it can be undefined or null
    if (typeof value === 'string') return decodeURIComponent(value);
    return value;
};
