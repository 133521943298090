import {createActions} from 'redux-actions';
import {ProjectsSortOptions} from 'app/containers/projects/projects.types';

export const {
    searchProjects,
    searchProjectsSuccess,
    searchProjectsFailure,
    resetProjectsState,
    resetProjectsItems,
    resetProjectsTab,
    applyProjectsFilters,
    setProjectsSortBy,
    setProjectsSortByError,
    getProjectDetails,
    getProjectDetailsSuccess,
    getProjectDetailsFailure,
} = createActions({
    SEARCH_PROJECTS: (query: string) => query,
    SEARCH_PROJECTS_SUCCESS: (news: any) => news,
    SEARCH_PROJECTS_FAILURE: (error: any) => error,
    RESET_PROJECTS_STATE: () => {},
    RESET_PROJECTS_ITEMS: () => {},
    RESET_PROJECTS_TAB: () => {},
    APPLY_PROJECTS_FILTERS: () => {},
    SET_PROJECTS_SORT_BY: (sortOption: ProjectsSortOptions) => sortOption,
    SET_PROJECTS_SORT_BY_ERROR: () => {},
    GET_PROJECT_DETAILS: (payload: any) => payload,
    GET_PROJECT_DETAILS_SUCCESS: (payload: any) => payload,
    GET_PROJECT_DETAILS_FAILURE: (error: any) => error,
});
