import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {useChannelContext} from 'app/hooks';
import {EntityType} from 'app/contexts';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {
    areTrainingsLoadingSelector,
    trainingFiltersSelector,
    trainingsSelector,
    trainingsSortSelector,
    areMoreTrainingsAvailableSelector,
    trainingsTotalCountSelector,
    trainingsErrorCodeSelector,
    ITrainingItem,
    applyTrainingsFilters,
    changeTrainingsSortBy,
    searchTrainings,
    resetTrainingsTab,
    resetTrainingsState,
} from 'app/state/trainings';

import {TrainingsSortOptions} from './trainings.types';
import {ISortOption} from 'app/views/components/sortPanel/sortPanel.types';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {TrainingsPreviewPanel} from 'app/views/trainings';
import {TrainingsListItem} from 'app/views/trainings/components/trainingsListItem';
import {TrainingsListItemSkeleton} from 'app/views/trainings/components/trainingsListItemSkeleton';
import {changeSingleFilter} from 'app/state/filter';
import trainingsChoose from 'app/static/images/trainings.svg';
import noResultsTrainings from 'app/static/images/noResultsTrainings.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const TrainingsSortOptionsI18n = {
    [TrainingsSortOptions.Feature]: 'trainings.trainingsContainer.sortFeature',
    [TrainingsSortOptions.Popularity]: 'trainings.trainingsContainer.sortPopularity',
    [TrainingsSortOptions.NewToOld]: 'trainings.trainingsContainer.sortNewToOld',
    [TrainingsSortOptions.OldToNew]: 'trainings.trainingsContainer.sortOldToNew',
    [TrainingsSortOptions.NameAToZ]: 'trainings.trainingsContainer.sortNameAToZ',
    [TrainingsSortOptions.NameZToA]: 'trainings.trainingsContainer.sortNameZToA',
};

const sortList: ISortOption[] = Object.values(TrainingsSortOptions).map(
    (option: TrainingsSortOptions): ISortOption => ({
        name: TrainingsSortOptionsI18n[option],
        value: option,
    })
);

export const TrainingsContainer = () => {
    const dispatch = useDispatch();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector, shallowEqual);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(trainingsSelector, shallowEqual);
    const areTrainingsLoading = useSelector(areTrainingsLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(areMoreTrainingsAvailableSelector, shallowEqual);
    const filters = useSelector(trainingFiltersSelector, shallowEqual);
    const totalItemsCount: number = useSelector(trainingsTotalCountSelector);
    const currentSort = useSelector(trainingsSortSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(trainingsErrorCodeSelector);

    const selectedItem: ITrainingItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetTrainingsState());
        dispatch(searchTrainings(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetTrainingsTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Training,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!areTrainingsLoading && areMoreResultsAvailable) {
            dispatch(searchTrainings(searchQuery));
        }
    }, [areMoreResultsAvailable, areTrainingsLoading, dispatch, searchQuery]);

    const onSelect = useCallback(
        (training: ITrainingItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: training.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onSortChange = useCallback(
        value => {
            dispatch(changeTrainingsSortBy(value));
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.Training,
                    filters: selectedFilters,
                })
            );
            dispatch(applyTrainingsFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filter = {
        filterProps: filters,
        onApplyFilters,
    };
    const list = {
        itemComponentRender: TrainingsListItem,
        itemSkeletonRender: TrainingsListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };
    const sorting = {
        sortList,
        onSortChange,
        value: currentSort,
    };

    return (
        <SearchResultLayout
            isLoading={areTrainingsLoading}
            filter={filter}
            list={list}
            totalItemsCount={totalItemsCount}
            sorting={sorting}
            selectedItem={selectedItem}
            previewComponent={TrainingsPreviewPanel}
            noResultImageUrl={noResultsTrainings}
            chooseItemImageUrl={trainingsChoose}
            translateRoot={'trainings'}
        />
    );
};
