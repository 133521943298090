import {handleActions} from 'redux-actions';
import {ISearchMailResponse} from 'app/infrastructure/search/searchService.types';
import {
    getMailAttachments,
    getMailAttachmentsFailure,
    getMailAttachmentsSuccess,
    getMailBody,
    getMailBodyFailure,
    getMailBodySuccess,
    resetMailItems,
    resetMailState,
    searchMail,
    searchMailFailure,
    searchMailSuccess,
} from './mail.actions';
import {IMailState} from './mail.types';

const initialState: IMailState = {
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
};

export default handleActions(
    {
        [String(searchMail)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(searchMailSuccess)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            areMoreResultsAvailable: (payload as ISearchMailResponse).areMoreResultsAvailable,
            items: [...state.items, ...(payload as ISearchMailResponse).items],
            hasErrors: payload.hasErrors,
        }),
        [String(searchMailFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetMailState)]: () => initialState,
        [String(resetMailItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
        }),
        [String(getMailBody)]: state => state,
        [String(getMailBodySuccess)]: (state, {payload: {emailId, body}}: any) => ({
            ...state,
            items: state.items.map(item =>
                item.id === emailId
                    ? {
                          ...item,
                          body: body.content,
                      }
                    : item
            ),
            hasErrorCode: undefined,
        }),
        [String(getMailBodyFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(getMailAttachments)]: state => state,
        [String(getMailAttachmentsSuccess)]: (state, {payload: {emailId, attachments}}: any) => ({
            ...state,
            items: state.items.map(item =>
                item.id === emailId
                    ? {
                          ...item,
                          attachments,
                      }
                    : item
            ),
        }),
        [String(getMailAttachmentsFailure)]: state => ({
            ...state,
            isLoading: false,
        }),
    },
    initialState
);
