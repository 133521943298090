import React, {useState} from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import customerStyles from '../../customersPreviewPanel.module.scss';
import {TabButtons} from '../tabButtons';
import {m$Formatter, numberFormatter} from 'app/utils';
import {Table, TableProps} from 'app/views/components/table';

export const SalesBreakdown = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();
    const {
        details: {countries = [], products = []},
    } = data;

    const tabs: Array<{title: string; name: string; tableProps: TableProps}> = [
        {
            title: t('customers.preview.products'),
            name: 'products',
            tableProps: {
                titles: [t('customers.preview.product'), null, t('customers.preview.value')],
                values: products.map(({materialName, amount, unitName, quantityNumber}: any) => [
                    materialName,
                    m$Formatter(amount),
                    `${numberFormatter(quantityNumber)} ${unitName}`,
                ]),
            },
        },
        {
            title: t('customers.preview.countries'),
            name: 'countries',
            tableProps: {
                titles: [t('customers.preview.countries'), null, t('customers.preview.value')],
                values: countries.map(
                    ({countryIso, countryChartName, amount, amountPercentage}: any) => [
                        `${countryIso} - ${countryChartName}`,
                        m$Formatter(amount),
                        `${numberFormatter(amountPercentage)} %`,
                    ]
                ),
            },
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);

    const tableProps = tabs.find(({name}) => name === selectedTab)?.tableProps;
    return (
        <div className={classNames(className)}>
            <div className={customerStyles.containerHeader}>
                <h3>{t('customers.preview.salesBreakdown')}</h3>
                <TabButtons
                    tabs={tabs.map(tab => ({
                        ...tab,
                        active: selectedTab === tab.name,
                    }))}
                    onSelect={setSelectedTab}
                />
            </div>
            {tableProps && (
                <Table
                    {...tableProps}
                    key={selectedTab}
                    tableProps={{className: customerStyles.salesBreakdownTable}}
                />
            )}
        </div>
    );
};
