import {
    searchGraphFederated as searchGraphFederatedAction,
    searchGraphFederatedSuccess,
    searchGraphFederatedFailure,
} from './searchAll.actions';
import {put, takeLatest, all, getContext} from 'redux-saga/effects';

export function* searchGraphFederated({payload}: ReturnType<typeof searchGraphFederatedAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const data = yield searchService.get().msGraphFederatedSearch(payload);
        yield put(searchGraphFederatedSuccess(data));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchGraphFederatedFailure({}));
    }
}

export default function* searchAllSaga() {
    yield all([yield takeLatest(String(searchGraphFederatedAction), searchGraphFederated)]);
}
