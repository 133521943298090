import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {numberFormatter} from 'app/utils';
import {SmallCard} from './smallCard';

export const Overview = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();

    const {
        details: {overview},
    } = data;

    const render = (value: any) => (value ? value : t('customers.preview.NA'));
    const renderNumber = (value: any) =>
        value ? numberFormatter(value) : t('customers.preview.NA');

    const overviewData = overview
        ? [
              {
                  name: t('customers.preview.rank'),
                  value: `${renderNumber(overview.rank)}/${renderNumber(overview.outOf)}`,
              },
              {
                  name: t('customers.preview.salesShare'),
                  unitName: '%',
                  value: render(overview.share),
              },
              {
                  name: t('customers.preview.crudeDailyAvg'),
                  unitName: t('customers.preview.bbl'),
                  value: renderNumber(overview.amount),
              },
          ]
        : [];

    return (
        <SmallCard
            className={className}
            title={t('customers.preview.overview')}
            data={overviewData}
        />
    );
};
