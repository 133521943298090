import {IGeocodeArr} from '../distributionMay.types';
import {Gmarker} from './marker';
import {get} from 'lodash';
import {ConsoleLogger} from 'app/infrastructure/logging';

export const FindByAddress = (map: any, address: string, callback: (data: any) => void) => {
    const google = get(window, 'google', null);
    const logger = new ConsoleLogger();

    if (!address || !map || !google) return null;

    try {
        const service = new google.maps.places.PlacesService(map);

        return service.findPlaceFromQuery({query: address, fields: ['ALL']}, function (
            results: IGeocodeArr
        ) {
            map.setCenter(results[0].geometry.location);

            Gmarker(map, results[0]);

            return callback(results[0]);
        });
    } catch (e) {
        logger.log(e);
        return callback(null);
    }
};
