import React, {FC} from 'react';
import classnames from 'classnames';
import commonStyles from 'app/views/components/filterPanel/styles/filterPanel.module.scss';
import styles from './filterBooleanOntion.module.scss';
import {useLocalizationContext} from 'app/hooks';
import {IFilterBooleanOntionProps} from './filterBooleanOntion.types';

export const FilterBooleanOntion: FC<IFilterBooleanOntionProps> = ({
    filter,
    allFilters,
    filterLabelMapper,
    changeFilterIsCheckedValue,
}) => {
    const {t} = useLocalizationContext();
    const [selectedEntry] = filter.Entries;

    if (!selectedEntry) return null;

    const getValue = (): boolean => {
        const currentFilter = allFilters.find(
            f => f.filter === filter.Name && f.refinementValue === selectedEntry.RefinementValue
        );

        return !!currentFilter?.isChecked;
    };

    const setValue = (): void => {
        changeFilterIsCheckedValue(
            selectedEntry.RefinementName,
            filter.Name,
            false,
            undefined,
            selectedEntry?.value
        );
    };

    return (
        <div className={styles.textFieldFilter}>
            <label className={classnames(commonStyles.property, styles.customProperty)}>
                <span>
                    <input
                        className={styles.checkbox}
                        type="checkbox"
                        checked={getValue()}
                        onChange={() => setValue()}
                    />
                    <span className={styles.checkboxMask} />
                </span>
                {filterLabelMapper ? filterLabelMapper(filter.Name) : t(filter.Name)}
            </label>
        </div>
    );
};
