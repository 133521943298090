import React, {useState} from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';
import styles from './topCStoreProducts.module.scss';
import {Link} from '../../../../components';

interface ITopCStoreProduct {
    topSellingProducts: string;
    averageRevenue: number;
    profitMargin: number;
}

export const TopCStoreProducts = ({
    className,
    products,
    isSmallWidth,
}: {
    className: string;
    products: ITopCStoreProduct[];
    isSmallWidth: boolean;
}) => {
    const {t} = useLocalizationContext();
    const [isTableCollapsed, setIsTableCollapsed] = useState<boolean>(true);
    const visibleTableRows = 5;
    const shownData = isTableCollapsed ? products.slice(0, visibleTableRows) : products;
    const toggleShowMoreProducts = () => {
        setIsTableCollapsed(!isTableCollapsed);
    };
    const titles = [
        t('distribution.preview.topCStoreProducts.name'),
        t('distribution.preview.topCStoreProducts.avgRevenue'),
        t('distribution.preview.topCStoreProducts.profitMargin'),
    ];

    const values = shownData.map(product => [
        product.topSellingProducts,
        product.averageRevenue,
        product.profitMargin,
    ]);

    return (
        <div className={className}>
            <header className={styles.containerTitle}>
                {t('distribution.preview.topCStoreProducts.title')}
            </header>

            <div className={styles.tableContainer}>
                <table
                    className={classNames(
                        styles.table,
                        isSmallWidth && styles.smallContainerDesktop
                    )}
                >
                    <tbody>
                        <tr>
                            {titles.map((title, i) => (
                                <th key={`th-${i}`}>{title}</th>
                            ))}
                        </tr>
                        {values.map((tr, i) => (
                            <tr key={`tr-${i}`}>
                                {tr.map((value, j) => (
                                    <td key={`value-${i}-${j}`}>
                                        {typeof value === 'string' && value.length > 25 ? (
                                            <span className={styles.textValue} title={value}>
                                                {value}
                                            </span>
                                        ) : (
                                            value
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Link onClick={toggleShowMoreProducts} component="button" className={styles.link}>
                {isTableCollapsed ? t('showMore') : t('showLess')}
            </Link>
        </div>
    );
};
