import React from 'react';
import {unescape} from 'html-escaper';
import {sanitize} from 'dompurify';

export const HtmlContent = ({content, className}: {content: string; className?: string}) => (
    <div
        className={className}
        dangerouslySetInnerHTML={{__html: content && sanitize(unescape(content))}}
    />
);
