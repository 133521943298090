import React from 'react';
import {ColorLabel, colors} from 'app/views/components/colorLabel/colorLabel';

export const TypeLabel = ({children}: {children: string}) => <ColorLabel>{children}</ColorLabel>;

export const StateLabel = ({children}: {children: string}) => (
    <ColorLabel color="gray">{children}</ColorLabel>
);

export const PriorityLabel = ({children}: {children: string}) => {
    const priorityLabel = children.split(' - ')[1];
    let color: colors | undefined;
    switch (priorityLabel) {
        case 'Low':
        case 'Planning':
            color = 'green';
            break;
        case 'Moderate':
        case 'Medium':
            color = 'orange';
            break;
        case 'High':
        case 'Critical':
            color = 'red';
    }

    return <ColorLabel color={color}>{priorityLabel}</ColorLabel>;
};
