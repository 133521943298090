import {
    ClientType,
    IEventDescriptor,
    IMessageDescriptor,
} from 'app/contexts/channelContext/channelContext.types';

export const sendMessage = (
    eventDescriptor: IEventDescriptor,
    client: ClientType = ClientType.OneHub
) => {
    const msgDescriptor: IMessageDescriptor = {
        client,
        author: ClientType.Search,
        msg: eventDescriptor,
    };

    window.parent?.postMessage(msgDescriptor, '*');
};
