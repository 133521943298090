import React from 'react';
import {
    DocumentListItem,
    DocumentListItemSkeleton,
} from 'app/views/documents/components/documentListItem';
import {List} from 'app/views/components';
import {IDocumentListProps} from './documentList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const DocumentList = ({
    documents,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: IDocumentListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    itemComponentRender={DocumentListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    itemComponentRender={DocumentListItem}
                    itemsData={documents}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    loadMoreHandler={loadMoreHandler}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
