import React from 'react';
import styles from './filterButton.module.scss';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';
import {RectSkeleton} from 'app/views/components/skeleton';

export interface IFilterButtonProps {
    isLoading: boolean;
    onClick: () => void;
    className?: string;
}

export const FilterButton = ({onClick, isLoading, className}: IFilterButtonProps) => {
    const {t} = useLocalizationContext();

    const filterButtonClasses = classNames('desktop-hidden', styles.filterButton, className);

    return isLoading ? (
        <div className={'desktop-hidden'}>
            <RectSkeleton width={61} />
        </div>
    ) : (
        <button className={filterButtonClasses} onClick={onClick}>
            {t('components.filterButton.button')}
        </button>
    );
};
