import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import isUndefined from 'lodash/isUndefined';
import without from 'lodash/without';
import styles from './styles/filterPanel.module.scss';
import {FilterPanelSkeleton} from './filterPanelSkeleton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import {
    FilterType,
    IAllFilters,
    IFilter,
    IFilterPanelProps,
    IFilterRefiner,
    IFiltersMapProps,
    IFiltersToggle,
} from './filterPanel.types';

import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import {Filter} from './filter';
import {hideFiltersIconStyles} from './styles';
import {FilterDatesRange} from 'app/views/components/filterPanel/filterDatesRange/filterDatesRange';
import {FilterTextField} from './filterTextField/filterTextField';
import {FilterSearchField} from './filterSearchField/filterSearchField';
import {FilterBooleanOntion} from './filterBooleanOntion/filterBooleanOntion';

export const arrowIconsStyles = {
    width: '18px',
    height: '18px',
};

export const FiltersMap = ({
    filters,
    filterLabelMapper,
    toggle,
    checkFilterIsActiveToggle,
    changeFilterIsCheckedValue,
    changeSingleFilterValue,
    getFilterIsCheckedValue,
    findCheckedFilters,
    allFilters,
    setAllFilters,
    setFilterDateValue,
    getFilterDateValue,
    setTextFilterValue,
    onSubmit,
}: IFiltersMapProps) => (
    <>
        {filters.map((filter, index) => {
            switch (filter.type) {
                case FilterType.DateRange:
                    return (
                        <FilterDatesRange
                            key={index}
                            filter={filter}
                            getFilterDateValue={getFilterDateValue}
                            setFilterDateValue={setFilterDateValue}
                            arrowIconsStyles={arrowIconsStyles}
                            toggle={toggle}
                            checkFilterIsActiveToggle={checkFilterIsActiveToggle}
                            filterLabelMapper={filterLabelMapper}
                        />
                    );
                case FilterType.SingleCheckbox:
                    return (
                        <Filter
                            filter={filter}
                            key={index}
                            filterLabelMapper={filterLabelMapper}
                            toggle={toggle}
                            checkFilterIsActiveToggle={checkFilterIsActiveToggle}
                            changeFilterIsCheckedValue={changeSingleFilterValue}
                            getFilterIsCheckedValue={getFilterIsCheckedValue}
                            checkedFiltersAmount={
                                findCheckedFilters(allFilters, filter.Name).length
                            }
                            arrowIconsStyles={arrowIconsStyles}
                        />
                    );
                case FilterType.TextField:
                    return (
                        <FilterTextField
                            key={index}
                            filter={filter}
                            allFilters={allFilters}
                            arrowIconsStyles={arrowIconsStyles}
                            toggle={toggle}
                            checkFilterIsActiveToggle={checkFilterIsActiveToggle}
                            filterLabelMapper={filterLabelMapper}
                            setValue={setTextFilterValue}
                            onSubmit={onSubmit}
                        />
                    );
                case FilterType.SearchField:
                    return (
                        <FilterSearchField
                            key={index}
                            filter={filter}
                            allFilters={allFilters}
                            setValue={setTextFilterValue}
                            onSubmit={onSubmit}
                        />
                    );
                case FilterType.BooleanOption:
                    return (
                        <FilterBooleanOntion
                            key={index}
                            filter={filter}
                            allFilters={allFilters}
                            filterLabelMapper={filterLabelMapper}
                            changeFilterIsCheckedValue={changeFilterIsCheckedValue}
                        />
                    );
                case FilterType.Radio:
                case FilterType.Checkbox:
                default:
                    return (
                        <Filter
                            filter={filter}
                            key={index}
                            filterLabelMapper={filterLabelMapper}
                            toggle={toggle}
                            checkFilterIsActiveToggle={checkFilterIsActiveToggle}
                            changeFilterIsCheckedValue={changeFilterIsCheckedValue}
                            getFilterIsCheckedValue={getFilterIsCheckedValue}
                            checkedFiltersAmount={
                                findCheckedFilters(allFilters, filter.Name).length
                            }
                            arrowIconsStyles={arrowIconsStyles}
                            isStarsFilter={filter.Name === 'ratings'}
                        />
                    );
            }
        })}
    </>
);

export const FilterPanel = ({
    filterProps,
    onApplyFilters,
    onCancel,
    mobileLabel,
    isLoading,
    filterLabelMapper,
    onHideFiltersClick,
}: IFilterPanelProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [filters, setFilters] = useState<IFilterRefiner[]>([]);
    const [allFilters, setAllFilters] = useState<IAllFilters[]>([]);
    const [activatedFilters, setActivatedFilters] = useState<IAllFilters[]>([]);
    const [appliedFilters, setAppliedFilters] = useState<IAllFilters[]>([]);
    const [filterListToggle, setFilterListToggle] = useState<IFiltersToggle[]>([]);
    const {t} = useLocalizationContext();

    useEffect(() => {
        const {current: container} = containerRef;

        if (!container) return;

        let timeoutId: null | ReturnType<typeof setTimeout> = null;

        const onScroll = (): void => {
            const input = document.activeElement as HTMLInputElement;
            if (input?.tagName !== 'INPUT') return;

            timeoutId && clearTimeout(timeoutId);

            input.style.caretColor = 'transparent';

            timeoutId = setTimeout((): void => {
                input.style.caretColor = 'inherit';
            }, 500);
        };

        container.addEventListener('scroll', onScroll);

        return () => {
            container.removeEventListener('scroll', onScroll);
        };
    }, []);

    const toggle = (filterName: string) => {
        const changeToggle: IFiltersToggle[] = filterListToggle.map((filter: any) =>
            filter.filterName === filterName
                ? {...filter, isActiveToggle: !filter.isActiveToggle}
                : {...filter}
        );
        setFilterListToggle(changeToggle);
    };

    const findFilter = useCallback(
        (filterName: string) => filters.find(filter => filter.Name === filterName),
        [filters]
    );
    const findEntry = useCallback(
        (filterName: string, entryName: string) =>
            findFilter(filterName)?.Entries.find(
                ({RefinementName}) => RefinementName === entryName
            ),
        [findFilter]
    );
    const findFilterToggle = (filterName: string) =>
        filterListToggle.find((toggle: any) => toggle.filterName === filterName);
    const checkFilterIsActiveToggle = (filterName: string) =>
        Boolean(findFilterToggle(filterName)?.isActiveToggle);
    const findCheckedFilters = (filters: IAllFilters[], filterName?: string) =>
        filters.filter(({filter, isChecked}: IAllFilters) =>
            filterName ? filterName === filter && isChecked : isChecked
        );
    const findAllFiltersFilter = useCallback(
        (filterName: string, refinementValue: string) =>
            allFilters.find(
                oldFilter =>
                    refinementValue === oldFilter.refinementValue && filterName === oldFilter.filter
            ),
        [allFilters]
    );
    const checkIfShouldBeSorted = (filter: IFilterRefiner, entry: IFilter) =>
        entry.isChecked && !(filter?.type === FilterType.DateRange);

    const setInitialFilters = useCallback(() => {
        const sortedFilters = filterProps.map(refiner => ({
            ...refiner,
            Entries: refiner.Entries.map((entry: IFilter) => ({
                ...entry,
                isChecked: Boolean(entry.isChecked),
            })).sort(entry => (checkIfShouldBeSorted(refiner, entry) ? -1 : 1)),
        }));
        const filters: IAllFilters[] = sortedFilters.flatMap(({Entries, Name}: IFilterRefiner) =>
            Entries.map(entry => ({
                filter: Name,
                refinementName: entry.RefinementName,
                refinementValue: entry.RefinementValue,
                isChecked: Boolean(entry.isChecked),
                value: entry.value,
            }))
        );
        const filtersToggle: IFiltersToggle[] = sortedFilters.map(({Name}: IFilterRefiner) => ({
            filterName: Name,
            isActiveToggle: true,
        }));
        const checkedFilters = findCheckedFilters(filters);

        setFilters(sortedFilters);
        setAllFilters(filters);
        setFilterListToggle(filtersToggle);
        setAppliedFilters(checkedFilters);
    }, [filterProps]);

    const onCancelClick = useCallback(() => {
        setInitialFilters();
        onCancel();
    }, [onCancel, setInitialFilters]);

    useEffect(() => {
        setInitialFilters();
    }, [filterProps, setInitialFilters]);

    useEffect(() => {
        setActivatedFilters(findCheckedFilters(allFilters));
    }, [allFilters]);

    const moveEntryToTop = useCallback(
        (filterName: string, refinementName: string) => {
            const entry = findEntry(filterName, refinementName);

            return filters.map(filter =>
                filter.Name === filterName && entry
                    ? {...filter, Entries: [entry, ...without(filter.Entries, entry)]}
                    : filter
            );
        },
        [filters]
    );

    const changeFilterValue = useCallback(
        (
            refinementName: string,
            filterName: string,
            shouldMoveToTop?: boolean,
            isChecked?: boolean,
            value?: any
        ) => {
            const filterTypesMap = filters.reduce((map: any, f) => {
                map[f.Name] = f.type;
                return map;
            }, {});

            const resultAllFilters: IAllFilters[] = allFilters.map((entry: IAllFilters) => {
                const newCheckedValue = isUndefined(isChecked) ? !entry.isChecked : isChecked;

                if (
                    entry.filter === filterName &&
                    filterTypesMap[entry.filter] === FilterType.Radio
                ) {
                    return entry.refinementName === refinementName
                        ? {
                              ...entry,
                              isChecked: newCheckedValue,
                              value,
                          }
                        : {...entry, isChecked: false};
                }

                return entry.filter === filterName && entry.refinementName === refinementName
                    ? {
                          ...entry,
                          isChecked: newCheckedValue,
                          value,
                      }
                    : {...entry};
            });

            const resultFilters = shouldMoveToTop
                ? moveEntryToTop(filterName, refinementName)
                : filters;

            setFilters(resultFilters);
            setAllFilters(resultAllFilters);
        },
        [allFilters, filters, findEntry]
    );
    const changeSingleFilterValue = useCallback(
        (refinementName: string, filterName: string, shouldMoveToTop?: boolean) => {
            const resultAllFilters: IAllFilters[] = allFilters.map((filter: IAllFilters) => {
                if (filter.filter !== filterName) {
                    return filter;
                }

                if (filter.refinementName !== refinementName) {
                    return {...filter, isChecked: false};
                }

                return {...filter, isChecked: true};
            });
            const entry = findEntry(filterName, refinementName);
            let resultFilters = filters;

            if (shouldMoveToTop) {
                resultFilters = filters.map(filter =>
                    filter.Name === filterName && entry
                        ? {...filter, Entries: [entry, ...without(filter.Entries, entry)]}
                        : filter
                );
            }

            setFilters(resultFilters);
            setAllFilters(resultAllFilters);
        },
        [allFilters, filters, findEntry]
    );

    const getFilterIsCheckedValue = (filterName: string): boolean =>
        Boolean(allFilters.find(({refinementName}) => refinementName === filterName)?.isChecked);

    const singleFilterNames = filterProps
        .filter(p => p.type === FilterType.SingleCheckbox)
        .map(p => p.Name);

    const clearAllFilters = () => {
        const isClearedFilters: any = allFilters.map((filter: IAllFilters) => {
            // skip clearing for Single Checkbox filter with default value
            if (singleFilterNames.includes(filter.filter)) return filter;

            return {
                ...filter,
                isChecked: false,
                value: null,
            };
        });
        setAllFilters(isClearedFilters);
        setActivatedFilters([]);
    };

    const applyFilters = () => {
        const isCheckedFilters = findCheckedFilters(allFilters);
        setAppliedFilters(isCheckedFilters);
        onApplyFilters?.(isCheckedFilters);
        onCancel();
    };

    const getFilterDateValue = useCallback(
        (filterName, refinementValue) => findAllFiltersFilter(filterName, refinementValue)?.value,
        [findAllFiltersFilter]
    );

    const setDatePickerSelectedValue = useCallback(
        (filter: IFilterRefiner, entry: IFilter, date: number | null) => {
            const currentFilter = findAllFiltersFilter(filter.Name, entry.RefinementValue);
            const isSameValue = currentFilter?.value === date;

            if (!isSameValue) {
                changeFilterValue(entry.RefinementName, filter.Name, false, Boolean(date), date);
            }
        },
        [changeFilterValue, findAllFiltersFilter]
    );

    const isDisabledApplyButton = isEqual(appliedFilters, activatedFilters);

    const buttonClasses = classNames('desktop-hidden', styles.cancelButton);
    const applyButtonClasses = classNames(styles.applyButton, {
        [styles.applyButtonDisabled]: isDisabledApplyButton,
    });

    const headerActionsClasses = classNames('mobile-hidden', styles.headerActions);

    const showFooter = useMemo<boolean>(() => {
        return !!activatedFilters.length || !!appliedFilters.length;
    }, [activatedFilters, appliedFilters]);

    const withoutSingle = (f: IAllFilters) => !singleFilterNames.includes(f.filter);

    const clearBtnClasses = classNames(styles.clearAll, {
        [styles.clearAllDisabled]:
            !activatedFilters.filter(withoutSingle).length &&
            !activatedFilters.filter(withoutSingle).length,
    });

    const setTextFilterValue = (filterName: string, entryName: string, value: string): void => {
        const resultAllFilters: IAllFilters[] = allFilters.map((filter: IAllFilters) => {
            if (filter.filter !== filterName || filter.refinementValue !== entryName) {
                return filter;
            }

            if (value && value.length) {
                return {...filter, isChecked: true, value};
            }

            return {...filter, isChecked: false, value: ''};
        });
        setFilters(filters);
        setAllFilters(resultAllFilters);
    };

    return (
        filterProps && (
            <div className={styles.filterPanel}>
                {isLoading ? (
                    <FilterPanelSkeleton />
                ) : (
                    <>
                        <div className={styles.header}>
                            <span className={headerActionsClasses}>
                                {t('components.filterPanel.filtersLabel')}
                                <span
                                    className={styles.hideFiltersAction}
                                    onClick={onHideFiltersClick}
                                >
                                    <ArrowBackIos style={hideFiltersIconStyles} />
                                    {t('components.filterPanel.hide')}
                                </span>
                            </span>
                            <span className="desktop-hidden">{mobileLabel}</span>
                            <button
                                className={buttonClasses}
                                onClick={onCancelClick}
                                aria-label={t('components.filterPanel.cancelButtonAriaLabel')}
                            />
                        </div>
                        <div className={styles.container} ref={containerRef}>
                            <FiltersMap
                                filters={filters}
                                filterLabelMapper={filterLabelMapper}
                                toggle={toggle}
                                checkFilterIsActiveToggle={checkFilterIsActiveToggle}
                                changeFilterIsCheckedValue={changeFilterValue}
                                changeSingleFilterValue={changeSingleFilterValue}
                                getFilterIsCheckedValue={getFilterIsCheckedValue}
                                findCheckedFilters={findCheckedFilters}
                                allFilters={allFilters}
                                setAllFilters={setAllFilters}
                                setFilterDateValue={setDatePickerSelectedValue}
                                getFilterDateValue={getFilterDateValue}
                                setTextFilterValue={setTextFilterValue}
                                onSubmit={isDisabledApplyButton ? () => {} : applyFilters}
                            />
                        </div>
                        {showFooter && (
                            <div className={styles.footer}>
                                <span className={clearBtnClasses} onClick={clearAllFilters}>
                                    {t('components.filterPanel.clearAll')}
                                </span>
                                <button
                                    className={applyButtonClasses}
                                    onClick={applyFilters}
                                    disabled={isDisabledApplyButton}
                                >
                                    {t('components.filterPanel.apply')}
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    );
};
