import React from 'react';
import {RectSkeleton} from 'app/views/components/skeleton';
import styles from './styles/filterPanel.module.scss';

const HeaderSkeleton = () => (
    <div className={styles.headerSkeleton}>
        <RectSkeleton width={80} />
    </div>
);
const FilterSkeleton = () => (
    <>
        <div className={styles.filterNameSkeleton}>
            <RectSkeleton width={56} />
        </div>
        <div className={styles.filterSkeleton}>
            <RectSkeleton width={184} />
        </div>
        <div className={styles.filterSkeleton}>
            <RectSkeleton width={100} />
        </div>
        <div className={styles.filterSkeleton}>
            <RectSkeleton width={158} />
        </div>
        <div className={styles.filterSkeleton}>
            <RectSkeleton width={184} />
        </div>
    </>
);

export const FilterPanelSkeleton = () => (
    <div className={styles.skeletons}>
        <HeaderSkeleton />
        <FilterSkeleton />
        <FilterSkeleton />
    </div>
);
