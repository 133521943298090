import {DocumentsSortOptions} from 'app/containers/documents';
import {handleActions} from 'redux-actions';
import {
    resetDocumentsItems,
    resetDocumentsState,
    searchDocuments,
    searchDocumentsFailure,
    searchDocumentsSuccess,
    setDocumentsFilters,
    setDocumentsSortBy,
} from './documents.actions';
import {IDocumentsState} from 'app/state/documents/documents.types';

const initialState: IDocumentsState = {
    items: [],
    filters: [],
    sortBy: DocumentsSortOptions.NoSort,
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
    totalCount: 0,
};

export default handleActions(
    {
        [String(resetDocumentsState)]: () => initialState,
        [String(resetDocumentsItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
            totalCount: 0,
        }),
        [String(searchDocuments)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(searchDocumentsSuccess)]: (state, {payload}: any) => ({
            ...state,
            items: [...state.items, ...payload.items],
            filters: payload.filters,
            isLoading: false,
            hasErrors: payload.hasErrors,
            areMoreResultsAvailable: payload.areMoreResultsAvailable,
            totalCount: payload.totalCount,
        }),
        [String(searchDocumentsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrors: true,
            hasErrorCode: payload.responseStatus,
        }),
        [String(setDocumentsSortBy)]: (state, {payload}: any) => ({
            ...state,
            sortBy: payload as DocumentsSortOptions,
        }),
        [String(setDocumentsFilters)]: (state, {payload}: any) => ({
            ...state,
            filters: payload,
        }),
    },
    initialState
);
