import {ISearchMailOptions} from 'app/api/searchAPI/mails/searchAPI.mails.types';
import {getFormattedDate} from 'app/utils/convertDate';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {MailAttachmentFilterValues, MailFilterTypes} from './filters';

export const getOptionsFromFilter = (filters: IFilterRefiner[]): Partial<ISearchMailOptions> => {
    const dateFilter = filters.find(f => f.Name === MailFilterTypes.Date);
    const attachmentFilter = filters.find(f => f.Name === MailFilterTypes.Attachments);
    const senderFilter = filters.find(f => f.Name === MailFilterTypes.Sender);

    const options: Partial<ISearchMailOptions> = {};

    if (dateFilter) {
        dateFilter.Entries.filter(e => e.isChecked).forEach(e => {
            options[e.RefinementValue as 'from' | 'to'] = getFormattedDate(e.value);
        });
    }

    if (attachmentFilter) {
        const selectedAttachment = attachmentFilter.Entries.find(
            e => e.RefinementValue === MailAttachmentFilterValues.HasAttachment
        );
        if (selectedAttachment?.isChecked) {
            options.hasAttachments = true;
        }
    }

    if (senderFilter) {
        senderFilter.Entries.filter(entry => !!entry.value).forEach(entry => {
            options[entry.RefinementValue as 'fromSender' | 'toSender'] = entry.value;
        });
    }

    return options;
};
