import {handleActions} from 'redux-actions';
import {
    searchPeople,
    searchPeopleFailure,
    searchPeopleSuccess,
    resetPeopleState,
    loadEmployeeProfile,
    loadEmployeeProfileSuccess,
    loadEmployeeProfileFailure,
    loadEmployeeActivity,
    loadEmployeeActivitySuccess,
    loadEmployeeActivityFailure,
    applyEmployeeFilters,
    clearEmployeeActivity,
    resetPeopleItems,
} from './people.actions';
import {IPeopleState} from 'app/state/people/people.types';
import uniqBy from 'lodash/uniqBy';

const initialState: IPeopleState = {
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    skipAmount: 0,
    employeeProfile: null,
    employeeActivity: null,
    isActivityLoading: false,
    isLoadingEmployeeProfile: false,
    hasErrors: false,
    hasErrorCode: undefined,
};

const idField = 'id';

export default handleActions(
    {
        [String(searchPeople)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(searchPeopleSuccess)]: (state, {payload}: any) => ({
            ...state,
            items: uniqBy([...state.items, ...payload.items], idField),
            areMoreResultsAvailable: payload.areMoreResultsAvailable,
            skipAmount: payload.skipAmount,
            isLoading: false,
            hasErrors: false,
        }),
        [String(searchPeopleFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            areMoreResultsAvailable: false,
            skipAmount: 0,
            hasErrors: true,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetPeopleState)]: () => initialState,
        [String(resetPeopleItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
        }),
        [String(loadEmployeeProfile)]: state => ({
            ...state,
            employeeProfile: null,
            isLoadingEmployeeProfile: true,
        }),
        [String(loadEmployeeProfileSuccess)]: (state, {payload}: any) => ({
            ...state,
            employeeProfile: payload,
            isLoadingEmployeeProfile: false,
        }),
        [String(loadEmployeeProfileFailure)]: state => ({
            ...state,
            employeeProfile: null,
            isLoadingEmployeeProfile: false,
        }),
        [String(loadEmployeeActivity)]: state => ({
            ...state,
            employeeActivity: null,
            isActivityLoading: true,
        }),
        [String(loadEmployeeActivitySuccess)]: (state, {payload}: any) => ({
            ...state,
            employeeActivity: payload,
            isActivityLoading: false,
        }),
        [String(loadEmployeeActivityFailure)]: state => ({
            ...state,
            employeeActivity: null,
            isActivityLoading: false,
        }),
        [String(applyEmployeeFilters)]: (state, {payload}: any) => ({
            ...state,
            skipAmount: 0,
        }),
        [String(clearEmployeeActivity)]: state => ({
            ...state,
            employeeActivity: null,
            isActivityLoading: false,
        }),
    },
    initialState
);
