import {inject, injectable} from 'inversify';
import {ISearchServiceTenders} from './searchService.tenders.types';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {
    ISearchTendersOptions,
    ITendersListItem,
} from 'app/api/searchAPI/tenders/searchAPICaller.tenders.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {SearchAPICallerTenders} from 'app/api/searchAPI/tenders/searchAPICaller.tenders';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {createTendersFilters} from '../searchService.transformer';

@injectable()
export class SearchServiceTenders implements ISearchServiceTenders {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchTenders(options: ISearchTendersOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerTenders.searchTenders(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse([]) as ITendersListItem[];
                }
            }
        );
    }

    public async searchTenderFilters(): Promise<IFilterRefiner[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const filters = await SearchAPICallerTenders.searchTenderFilters(
                    appConfig.skey_38,
                    accessToken
                );
                return createTendersFilters(filters);
            },
            (e: any) => {
                this.logger.error(e);
                return [];
            }
        );
    }
}
