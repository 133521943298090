import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {appConfig} from 'app/config';

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: appConfig.keys.skey_48,
        connectionString: `InstrumentationKey=${appConfig.keys.skey_48}`,
        disableFetchTracking: false,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: true,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        isStorageUseDisabled: true,
        maxAjaxCallsPerView: 250, // default is 500
        samplingPercentage: 75, // default is 100 ( % )
    },
});

export {appInsights};
