import {EntityType} from '../common.types';

import {ICorrespondenceItem} from 'app/state/correspondence/correspondence.types';
import {IDocumentItem} from 'app/state/documents/documents.types';
import {IPeopleItem} from 'app/state/people';
import {INewsItem} from 'app/state/news';
import {ITenderItem} from 'app/state/tenders';
import {IProjectItem} from 'app/state/projects';
import {IServiceItem} from 'app/state/serviceNow';
import {IMailItem} from 'app/state/mail/mail.types';
import {ITrainingItem} from 'app/state/trainings';
import {ISupplierItem} from 'app/state/suppliers';
import {ICustomerItem} from 'app/state/customers';
import {IDistributionItem} from 'app/state/distribution';

export interface IChannelContext {
    onOpenLink: (msg: IOnOpenLinkMessage) => void;
    onDownloadLink: (msg: IOnDownloadLinkMessage) => void;
    onPreviewLink: (msg: IOnDownloadLinkMessage) => void;
    onOpenProfile: (msg: IOnOpenEmployeeProfileMessage) => void;
    onOpenOrgChart: (msg: IOnOpenOrgChartMessage) => void;
    onMobileMailClientOpen: (msg: IOnMobileMailClientOpen) => void;
    usePreviewItem: (msg: IOnPreviewItemMessage) => void;
    onDocumentPreview: (msg: IOnDocumentPreviewMessage) => void;
    onDocumentPreviewMobile: (msg: IOnDocumentPreviewMessage) => void;
    onGoToEServiceProfile: (msg: IOnOpenProfileCompletnessMessage) => void;
    onEmployeeHintShow: (msg: IOnEmployeeHintShow) => void;
    onUpdateTokenFromParent: () => void;
}

export enum EventType {
    // ClientType.OneHub
    Search = 'search',
    Nav = 'nav',
    Hint = 'hint',
    OpenProfile = 'openProfile',
    OpenOrgChart = 'openOrgChart',
    TokenRotation = 'token-rotation',

    // ClientType.RN events
    OpenLink = 'openLink',
    DownloadLink = 'downloadLink',
    PreviewLink = 'previewLink',
    OpenCorrespondenceDetails = 'openCorrespondenceDetails',
    ShowToastr = 'showToastr',
    ShowPdf = 'showPdf',
    PreviewClick = 'previewClick',
    OpenMailClient = 'openMailApp',
    OpenProfileCompleteness = 'openProfileCompleteness',
}

export enum ClientType {
    OneHub = 'oneHub',
    Search = 'search',
    RN = 'RN',
}

export enum NavigationType {
    Back = 'back',
}

export class EventBase {
    private eventType: EventType;

    constructor(eventType: EventType) {
        this.eventType = eventType;
    }

    get type() {
        return this.eventType;
    }
}

export interface IEventDescriptor {
    eventType?: string;
    eventData?: any;
    entityId?: any;
    entityType?: any;
}

export interface IMessageDescriptor {
    client: string;
    author: string;
    msg: IEventDescriptor;
}

export interface IToastrMessageDescriptor {
    client: string;
    author: string;
    application: string;
    requestId: string;
    status: string;
    toaster: {
        id: string;
        type: string;
        action: string;
        message: string;
    };
}

export interface IDocumentPreviewMessageDescriptor {
    client: string;
    author: string;
    msg: IEventDescriptor;
}

export interface IDocumentPreviewMessageMobileDescriptor {
    client: string;
    author: string;
    application: string;
    // msg: ISendPreviewMessageMobile; // TODO remove after check
    generated_at: number;
    id: string;
    payload: {
        eventCode: string;
        downloadUrl: string;
        url: string;
    };
}

export interface ISendDocumentPreviewMessage {
    action: string;
    payload: {
        downloadUrl: string;
        url: string;
        extension: string;
    };
}

// TODO remove after check
// export interface ISendPreviewMessageMobile {
//     generated_at: number;
//     id: string;
//     payload: {
//         eventCode: string;
//         downloadUrl: string;
//         url: string;
//     };
// }

export type IOnPreviewItemMessage =
    | {
          entityType: null;
          entity: null;
      }
    | {
          entityType: EntityType.Employee;
          entity: IPeopleItem;
      }
    | {
          entityType: EntityType.Training;
          entity: ITrainingItem;
      }
    | {
          entityType: EntityType.Email;
          entity: IMailItem;
      }
    | {
          entityType: EntityType.Document;
          entity: IDocumentItem;
      }
    | {
          entityType: EntityType.Correspondence;
          entity: ICorrespondenceItem;
      }
    | {
          entityType: EntityType.News;
          entity: INewsItem;
      }
    | {
          entityType: EntityType.Tender;
          entity: ITenderItem;
      }
    | {
          entityType: EntityType.Projects;
          entity: IProjectItem;
      }
    | {
          entityType: EntityType.Service;
          entity: IServiceItem;
      }
    | {
          entityType: EntityType.Suppliers;
          entity: ISupplierItem;
      }
    | {
          entityType: EntityType.Customers;
          entity: ICustomerItem;
      }
    | {
          entityType: EntityType.Distribution;
          entity: IDistributionItem;
      }
    | null;

export interface IOnOpenLinkMessage {
    absoluteURL: string;
}

export interface IOnDownloadLinkMessage {
    absoluteURL: string | {pathname: string; origin: string; search: string};
    // documentName: string;
}

export interface IOnDocumentPreviewMessage {
    downloadUrl: string;
    previewUrl: string;
}

export interface IOnPreviewLinkMessage {
    absoluteURL: string | {pathname: string; origin: string; search: string};
}

export interface IOnOpenEmployeeProfileMessage {
    email: string;
}

export interface IOnOpenProfileCompletnessMessage {
    url: string;
}

export interface IOnOpenOrgChartMessage {
    email: string;
}

export interface IOnMobileMailClientOpen {
    email: string;
}

export interface IOnEmployeeHintShow {
    isVisible: boolean;
}

export enum NodeType {
    Root = 'root',
    Leaf = 'nested',
}
