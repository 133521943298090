import React, {useCallback, useEffect, useState} from 'react';
import {has} from 'lodash';
import classNames from 'classnames';
import {DistributionMapDetails} from '../distributionMapDetails';
import {useGoogleMap} from 'app/hooks/useGoogleMap';
import {GMapInitalize} from './map/initialize';
import {IGMapInitalize} from './distributionMay.types';
import {DistributionMapPanelCard} from './distributionMapPanelCard';

import errorStyles from 'app/views/components/errors/error/error.module.scss';
import styles from './distributionMap.module.scss';

export const DistributionMap = ({
    className,
    isSmall,
    data,
}: {
    className?: string;
    isSmall: boolean;
    data: any;
}) => {
    const [gData, setGData] = useState(null);
    const [errorLoadingMap, setErrorLoadingMap] = useState(null);
    const {placeId, cityName, id} = data;
    const mapRef = React.useRef<HTMLDivElement>(null);
    const _GMapInitalize = useCallback(GMapInitalize, []);
    const GMAP = useGoogleMap<IGMapInitalize | null>(mapRef, _GMapInitalize, setErrorLoadingMap);

    useEffect(() => {
        if (has(GMAP, 'map')) {
            let zoom = 16;

            switch (true) {
                case !!placeId: {
                    GMAP?.placeIdAddress(GMAP.map, placeId, setGData);

                    break;
                }
                case !!cityName: {
                    GMAP?.codeAddress(GMAP.map, `${id} ${cityName}`, setGData);

                    break;
                }
                default:
                    zoom = 10;
            }

            GMAP?.map?.setZoom(zoom);
        }
    }, [GMAP, placeId, cityName]);

    return (
        <div className={classNames(className, styles.container, isSmall && styles.containerSmall)}>
            <DistributionMapDetails
                className={classNames(styles.details)}
                data={data}
                gData={gData}
            />
            <div className={styles['map-container']}>
                <DistributionMapPanelCard gData={gData} data={data} />

                {errorLoadingMap ? (
                    <div className={classNames(styles['g-map__error'], errorStyles.title)}>
                        Error loading map
                    </div>
                ) : (
                    <div
                        ref={mapRef}
                        className={styles['g-map']}
                        style={{width: '100%', height: '100%'}}
                    />
                )}
            </div>
        </div>
    );
};
