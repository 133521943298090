import {toLower} from 'lodash';

type TheObjType = {
    [key: string]: any;
};
export const transformStringToObject = (string: string, separator: string = ' ') => {
    if (!string) {
        return null;
    }

    const temp = string.split(separator);
    const theObj: TheObjType = {};

    for (let i = 0; i < temp.length; i += 2) {
        theObj[toLower(temp[i])] = temp[i + 1];
    }

    return theObj;
};
