import React from 'react';
import {CircleSkeleton, RectSkeleton} from 'app/views/components/skeleton';
import styles from './mailListItemSkeleton.module.scss';

export const MailListItemSkeleton = () => (
    <div className={styles.skeleton}>
        <div className={styles.thumbnail}>
            <CircleSkeleton />
        </div>
        <div className={styles.info}>
            <div className={styles.textInfo}>
                <RectSkeleton width={144} />
                <RectSkeleton width={70} />
            </div>
            <div className={styles.textInfo}>
                <RectSkeleton width={102} />
            </div>
            <div className={styles.textInfo}>
                <RectSkeleton width={207} />
            </div>
            <div className={styles.textInfo}>
                <RectSkeleton width={175} />
            </div>
        </div>
    </div>
);
