import {handleActions} from 'redux-actions';
import {TendersSortOptions} from 'app/containers/tenderBoard/tenderBoard.types';
import {
    resetTendersItems,
    resetTendersState,
    searchTenders,
    searchTendersFailure,
    searchTendersSuccess,
    setTendersSortBy,
} from './tenders.actions';

import {ITenderItem} from './tender.types';

export interface ITendersState {
    items: ITenderItem[];
    totalResults: number;
    isLoading: boolean;
    areMoreResultsAvailable: boolean;
    hasErrors: boolean;
    hasErrorCode?: number;
    sortBy: TendersSortOptions;
}

const initialState: ITendersState = {
    items: [],
    totalResults: 0,
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
    sortBy: TendersSortOptions.none,
};

export default handleActions(
    {
        [String(searchTenders)]: state => ({...state, isLoading: true}),
        [String(searchTendersSuccess)]: (
            state,
            {payload}: {payload: {data: ITenderItem[]; totalResults: number}}
        ) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload.data],
            totalResults: payload.totalResults,
            areMoreResultsAvailable:
                payload.totalResults > state.items.length + payload.data.length,
        }),
        [String(searchTendersFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            totalResults: 0,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetTendersState)]: () => initialState,
        [String(resetTendersItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
            totalResults: 0,
        }),
        [String(setTendersSortBy)]: (state, {payload}: any) => ({
            ...state,
            sortBy: payload as TendersSortOptions,
        }),
    },
    initialState
);
