import {RootState} from 'app/state/store';
import {EntityType} from '../../contexts';

export const distributionsSelector = ({distributions: {items}}: RootState) => items;
export const areDistributionsLoadingSelector = ({distributions: {isLoading}}: RootState) =>
    isLoading;
export const areDistributionsDetailsLoadingSelector = ({
    distributions: {isLoadingDetails},
}: RootState) => isLoadingDetails;
export const areMoreDistributionsAvailableSelector = ({
    distributions: {areMoreResultsAvailable},
}: RootState) => areMoreResultsAvailable;
export const filtersSelector = ({filter}: RootState) =>
    filter.repository[EntityType.Distribution].filters;
export const distributionsSortSelector = (state: RootState) => state.distributions.sortBy;
export const distributionsErrorCodeSelector = ({distributions: {hasErrorCode}}: RootState) =>
    hasErrorCode;
export const distributionsTotalCountSelector = (state: RootState) => state.distributions.totalCount;
