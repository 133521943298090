import React, {FC} from 'react';

import styles from './CardTable.module.scss';
import {CardTableProps} from './CardTable.types';

export const CardTable: FC<CardTableProps> = ({data}) => {
    return (
        <div className={styles.table}>
            {data.map((set, i) => (
                <div className={styles.tableRow} key={i}>
                    <div className={styles.tableRowHeader}>{set.title}</div>
                    <div className={styles.tableValues}>
                        <span className={styles.titleHeaderAdditional}>{set.title}</span>
                        <span>{set.value1}</span>
                        <span>{set.value2}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};
