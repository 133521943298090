import React from 'react';

import {List} from 'app/views/components/list';
import {CustomersListItem} from '../customersListItem';
import {CustomersListItemSkeleton} from '../customersListItemSkeleton';
import {ICustomerItem} from 'app/state/customers';
import {IOnListItemSelect} from 'app/views/components/list/list.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const CustomersList = ({
    items,
    onSelect,
    searchWords,
    isLoading,
}: {
    items: ICustomerItem[];
    onSelect: IOnListItemSelect<any>;
    searchWords: string[];
    isLoading: boolean;
}) => {
    return isLoading ? (
        <List
            itemComponentRender={CustomersListItemSkeleton}
            itemsData={skeletons}
            searchWords={[]}
        />
    ) : (
        <List
            itemComponentRender={CustomersListItem}
            itemsData={items}
            onSelect={onSelect}
            searchWords={searchWords}
        />
    );
};
