import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {ISearchDocumentsOptions, ISearchServiceDocuments} from './searchService.documents.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {
    getSharepointFiltersArray,
    getSharepointSortArray,
    searchDocumentsResultsTransformer,
} from '../searchService.transformer';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {SearchAPICallerDocuments} from 'app/api/searchAPI/documents/searchAPICaller.documents';

@injectable()
export class SearchServiceDocuments implements ISearchServiceDocuments {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchDocuments({query, startFrom, sort, filters}: ISearchDocumentsOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const sortArray = getSharepointSortArray(sort);
                const filtersArray = getSharepointFiltersArray(filters);

                const fetchDocuments = await SearchAPICallerDocuments.searchDocuments({
                    query,
                    searchAPIURL: appConfig.skey_38,
                    accessToken,
                    startFrom,
                    sortArray,
                    filtersArray,
                });
                return searchDocumentsResultsTransformer(fetchDocuments, filters, !startFrom);
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse({
                        filters: [],
                        sorting: [],
                        items: [],
                    });
                }
            }
        );
    }

    public async downloadDocument(path: string, siteName: string) {
        const accessToken = await this.authProvider.getAccessToken();
        const url = appConfig.skey_38;
        return SearchAPICallerDocuments.getDocumentBlob(url, accessToken, path, siteName);
    }

    public previewDocumentLink(path: string, siteName: string) {
        const url = appConfig.skey_38;
        return SearchAPICallerDocuments.getDocumentPreviewLink(url, path, siteName);
    }

    public downloadDocumentLink(path: string, siteName: string) {
        const url = appConfig.skey_38;
        return SearchAPICallerDocuments.getDocumentDownloadLink(url, path, siteName);
    }
}
