import React, {SyntheticEvent, useCallback} from 'react';
import styles from './trainingsPreviewPanel.module.scss';
import {unescape} from 'html-escaper';
import DOMPurify from 'dompurify';
import {ITrainingsPreviewPanel} from './trainingsPreviewPanel.types';
import {LocalizationFuncType, AppMode} from 'app/contexts';
import {useLocalizationContext, useChannelContext, useAppContext} from 'app/hooks';
import {ITrainingItem} from 'app/state/trainings';
import {PreviewContentLayout} from 'app/layouts';

const preparedCourseSummaryData = (data: any) => {
    const unescapedText = unescape(data);
    return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(unescapedText)}} />;
};

type OnClickType = (e: SyntheticEvent) => void;

export const TrainingsPreviewPanel = ({data}: {data: ITrainingsPreviewPanel}) => {
    const {t} = useLocalizationContext();
    const {mode} = useAppContext();
    const isWebView = mode === AppMode.WebView;
    const {onOpenLink} = useChannelContext();

    const trainingsLinkButton = (
        className: string,
        data: ITrainingsPreviewPanel,
        onClick: OnClickType,
        t: LocalizationFuncType
    ) => (
        <a
            className={className && styles[className]}
            href={isWebView ? undefined : data.courseUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
        >
            {t('trainings.previewPanel.viewCourse')}
        </a>
    );

    const onViewCourseClick = useCallback(
        (e: SyntheticEvent) => {
            if (isWebView) {
                e.preventDefault();
                data?.courseUrl && onOpenLink({absoluteURL: data!.courseUrl});
            }
        },
        [data, mode, onOpenLink]
    );

    return (
        <PreviewContentLayout>
            <div className={styles.header}>
                <div className={styles.labelsContainer}>
                    <div className={styles.labels}>
                        <span className={styles.courseType}>{data!.courseType}</span>
                        <span className={styles.courseLanguage}>{data!.courseLanguage}</span>
                    </div>
                    {data?.courseProvider && (
                        <div className={styles.courseProvider}>{data!.courseProvider}</div>
                    )}
                </div>
                <div>
                    <div className={styles.title}>{data!.courseFullName}</div>
                    <div className={styles.specification}>
                        {data!.courseDuration && (
                            <>
                                <span className={styles.iconContainer}>
                                    <span className={styles.clockIcon} />
                                </span>
                                <span className={styles.duration}>{data!.courseDuration}</span>
                            </>
                        )}
                        <span className={styles.courseCategory}>{data!.courseCategory}</span>
                    </div>
                </div>
            </div>
            {data && data.courseImageUrl_Base64 ? (
                <div className={styles.courseImageContainer}>
                    <img
                        className={styles.courseImage}
                        alt={data.courseFullName}
                        src={data.courseImageUrl_Base64}
                    />
                    {trainingsLinkButton('transparentButton', data, onViewCourseClick, t)}
                </div>
            ) : (
                <>
                    {' '}
                    {trainingsLinkButton(
                        'blueButton',
                        data as ITrainingItem,
                        onViewCourseClick,
                        t
                    )}{' '}
                </>
            )}
            <div className={styles.descriptionText}>
                {preparedCourseSummaryData(data!.courseSummary)}
            </div>
        </PreviewContentLayout>
    );
};
