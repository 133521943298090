export default function asEnumValue<T>(
    param: string | null,
    availableValues: Array<string>
): T | undefined {
    if (param && availableValues.includes(param)) {
        const value: unknown = param;
        return value as T;
    }
    return undefined;
}
