import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {
    AbortControllerCorrespondence,
    ICorrespondenceLinkOptionsPreview,
    ISearchCorrespondenceOptions,
} from './searchAPICaller.correspondence.types';
import {fetchData} from 'app/utils';
import {CorrespondenceRoutes} from './routes.correspondence';
import {assignIn} from 'lodash';

export const abortControllers: AbortControllerCorrespondence = {
    CORRESPONDENCE: null,
};

export abstract class SearchAPICallerCorrespondence {
    public static async searchCorrespondence(
        {query = '', page = 0, perPage = DEFAULT_PAGE_SIZE, filter}: ISearchCorrespondenceOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.CORRESPONDENCE?.abort();
        abortControllers.CORRESPONDENCE = new AbortController();

        const data = {
            searchString: query,
            page,
            perPage,
        };

        if (filter) {
            assignIn(data, filter);
        }

        return await fetchData(
            String(CorrespondenceRoutes.correspondence(searchAPIURL)),
            accessToken,
            'POST',
            data,
            null,
            null,
            abortControllers.CORRESPONDENCE.signal
        );
    }

    public static async getCorrespondenceAttachments(
        id: number,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${CorrespondenceRoutes.correspondenceAttachments(searchAPIURL)}?id=${id}`
        );

        return fetchData(String(url), accessToken);
    }

    public static async getCorrespondenceTracking(
        id: number,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${CorrespondenceRoutes.correspondenceTracking(searchAPIURL)}?id=${id}`
        );

        return fetchData(String(url), accessToken);
    }

    public static async getCorrespondenceDocument(url: string, accessToken: string) {
        return await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response: any) => response);
    }

    public static async getCorrespondenceAttachmentLinkPreview(
        {isMain, name = 'unknown', isFullScreen = false, id}: ICorrespondenceLinkOptionsPreview,
        accessToken: string,
        searchAPIURL: string
    ) {
        const url = `${CorrespondenceRoutes.correspondenceAttachmentsPreviewLink(
            searchAPIURL,
            id
        )}?isMain=${isMain ? 'true' : 'false'}&isFullScreen=${isFullScreen}&name=${name}`;

        return {url};
    }

    public static async getCorrespondenceAttachmentLinkDownload(
        {isMain, name = 'unknown', isFullScreen = false, id}: ICorrespondenceLinkOptionsPreview,
        accessToken: string,
        searchAPIURL: string
    ) {
        const url = `${CorrespondenceRoutes.correspondenceAttachmentsDownloadLink(
            searchAPIURL,
            id
        )}?isMain=${isMain ? 'true' : 'false'}&isFullScreen=${isFullScreen}&name=${name}`;

        return {url};
    }
}
