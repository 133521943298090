import {
    ISearchServicesOptions,
    ServiceFilter,
} from 'app/api/searchAPI/services/searchAPICaller.services.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {RootState} from 'app/state';
import {IFilter, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';

const ticketTypes: {[key: string]: string[]} = {
    IT: ['incident', 'change_request', 'change_task', 'problem', 'sc_req_item', 'sc_task'],
    GS: ['facilities_request'],
    HR: ['sn_hr_core_case'],
};
const defaultCategoryFilter: Array<{field: string; values: string[]}> = [
    {
        field: 'Category',
        values: ['Catalog Item', 'Knowledge Article'],
    },
    {
        field: 'ticketType',
        values: [...ticketTypes.IT, ...ticketTypes.GS, ...ticketTypes.HR],
    },
];

const utils = {
    selected: (entries: IFilter[]) => entries.filter(e => e.isChecked),
    values: (entries: IFilter[]) => entries.map(e => e.value),
    refinementValues: (entries: IFilter[]) => entries.map(e => e.RefinementValue),
};

/**
 * Function for received unique keys from object array;
 * For example:
 * [{ a: 1, b: 2, c: 3}, {b: 4}, {d: 5}]    =>  ['a', 'b', 'c', 'd']
 */
const getUniqKeys = <T>(values: Array<{[key: string]: T}>): string[] => {
    return (
        values
            // get all keys from object array
            .reduce((acc, cur) => [...acc, ...Object.keys(cur)], [] as string[])
            // get uniq keys
            .filter((key, i, arr) => arr.indexOf(key) === i)
    );
};

/**
 * Function for merging object array to values array
 * For example:
 * [{ a: 1, b: 2, c: 3}, {b: 4}, {d: 5}], 'b'   =>  [2, 4]
 */
const deepMerge = (values: any[], key: string) => {
    return values.reduce((acc, cur) => (cur[key] ? [...acc, cur[key]] : acc), []);
};

const mergeValues = (values: {[key: string]: string}[]) => {
    return getUniqKeys(values).map(k => ({
        ticketType: k,
        id: deepMerge(values, k),
    }));
};

const createCategoryFilter = (filter: IFilterRefiner) => {
    const values = utils.refinementValues(utils.selected(filter.Entries));
    if (values.length) {
        return [
            {
                field: 'Category',
                values: values,
            },
            {
                field: 'ticketType',
                values: values
                    .map((v: string) => (ticketTypes[v] ? ticketTypes[v] : []))
                    .reduce((acc, cur) => [...acc, ...cur], []),
            },
        ];
    }

    return defaultCategoryFilter;
};

const parseFilter = (filters: IFilterRefiner[]) => {
    if (!filters?.length) {
        return {
            filter: defaultCategoryFilter,
            from: '',
            to: '',
        };
    }

    const requestFilter: ServiceFilter = [];
    let from = '';
    let to = '';

    filters.forEach(f => {
        switch (f.Name) {
            case 'Category':
                requestFilter.push(...createCategoryFilter(f));
                break;
            case 'Status':
                requestFilter.push({
                    field: f.Name,
                    values: mergeValues(utils.values(utils.selected(f.Entries))),
                });
                break;
            case 'Date':
                f.Entries.forEach(e => {
                    if (!e.value) return;
                    if (e.RefinementName === 'From') from = new Date(e.value).toISOString();
                    if (e.RefinementName === 'To') to = new Date(e.value).toISOString();
                });
                break;
            case 'AssignedTo':
            case 'RequestedBy':
            case 'RequestedFor':
                const values = utils.values(utils.selected(f.Entries));
                if (values.length) {
                    requestFilter.push({
                        field: f.Name,
                        values,
                    });
                }
                break;
            default:
                requestFilter.push({
                    field: f.Name,
                    values: utils.refinementValues(utils.selected(f.Entries)),
                });
        }
    });

    const statusFilter = requestFilter.find(({field}) => field === 'Status');
    if (statusFilter?.values?.length) {
        const catFilter = requestFilter.findIndex(({field}) => field === 'Category');
        requestFilter.splice(catFilter, 1);
        const ttFilter = requestFilter.findIndex(({field}) => field === 'ticketType');

        const filterTicketTypes = requestFilter[ttFilter].values as string[];
        const statusTicketTypes = deepMerge(statusFilter.values, 'ticketType') as string[];
        const commonTicketTypes = filterTicketTypes.filter((e: string) =>
            statusTicketTypes.includes(e)
        );

        requestFilter.splice(ttFilter, 1);

        requestFilter.push({
            field: 'ticketType',
            values: commonTicketTypes,
        });
    }

    if (!from && to) {
        from = new Date(0).toISOString();
    }

    return {
        filter: requestFilter.filter(f => f.values.length),
        from,
        to,
    };
};

export const getSearchServicesOptions = (
    keyWord: string,
    state: RootState,
    filters: IFilterRefiner[]
): ISearchServicesOptions => {
    const pagesize = DEFAULT_PAGE_SIZE;
    const nextPage = Math.floor((state.serviceNow.items.length + pagesize) / pagesize);

    const {filter, from, to} = parseFilter(filters);

    return {
        keyWord,
        page: nextPage,
        pageSize: DEFAULT_PAGE_SIZE,
        filters: filter,
        from,
        to,
        order: 'desc',
        orderBy: 'number',
    };
};
