import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import styles from './suppliersListItem.module.scss';
import {ISupplierItem} from 'app/state/suppliers';
import {ColorLabel} from 'app/views/components/colorLabel/colorLabel';

export const SuppliersListItem: FC<{item: ISupplierItem; searchWords: string[]}> = ({
    item: {id, text, entityLabel},
    searchWords = [],
}) => (
    <div className={styles.item}>
        <div>
            <ColorLabel color={entityLabel === 'COUNTRY' ? 'whiteGreen' : 'whiteBlue'}>
                {entityLabel}
            </ColorLabel>
        </div>
        <Highlighter
            className={styles.title}
            highlightClassName="highlight"
            searchWords={searchWords}
            textToHighlight={text || ''}
        />
        <Highlighter
            className={styles.subtitle}
            highlightClassName="highlight"
            searchWords={searchWords}
            textToHighlight={id || ''}
        />
    </div>
);
