import {fetchData} from 'app/utils';
import {EmployeeRoutes} from './routes.employee';
import {AbortControllerEmployee, IPeopleSearchBody} from './searchAPICaller.employee.types';

export const abortControllers: AbortControllerEmployee = {
    PEOPLE: null,
};

export abstract class SearchAPICallerEmployee {
    public static async searchPeople(
        body: IPeopleSearchBody,
        accessToken: string,
        searchAPIURL: string
    ) {
        abortControllers.PEOPLE?.abort();
        abortControllers.PEOPLE = new AbortController();

        return fetchData(
            EmployeeRoutes.employees(searchAPIURL),
            accessToken,
            'POST',
            body,
            null,
            null,
            abortControllers.PEOPLE.signal
        );
    }

    public static async searchPeopleCompanies(accessToken: string, searchAPIURL: string) {
        return fetchData(EmployeeRoutes.employeeCompanies(searchAPIURL), accessToken, 'GET');
    }

    public static async searchPeopleJobs(accessToken: string, searchAPIURL: string) {
        return fetchData(EmployeeRoutes.employeeJobs(searchAPIURL), accessToken, 'GET');
    }

    public static async apiGetEmployeeActivity(options: any) {
        return fetchData(
            EmployeeRoutes.employeeActivity(options.apiURL),
            options.accessToken,
            'GET',
            null,
            null,
            null
        );
    }
}
