import React from 'react';
import styles from './peopleListItemSkeleton.module.scss';
import {CircleSkeleton, RectSkeleton} from 'app/views/components/skeleton';

export const PeopleListItemSkeleton = () => (
    <div className={styles.skeleton}>
        <div className={styles.avatar}>
            <CircleSkeleton size={40} />
        </div>
        <div className={styles.infoPanel}>
            <div className={styles.info}>
                <RectSkeleton width={144} />
            </div>
            <div className={styles.info}>
                <RectSkeleton width={102} />
            </div>
            <div className={styles.info}>
                <RectSkeleton width={207} />
            </div>
        </div>
    </div>
);
