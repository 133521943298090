export const SuppliersRoutes = {
    suppliers: (origin: string) => `${origin}/suppliers`,
    suppliersCountry: (origin: string, id: string) => `${origin}/suppliers/country/${id}`,
    suppliersCompanies: (origin: string) => `${origin}/suppliers/companies`,
    suppliersSubItems: (origin: string) => `${origin}/suppliers/sub-items`,
    supplierDocumentPreviewURL: (origin: string, id: string) =>
        `${origin}/suppliers/preview?id=${id}`,
    supplierDocumentDownloadURL: (origin: string, id: string) =>
        `${origin}/suppliers/download?id=${id}`,
};
