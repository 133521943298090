import * as Msal from '@azure/msal-browser';
import {appConfig} from 'app/config';

export const authConfig: Msal.Configuration = {
    auth: {
        clientId: appConfig.skey_35,
        authority: appConfig.skey_36,
        redirectUri: window.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};

const {skey_41, skey_42 = []} = appConfig;
export const appScopes: string[] = [skey_41, ...skey_42];
