import {
    ILogger,
    ITrackException,
    LoggerErrorFunctionType,
    LoggerFullPropertiesType,
    LoggerFunctionType,
    LoggerPropertiesType,
} from './logger.types';
import {injectable} from 'inversify';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {appInsights} from 'app/infrastructure/logging/AppInsights';
import {isLoggingOff} from './isLoggingOff';

@injectable()
export class ConsoleLogger implements ILogger {
    getFullProperties = (
        properties: LoggerPropertiesType | undefined
    ): LoggerFullPropertiesType => {
        return {...properties, ...{email: this.email}};
    };

    setUserEmail = (email: string) => {
        this.email = email;
    };

    email: string;

    constructor() {
        this.email = '';
    }

    log = (message: string): void => {
        return console.log(message);
    };

    info: LoggerFunctionType = (message, properties) => {
        const fullProperties = this.getFullProperties(properties);

        if (isLoggingOff(SeverityLevel.Information)) {
            return console.log(message, fullProperties);
        }

        return appInsights.trackTrace({
            message,
            severityLevel: SeverityLevel.Information,
            properties: fullProperties,
        });
    };

    verbose: LoggerFunctionType = (name, properties) => {
        const fullProperties = this.getFullProperties(properties);
        if (isLoggingOff(SeverityLevel.Verbose)) {
            return console.log(name, fullProperties);
        }

        return appInsights.trackEvent({
            name,
            properties: fullProperties,
        });
    };

    warn: LoggerErrorFunctionType = (error, properties) => {
        const fullProperties = this.getFullProperties(properties);

        if (isLoggingOff(SeverityLevel.Warning)) {
            return console.warn(error, fullProperties);
        }

        return appInsights.trackException({
            // @ts-ignore
            exception: error,
            severityLevel: SeverityLevel.Warning,
            properties: fullProperties,
        });
    };

    error: LoggerErrorFunctionType = (error, properties) => {
        const fullProperties = this.getFullProperties(properties);
        if (isLoggingOff(SeverityLevel.Error)) {
            return console.error(error, fullProperties);
        }

        return appInsights.trackException({
            // @ts-ignore
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: fullProperties,
        });
    };

    critical: LoggerErrorFunctionType = (error, properties) => {
        const fullProperties = this.getFullProperties(properties);
        if (isLoggingOff(SeverityLevel.Critical)) {
            return console.error(error, fullProperties);
        }

        return this.trackException({
            exception: error,
            severityLevel: SeverityLevel.Critical,
            properties: fullProperties,
        });
    };

    private trackException: ITrackException = telemetry => {
        const {properties, exception} = telemetry;

        return appInsights.trackException({
            ...telemetry,
            exception: exception instanceof Error ? exception : new Error(exception),
            properties,
        });
    };
}
