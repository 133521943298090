import {handleActions} from 'redux-actions';
import {IServiceItem} from './serviceNow.types';

import {
    getServiceDetails,
    getServiceDetailsFailure,
    getServiceDetailsSuccess,
    resetServicesItems,
    resetServicesState,
    searchServices,
    searchServicesFailure,
    searchServicesSuccess,
} from './serviceNow.actions';
import {DEFAULT_PAGE_SIZE} from 'app/constants';

export interface IServiceNowState {
    items: IServiceItem[];
    isLoading: boolean;
    areMoreResultsAvailable: boolean;
    hasErrors: boolean;
    hasErrorCode?: number;
}

const initialState: IServiceNowState = {
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
};

export default handleActions(
    {
        [String(searchServices)]: state => ({...state, isLoading: true}),
        [String(searchServicesSuccess)]: (state, {payload}: {payload: IServiceItem[]}) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload],
            areMoreResultsAvailable: payload.length === DEFAULT_PAGE_SIZE,
        }),
        [String(searchServicesFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetServicesState)]: () => initialState,
        [String(resetServicesItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
        }),
        [String(getServiceDetails)]: state => state,
        [String(getServiceDetailsSuccess)]: (state, {payload: {sysId, details}}: any) => ({
            ...state,
            items: state.items.map(item => {
                if (item.sysId === sysId || item.id === sysId) return {...item, details};
                return item;
            }),
        }),
        [String(getServiceDetailsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
    },
    initialState
);
