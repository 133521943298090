import {handleActions} from 'redux-actions';
import {
    getNewsDetails,
    getNewsDetailsFailure,
    getNewsDetailsSuccess,
    resetNewsCurrentItem,
    resetNewsItems,
    resetNewsState,
    searchNews,
    searchNewsFailure,
    searchNewsSuccess,
} from 'app/state/news/news.actions';

import {INewsItem} from './news.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';

export interface INewsState {
    items: INewsItem[];
    currentItem: INewsItem | {};
    isLoading: boolean;
    areMoreResultsAvailable: boolean;
    hasErrors: boolean;
    hasErrorCode?: number;
}

const initialState: INewsState = {
    items: [],
    currentItem: {},
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
};

export default handleActions(
    {
        [String(searchNews)]: state => ({...state, isLoading: true}),
        [String(searchNewsSuccess)]: (state, {payload}: {payload: {news: INewsItem[]}}) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload.news],
            areMoreResultsAvailable: payload.news.length === DEFAULT_PAGE_SIZE,
        }),
        [String(searchNewsFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetNewsState)]: () => initialState,
        [String(resetNewsItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
        }),
        [String(resetNewsCurrentItem)]: state => ({
            ...state,
            currentItem: {},
        }),
        [String(getNewsDetails)]: state => ({
            ...state,
            currentItem: {},
            isLoading: true,
        }),
        [String(getNewsDetailsSuccess)]: (state, {payload}: any) => ({
            ...state,
            currentItem: payload.data,
            isLoading: !state.items.length && !state.hasErrorCode,
        }),
        [String(getNewsDetailsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: state.items.length > 0 ? false : state.isLoading,
            hasErrorCode: payload.responseStatus,
        }),
    },
    initialState
);
