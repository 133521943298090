import {get, keyBy, uniqBy, values} from 'lodash';
import deepmerge from 'deepmerge';
import {
    ICorrespondenceAttachmentsResponse,
    ICorrespondenceTrackingResponse,
    ICorrespondenceTrackingTransformed,
    ISearchCorrespondenceData,
    ISearchCorrespondenceResponse,
} from 'app/api/searchAPI/correspondence/searchAPICaller.correspondence.types';
import {getParsedDate} from 'app/utils';
import {DocumentsSortOptions} from 'app/containers/documents';
import {
    IPeopleSearchBody,
    ISearchAPIEmployee,
} from 'app/api/searchAPI/employee/searchAPICaller.employee.types';
import {ITransformedPeopleResponse} from './employee/searchService.employee.types';
import {
    CreateNewsFilterParams,
    FilterRangeValue,
    IDocumentRefiners,
    IDocumentRefinersData,
    IDocumentResponse,
    IDocumentsResponse,
    IFilter,
    IFilterData,
    ISortItem,
    SharePointConstants,
} from './searchService.types';
import {
    ICorrespondenceAttachment,
    ITransformedCorrespondenceResponse,
} from './correspondence/searchService.correspondence.types';
import {FilterType, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {getFormattedDate} from 'app/utils/convertDate';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {NewsFiltersOptions} from 'app/containers/news/news.types';
import {ServiceNowFiltersOptions} from 'app/containers/serviceNow';

import {
    DocumentsFilterDateEntry,
    DocumentsFiltersOptions,
} from 'app/containers/documents/documents.types';
import {TendersFiltersOptions} from 'app/containers/tenderBoard';

const documentsCustomFilters = [
    {
        Name: DocumentsFiltersOptions.SiteTemplateId,
        type: FilterType.Checkbox,
        Entries: [
            {
                RefinementName: 'SharePoint Online',
                RefinementValue: 'not(SiteTemplateId:21)',
            },
            {
                RefinementName: 'OneDrive',
                RefinementValue: 'SiteTemplateId:21',
            },
        ],
    },
    {
        Name: DocumentsFiltersOptions.CreatedDate,
        type: FilterType.DateRange,
        Entries: [
            {
                RefinementName: DocumentsFilterDateEntry.From,
                RefinementValue: FilterRangeValue.Min,
            },
            {
                RefinementName: DocumentsFilterDateEntry.To,
                RefinementValue: FilterRangeValue.Max,
            },
        ],
    },
];

function getOldFilters(filters: IFilter[]): IFilter[] {
    return filters.map(({Name, Entries, type}) => ({
        Name,
        type,
        Entries: Entries.filter(({isChecked}) => isChecked || type === FilterType.DateRange),
    }));
}

function getFinalFiltersFormat(filters: IFilter[]): IFilter[] {
    return filters
        .map(({Name, Entries, type}: IFilter) => ({
            Name,
            type,
            Entries: uniqBy(Entries, 'RefinementValue'),
        }))
        .filter(({Entries}) => Boolean(Entries.length));
}

const mergeSharepointFilters = (
    filters: IFilter[],
    newFilters: IFilter[],
    shouldAddCustomFilters: boolean = false
): IFilter[] => {
    const oldFilters = getOldFilters(filters);

    const mergedFilters = values(deepmerge(keyBy(oldFilters, 'Name'), keyBy(newFilters, 'Name')));

    if (shouldAddCustomFilters) {
        return getFinalFiltersFormat(
            values(deepmerge(keyBy(mergedFilters, 'Name'), keyBy(documentsCustomFilters, 'Name')))
        );
    }

    return getFinalFiltersFormat(mergedFilters);
};

function sortDocumentsFilters(filters: IFilter[]) {
    return filters.sort(({Name: firstItemName}, {Name: lastItemName}) => {
        const sortValues: string[] = [
            DocumentsFiltersOptions.SiteTemplateId,
            DocumentsFiltersOptions.FileType,
            DocumentsFiltersOptions.CreatedDate,
            DocumentsFiltersOptions.DisplayAuthor,
        ];

        return sortValues.indexOf(firstItemName) - sortValues.indexOf(lastItemName);
    });
}

const transformDocumentsFilters = (data: IDocumentsResponse | null, filters: IFilter[]) => {
    const transformedRefiners: IFilter[] =
        data?.filterResults?.map((refinerData: IDocumentRefiners) => ({
            Name: refinerData.name,
            Entries: refinerData.filters.map((filter: IDocumentRefinersData) => ({
                RefinementCount: filter.refinementCount,
                RefinementName: filter.refinementName,
                RefinementToken: filter.refinementToken,
                RefinementValue: filter.refinementValue,
                RefinementValue2: filter.refinementValue2,
            })),
        })) || [];

    const refiners = transformedRefiners ? transformedRefiners : [];

    const shouldShowCustomFilters =
        Boolean(data?.totalRows) ||
        filters.some(({Entries}) => Entries.some(({isChecked}) => isChecked));

    return sortDocumentsFilters(mergeSharepointFilters(filters, refiners, shouldShowCustomFilters));
};

const transformDocumentsToStateItems = (data: IDocumentsResponse) =>
    data?.documents.map((response: IDocumentResponse) => ({
        id: response.workId,
        documentName: response.fileName,
        documentExtention: response.fileExtension,
        documentType: response.fileType,
        documentAuthors: response.author.split(';'),
        documentEmbedUrl: response.serverRedirectedEmbedURL,
        documentThumbnailUrl: response.pictureThumbnailURL,
        documentCreatedDate: response.created,
        documentUpdatedDate: response.lastModifiedTime,
        documentURL: response.path || response.originalPath,
        documentSize: response.size,
        documentDescription: response.description,
        documentCulture: response.culture,
        documentSiteName: response.siteName,
    })) || [];

export const searchDocumentsResultsTransformer = (
    data: IDocumentsResponse | any,
    filters: IFilter[],
    haveFiltersChanged: boolean
) => ({
    filters: haveFiltersChanged ? transformDocumentsFilters(data, filters) || [] : filters,
    sorting: [],
    items: data ? transformDocumentsToStateItems(data) : [],
    hasErrors: false,
    totalCount: data?.totalRows || 0,
});

const highPriority = 'urgent';

export const searchCorrespondenceResultsTransformer = (
    response: ISearchCorrespondenceResponse | any,
    itemsCount: number
): ITransformedCorrespondenceResponse => {
    return {
        areMoreResultsAvailable: response?.totalCount > itemsCount + response?.data?.length,
        items: response?.data
            ? response.data.map(
                  ({
                      id,
                      isReply,
                      reference,
                      date,
                      status,
                      subject,
                      senderOffice: {officeName},
                      hasAttachments,
                      priority,
                  }: ISearchCorrespondenceData) => ({
                      id: String(id),
                      isReply,
                      status,
                      reference,
                      unit: officeName,
                      subject,
                      date,
                      isHighPriority: priority === highPriority,
                      hasAttachments,
                      attachments: null,
                      tracking: null,
                  })
              )
            : [],
        totalCount: response?.totalCount,
        hasErrors: false,
    };
};

const correspondenceTrackingDateTimeFormat = 'dd/MM/yyyy-hh:mm';
export const correspondenceTrackingTransformer = (
    receivedTracking: ICorrespondenceTrackingResponse | any
): ICorrespondenceTrackingTransformed[] => {
    if (!receivedTracking || !receivedTracking?.data || !Array.isArray(receivedTracking?.data)) {
        return [];
    }

    return receivedTracking.data.map(
        ({
            id,
            fromUser,
            toUser,
            notes,
            purpose,
            receivedDate,
        }: ICorrespondenceTrackingTransformed) => ({
            id: String(id),
            fromUser,
            toUser,
            notes,
            purpose,
            receivedDate: getParsedDate(receivedDate, correspondenceTrackingDateTimeFormat),
        })
    );
};

export const correspondenceAttachmentsTransformer = (
    receivedAttachments: ICorrespondenceAttachmentsResponse | any
): ICorrespondenceAttachment[] | [] => {
    if (
        !receivedAttachments ||
        !receivedAttachments?.data ||
        !Array.isArray(receivedAttachments?.data)
    ) {
        return [];
    }

    return receivedAttachments.data
        .filter(({isMain}: any) => !isMain)
        .map(({attachmentID, title, extension}: ICorrespondenceAttachment) => ({
            id: String(attachmentID),
            title,
            extension,
        }));
};

export const searchPeopleResultsTransformer = (
    response: ISearchAPIEmployee[] | any,
    skipAmount: number
): ITransformedPeopleResponse => {
    if (!response || typeof response?.length !== 'number' || !Array.isArray(response)) {
        return {
            skipAmount: 0,
            hasErrors: true,
            areMoreResultsAvailable: false,
            items: [],
        };
    }

    return {
        skipAmount: skipAmount + response.length,
        hasErrors: false,
        areMoreResultsAvailable: response.length === DEFAULT_PAGE_SIZE,
        items: response.map(
            ({
                id,
                city,
                jobTitle,
                userPrincipalName,
                displayName,
                givenName,
                surname,
                companyName,
                department,
                officeLocation,
                phones,
            }) => ({
                id,
                unit: null,
                givenName: givenName || displayName,
                surname,
                jobtitle: jobTitle,
                pictureurl: '',
                city,
                workEmail: userPrincipalName,
                companyName,
                department,
                officeLocation,
                phones,
            })
        ),
    };
};

export const searchPeopleRequestTransformer = (
    query: string,
    skipAmount: number | null,
    companies: string[],
    jobTitles: string[],
    exactMatch: boolean
): IPeopleSearchBody => {
    const body: IPeopleSearchBody = {
        Top: 10,
        Query: query,
        Skip: skipAmount || 0,
        ExactMatch: exactMatch,
    };
    if (companies.length > 0) {
        body.Companies = companies;
    }
    if (jobTitles.length > 0) {
        body.JobTitles = jobTitles;
    }
    return body;
};

const generateCorrespondenceFilters = (filters: IFilter[]) => {
    const selectedFilters: string[][] = filters.map(filter =>
        filter.Entries.reduce(
            // @ts-ignore
            (acc: string[], entry: IFilterData) =>
                entry.isChecked ? [...acc, entry.RefinementValue2] : acc,
            []
        )
    );

    return {
        documentType: get(selectedFilters, '[0]', null),
        fromDate: get(selectedFilters, '[1][0].FromDate', null),
        toDate: get(selectedFilters, '[1][0].ToDate', null),
    };
};

export const getSearchCorrespondenceOptions = (
    query: string,
    itemCount: number,
    filters: IFilter[]
) => {
    const perPage = DEFAULT_PAGE_SIZE;
    const nextPage = itemCount / DEFAULT_PAGE_SIZE;
    const generatedFilters = generateCorrespondenceFilters(filters);

    return {
        perPage,
        query,
        filter: generatedFilters,
        page: nextPage,
    };
};

export const getSharepointSortArray = (sortField: string): ISortItem[] | undefined => {
    switch (sortField) {
        case DocumentsSortOptions.NewToOld:
            return [
                {
                    Property: 'Write',
                    Direction: '1',
                },
            ];
        case DocumentsSortOptions.OldToNew:
            return [
                {
                    Property: 'Write',
                    Direction: '0',
                },
            ];
        case DocumentsSortOptions.NoSort:
        default:
            return undefined;
    }
};

export const getSharepointFiltersArray = (filters: IFilter[]): string[] | undefined => {
    const resultArray: string[] = [];
    filters
        .filter(filter => !filter.type || filter.type === FilterType.Checkbox)
        .forEach(({Name, Entries}) => {
            const checkedEntries = Entries.filter(f => f.isChecked);
            if (!checkedEntries.length) return false;
            const values = checkedEntries
                .map(e => (e.RefinementToken ? `${Name}:${e.RefinementToken}` : e.RefinementValue))
                .join(',');
            const filter = checkedEntries.length === 1 ? values : `or(${values})`;
            resultArray.push(filter);
        });

    const createdDateFilter: any = {
        min: SharePointConstants.Min,
        max: SharePointConstants.Max,
    };
    filters
        .filter(filter => filter.Name === DocumentsFiltersOptions.CreatedDate)
        .forEach(({Entries}) => {
            const checkedEntries = Entries.filter(f => f.isChecked);
            if (!checkedEntries.length) return false;

            const minField = checkedEntries.find(
                entry => entry.RefinementName === DocumentsFilterDateEntry.From
            );
            const maxField = checkedEntries.find(
                entry => entry.RefinementName === DocumentsFilterDateEntry.To
            );

            createdDateFilter.min = minField?.value
                ? getFormattedDate(minField.value)
                : SharePointConstants.Min;
            createdDateFilter.max = maxField?.value
                ? getFormattedDate(maxField.value)
                : SharePointConstants.Max;
        });
    if (
        createdDateFilter.min !== SharePointConstants.Min ||
        createdDateFilter.max !== SharePointConstants.Max
    ) {
        resultArray.push(`Created:range(${createdDateFilter.min}, ${createdDateFilter.max})`);
    }

    return resultArray;
};

export const createNewsFilters = ({companies}: CreateNewsFilterParams): IFilterRefiner[] => {
    const dateFilterConfig: IFilterRefiner = {
        Name: NewsFiltersOptions.Date,
        type: FilterType.DateRange,
        Entries: [
            {
                RefinementName: 'From',
                RefinementValue: 'min',
            },
            {
                RefinementName: 'To',
                RefinementValue: 'max',
            },
        ],
    };

    const companyEntries = Object.keys(companies).map(companyId => ({
        RefinementValue: companyId,
        RefinementName: companies[companyId],
        isChecked: false,
    }));

    return [
        dateFilterConfig,
        {
            type: FilterType.Checkbox,
            Name: 'Company',
            Entries: companyEntries,
        },
    ];
};

export const createTendersFilters = (response: {
    [key: string]: {id: string; name: string}[];
}): IFilterRefiner[] => {
    const filters = Object.keys(response)
        .map(key => ({
            Name: key,
            Entries: response[key].map(entry => ({
                RefinementValue: entry.id,
                RefinementName: entry.name,
            })),
        }))
        .sort(({Name: firstItemName}, {Name: lastItemName}) => {
            const sortValues: string[] = [
                TendersFiltersOptions.TypeId,
                TendersFiltersOptions.StatusId,
                TendersFiltersOptions.CompanyId,
            ];

            return sortValues.indexOf(firstItemName) - sortValues.indexOf(lastItemName);
        });

    const dateFilterConfig: IFilterRefiner = {
        Name: TendersFiltersOptions.Date,
        type: FilterType.DateRange,
        Entries: [
            {
                RefinementName: 'From',
                RefinementValue: 'From',
            },
            {
                RefinementName: 'To',
                RefinementValue: 'To',
            },
        ],
    };

    return [...filters, dateFilterConfig];
};

type ServiceFilterValue = {id: string; name: string};
type DeepFilter = {
    [key: string]: {
        [key: string]: ServiceFilterValue[];
    };
};
type ServicesFilterResponse = {
    Category: ServiceFilterValue[];
    Status: DeepFilter;
    CompanyId: ServiceFilterValue[];
    ContactType: DeepFilter;
    Priority: DeepFilter;
};

const createDeepFilter = (filter: DeepFilter, key: string): IFilterRefiner => {
    const items = Object.values(filter)
        .map(category => Object.values(category))
        .map(subCategory => subCategory.reduce((acc, cur) => [...acc, ...cur], []))
        .reduce((acc, cur) => [...acc, ...cur], []);

    const itemsEntries = items
        .filter((s, i) => i === items.findIndex(entry => entry.name === s.name))
        .map(entry => ({
            RefinementValue: entry.id,
            RefinementName: entry.name,
            value: items.filter(s => s.name === entry.name).map(s => s.id),
        }));

    return {
        Name: key,
        Entries: itemsEntries,
    };
};

const createStatusFilter = (filter: DeepFilter, key: string): IFilterRefiner => {
    type Items = {
        id: string;
        name: string;
        ticketType: string;
    }[];
    const items = Object.values(filter)
        .map(category =>
            Object.keys(category).reduce(
                (acc: Items, key) => [
                    ...acc,
                    ...category[key].map(status => ({...status, ticketType: key})),
                ],
                []
            )
        )
        .reduce((acc, cur) => [...acc, ...cur], []);

    const getTicketTypesMap = (items: Items) => {
        const ticketTypesMap: {[key: string]: string} = {};
        items.forEach(i => {
            ticketTypesMap[i.ticketType] = i.id;
        });

        return ticketTypesMap;
    };

    const itemsEntries = items
        .filter((s, i) => i === items.findIndex(entry => entry.name === s.name))
        .map(entry => ({
            RefinementValue: entry.name,
            RefinementName: entry.name,
            value: getTicketTypesMap(items.filter(s => s.name === entry.name)),
        }));

    return {
        Name: key,
        Entries: itemsEntries,
    };
};

export const createServicesFilters = (filters: ServicesFilterResponse): IFilterRefiner[] => {
    const dateFilterConfig: IFilterRefiner = {
        Name: ServiceNowFiltersOptions.Date,
        type: FilterType.DateRange,
        Entries: [
            {
                RefinementName: 'From',
                RefinementValue: 'min',
            },
            {
                RefinementName: 'To',
                RefinementValue: 'max',
            },
        ],
    };

    const {Category, Status, CompanyId, ContactType, Priority} = filters;

    const categoryFilter: IFilterRefiner = {
        Name: 'Category',
        Entries: Category.map(entry => ({
            RefinementValue: entry.id,
            RefinementName: entry.name,
        })),
    };

    const CompanyIdFilter: IFilterRefiner = {
        Name: 'CompanyID',
        Entries: CompanyId.map(entry => ({
            RefinementValue: entry.id,
            RefinementName: entry.name,
        })),
    };

    const assignedToFilter: IFilterRefiner = {
        Name: 'AssignedTo',
        Entries: [
            {
                RefinementName: 'serviceNow.serviceNowFilters.filterAssignedTo',
                RefinementValue: 'AssignedTo',
                isChecked: false,
                value: '',
            },
        ],
        type: FilterType.TextField,
    };
    const requestedByFilter: IFilterRefiner = {
        Name: 'RequestedBy',
        Entries: [
            {
                RefinementName: 'serviceNow.serviceNowFilters.filterRequestedBy',
                RefinementValue: 'RequestedBy',
                isChecked: false,
                value: '',
            },
        ],
        type: FilterType.TextField,
    };
    const requestedForFilter: IFilterRefiner = {
        Name: 'RequestedFor',
        Entries: [
            {
                RefinementName: 'serviceNow.serviceNowFilters.filterRequestedFor',
                RefinementValue: 'RequestedFor',
                isChecked: false,
                value: '',
            },
        ],
        type: FilterType.TextField,
    };

    return [
        categoryFilter,
        dateFilterConfig,
        createStatusFilter(Status, 'Status'),
        CompanyIdFilter,
        createDeepFilter(ContactType, 'ContactType'),
        createDeepFilter(Priority, 'Priority'),
        assignedToFilter,
        requestedByFilter,
        requestedForFilter,
    ];
};

export const createProjectFilters = (response: any = {}): IFilterRefiner[] =>
    Object.keys(response).map(filterName => ({
        Name: filterName,
        Entries: response[filterName].map(({id, name}: {id: string; name: string}) => ({
            RefinementValue: id,
            RefinementName: name,
        })),
    }));

export const createDistributionsFilters = (response: any = {}): IFilterRefiner[] =>
    Object.keys(response).map(Name => {
        if (Name === 'stationStatuses') {
            return {
                Name,
                Entries: response[Name].map((name: {name: string}) => ({
                    RefinementName: name,
                    RefinementValue: name,
                })),
                type: FilterType.Radio,
            };
        } else {
            return {
                Name,
                Entries: response[Name].map((name: {name: string}) => ({
                    RefinementName: name,
                    RefinementValue: name,
                })),
            };
        }
    });
