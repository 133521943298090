import {createActions} from 'redux-actions';
import {IMSGraphBatchResponse} from 'app/api/searchAPI/searchAPI.MSGraph.types';

export const {
    searchGraphFederated,
    searchGraphFederatedSuccess,
    searchGraphFederatedFailure,
} = createActions({
    SEARCH_GRAPH_FEDERATED: (query: string) => query,
    SEARCH_GRAPH_FEDERATED_SUCCESS: (result: IMSGraphBatchResponse) => result,
    SEARCH_GRAPH_FEDERATED_FAILURE: (result: any) => result,
});
