import {HandleDataRow} from './Cards.types';

export const mapTendering: HandleDataRow<any> = [
    tendering => ({
        title: 'suppliers.previewPanel.cards.statuses.awarded',
        val1: tendering?.rfqCountAwarded || 0,
        val2: {
            usd: tendering?.reqAmountAwarded || 0,
            aed: tendering?.rfqAmountAwardedAed || 0,
        },
    }),
    tendering => ({
        title: 'suppliers.previewPanel.cards.statuses.invited',
        val1: tendering?.rfqCountInvited || 0,
        val2: {
            usd: tendering?.RfqAmountInvited || 0,
            aed: tendering?.rfqAmountInvitedAed || 0,
        },
    }),
];
export const mapMaterials: HandleDataRow<any> = [
    material => ({
        title: 'suppliers.previewPanel.cards.statuses.ongoing',
        val1: material?.poCountOpen || 0,
        val2: {
            usd: material?.poAmountOpen || 0,
            aed: material?.poAmountOpenAed || 0,
        },
    }),
    material => ({
        title: 'suppliers.previewPanel.cards.statuses.closed',
        val1: material?.poCountClosed || 0,
        val2: {
            usd: material?.poAmountClosed || 0,
            aed: material?.poAmountClosedAed || 0,
        },
    }),
    material => ({
        title: 'suppliers.previewPanel.cards.statuses.total',
        val1: (material?.poCountOpen || 0) + (material?.poCountClosed || 0),
        val2: {
            usd: (material?.poAmountOpen || 0) + (material?.poAmountClosed || 0),
            aed: (material?.poAmountOpenAed || 0) + (material?.poAmountClosedAed || 0),
        },
    }),
];
export const mapServices: HandleDataRow<any> = [
    service => ({
        title: 'suppliers.previewPanel.cards.statuses.ongoing',
        val1: service?.contrCountOpen || 0,
        val2: {
            usd: service?.contrAmountOpen || 0,
            aed: service?.contrAmountOpenAed || 0,
        },
    }),
    service => ({
        title: 'suppliers.previewPanel.cards.statuses.closed',
        val1: service?.contrCountClosed || 0,
        val2: {
            usd: service?.contrAmountClosed || 0,
            aed: service?.contrAmountClosedAed || 0,
        },
    }),
    service => ({
        title: 'suppliers.previewPanel.cards.statuses.total',
        val1: (service?.contrCountOpen || 0) + (service?.contrCountClosed || 0),
        val2: {
            usd: (service?.contrAmountOpen || 0) + (service?.contrAmountClosed || 0),
            aed: (service?.contrAmountOpenAed || 0) + (service?.contrAmountClosedAed || 0),
        },
    }),
];
export const mapInvoices: HandleDataRow<any> = [
    invoice => ({
        title: 'suppliers.previewPanel.cards.statuses.invoiced',
        val1: invoice?.invCountInvoiced || 0,
        val2: {
            usd: invoice?.['invAmountInvoiced`'] || 0,
            aed: invoice?.invAmountInvoicedAed || 0,
        },
    }),
    invoice => ({
        title: 'suppliers.previewPanel.cards.statuses.paid',
        val1: invoice?.invCountPaid || 0,
        val2: {
            usd: invoice?.invAmountPaid || 0,
            aed: invoice?.invAmountPaidAed || 0,
        },
    }),
    invoice => ({
        title: 'suppliers.previewPanel.cards.statuses.total',
        val1: (invoice?.invCountInvoiced || 0) + (invoice?.invCountPaid || 0),
        val2: {
            usd: (invoice?.['invAmountInvoiced`'] || 0) + (invoice?.invAmountPaid || 0),
            aed: (invoice?.invAmountInvoicedAed || 0) + (invoice?.invAmountPaidAed || 0),
        },
    }),
];
