import {spawn} from 'redux-saga/effects';
import searchAllSaga from './searchAll/searchAll.sagas';
import {routerSagas} from './router/router.sagas';
import {filterSaga} from './filter/filter.sagas';
import {documentsSaga} from './documents/documents.sagas';
import {mailSaga} from './mail/mail.sagas';
import {peopleSaga} from './people/people.sagas';
import {trainingsSaga} from './trainings/trainings.sagas';
import {correspondenceSaga} from './correspondence/correspondence.sagas';
import {suggestionsSaga} from './suggestions/suggestions.sagas';
import {newsSaga} from './news/news.sagas';
import {tendersSaga} from './tenders/tenders.sagas';
import {projectsSaga} from './projects/projects.sagas';
import {suppliersSaga} from './suppliers/suppliers.sagas';
import {customersSaga} from './customers/customers.sagas';
import {serviceNowSaga} from './serviceNow/serviceNow.sagas';
import {distributionsSaga} from './distribution/distribution.sagas';
import {myProfileSaga} from './myProfile/myProfile.sagas';

export default function* rootSaga() {
    yield spawn(searchAllSaga);
    yield spawn(routerSagas);
    yield spawn(filterSaga);
    yield spawn(peopleSaga);
    yield spawn(mailSaga);
    yield spawn(documentsSaga);
    yield spawn(trainingsSaga);
    yield spawn(correspondenceSaga);
    yield spawn(suggestionsSaga);
    yield spawn(newsSaga);
    yield spawn(tendersSaga);
    yield spawn(projectsSaga);
    yield spawn(suppliersSaga);
    yield spawn(customersSaga);
    yield spawn(serviceNowSaga);
    yield spawn(distributionsSaga);
    yield spawn(myProfileSaga);
}
