import {handleActions} from 'redux-actions';
import {ICustomerItem} from './customers.types';

import {
    getCustomerDetails,
    getCustomerDetailsFailure,
    getCustomerDetailsSuccess,
    resetCustomerDetails,
    resetCustomersState,
    searchCustomers,
    searchCustomersFailure,
    searchCustomersSuccess,
    setPeriod,
} from './customers.actions';

export interface ICustomersState {
    items: ICustomerItem[];
    isLoading: boolean;
    isLoadingDetails: boolean;
    hasErrors: boolean;
    hasErrorCode?: number;
    period: number;
}

const initialState: ICustomersState = {
    items: [],
    isLoading: false,
    isLoadingDetails: false,
    hasErrors: false,
    hasErrorCode: undefined,
    period: 0,
};

export default handleActions(
    {
        [String(searchCustomers)]: state => ({...state, isLoading: true}),
        [String(searchCustomersSuccess)]: (
            state,
            {
                payload,
            }: {
                payload: {
                    customers: ICustomerItem[];
                };
            }
        ) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload.customers],
        }),
        [String(searchCustomersFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetCustomersState)]: () => initialState,
        [String(setPeriod)]: (state, {payload}: any) => ({
            ...state,
            period: payload,
        }),
        [String(getCustomerDetails)]: state => state,
        [String(getCustomerDetailsSuccess)]: (state, {payload: {customerId, details}}: any) => ({
            ...state,
            items: state.items.map(item => {
                if (item.id === customerId) return {...item, details};
                return item;
            }),
        }),
        [String(getCustomerDetailsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetCustomerDetails)]: state => ({
            ...state,
            items: state.items.map(item => ({
                ...item,
                details: null,
            })),
            isLoading: true,
        }),
    },
    initialState
);
