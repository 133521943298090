export const DEFAULT_PAGE_SIZE = 10;

export const SUPPORTED_DOCUMENTS_FILE_TYPES = ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt'];

export const UPDATE_TOKEN_MAX_ATTEMPTS = 5;

// TODO Replace by https://www.npmjs.com/package/http-status-codes after fix NPM
export const HTTPS_STATUS_CODES = {
    OK: 200,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    REQUEST_TIMEOUT: 408,
};
