import React from 'react';
import {RectSkeleton} from 'app/views/components/skeleton';
import styles from './suppliersListItemSkeleton.module.scss';

export const SuppliersListItemSkeleton = () => (
    <div className={styles.item}>
        <RectSkeleton width={144} />
        <RectSkeleton width={200} />
        <div className={styles.row}>
            <RectSkeleton width={120} />
            <RectSkeleton width={80} />
        </div>
    </div>
);
