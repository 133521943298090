import React, {FC, MutableRefObject, useCallback, useEffect} from 'react';
import classNames from 'classnames';
import {size} from 'lodash';
import {Link} from 'app/views/components';
import {useLocalizationContext} from 'app/hooks';
import {ReactComponent as Icon} from 'app/static/images/magnifyingGlass.svg';
import {
    INoResultsProps,
    ISuggestionCategoryProps,
    ISuggestionComponent,
    ISuggestionsProps,
} from './suggestions.types';
import {Skeleton} from './skeleton';
import styles from './styles/suggestions.module.scss';
import {HistoryDisableValues, routeParams} from 'app/routes';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {stringify} from 'querystring';
import {LightAppsSuggestions} from '../lightAppItem/lightAppsSuggestions';
import {ISelectedResult} from 'app/utils/mainSearch';
import searchIcon from 'app/static/images/search.svg';

const NoResults = ({searchQuery, onItemClick}: INoResultsProps) => {
    const {t} = useLocalizationContext();
    const onClick = useCallback(() => {
        onItemClick(null, null, searchQuery);
    }, [onItemClick, searchQuery]);

    return (
        <div className={styles.noResults} onClick={onClick}>
            <Icon className={styles.noResultsIcon} />
            {searchQuery.length > 0 ? (
                <>
                    <span className={styles.noResultsText}>
                        {t('components.suggestions.searchFor')}
                    </span>
                    &nbsp;
                    <span>{searchQuery}</span>
                </>
            ) : (
                <span className={styles.noResultsText}>
                    {t('components.suggestions.noHistory')}
                </span>
            )}
        </div>
    );
};

const SuggestionList = ({
    suggestions,
    selected,
    suggestionItemRefs,
}: {
    suggestions: ISuggestionCategoryProps<any>[];
    selected: ISelectedResult;
    suggestionItemRefs: MutableRefObject<any>;
}) => {
    const {t} = useLocalizationContext();
    const dispatch = useDispatch();

    const onPush = useCallback<ISuggestionComponent<any>['onPush']>(
        (route: string, id, searchString, historyDate) => {
            const params = {
                [routeParams.QUERY]: searchString,
                [routeParams.SELECTED_ID]: id,
            };
            if (historyDate) {
                params[routeParams.DISABLE_HISTORY_ONCE] = HistoryDisableValues.disable;
            }

            dispatch(push(route + '?' + stringify(params)));
        },
        [dispatch]
    );

    useEffect(() => {
        let selectedEl;
        if (
            selected.suggestionsSelectedI !== undefined &&
            selected.suggestionsSelectedItemI !== undefined
        ) {
            selectedEl =
                suggestionItemRefs.current[selected.suggestionsSelectedI][
                    selected.suggestionsSelectedItemI
                ];
        }

        selectedEl?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, [selected]);

    return (
        <div className={styles.suggestionList}>
            {suggestions.map(({name, items, component}, i) => (
                <div className={styles.suggestionCategory} key={`suggestionCategory-${i}`}>
                    <div className={styles.suggestionCategoryName}>{t(name)}</div>
                    <div className={styles.suggestionCategoryItems}>
                        {items.map((item, itemI) => {
                            const Item = component;
                            const isSelected =
                                selected.suggestionsSelectedI === i &&
                                selected.suggestionsSelectedItemI === itemI;
                            return (
                                <Item
                                    item={item}
                                    onPush={onPush}
                                    key={`suggestionItem-${itemI}`}
                                    selected={isSelected}
                                    itemRef={suggestionItemRefs}
                                    categoryI={i}
                                    itemI={itemI}
                                />
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export const Suggestions: FC<ISuggestionsProps<any>> = ({
    searchQuery,
    suggestions,
    isLoading,
    showSuggestions,
    onSearch,
    lightAppsSuggestions,
    selected,
    allLightAppsItemRef,
    lightAppsItemRefs,
    suggestionItemRefs,
    allSuggestionsItemRefs,
}) => {
    const {t} = useLocalizationContext();
    const showResults = !isLoading && suggestions.length > 0;
    const lightAppsSuggestionsCount = lightAppsSuggestions?.length || 0;
    const showLightAppsResults =
        !isLoading && lightAppsSuggestionsCount > 0 && searchQuery.trim().length > 0;
    const showNoResults = !isLoading && suggestions.length === 0 && lightAppsSuggestionsCount === 0;

    const suggestionsClasses = classNames(
        {
            [styles.isVisible]: showSuggestions,
        },
        styles.suggestions
    );

    useEffect(() => {
        if (selected?.allSuggestionsSelected) {
            allSuggestionsItemRefs?.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [allSuggestionsItemRefs, selected]);

    return (
        <div className={suggestionsClasses}>
            <div className={styles.dataContainer}>
                {isLoading && (
                    <>
                        {size(searchQuery) > 0 && (
                            <div className={styles.skeletonTitle}>
                                <img
                                    className={styles.searchIcon}
                                    src={searchIcon}
                                    alt="searchIcon"
                                />
                                <span>Search for - </span>
                                <span className={styles.skeletonTitleQuery}>{searchQuery}</span>
                            </div>
                        )}
                        <Skeleton />
                    </>
                )}
                {showLightAppsResults && (
                    <LightAppsSuggestions
                        lightAppsSuggestions={lightAppsSuggestions}
                        selected={selected}
                        allLightAppsItemRef={allLightAppsItemRef}
                        lightAppsItemRefs={lightAppsItemRefs}
                    />
                )}
                {showResults && (
                    <SuggestionList
                        suggestions={suggestions}
                        selected={selected}
                        suggestionItemRefs={suggestionItemRefs}
                    />
                )}
                {showNoResults && <NoResults searchQuery={searchQuery} onItemClick={onSearch} />}
            </div>
            {showResults && searchQuery.length > 0 && (
                <div
                    className={classNames(
                        styles.showAllResults,
                        selected.allSuggestionsSelected ? styles.selected : undefined
                    )}
                >
                    <Link onClick={onSearch} component="button" linkRef={allSuggestionsItemRefs}>
                        {t('components.suggestions.showAllResults')}
                    </Link>
                </div>
            )}
        </div>
    );
};
