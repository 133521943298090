import React from 'react';
import {RectSkeleton} from 'app/views/components/skeleton';
import styles from './projectsListItemSkeleton.module.scss';

export const ProjectsListItemSkeleton = () => (
    <div className={styles.item}>
        <div className={styles.row}>
            <RectSkeleton width={144} />
            <RectSkeleton width={30} />
        </div>
        <div className={styles.title}>
            <RectSkeleton width={184} height={60} />
        </div>
        <RectSkeleton width={80} />
    </div>
);
