import React, {FC} from 'react';
import styles from './distributionListItemSkeleton.module.scss';
import {RectSkeleton} from '../../../components/skeleton';

export const DistributionListItemSkeleton: FC = () => (
    <div className={styles.skeleton}>
        <div className={styles.itemHeader}>
            <RectSkeleton width={150} height={20} />
            <RectSkeleton width={50} height={20} />
        </div>
        <div className={styles.title}>
            <RectSkeleton width={235} height={20} />
        </div>
        <RectSkeleton width={250} height={40} />
    </div>
);
