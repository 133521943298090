import React from 'react';
import {TrainingsListItem} from 'app/views/trainings/components/trainingsListItem';
import {TrainingsListItemSkeleton} from 'app/views/trainings/components/trainingsListItemSkeleton';
import {List} from 'app/views/components/list';
import {ITrainingsListProps} from 'app/views/trainings/components/trainingsList/trainingsList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const TrainingsList = ({
    trainings,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: ITrainingsListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    itemComponentRender={TrainingsListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    itemComponentRender={TrainingsListItem}
                    itemsData={trainings}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
