import React from 'react';
import styles from './filterEntry.module.scss';
import {IFilterEntryProps} from './filterEntry.types';
import {StationRating} from '../../../distribution/components/distributionPreviewPanel/components/stationRating';

export const FilterEntry = ({
    inputType,
    inputName,
    entry: {RefinementName, RefinementValue},
    onChange,
    getFilterIsCheckedValue,
    isStarsFilter,
}: IFilterEntryProps) => {
    let id = `${inputName}-${RefinementName}-${RefinementValue}`;
    return (
        <li className={styles.options}>
            <input
                type={inputType || 'checkbox'}
                name={inputName || 'refinersCheckbox'}
                id={id}
                onChange={() => onChange(RefinementName)}
                value={RefinementValue || ''}
                className={styles.customCheckbox}
                checked={getFilterIsCheckedValue(RefinementName)}
            />
            <label htmlFor={id}>
                {isStarsFilter ? (
                    <StationRating rating={parseInt(RefinementValue)} showOnlyFilledStars={true} />
                ) : (
                    <span className={styles.refinementName}>{RefinementName}</span>
                )}
            </label>
        </li>
    );
};
