import {handleActions} from 'redux-actions';
import {triggerSameValueSearch} from 'app/state/search/search.actions';

export interface ISearchState {
    searchSameValueCount: number;
}

const initialState: ISearchState = {
    searchSameValueCount: 0,
};

export default handleActions(
    {
        [String(triggerSameValueSearch)]: state => ({
            searchSameValueCount: ++state.searchSameValueCount,
        }),
    },
    initialState
);
