import {IPieChartBase} from './pieChartBase.types';
import {Pie} from 'react-chartjs-2';
import React from 'react';
import {getColorsList} from 'app/utils/getColor';
import classNames from 'classnames';
import baseStyles from '../lineChartBase/lineChartBase.module.scss';
import {NoResultsCharts} from 'app/layouts/searchResultLayout/noResultsCharts';
import noResultsCharts from 'app/static/images/noResultsCharts.svg';
import {useLocalizationContext} from 'app/hooks';
import {every} from 'lodash';

export const PieChartBase = ({chartConfig, data, chartDataPercentage, styles}: IPieChartBase) => {
    const colors: string[] = getColorsList(data.length);
    const {t} = useLocalizationContext();

    let isEmptyOrBrokenData = false;

    if (!every(data, part => !!part.value)) {
        isEmptyOrBrokenData = true;
    }

    return (
        <div
            className={classNames(
                baseStyles.container,
                isEmptyOrBrokenData ? baseStyles.containerNoData : ''
            )}
        >
            {!isEmptyOrBrokenData ? (
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.chart}>
                            <Pie {...chartConfig} />
                        </div>
                        <div className={styles.legend}>
                            <div className={styles.legendTable}>
                                {data.map(({label}, index) => (
                                    <div key={label}>
                                        <div className={styles.label}>
                                            <span
                                                className={styles.color}
                                                style={{
                                                    backgroundColor: colors[index],
                                                }}
                                            />
                                            {label}
                                        </div>
                                        <div className={styles.value}>
                                            {chartDataPercentage[index]} %
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <NoResultsCharts
                    noResultImageUrl={noResultsCharts}
                    noResultsTitle={t('components.errors.emptyData.title')}
                    noResultsDescription={t('components.errors.emptyData.description')}
                />
            )}
        </div>
    );
};
