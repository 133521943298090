const colors = [
    '#3366CC',
    '#ffc800',
    '#ff9900',
    '#DC3912',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
];

export const getColorsList = (n: number | undefined) => colors.slice(0, n);
