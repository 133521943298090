import React from 'react';
import styles from './employeeGuide.module.scss';

export const EmployeeGuide = () => {
    return (
        <div>
            <div className={styles.title}>
                <span>~</span>for fuzzy search
            </div>
            <div className={styles.description}>
                Type <span>Mohamed~</span> to find <span>Mohamed, Mahammed</span>
            </div>
            <div className={styles.title}>
                <span>*</span>for partial search
            </div>
            <div className={styles.description}>
                Type <span>Moh*</span> to find <span>Mohamed, Mohammed</span>
            </div>
            <div className={styles.title}>
                <span>“ “</span>for exact match
            </div>
            <div className={styles.description}>
                Type <span>“Mohammad”</span> to find only <span>Mohammad</span>
            </div>
        </div>
    );
};
