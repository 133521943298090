import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

import styles from './costsInfo.module.scss';
import commonStyles from '../projectsPreviewPanel.module.scss';
import {IProjectDetails} from 'app/state/projects/projects.types';
import {m$FormatterShort} from 'app/utils';

export const CostsInfo = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {stgCostInfo, ytdCostInfo, stgEstimatedCostInfo},
        isSmall,
    } = props;

    const p = (a: number, b: number) => (a <= b ? 100 : (b / a) * 100);

    let stgPercentage =
        stgCostInfo.budget !== 0 ? (stgCostInfo.actualCost / stgCostInfo.budget) * 100 : 100;
    let ytdPercentage =
        ytdCostInfo.budget !== 0 ? (ytdCostInfo.actualCost / ytdCostInfo.budget) * 100 : 100;

    const stgBudgetPercentage = p(stgCostInfo.actualCost, stgCostInfo.budget);
    const ytdBudgetPercentage = p(ytdCostInfo.actualCost, ytdCostInfo.budget);

    if (stgPercentage > 100) stgPercentage = 100;
    if (ytdPercentage > 100) ytdPercentage = 100;

    let stgLabel = '';
    const stgCostInfoDif = stgCostInfo.actualCost - stgCostInfo.budget;
    if (stgCostInfoDif === 0) stgLabel = 'projects.preview.onBudgetIfo';
    if (stgCostInfoDif > 0) stgLabel = 'projects.preview.overBudgetIfo';
    if (stgCostInfoDif < 0) stgLabel = 'projects.preview.underBudgetIfo';

    let ytdLabel = '';
    const ytdCostInfoDif = ytdCostInfo.actualCost - ytdCostInfo.budget;
    if (ytdCostInfoDif === 0) ytdLabel = 'projects.preview.onBudgetIfo';
    if (ytdCostInfoDif > 0) ytdLabel = 'projects.preview.overBudgetIfo';
    if (ytdCostInfoDif < 0) ytdLabel = 'projects.preview.underBudgetIfo';

    let stgEstimatedPercentage =
        stgEstimatedCostInfo.budget !== 0
            ? Math.round(
                  ((stgEstimatedCostInfo.actualCost - stgEstimatedCostInfo.budget) /
                      stgEstimatedCostInfo.budget) *
                      10000
              ) / 100
            : stgEstimatedCostInfo.actualCost;
    let stgEstimatedLabel = '';
    if (stgEstimatedPercentage === 0) stgEstimatedLabel = 'projects.preview.asSanctioned';
    if (stgEstimatedPercentage > 0) stgEstimatedLabel = 'projects.preview.moreThanSanctioned';
    if (stgEstimatedPercentage < 0) stgEstimatedLabel = 'projects.preview.lessThanSanctioned';
    stgEstimatedLabel = t(stgEstimatedLabel, {value: Math.abs(stgEstimatedPercentage)});

    const containerClassname = classNames(styles.container, commonStyles.itemContainer);

    return (
        <div className={classNames({[commonStyles.threeColumns]: !isSmall})}>
            <div className={containerClassname}>
                <h4>{t('projects.preview.actualVsCurrent')}</h4>
                <div className={styles.value}>{m$FormatterShort(stgCostInfo.actualCost)}</div>
                <div className={styles.progressBar}>
                    <div
                        className={classNames(styles.progress, {
                            [styles.green]: stgPercentage < 100,
                            [styles.orange]: stgPercentage === 100,
                        })}
                        style={{
                            width: `${stgPercentage}%`,
                        }}
                    />
                    <div className={styles.planned} style={{left: `${stgBudgetPercentage}%`}}>
                        <span
                            className={classNames({
                                [styles.min]: stgBudgetPercentage < 15,
                                [styles.max]: stgBudgetPercentage > 85,
                            })}
                        >
                            {m$FormatterShort(stgCostInfo.budget)}
                        </span>
                    </div>
                </div>
                <div className={styles.label}>
                    {t(stgLabel, {value: m$FormatterShort(Math.abs(stgCostInfoDif))})}
                </div>
            </div>
            <div className={containerClassname}>
                <h4>{t('projects.preview.actualVsPlanned')}</h4>
                <div className={styles.value}>{m$FormatterShort(ytdCostInfo.actualCost)}</div>
                <div className={styles.progressBar}>
                    <div
                        className={classNames(styles.progress, {
                            [styles.green]: ytdPercentage < 100,
                            [styles.orange]: ytdPercentage === 100,
                        })}
                        style={{
                            width: `${ytdPercentage}%`,
                        }}
                    />
                    <div className={styles.planned} style={{left: `${ytdBudgetPercentage}%`}}>
                        <span
                            className={classNames({
                                [styles.min]: ytdBudgetPercentage < 15,
                                [styles.max]: ytdBudgetPercentage > 85,
                            })}
                        >
                            {m$FormatterShort(ytdCostInfo.budget)}
                        </span>
                    </div>
                </div>
                <div className={styles.label}>
                    {t(ytdLabel, {value: m$FormatterShort(Math.abs(ytdCostInfoDif))})}
                </div>
            </div>
            <div className={containerClassname}>
                <h4>{t('projects.preview.efc')}</h4>
                <div className={styles.value}>
                    {m$FormatterShort(stgEstimatedCostInfo.actualCost)}
                </div>
                <div className={styles.space} />
                <div className={styles.label}>{stgEstimatedLabel}</div>
            </div>
        </div>
    );
};
