import {EntityType} from 'app/contexts';
import {RootState} from '../store';

export const itemsSelector = (state: RootState) => state.tenders.items;
export const areTendersLoadingSelector = (state: RootState) => state.tenders.isLoading;
export const areMoreTendersAvailableSelector = (state: RootState) =>
    state.tenders.areMoreResultsAvailable;
export const filtersSelector = ({filter}: RootState) =>
    filter.repository[EntityType.Tender].filters;
export const totalItemsCountSelector = (state: RootState) => state.tenders.totalResults;
export const tendersSortSelector = (state: RootState) => state.tenders.sortBy;
export const tendersErrorCodeSelector = (state: RootState) => state.tenders.hasErrorCode;
