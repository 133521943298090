import React, {useRef} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import classNames from 'classnames';
import useDimensions from 'react-cool-dimensions';

import {serviceStatusesSelector} from 'app/state/serviceNow';
import {TicketState} from './components/ticketState';
import {useLocalizationContext} from 'app/hooks';
import linkIcon from 'app/static/images/link.svg';

import {PreviewContentLayout} from 'app/layouts';
import {Incident} from './components/it/incident';
import {ChangeRequest} from './components/it/changeRequest';
import {ChangeTask} from './components/it/changeTask';
import {Problem} from './components/it/problem';
import {RequestTask} from './components/it/requestTask';
import {FacilitiesRequest} from './components/gs/facilitiesRequest';
import {HrCase} from './components/hr/hrCase';
import {CatalogItem} from './components/catalogItem/catalogItem';
import {KnowledgeArticle} from './components/knowledgeArticle/knowledgeArticle';

import styles from './servicePreviewPanel.module.scss';

const components = {
    IT: {
        incident: Incident,
        change_request: ChangeRequest,
        change_task: ChangeTask,
        problem: Problem,
        sc_req_item: RequestTask,
        sc_task: RequestTask,
    },
    GS: {
        facilities_request: FacilitiesRequest,
    },
    HR: {
        sn_hr_core_case: HrCase,
    },
    'Catalog Item': {
        'Catalog Item': CatalogItem,
    },
    'Knowledge Article': {
        'Knowledge Article': KnowledgeArticle,
    },
};

export const ServicePreviewPanel = ({data}: any) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const {width} = useDimensions({ref: containerRef});

    const statuses = useSelector(serviceStatusesSelector, shallowEqual);
    const {t} = useLocalizationContext();

    const {details} = data;
    const {
        category,
        ticketType,
        state,
        summary,
        description,
        shortDescription,
        sourceLink,
    } = details;

    // @ts-ignore
    const DetailsComponent = components[category][ticketType];

    return (
        <PreviewContentLayout
            layoutAttributes={{
                className: classNames(styles.container, {
                    [styles.small]: width < 740,
                }),
            }}
            ref={containerRef}
        >
            {state && <TicketState ticketStatuses={statuses[category][ticketType]} state={state} />}
            <div className={styles.content}>
                <div className={styles.itemContainer}>
                    <div className={styles.header}>
                        <h3>{t('serviceNow.preview.details')}</h3>
                        {sourceLink && (
                            <a href={sourceLink} target="_blank" rel="noopener noreferrer">
                                {t('serviceNow.preview.viewSource')}
                                <img src={linkIcon} alt="icon" />
                            </a>
                        )}
                    </div>
                    <DetailsComponent data={details} />
                </div>

                {summary && (
                    <div className={styles.itemContainer}>
                        <h3>{t('serviceNow.preview.summary')}</h3>
                        <p>{summary}</p>
                    </div>
                )}
                {description && (
                    <div className={styles.itemContainer}>
                        <h3>{t('serviceNow.preview.description')}</h3>
                        <p>{description.replace(/\\n/g, '\n')}</p>
                    </div>
                )}
                {!state && shortDescription && (
                    <div className={styles.itemContainer}>
                        <h3>{t('serviceNow.preview.description')}</h3>
                        <p>{shortDescription.replace(/\\n/g, '\n')}</p>
                    </div>
                )}
            </div>
        </PreviewContentLayout>
    );
};
