import React from 'react';
import {getColorsList} from 'app/utils/getColor';
import {LineChartBase} from 'app/views/components/charts/lineChartBase';
import styles from './lineChart.module.scss';

export const LineChart = ({
    labels,
    data,
    formatter = v => v.toString(),
    aspectRatio,
}: {
    labels: string[];
    data: {
        label: string;
        values: number[];
    }[];
    formatter?: (value: number) => string;
    aspectRatio?: number;
}) => {
    const colors: string[] = getColorsList(data.length);
    const datasets: {
        label: string;
        data: number[];
        fill: boolean;
        backgroundColor: string;
        borderColor: string;
    }[] = data.map(({label, values}, index) => ({
        label,
        data: values,
        fill: false,
        lineTension: 0,
        backgroundColor: '#FFF',
        borderColor: colors[index],
        borderWidth: 1,
    }));

    const chartConfig = {
        key: datasets.map(d => d.label).join(),
        data: {
            labels,
            datasets,
        },
        legend: {
            display: false,
        },
        options: {
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function ({index, datasetIndex}: any, {datasets}: any) {
                        const dataset = datasets[datasetIndex];
                        const label = dataset.label;
                        const value = dataset.data[index];

                        return `${label}: ${formatter(value)}`;
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: formatter,
                        },
                    },
                ],
            },
        },
        width: 100,
        height: aspectRatio ? aspectRatio * 100 : 50,
    };

    return (
        <LineChartBase
            chartConfig={chartConfig}
            labels={labels}
            datasets={datasets}
            data={data}
            styles={styles}
        />
    );
};
