import React, {FC} from 'react';
import classNames from 'classnames';
import styles from './Table.module.scss';
import {TableProps} from './Table.types';

export const Table: FC<TableProps> = ({titles, values, tableProps = {}, singleLineRow}) => {
    return (
        <div className={styles.tableScrollWrapper}>
            <table {...tableProps} className={classNames(tableProps?.className, styles.table)}>
                <tbody>
                    <tr>
                        {titles.map((title, i) => (
                            <th key={`th-${i}`}>{title}</th>
                        ))}
                    </tr>
                    {values.map((tr, i) => (
                        <tr key={`tr-${i}`}>
                            {tr.map((value, j) => (
                                <td key={`value-${i}-${j}`}>
                                    {typeof value === 'string' &&
                                    value.length > 25 &&
                                    !singleLineRow ? (
                                        <span className={styles.textValue} title={value}>
                                            {value}
                                        </span>
                                    ) : (
                                        value
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
