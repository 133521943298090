import React from 'react';
import {IErrorProps} from './error.types';
import {RequestTimeoutError} from './requestTimeouteError';
import {OtherErrors} from './otherErrors';
import {NotFoundError} from './notFoundError';
import {ForbiddenError} from './forbiddenError';
import {AppMode, IAppContext} from 'app/contexts';
import {useAppContext} from 'app/hooks';
import {FallbackLayout} from 'app/layouts/fallbackLayout/fallbackLayout';
import {HTTPS_STATUS_CODES, UPDATE_TOKEN_MAX_ATTEMPTS} from 'app/constants';

export const Error = ({
    fetchErrors = false,
    children,
    errorCode = null,
    attempts = 0,
    extraStyles,
    isHideBackButton = true,
}: IErrorProps) => {
    const appContext: IAppContext = useAppContext();

    switch (errorCode) {
        case HTTPS_STATUS_CODES.REQUEST_TIMEOUT:
            return <RequestTimeoutError className={extraStyles} />;
        case HTTPS_STATUS_CODES.NOT_FOUND:
            return <NotFoundError className={extraStyles} isHideBackButton={isHideBackButton} />;
        case HTTPS_STATUS_CODES.FORBIDDEN:
            return <ForbiddenError className={extraStyles} />;
        default: {
            if (
                errorCode === HTTPS_STATUS_CODES.UNAUTHORIZED &&
                appContext.mode !== AppMode.FullScreen
            ) {
                if (attempts > UPDATE_TOKEN_MAX_ATTEMPTS) {
                    return (
                        <OtherErrors className={extraStyles} fetchErrors={fetchErrors}>
                            {children}
                        </OtherErrors>
                    );
                }

                return <FallbackLayout />;
            }

            return (
                <OtherErrors className={extraStyles} fetchErrors={fetchErrors}>
                    {children}
                </OtherErrors>
            );
        }
    }
};
