import React, {useState} from 'react';
import {useLocalizationContext} from 'app/hooks';
import {LineChart} from './lineChart';
import styles from './trend.module.scss';
import {TabButtons} from './tabButtons';
import {m$FormatterShort} from 'app/utils';
import {useMediaQuery} from '@material-ui/core';
import scssVariables from 'app/variables.module.scss';

export const RevenueTrend = ({
    className,
    width,
    data,
    labels,
}: {
    className?: string;
    width: number;
    data: any;
    labels: string[];
}) => {
    const {kpiData, avgData} = data;
    const {t} = useLocalizationContext();
    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`);
    const isExtraSmall = useMediaQuery(`(${scssVariables.mediaExtraSmall.slice(1, -1)})`);
    let height = 259;
    if (isSmall) {
        height = 256;
    }
    if (isExtraSmall) {
        height = 232;
    }

    const tabs = [
        {
            title: t('distribution.preview.fuel'),
            name: 'fuel',
            content: (
                <LineChart
                    labels={labels}
                    formatter={m$FormatterShort}
                    height={height}
                    dataLabelsVisible={false}
                    data={[
                        {
                            label: t('distribution.preview.currentRevenue'),
                            values: kpiData.map((d: any) => d.fuelRevenue / 1000000),
                        },
                        {
                            label: t('distribution.preview.avgSimilarService'),
                            values: avgData.fuelRevenue.map((d: any) => d / 1000000),
                        },
                    ]}
                />
            ),
        },
        {
            title: t('distribution.preview.cStore'),
            name: 'cStore',
            content: (
                <LineChart
                    labels={labels}
                    formatter={m$FormatterShort}
                    height={height}
                    dataLabelsVisible={false}
                    data={[
                        {
                            label: t('distribution.preview.currentRevenue'),
                            values: kpiData.map((d: any) => d.cStoreRevenue / 1000000),
                        },
                        {
                            label: t('distribution.preview.avgSimilarService'),
                            values: avgData.cStoreRevenue.map((d: any) => d / 1000000),
                        },
                    ]}
                />
            ),
        },
        {
            title: t('distribution.preview.carWash'),
            name: 'carWash',
            content: (
                <LineChart
                    labels={labels}
                    formatter={m$FormatterShort}
                    height={height}
                    dataLabelsVisible={false}
                    data={[
                        {
                            label: t('distribution.preview.currentRevenue'),
                            values: kpiData.map((d: any) => d.carWashRevenue / 1000000),
                        },
                        {
                            label: t('distribution.preview.avgSimilarService'),
                            values: avgData.carWashRevenue.map((d: any) => d / 1000000),
                        },
                    ]}
                />
            ),
        },
        {
            title: t('distribution.preview.lube'),
            name: 'lube',
            content: (
                <LineChart
                    labels={labels}
                    formatter={m$FormatterShort}
                    height={height}
                    dataLabelsVisible={false}
                    data={[
                        {
                            label: t('distribution.preview.currentRevenue'),
                            values: kpiData.map((d: any) => d.lubeRevenue / 1000000),
                        },
                        {
                            label: t('distribution.preview.avgSimilarService'),
                            values: avgData.lubeRevenue.map((d: any) => d / 1000000),
                        },
                    ]}
                />
            ),
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);

    return (
        <div className={className}>
            <div className={styles.trendHeader}>
                <h3 className={styles.trendTitle}>
                    {t(`distribution.preview.${selectedTab}`)} {t('distribution.preview.revenue')}
                </h3>
                <TabButtons
                    tabs={tabs.map(tab => ({
                        ...tab,
                        active: selectedTab === tab.name,
                    }))}
                    onSelect={setSelectedTab}
                />
            </div>
            {tabs.find(({name}) => name === selectedTab)?.content}
        </div>
    );
};
