import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';

import {
    applyMailFilters,
    filtersSelector,
    getMailAttachments,
    getMailBody,
    isMailLoadingSelector,
    mailAreMoreResultsAvailableSelector,
    mailErrorCodeSelector,
    mailItemsSelector,
    resetMailState,
    resetMailTab,
    searchMail,
} from 'app/state/mail';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {useChannelContext, useLocalizationContext} from 'app/hooks';
import {EntityType} from 'app/contexts';
import {IMailItem} from 'app/state/mail/mail.types';

import {DEFAULT_PAGE_SIZE} from 'app/constants';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {MailListItem} from 'app/views/mail/components/mailListItem';
import {MailListItemSkeleton} from 'app/views/mail/components/mailListItemSkeleton/mailListItemSkeleton';
import {MailPreviewPanel} from 'app/views/mail';
import {MailFilterTypes} from 'app/state/mail/utils/filters';
import {changeSingleFilter} from 'app/state/filter';
import pleaseChooseEmail from 'app/static/images/pleaseChooseEmail.svg';
import noResultsEmails from 'app/static/images/noResultsEmails.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const MailContainer = () => {
    const dispatch = useDispatch();
    const {usePreviewItem} = useChannelContext();
    const {t} = useLocalizationContext();

    const searchQuery = useSelector(searchQuerySelector, shallowEqual);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const areMoreResultsAvailable = useSelector(mailAreMoreResultsAvailableSelector, shallowEqual);
    const mailItems = useSelector(mailItemsSelector, shallowEqual);
    const isMailLoading = useSelector(isMailLoadingSelector) && !mailItems?.length;
    const filters = useSelector(filtersSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(mailErrorCodeSelector);

    const selectedItem: IMailItem | null = useMemo(() => {
        const item = mailItems.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, mailItems]);

    const isPreviewLoading = Boolean(selectedItem) && !selectedItem?.body && !errorCode; // TODO: check:  && selectedItem?.attachments

    useEffect(() => {
        dispatch(resetMailState());
        dispatch(searchMail(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetMailTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Email,
            entity: selectedItem,
        }
    );

    useEffect(() => {
        if (selectedItem && !selectedItem.body && !selectedItem.attachments) {
            dispatch(getMailBody(selectedItem.id));
            dispatch(getMailAttachments(selectedItem.id));
        }
    }, [dispatch, selectedItem]);

    const onSelect = useCallback(
        (mail: IMailItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: mail.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const loadMoreHandler = useCallback(() => {
        if (areMoreResultsAvailable && !isMailLoading) {
            if (!mailItems?.length) return;
            if (mailItems.length < DEFAULT_PAGE_SIZE) return;

            const page: number = Math.floor(mailItems.length / DEFAULT_PAGE_SIZE);

            dispatch(searchMail(searchQuery, {perPage: DEFAULT_PAGE_SIZE, page}));
        }
    }, [areMoreResultsAvailable, isMailLoading, dispatch, mailItems, searchQuery]);

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.Email,
                    filters: selectedFilters,
                })
            );
            dispatch(applyMailFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filterLabelMapper = useCallback(
        (name: string) => {
            if (name === MailFilterTypes.Date) {
                return t('mail.mailFilters.date');
            }

            return name;
        },
        [t]
    );

    const filter = {
        filterProps: filters,
        onApplyFilters,
        filterLabelMapper,
    };
    const list = {
        itemComponentRender: MailListItem,
        itemSkeletonRender: MailListItemSkeleton,
        itemsData: mailItems,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    return (
        <SearchResultLayout
            isLoading={isMailLoading}
            filter={filter}
            list={list}
            selectedItem={selectedItem}
            previewComponent={MailPreviewPanel}
            isPreviewLoading={isPreviewLoading}
            noResultImageUrl={noResultsEmails}
            chooseItemImageUrl={pleaseChooseEmail}
            translateRoot={'mail'}
        />
    );
};
