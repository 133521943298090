export const handleBlob = (
    arrayBuffer: ArrayBuffer | string,
    blobType: string,
    shouldDownload: boolean = false,
    documentName?: string
) => {
    const blob = new Blob([arrayBuffer], {type: blobType});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('href', url);

    if (shouldDownload && documentName) {
        a.setAttribute('download', String(documentName));
    }

    if (!shouldDownload) {
        a.setAttribute('target', '_blank');
    }

    a.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 0);
};
