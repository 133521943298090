import React, {useCallback, SyntheticEvent, useRef, useEffect, useState} from 'react';
import {useLocalizationContext, useAppContext, useChannelContext, useInjection} from 'app/hooks';
import {getDocumentIcon, getDocumentPath, getFullDate, getDate} from 'app/utils';
import {Link} from 'app/views/components';
import {ReactComponent as EyeSvg} from 'app/static/images/eyeIcon.svg';
import {ReactComponent as LinkSvg} from 'app/static/images/link.svg';
import {ReactComponent as DownloadSvg} from 'app/static/images/downloadIcon.svg';
import {ReactComponent as FileCopySvg} from 'app/static/images/file_copy.svg';
import {PathArray} from 'app/views/documents/components/pathArray';
import {IDocumentPreviewInfoProps} from './documentPreviewInfo.types';
import styles from './documentPreviewInfo.module.scss';
import {AppMode} from 'app/contexts';
import {IoC} from 'app/ioc';
import {ILogger} from 'app/infrastructure/logging';
import {ReactComponent as CollapseTopSvg} from 'app/static/images/collapseArrowTop.svg';
import {ReactComponent as CollapseBottomSvg} from 'app/static/images/collapseArrowBottom.svg';
import classNames from 'classnames';
import {Collapse} from '@material-ui/core';
import {Tooltip} from 'app/views/components/tooltip';

export const DocumentPreviewInfo = ({
    document,
    shouldShowPreviewInfo,
    onExpandPreviewInfoClick,
    isSmall,
    onPreviewClick,
    canPreview,
    onDownloadClick,
}: IDocumentPreviewInfoProps) => {
    const pathArrayRef = useRef<HTMLDivElement | null>(null);
    const {t} = useLocalizationContext();
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const {mode} = useAppContext();
    const {onOpenLink, onDownloadLink} = useChannelContext();
    const createDateTime = getFullDate(document.documentCreatedDate);
    const createDate = getDate(document.documentCreatedDate);
    const updateDate = getDate(document.documentUpdatedDate);
    const altText = t('documents.documentPreviewInfo.docAltIcon');
    const createdOn = t('documents.documentPreviewInfo.createdOn', {
        person: document.documentAuthors[0],
    });
    const modifiedOn = t('documents.documentPreviewInfo.modifiedOn', {
        person: document.documentAuthors[document.documentAuthors.length - 1],
    });
    const isWebView = mode === AppMode.WebView;

    const [showCopyTooltip, setCopyTooltip] = useState<boolean>(false);
    const [tooltipTimerId, setTooltipTimerId] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        return () => {
            if (tooltipTimerId) clearTimeout(tooltipTimerId);
        };
    }, [tooltipTimerId]);

    const getDocumentPathHandler = (flag: boolean) => {
        return flag
            ? getDocumentPath(document.documentURL, logger)
            : getDocumentPath(document.documentURL, logger, true);
    };

    const [isPathArrayHigh, setIsPathArrayHigh] = useState<boolean>(false);
    const {fullPath, pathArray} = getDocumentPathHandler(isPathArrayHigh);

    useEffect(() => {
        setIsPathArrayHigh(false);
        const currentPathArray = pathArrayRef.current;
        if ((currentPathArray?.clientHeight || 0) > 30 && isSmall) {
            setIsPathArrayHigh(true);
        }
        // eslint-disable-next-line
    }, [pathArrayRef]);

    const onOpenSharepointClick = useCallback(
        (e: SyntheticEvent) => {
            if (isWebView) {
                e.preventDefault();
                onOpenLink({absoluteURL: document.documentEmbedUrl});
            }
        },
        [document.documentEmbedUrl, mode, onOpenLink]
    );

    const handleCopy = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            navigator.clipboard.writeText(document.documentURL).then(() => {
                setCopyTooltip(true);
                setTooltipTimerId(
                    setTimeout(() => {
                        setCopyTooltip(false);
                        setTooltipTimerId(null);
                    }, 3000)
                );
            });
        },
        [document.documentURL]
    );

    const documentTitleClasses = classNames(styles.title, {
        [styles.fontSizeTitleChanged]: !shouldShowPreviewInfo,
    });

    const leftSideClassNames = classNames(
        {
            [styles.docIconCollapsed]: !shouldShowPreviewInfo,
        },
        styles.left
    );

    return (
        <div>
            <div className={styles.documentPreviewInfo}>
                <div className={leftSideClassNames}>
                    <img
                        src={getDocumentIcon(document.documentExtention)}
                        alt={altText}
                        className={styles.documentIcon}
                    />
                </div>

                <div className={styles.right}>
                    <div className={styles.mainInfo}>
                        <div className={styles.mainInfoLeft}>
                            <div className={documentTitleClasses}>
                                <span className={styles.documentName}>{document.documentName}</span>
                                <div className={styles.mainInfoRight}>{createDateTime}</div>
                                <span
                                    className={styles.collapseButton}
                                    onClick={onExpandPreviewInfoClick}
                                >
                                    {shouldShowPreviewInfo ? (
                                        <CollapseTopSvg />
                                    ) : (
                                        <CollapseBottomSvg />
                                    )}
                                </span>
                            </div>
                            <Collapse ref={pathArrayRef} in={shouldShowPreviewInfo}>
                                <PathArray
                                    fullPath={fullPath}
                                    pathArray={pathArray}
                                    styleClasses={{
                                        pathArrayClasses: styles.pathArray,
                                        pathSpanItemClasses: {
                                            path: styles.path,
                                            pathChevron: styles.pathChevron,
                                            fullPath: styles.fullPath,
                                        },
                                    }}
                                />
                            </Collapse>
                        </div>
                    </div>
                    <Collapse in={shouldShowPreviewInfo}>
                        <div className={styles.extraInfo}>
                            <span>{createdOn}</span>&nbsp;
                            <span className={styles.extraInfoDate}>{createDate}</span>
                        </div>
                        <div className={styles.extraInfo}>
                            <span>{modifiedOn}</span>&nbsp;
                            <span className={styles.extraInfoDate}>{updateDate}</span>
                        </div>
                    </Collapse>
                </div>
            </div>
            <Collapse in={shouldShowPreviewInfo}>
                <div className={styles.actions}>
                    {canPreview && (
                        <Link component="a" onClick={onPreviewClick}>
                            <>
                                <EyeSvg className={styles.eyeIcon} />
                                <span className={styles.actionsText}>
                                    {t('documents.documentPreviewInfo.viewDocument')}
                                </span>
                            </>
                        </Link>
                    )}
                    <Link
                        href={document.documentURL}
                        target="_blank"
                        component="a"
                        onClick={onDownloadClick}
                    >
                        <>
                            <DownloadSvg className={styles.downloadIcon} />
                            <span className={styles.actionsText}>
                                {t('documents.documentPreviewInfo.download')}
                            </span>
                        </>
                    </Link>
                    {document.documentEmbedUrl && (
                        <Link
                            href={isWebView ? undefined : document.documentEmbedUrl}
                            target="_blank"
                            component="a"
                            onClick={onOpenSharepointClick}
                        >
                            <>
                                <LinkSvg className={styles.linkIcon} />
                                <span className={styles.actionsText}>
                                    {t('documents.documentPreviewInfo.openSharepoint')}
                                </span>
                            </>
                        </Link>
                    )}
                    <Link href={document.documentURL} component="a" onClick={handleCopy}>
                        <>
                            <FileCopySvg className={styles.copyIcon} />
                            <span className={styles.actionsText}>
                                {t('documents.documentPreviewInfo.copy')}
                            </span>
                            <Tooltip
                                className={classNames(styles.copyTooltip, {
                                    [styles.visible]: showCopyTooltip,
                                })}
                            >
                                {t('documents.documentPreviewInfo.copiedStatus')}
                            </Tooltip>
                        </>
                    </Link>
                </div>
            </Collapse>
        </div>
    );
};
