import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

import {getSlashDate} from 'app/utils';
import styles from '../projectsPreviewPanel.module.scss';
import {DetailsList} from 'app/views/components/detailsList/detailsList';
import {IProjectDetails} from 'app/state/projects/projects.types';
import {ColorLabel} from '../../../../components/colorLabel/colorLabel';

export const ProjectInfo = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {projectInfo},
        isSmall,
    } = props;

    const budgetRenderer = (value: string, d: any) => {
        const {onBudget, overBudget} = d;
        return (
            <>
                {onBudget === 1 && (
                    <ColorLabel color="green">{t('projects.preview.onBudget')}</ColorLabel>
                )}
                {overBudget === 1 && (
                    <ColorLabel color="red">{t('projects.preview.overBudget')}</ColorLabel>
                )}
            </>
        );
    };

    const projectCodeRenderer = (value: string) => {
        return <ColorLabel color="gray">{value}</ColorLabel>;
    };

    return (
        <div className={styles.itemContainer}>
            <h3>{t('projects.preview.details')}</h3>
            <div className={classNames({[styles.twoColumns]: !isSmall})}>
                <DetailsList
                    data={projectInfo}
                    localizationPath={'projects.preview'}
                    fields={[
                        'company',
                        {
                            name: 'projectCode',
                            renderer: projectCodeRenderer,
                        },
                        {
                            name: 'budget',
                            renderer: budgetRenderer,
                        },
                        {
                            name: 'actualStartDate',
                            renderer: getSlashDate,
                        },
                        {
                            name: 'forecastCompletedDate',
                            renderer: getSlashDate,
                        },
                    ]}
                />
                <DetailsList
                    data={projectInfo}
                    localizationPath={'projects.preview'}
                    fields={['irr', 'npv', 'strategicTheme', 'type', 'class']}
                />
            </div>
        </div>
    );
};
