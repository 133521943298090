import React from 'react';
import classNames from 'classnames';
import {IButtonProps} from './button.types';
import styles from './button.module.scss';

export const Button = ({onClick, styleClasses, children}: IButtonProps) => {
    const classes = classNames(styleClasses, styles.button);
    return (
        <button className={classes} onClick={onClick}>
            {children}
        </button>
    );
};
