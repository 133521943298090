import {
    ClientType,
    EventType,
    IDocumentPreviewMessageDescriptor,
    IDocumentPreviewMessageMobileDescriptor,
    IOnDocumentPreviewMessage,
    ISendDocumentPreviewMessage,
} from '../../contexts/channelContext/channelContext.types';
import {generateUniqueString} from '../../utils/generateUniqueString';

export const sendDocumentPreviewMessage = (
    messageData: ISendDocumentPreviewMessage,
    client: ClientType = ClientType.RN
) => {
    const {action: eventType, payload: eventData} = messageData || {};
    const msgDescriptor: IDocumentPreviewMessageDescriptor = {
        client,
        author: ClientType.Search,
        msg: {eventType, eventData},
    };
    window.parent?.postMessage(msgDescriptor, '*');
};

export const sendDocumentPreviewMessageMobile = (
    messageData: IOnDocumentPreviewMessage,
    client: ClientType = ClientType.RN
) => {
    const {downloadUrl, previewUrl} = messageData || {};
    const msgDescriptor: IDocumentPreviewMessageMobileDescriptor = {
        client,
        author: ClientType.Search,
        application: `${ClientType.OneHub.toLowerCase()}-${ClientType.Search}`,
        generated_at: Date.now(),
        id: generateUniqueString(),
        payload: {
            eventCode: EventType.PreviewClick,
            downloadUrl: downloadUrl,
            url: previewUrl,
        },
    };
    window.parent?.postMessage(msgDescriptor, '*');
};
