import React, {useEffect, useRef} from 'react';
import {useLocalizationContext} from 'app/hooks';
import styles from './peakHours.module.scss';

export const PeakHours = ({
    stationTrafics,
    className,
}: {
    stationTrafics: [[number]];
    className?: string;
}) => {
    const {t} = useLocalizationContext();
    const yLabels = [
        t('distribution.preview.weekDaysShort.mon'),
        t('distribution.preview.weekDaysShort.tue'),
        t('distribution.preview.weekDaysShort.wed'),
        t('distribution.preview.weekDaysShort.thu'),
        t('distribution.preview.weekDaysShort.fri'),
        t('distribution.preview.weekDaysShort.sat'),
        t('distribution.preview.weekDaysShort.sun'),
    ];
    const xLabels = Array(24).fill(1);

    const max = stationTrafics.reduce(
        (a: number, b: [number]) =>
            Math.max(
                a,
                b.reduce((a, b) => Math.max(a, b), 0)
            ),
        0
    );

    const colorStep = max / 5;
    const getColorType = (value: number) =>
        value === 0 ? 'colorType1' : `colorType${Math.floor(value / colorStep) + 1}`;

    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = elRef.current;

        const handleScroll = (e: WheelEvent) => {
            if (el) {
                if (e.deltaY === 0) return;

                if (
                    !(el?.scrollLeft === 0 && e.deltaY < 0) &&
                    !(
                        el.scrollWidth - el.clientWidth - Math.round(el.scrollLeft) === 0 &&
                        e.deltaY > 0
                    )
                ) {
                    e.preventDefault();
                    el.scrollTo({
                        left: el.scrollLeft + e.deltaY,
                    });
                }
            }
        };
        el?.addEventListener('wheel', handleScroll);
        return function () {
            el?.removeEventListener('wheel', handleScroll);
        };
    }, []);

    return (
        <div className={className}>
            <div className={styles.header}>
                <h3 className={styles.title}>{t('distribution.preview.peakHours')}</h3>
            </div>
            <div className={styles.peakHoursWrapper} ref={elRef}>
                <table className={styles.table}>
                    <tbody>
                        {stationTrafics.map((day: number[], index: number) => (
                            <tr key={`peak-hours-${yLabels[index]}${index}`}>
                                <th>{yLabels[index]}</th>
                                {day.map((hour, i) => (
                                    <td
                                        key={`value-${yLabels[index]}-${i}`}
                                        className={styles[getColorType(hour)]}
                                    />
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <th />
                            {xLabels.map((h: number, i: number) => (
                                <td key={`hour-${i + 1}`} className={styles.days}>
                                    {i + 1}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
