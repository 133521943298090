import {AbortControllerNews, ISearchNewsOptions} from './searchAPICaller.news.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {getFormattedDate} from 'app/utils/convertDate';
import {fetchData} from 'app/utils';
import {NewsRoutes} from './routes.news';

export const abortControllers: AbortControllerNews = {
    NEWS: null,
};

export abstract class SearchAPICallerNews {
    public static async searchNews(
        {
            keyword = '',
            pageNumber = 1,
            pagesize = DEFAULT_PAGE_SIZE,
            dateFrom,
            dateTo,
            companyIds,
        }: ISearchNewsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        const companyIdsQuery = companyIds?.reduce(
            (acc, cur) => acc + '&CompanyIds=' + encodeURIComponent(cur),
            ''
        );

        const url = new URL(
            `${NewsRoutes.news(searchAPIURL)}` +
                `?keyword=${encodeURIComponent(keyword)}` +
                `&pageNumber=${pageNumber}` +
                `&pagesize=${pagesize}` +
                `${dateFrom ? `&from=${encodeURIComponent(getFormattedDate(dateFrom))}` : ''}` +
                `${dateTo ? `&to=${encodeURIComponent(getFormattedDate(dateTo))}` : ''}` +
                `${companyIdsQuery || ''}`
        );

        abortControllers.NEWS?.abort();
        abortControllers.NEWS = new AbortController();

        return await fetchData(
            String(url),
            accessToken,
            'GET',
            null,
            null,
            null,
            abortControllers.NEWS.signal
        );
    }

    public static async getNewsDetails(
        {
            id,
        }: {
            id: string;
        },
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(`${NewsRoutes.newsFull(searchAPIURL)}?id=${id}`);

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }

    public static async searchNewsCompanies(searchAPIURL: string, accessToken: string) {
        return fetchData(
            NewsRoutes.newsFilters(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }
}
