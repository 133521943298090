import {all, getContext, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    applyMailFilters as applyMailFiltersAction,
    getMailAttachments as getMailAttachmentsAction,
    getMailAttachmentsFailure,
    getMailAttachmentsSuccess,
    getMailBody as getMailBodyAction,
    getMailBodyFailure,
    getMailBodySuccess,
    resetMailItems,
    resetMailState,
    resetMailTab as resetMailTabAction,
    searchMail as searchMailAction,
    searchMailFailure,
    searchMailSuccess,
} from './mail.actions';
import {searchQuerySelector} from 'app/state/router';
import {filtersSelector} from './mail.selectors';
import {isFilterSelected} from 'app/utils/filterUtils';
import {getOptionsFromFilter} from './utils/getOptionsFromFilter';
import {resetSingleFilter} from '../filter';
import {EntityType} from 'app/contexts';

export function* searchMail({payload}: ReturnType<typeof searchMailAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const {query, options = {}} = payload;

        const filters = yield select(filtersSelector);
        const filterOptions = getOptionsFromFilter(filters);

        const data = yield searchService.mails
            .get()
            .searchMail({query, ...options, ...filterOptions});
        yield put(searchMailSuccess(data));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchMailFailure(error));
    }
}

export function* getMailBody({payload: emailId}: ReturnType<typeof getMailBodyAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const body = yield searchService.mails.get().getMailBody(emailId);
        yield put(getMailBodySuccess({body, emailId}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getMailBodyFailure(error));
    }
}

export function* getMailAttachments({payload: emailId}: ReturnType<typeof getMailBodyAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const attachments = yield searchService.mails.get().getMailAttachments(emailId);
        yield put(getMailAttachmentsSuccess({attachments, emailId}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getMailAttachmentsFailure(error));
    }
}

function* applyMailFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetMailItems());
        yield put(searchMailAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetMailTab(): any {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(filtersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Email}));
            yield put(resetMailState());
            const query = yield select(searchQuerySelector);
            yield put(searchMailAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* mailSaga() {
    yield all([
        yield takeLatest(String(searchMailAction), searchMail),
        yield takeEvery(String(getMailBodyAction), getMailBody),
        yield takeEvery(String(getMailAttachmentsAction), getMailAttachments),
        yield takeLatest(String(resetMailTabAction), resetMailTab),
        yield takeLatest(String(applyMailFiltersAction), applyMailFilters),
    ]);
}
