import React, {FC} from 'react';
import styles from './previewLayout.module.scss';
import {Loader} from 'app/views/components/loader';
import classNames from 'classnames';

type ChooseItemProps = {
    isLoading?: boolean;
    title: string;
    imageUrl: string;
};

export const ChooseItem: FC<ChooseItemProps> = ({isLoading = false, title, imageUrl}) => {
    return (
        <div className={styles.noResults}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.promptTextLogo}>
                        <img src={imageUrl} alt="Choose item" className={styles.image} />
                    </div>
                    <div className={classNames(styles.promptText, styles.promptTextLight)}>
                        {title}
                    </div>
                </>
            )}
        </div>
    );
};
