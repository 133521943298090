import React, {useCallback, useState} from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {useMediaQuery} from '@material-ui/core';
import classNames from 'classnames';
import styles from './resultList.module.scss';
import {useAppContext, useLocalizationContext} from 'app/hooks';
import {List} from 'app/views/components/list/list';
import {showFiltersIconStyles} from 'app/views/components/filterPanel/styles';
import {FilterButton} from 'app/views/components/filterButton/filterButton';
import {SortPanel} from 'app/views/components/sortPanel';
import scssVariables from 'app/variables.module.scss';
import {AppMode} from 'app/contexts';

const DEFAULT_PADDING_PX = 50;

const onGenericListScroll = (
    {currentTarget: {scrollHeight, scrollTop, clientHeight}}: React.UIEvent<HTMLUListElement>,
    loadMoreHandler: () => void
) => {
    const isBottom = scrollHeight - scrollTop <= clientHeight + DEFAULT_PADDING_PX;
    if (isBottom) {
        loadMoreHandler();
    }
};

export const ResultList = (props: any) => {
    const {
        totalItemsCount,
        sorting,
        hasFilter,
        list,
        areFilterShow,
        onShowFilterClick,
        onShowMobileFilterClick,
        isLoading,
    } = props;
    const {loadMoreHandler, itemSkeletonRender, areMoreResultsAvailable} = list;
    const {t} = useLocalizationContext();
    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`);
    const {mode} = useAppContext();

    const onScroll = useCallback(
        event => {
            if (!areMoreResultsAvailable) return;
            if (loadMoreHandler) onGenericListScroll(event, loadMoreHandler);
        },
        [areMoreResultsAvailable, loadMoreHandler]
    );

    const [isAvailableLoadMoreButton, setIsAvailableLoadMoreButton] = useState(
        areMoreResultsAvailable
    );

    const measuredRef = (node: any) => {
        if (!areMoreResultsAvailable) return;
        if (node !== null) {
            if (node.clientHeight < node.scrollHeight) {
                setIsAvailableLoadMoreButton(false);
            } else {
                setIsAvailableLoadMoreButton(true);
            }
        }
    };

    const skeletons = [...Array(5)].map((item, i) => ({id: String(i)}));

    const showFiltersButton = Boolean(hasFilter && !areFilterShow);
    const showFiltersButtonMobile = Boolean(hasFilter && isSmall);
    const showSorting = Boolean(sorting);
    const showTotalCount = Boolean(totalItemsCount);
    const showExtrasPanel =
        showFiltersButton || showFiltersButtonMobile || showSorting || showTotalCount;

    return (
        <div
            className={classNames(styles.container, {
                [styles.withoutFilter]: showFiltersButton || !hasFilter,
                [styles.withoutExtraPanel]: !showExtrasPanel,
            })}
        >
            <div className={classNames({[styles.extrasPanel]: showExtrasPanel})}>
                {showFiltersButton && (
                    <div className={styles.showFiltersButton} onClick={onShowFilterClick}>
                        {t('components.filterPanel.showFilters')}
                        <ArrowForwardIosIcon style={showFiltersIconStyles} />
                    </div>
                )}
                {showFiltersButtonMobile && (
                    <FilterButton
                        onClick={onShowMobileFilterClick}
                        isLoading={isLoading}
                        className={styles.filterButton}
                    />
                )}
                {showSorting && (
                    <SortPanel {...sorting} isLoading={isLoading} className={styles.sortPanel} />
                )}
                {showTotalCount && (
                    <span className={styles.totalItemsCount}>
                        {t('totalItemsCount', {totalItemsCount})}
                    </span>
                )}
            </div>
            {isLoading ? (
                <List
                    className={classNames(styles.list, styles.listLoading)}
                    itemComponentRender={itemSkeletonRender}
                    itemsData={skeletons}
                    key="skeletons"
                />
            ) : (
                <List
                    className={styles.list}
                    hideHighlightSelectedItem={mode === AppMode.WebView}
                    listRef={measuredRef}
                    onScroll={onScroll}
                    key="list"
                    {...list}
                />
            )}
            {!isLoading && isAvailableLoadMoreButton && (
                <div className={styles.loadMoreContainer}>
                    <p onClick={loadMoreHandler} className={styles.loadMore}>
                        {t('components.loadMore.title')}
                    </p>
                </div>
            )}
        </div>
    );
};
