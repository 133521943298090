import React, {FC} from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styles from './previewLayout.module.scss';
import {showFiltersIconStyles} from 'app/views/components/filterPanel/styles';
import {FilterButton} from 'app/views/components/filterButton/filterButton';
import {useLocalizationContext} from 'app/hooks';

export interface INoResultsProps {
    isFilterSelected: boolean;
    areFilterShow: boolean;
    onShowFilterClick: () => void;
    onShowMobileFilterClick: () => void;

    noResultImageUrl: string;
    noResultsTitle: string;
    noResultsDescription: string;
    noResultsDescriptionFilters: string;
}

export const NoResults: FC<INoResultsProps> = ({
    isFilterSelected,
    areFilterShow,
    onShowFilterClick,
    onShowMobileFilterClick,
    noResultImageUrl,
    noResultsTitle,
    noResultsDescription,
    noResultsDescriptionFilters,
}) => {
    const {t} = useLocalizationContext();

    return (
        <div className={styles.noResults}>
            {isFilterSelected && (
                <div className={styles.filterButtonsContainer}>
                    {!areFilterShow && (
                        <div className={styles.showFiltersButton} onClick={onShowFilterClick}>
                            {t('components.filterPanel.showFilters')}
                            <ArrowForwardIosIcon style={showFiltersIconStyles} />
                        </div>
                    )}
                    <FilterButton
                        onClick={onShowMobileFilterClick}
                        isLoading={false}
                        className={styles.filterButton}
                    />
                </div>
            )}

            <img src={noResultImageUrl} alt="No result" className={styles.image} />
            <div className={styles.promptText}>{noResultsTitle}</div>
            <div className={styles.helperText}>
                {isFilterSelected ? noResultsDescriptionFilters : noResultsDescription}
            </div>
        </div>
    );
};
