import {IEmployeeProfile, IPeopleItem} from './people.types';

export const mapEmployeeToProfileFormat = (employee: IPeopleItem): IEmployeeProfile => {
    let mobileNo = '';
    if (employee?.phones?.length) {
        const mobileNumber = employee.phones.find(p => p.type === 'mobile');
        mobileNo = mobileNumber?.number || '';
    }

    return {
        id: employee.id,
        hasErrors: false,
        employeeInformation: {},
        previousEmployment: [],
        personalInformation: {},
        myTeam: [],
        shouldShowExportPdfButton: false,
        profileHeader: {
            firstName: employee.givenName,
            lastName: employee.surname || '',
            location: employee.officeLocation || '',
            company: employee.companyName || '',
            position: employee.jobtitle || '',
            department: employee.department || '',
            email: employee.workEmail || '',
            mobileNo,
        },
        externalUrls: {
            fursaUrl: '',
            msTeamsUrl: '',
            oneErpUrl: '',
        },
        employeeNumber: employee.employeeNumber || 0,
    };
};
