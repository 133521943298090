import {EntityType} from 'app/contexts';
import {ISuggestionCategory, SuggestionHandler} from './suggestionsService.types';
import {
    IGetSuggestionResponse,
    IGetHistoryResponse,
    SuggestionResponse,
    ICorrespondence,
    IDocument,
    IEmail,
    IEmployee,
    ITraining,
    INews,
    ITender,
    ISupplier,
    ICustomer,
    IService,
    IProject,
    IDistribution,
} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import {isEntityEnabled} from 'app/utils/isEntityEnabled';

const getI18nSuggestionKey = (category: string) => `suggestions.${category}`;

const handleCorrespondences: SuggestionHandler<ICorrespondence> = (correspondences, count) => {
    if (correspondences?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Correspondence),
            type: EntityType.Correspondence,
            items: correspondences.slice(0, count),
        };
    }
};

const handleDocuments: SuggestionHandler<IDocument> = (documents, count) => {
    if (documents?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Document),
            type: EntityType.Document,
            items: documents.slice(0, count),
        };
    }
};

const handleEmails: SuggestionHandler<IEmail> = (emails, count) => {
    if (emails?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Email),
            type: EntityType.Email,
            items: emails.slice(0, count),
        };
    }
};

const handleEmployees: SuggestionHandler<IEmployee> = (employees, count) => {
    if (employees?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Employee),
            type: EntityType.Employee,
            items: employees.slice(0, count),
        };
    }
};

const handleTrainings: SuggestionHandler<ITraining> = (trainings, count) => {
    if (trainings?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Training),
            type: EntityType.Training,
            items: trainings.slice(0, count),
        };
    }
};

const handleNews: SuggestionHandler<INews> = (news, count) => {
    if (news?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.News),
            type: EntityType.News,
            items: news.slice(0, count),
        };
    }
};

const handleTenders: SuggestionHandler<ITender> = (tenders, count) => {
    if (tenders?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Tender),
            type: EntityType.Tender,
            items: tenders.slice(0, count),
        };
    }
};

const handleSuppliers: SuggestionHandler<ISupplier> = (suppliers, count) => {
    if (suppliers?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Supplier),
            type: EntityType.Suppliers,
            items: suppliers.slice(0, count),
        };
    }
};

const handleCustomers: SuggestionHandler<ICustomer> = (customers, count) => {
    if (customers?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Customer),
            type: EntityType.Customers,
            items: customers.slice(0, count),
        };
    }
};

const handleServices: SuggestionHandler<IService> = (services, count) => {
    if (services?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Service),
            type: EntityType.Service,
            items: services.slice(0, count),
        };
    }
};

const handleProjects: SuggestionHandler<IProject> = (projects, count) => {
    if (projects?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Project),
            type: EntityType.Projects,
            items: projects.slice(0, count),
        };
    }
};

const handleDistribution: SuggestionHandler<IDistribution> = (distributions, count) => {
    if (distributions?.length) {
        return {
            name: getI18nSuggestionKey(SuggestionResponse.Distribution),
            type: EntityType.Distribution,
            items: distributions.slice(0, count),
        };
    }
};

const suggestionHandlers: {[key in SuggestionResponse]: SuggestionHandler<any>} = {
    [SuggestionResponse.Correspondence]: handleCorrespondences,
    [SuggestionResponse.Document]: handleDocuments,
    [SuggestionResponse.Email]: handleEmails,
    [SuggestionResponse.Employee]: handleEmployees,
    [SuggestionResponse.Training]: handleTrainings,
    [SuggestionResponse.News]: handleNews,
    [SuggestionResponse.Tender]: handleTenders,
    [SuggestionResponse.Supplier]: handleSuppliers,
    [SuggestionResponse.Customer]: handleCustomers,
    [SuggestionResponse.Service]: handleServices,
    [SuggestionResponse.Project]: handleProjects,
    [SuggestionResponse.Distribution]: handleDistribution,
};

export const suggestionsTransformer = (
    response: IGetSuggestionResponse,
    maxCount: number,
    suggestionsMaxCount: number
): ISuggestionCategory[] => {
    const responseKeys = Object.keys(response) as SuggestionResponse[];

    const categories = responseKeys
        .filter(key => !!suggestionHandlers[key])
        .map(key => suggestionHandlers[key](response[key], suggestionsMaxCount))
        .filter(category => !!category)
        .slice(0, maxCount);

    return categories as ISuggestionCategory[];
};

export const historyTransformer = (
    response: IGetHistoryResponse,
    maxCount: number,
    suggestionsMaxCount: number
): ISuggestionCategory[] => {
    const dateReducer = (acc: number, cur: any): number => {
        const curDate: number = +new Date(cur?.historyDate || 0);
        if (acc === 0) return curDate;
        if (curDate > acc) return curDate;
        return acc;
    };

    const newestDates: {[key in keyof IGetHistoryResponse]: number} = {
        correspondence: response.correspondence?.reduce(dateReducer, 0),
        document: response.document?.reduce(dateReducer, 0),
        email: response.email?.reduce(dateReducer, 0),
        employee: response.employee?.reduce(dateReducer, 0),
        training: response.training?.reduce(dateReducer, 0),
        news: response.news?.reduce(dateReducer, 0),
        tenders: response.tenders?.reduce(dateReducer, 0),
        suppliers: response.suppliers?.reduce(dateReducer, 0),
        customers: response.customers?.reduce(dateReducer, 0),
        tickets: response.tickets?.reduce(dateReducer, 0),
        projects: response.projects?.reduce(dateReducer, 0),
        distributions: response.distributions?.reduce(dateReducer, 0),
    };

    const suggestionsOrderByDate = (Object.keys(response) as Array<keyof IGetHistoryResponse>).sort(
        (firstKey: keyof IGetHistoryResponse, lastKey: keyof IGetHistoryResponse) =>
            newestDates[lastKey] - newestDates[firstKey]
    );

    const mapHistoryResponseToHandler: {
        [key in keyof IGetHistoryResponse]: SuggestionHandler<any> | false;
    } = {
        correspondence: isEntityEnabled(EntityType.Correspondence) && handleCorrespondences,
        document: isEntityEnabled(EntityType.Document) && handleDocuments,
        email: isEntityEnabled(EntityType.Email) && handleEmails,
        employee: isEntityEnabled(EntityType.Employee) && handleEmployees,
        training: isEntityEnabled(EntityType.Training) && handleTrainings,
        news: isEntityEnabled(EntityType.News) && handleNews,
        tenders: isEntityEnabled(EntityType.Tender) && handleTenders,
        suppliers: isEntityEnabled(EntityType.Suppliers) && handleSuppliers,
        customers: isEntityEnabled(EntityType.Customers) && handleCustomers,
        tickets: isEntityEnabled(EntityType.Service) && handleServices,
        projects: isEntityEnabled(EntityType.Projects) && handleProjects,
        distributions: isEntityEnabled(EntityType.Distribution) && handleDistribution,
    };

    const suggestionValues = suggestionsOrderByDate
        .map(key => {
            const handler = mapHistoryResponseToHandler[key] || (() => {});
            return handler(response[key], suggestionsMaxCount);
        })
        .filter(s => {
            if (!s) return false;
            return s.items.length > 0;
        }) as ISuggestionCategory[];

    return suggestionValues.slice(0, maxCount);
};
