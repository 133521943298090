import React, {useCallback, useEffect, useState} from 'react';
import {IframeViewer} from 'app/views/components';
import styles from './mailPreviewPanel.module.scss';
import {MailInfo} from '../mailInfo';
import {IMailAttachment, IMailItem} from 'app/state/mail/mail.types';
import {AppMode} from 'app/contexts';
import {ILogger} from 'app/infrastructure/logging';
import {IoC} from 'app/ioc';
import {useAppContext, useChannelContext, useInjection, useLocalizationContext} from 'app/hooks';
import {PreviewContentLayout} from 'app/layouts';
import {DocViewer} from 'app/views/components/DocViewer/DocViewer';
import CannotBePreviewed from 'app/static/images/cannotBePreviewed.svg';
import {checkIfSafariOrIOS, handleBlob} from 'app/utils';
import {toastrDownloadError, toastrDownloadInfo} from 'app/views/components/toastr/toastrDownload';
import {downloadFileErrorMessage} from 'app/utils/downloadFileErrorMessage';
import {useDispatch} from 'react-redux';
import {Loader} from 'app/views/components/loader';
import {urlToPath} from 'app/utils/urlUtils';
import {ISearchServiceMails} from 'app/infrastructure/search/mails';
import {DocViewerError} from 'app/views/components/DocViewer/DocViewerError';
import type {ToastrBaseType} from 'app/views/components/toastr/toastrBaseComponent';

export const MailPreviewPanel = ({data: mail}: {data: IMailItem}) => {
    const {t} = useLocalizationContext();
    const {id: emailId} = mail;
    const {mode} = useAppContext();
    const searchService = useInjection<ISearchServiceMails>(IoC.SearchServiceMails);
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const [areAttachmentsShown, setAreAttachmentsShown] = useState<boolean>(true);
    const [isSubjectShown, setIsSubjectShown] = useState<boolean>(true);
    const {onDownloadLink, onDocumentPreviewMobile} = useChannelContext();
    const dispatch = useDispatch();

    const [documentPreview, setDocumentPreview] = useState<
        undefined | {url: string; name: string; id: string}
    >();
    const [documentLoading, setDocumentLoading] = useState<boolean>(false);
    const [documentError, setDocumentError] = useState<string | undefined>();
    const isWebView = mode === AppMode.WebView;

    useEffect(() => {
        setDocumentError(undefined);
        setDocumentLoading(true);
        setDocumentPreview(undefined);
    }, [mail]);

    const toggleEmailDetails = useCallback(
        (state: boolean) => {
            if (!mail && state) return;
            setAreAttachmentsShown(state);
            setIsSubjectShown(state);
        },
        [mail]
    );

    const showDetails = useCallback(() => toggleEmailDetails(true), [toggleEmailDetails]);
    const hideDetails = useCallback(() => toggleEmailDetails(false), [toggleEmailDetails]);

    useEffect(() => {
        showDetails();
    }, [showDetails]);

    const downloadAttachment = async ({id, name}: {id: string; name: string}) => {
        let toastr: ToastrBaseType = {
            id: '',
            destroy: () => {},
        };

        if (!isWebView) {
            toastr = toastrDownloadInfo(dispatch, {documentName: name, mode});
        }

        try {
            if (mode === AppMode.WebView) {
                const {url} = (await searchService.getMailAttachmentLinkDownload(emailId, id)) as {
                    url: string;
                };

                onDownloadLink({absoluteURL: url});
            } else {
                const response: any = await searchService.getMailAttachmentDownload(emailId, id);

                if (+response.status === 200) {
                    const arrayBuffer = await response.arrayBuffer();
                    const fileName = response.headers
                        .get('content-disposition')
                        .match(/filename=(.*);/)[1]
                        .replace(/"/g, '');

                    handleBlob(arrayBuffer, 'octet/stream', true, fileName);

                    toastr.destroy();
                } else {
                    let data;

                    try {
                        data = await response.json();
                    } catch (e) {}
                    const text = downloadFileErrorMessage(data?.ExceptionType);

                    toastr.destroy({force: true});

                    toastrDownloadError(dispatch, {text, mode});
                }
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const onDownloadPreviewDocument = () => {
        if (documentPreview?.id) {
            let name = documentPreview.name.replace(/\.xod$/, '');

            downloadAttachment({id: documentPreview.id, name});
        } else {
            logger.error('Document id is undefined');
        }
    };

    const showDocumentPreview = async ({id, name}: IMailAttachment) => {
        try {
            const isMobileOrFrame = isWebView || checkIfSafariOrIOS();
            const previewUrl = (await searchService.getMailAttachmentLinkPreview(emailId, id)) as {
                url: string;
            };
            const downloadUrl = (await searchService.getMailAttachmentLinkDownload(
                emailId,
                id
            )) as {url: string};

            if (isMobileOrFrame) {
                const _previewUrl = urlToPath(previewUrl.url);
                onDocumentPreviewMobile({downloadUrl: downloadUrl.url, previewUrl: _previewUrl});
            } else {
                setDocumentPreview({url: previewUrl.url, name, id: id});
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const handleCloseDocViewEmulator = () => {
        setDocumentPreview(undefined);
        // Reset error if attachments more than 1, and one of there is broken
        setDocumentError(undefined);
    };

    return (
        <PreviewContentLayout layoutAttributes={{className: styles.mailPreviewPanel}}>
            {mail?.body && (
                <MailInfo
                    mail={mail}
                    downloadAttachment={downloadAttachment}
                    showDocumentPreview={showDocumentPreview}
                    shouldShowAttachments={areAttachmentsShown}
                    shouldShowSubject={isSubjectShown}
                    onSelect={showDetails}
                />
            )}
            {documentPreview && !isWebView && (
                <div className={styles.documentPreviewContainer}>
                    {documentLoading && (
                        <div className={styles.loading}>
                            <Loader />
                        </div>
                    )}
                    {documentError ? (
                        <div className={styles.error}>
                            <DocViewerError
                                onClose={handleCloseDocViewEmulator}
                                onDownload={onDownloadPreviewDocument}
                                title={t('documents.documentPreview.documentCannotBePreviewed')}
                                description={t('documents.documentPreview.pleaseDownloadToView')}
                                imageSrc={CannotBePreviewed}
                                imageClasses={styles.noResultsIcon}
                                noResultsContainerClasses={styles.noResultsContainer}
                            />
                        </div>
                    ) : (
                        <DocViewer
                            url={documentPreview.url}
                            fileName={documentPreview.name}
                            onLoad={setDocumentLoading}
                            onDownload={onDownloadPreviewDocument}
                            onError={setDocumentError}
                            className={styles.documentPreview}
                            onClose={() => setDocumentPreview(undefined)}
                            isFullScreen={true}
                        />
                    )}
                </div>
            )}

            {mail?.webLink ? (
                <IframeViewer
                    webLink={mail?.webLink}
                    body={mail?.body}
                    styleClasses={styles.iframeContainer}
                    onSelect={hideDetails}
                />
            ) : null}
        </PreviewContentLayout>
    );
};
