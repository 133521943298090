import React, {ReactChild} from 'react';
import classNames from 'classnames';
import styles from './colorLabel.module.scss';

export type colors =
    | 'red'
    | 'gray'
    | 'lightGray'
    | 'blue'
    | 'darkBlue'
    | 'orange'
    | 'brightRed'
    | 'green'
    | 'brown'
    | 'brightOrange'
    | 'whiteBlue'
    | 'whiteBrightOrange'
    | 'whiteGreen';

export interface IColorLabelProps {
    children: string | string[] | ReactChild | ReactChild[];
    color?: colors;
    className?: string;
    title?: string;
}

export const ColorLabel = ({className, color, children, title}: IColorLabelProps) => {
    const classes = classNames(className, styles.label, {
        [styles.blue]: color === 'blue',
        [styles.darkBlue]: color === 'darkBlue',
        [styles.orange]: color === 'orange',
        [styles.brightOrange]: color === 'brightOrange',
        [styles.brightRed]: color === 'brightRed',
        [styles.red]: color === 'red',
        [styles.brown]: color === 'brown',
        [styles.green]: color === 'green',
        [styles.lightGray]: color === 'lightGray',
        [styles.gray]: color === 'gray',

        [styles.whiteBlue]: color === 'whiteBlue',
        [styles.whiteBrightOrange]: color === 'whiteBrightOrange',
        [styles.whiteGreen]: color === 'whiteGreen',
    });
    return children ? (
        <span className={classes} title={title}>
            {children}
        </span>
    ) : null;
};
