import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames';
import styles from './horizontalScroll.module.scss';

export const HorizontalScroll = ({
    children,
    className,
    leftButtonClassname,
    rightButtonClassName,
}: {
    children: React.ReactNode;
    className?: string;
    leftButtonClassname?: string;
    rightButtonClassName?: string;
}) => {
    const scrollEl = useRef<HTMLDivElement | null>(null);
    const [showButtons, setShowButtons] = useState({
        left: false,
        right: false,
    });

    const onScroll = () => {
        if (!scrollEl?.current) return;
        const el = scrollEl?.current;

        setShowButtons({
            left: el.scrollLeft !== 0,
            right: el?.scrollLeft < el?.scrollWidth - el?.clientWidth,
        });
    };

    useEffect(onScroll, [children]);

    const onScrollClick = (direction: number) => {
        if (!scrollEl?.current) return;
        const el = scrollEl?.current;
        const currentScrollPosition = el.scrollLeft;
        let shift = el?.clientWidth * 0.5;
        const scrollTo = currentScrollPosition + shift * direction;
        el.scrollTo({
            left: scrollTo,
            behavior: 'smooth',
        });
    };

    const onLeftClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        onScrollClick(-1);
    };

    const onRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        onScrollClick(1);
    };

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.contentContainer} ref={scrollEl} onScroll={onScroll}>
                <div className={styles.content}>{children}</div>
            </div>

            {showButtons.left && (
                <div className={classNames(styles.left, leftButtonClassname)} onClick={onLeftClick}>
                    <span />
                </div>
            )}
            {showButtons.right && (
                <div
                    className={classNames(styles.right, rightButtonClassName)}
                    onClick={onRightClick}
                >
                    <span />
                </div>
            )}
        </div>
    );
};
