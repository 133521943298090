import {IGeocode} from '../distributionMay.types';
import {Gmarker} from './marker';
import {get} from 'lodash';
import {ConsoleLogger} from 'app/infrastructure/logging';

export const FindByPlaceId = (
    map: any,
    placeId: string | number,
    callback: (data: any) => void
) => {
    const google = get(window, 'google', null);
    const logger = new ConsoleLogger();

    if (!placeId || !map || !google) return null;

    try {
        const service = new google.maps.places.PlacesService(map);

        return service.getDetails({placeId: placeId}, function (result: IGeocode) {
            map.setCenter(result.geometry.location);

            Gmarker(map, result);

            return callback(result);
        });
    } catch (e) {
        logger.log(e);
        return callback(null);
    }
};
