export enum DistributionsFiltersOptions {
    cityNames = 'cityNames',
    stationStatuses = 'stationStatuses',
    services = 'services',
    ratings = 'ratings',
}

export enum DistributionsSortOptions {
    nameAZ = 'Name ASC',
    nameZA = 'Name DESC',
    ratingDesc = 'Rating DESC',
}
