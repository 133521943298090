import styles from '../error.module.scss';
import ErrorIcon from 'app/static/images/error.svg';
import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

type ForbiddenErrorType = {
    className?: string;
};

export const ForbiddenError = ({className}: ForbiddenErrorType) => {
    const {t} = useLocalizationContext();

    return (
        <div className={classNames(styles.errorContainer, className)}>
            <div className={styles.error}>
                <span style={{backgroundImage: `url(${ErrorIcon})`}} className={styles.image} />
                <span className={styles.title}>{t('components.errors.forbidden.title')}</span>
                <span className={styles.description}>
                    <pre className={styles.description}>
                        {t('components.errors.forbidden.description')}
                    </pre>
                </span>
            </div>
        </div>
    );
};
