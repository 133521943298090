import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import styles from './detailsList.module.scss';

type propsTypes = {
    data: {[key: string]: any};
    fields: (
        | string
        | {
              name: string;
              renderer: (f: any, d?: any, n?: string) => any;
          }
    )[];
    localizationPath: string;
};

export const DetailsList = ({data, fields, localizationPath}: propsTypes) => {
    const {t} = useLocalizationContext();

    const renderer = (value: any): string => {
        switch (typeof value) {
            case 'string':
                return value;
            case 'number':
                return value.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                });
            default:
                return t('NA');
        }
    };

    return (
        <div className={styles.details}>
            {fields.map((field: any) => {
                if (typeof field === 'string') {
                    return (
                        <div className={styles.detailsItem} key={field}>
                            <div className={styles.name}>{t(`${localizationPath}.${field}`)}:</div>
                            <div className={styles.value}>{renderer(data[field])}</div>
                        </div>
                    );
                }
                return (
                    <div className={styles.detailsItem} key={field.name}>
                        <div className={styles.name}>{t(`${localizationPath}.${field.name}`)}:</div>
                        <div className={styles.value}>
                            {field.renderer(data[field.name], data, field.name)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
