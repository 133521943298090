import React from 'react';
import {map, replace, toLower} from 'lodash';
import styles from './smallCard.module.scss';
import classNames from 'classnames';
import iconStyles from './cardIcon.module.scss';
import variables from 'app/variables.module.scss';

export const SmallCard = ({
    className,
    title,
    subtitle,
    total,
    currency,
    data,
    type,
}: {
    className?: string;
    title: string;
    subtitle: string;
    total: number | string;
    currency?: string;
    data: any;
    type: string;
}) => {
    const warningColor = variables.SecondaryRed; // '#A9343A';
    const naColor = variables.neutralGray2; // '#8c8c8c';
    const regularColor = variables.neutralBlack; // '#000';

    return (
        <div className={className}>
            <div className={styles.header}>
                <div>
                    <h3 className={styles.title}>{title}</h3>
                    <div className={styles.subtitle}>{subtitle}</div>
                </div>
                <div className={classNames(styles.icon, iconStyles[type])} />
            </div>
            <div className={styles.total}>
                <span
                    style={{
                        color: +replace(total.toString(), 'M', '') >= 0 ? regularColor : naColor,
                    }}
                >
                    {total}
                </span>
                {currency && <span className={styles.currency}> {currency}</span>}
            </div>
            <div>
                {map(data, ({name, value}: {name: string; value: string}) => {
                    const negativeValue = value[0] === '-';
                    const naValue = toLower(value).slice(0, 3) === 'n/a';

                    return (
                        <div key={name} className={styles.row}>
                            <div className={styles.label}>{name}</div>
                            <div
                                className={styles.value}
                                style={{
                                    color: negativeValue
                                        ? warningColor
                                        : naValue
                                        ? naColor
                                        : regularColor,
                                }}
                            >
                                {value}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
