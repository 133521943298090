import React from 'react';
import {default as MUISkeleton} from '@material-ui/lab/Skeleton';
import styles from './skeleton.module.scss';
import {ISkeletonProps, ICircleSkeletonProps, IRectSkeletonProps} from './skeleton.types';

const Skeleton = ({variant, height, width}: ISkeletonProps) => {
    return (
        <MUISkeleton
            className={styles.wave}
            variant={variant}
            animation="wave"
            width={width}
            height={height}
        />
    );
};

const CircleSkeleton = ({size = 40}: ICircleSkeletonProps) => (
    <Skeleton variant="circle" height={size} width={size} />
);

const RectSkeleton = ({height = 16, width}: IRectSkeletonProps) => (
    <Skeleton variant="rect" height={height} width={width} />
);

export {Skeleton, CircleSkeleton, RectSkeleton};
