import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import {Avatar} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import styles from './newsListItem.module.scss';
import {TimeAgo} from 'app/views/components/timeAgo';
import {parseISO} from 'date-fns';
import {INewsItem} from 'app/state/news';

export const NewsListItem: FC<{item: INewsItem; searchWords: string[]}> = ({
    item: {imageBase64 = '', title, date, company},
    searchWords = [],
}) => (
    <div className={styles.item}>
        <Avatar variant="rounded" alt={title} src={imageBase64} className={styles.thumbnail}>
            <DescriptionIcon />
        </Avatar>
        <div>
            <span className={styles.author}>{company}</span>
            <span title={title}>
                <Highlighter
                    className={styles.title}
                    highlightClassName="highlight"
                    searchWords={searchWords}
                    textToHighlight={title || ''}
                />
            </span>
            <div className={styles.date}>
                <TimeAgo date={parseISO(date)} />
            </div>
        </div>
    </div>
);
