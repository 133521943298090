import React, {useState, createContext, FC} from 'react';
import {Currency, CurrencyContextType} from './types';

const initialValue: Currency = 'usd';

export const CurrencyContext = createContext<CurrencyContextType>([initialValue, () => {}]);

export const CurrencyContextProvider: FC = ({children}) => {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(initialValue);

    return (
        <CurrencyContext.Provider value={[selectedCurrency, setSelectedCurrency]}>
            {children}
        </CurrencyContext.Provider>
    );
};
