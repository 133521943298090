import {createActions} from 'redux-actions';
import {TendersSortOptions} from 'app/containers/tenderBoard/tenderBoard.types';

export const {
    searchTenders,
    searchTendersSuccess,
    searchTendersFailure,
    resetTendersState,
    resetTendersItems,
    setTendersSortBy,
    setTendersSortByError,
    applyTenderFilters,
    resetTenderBoardTab,
} = createActions({
    SEARCH_TENDERS: (query: string) => query,
    SEARCH_TENDERS_SUCCESS: tendersResponse => tendersResponse,
    SEARCH_TENDERS_FAILURE: (error: any) => error,
    RESET_TENDERS_STATE: () => {},
    RESET_TENDERS_ITEMS: () => {},
    SET_TENDERS_SORT_BY: (sortOption: TendersSortOptions) => sortOption,
    SET_TENDERS_SORT_BY_ERROR: () => {},
    APPLY_TENDER_FILTERS: () => {},
    RESET_TENDER_BOARD_TAB: () => {},
});
