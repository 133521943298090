import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useChannelContext, useLocalizationContext} from 'app/hooks';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {EntityType} from 'app/contexts';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {
    areMoreTendersAvailableSelector,
    itemsSelector,
    areTendersLoadingSelector,
    searchTenders,
    filtersSelector,
    totalItemsCountSelector,
    tendersSortSelector,
    tendersErrorCodeSelector,
    setTendersSortBy,
    ITenderItem,
    applyTenderFilters,
    resetTendersState,
    resetTenderBoardTab,
} from 'app/state/tenders';

import {TendersFiltersOptions, TendersSortOptions} from './tenderBoard.types';
import {ISortOption} from 'app/views/components/sortPanel/sortPanel.types';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {TenderBoardListItem} from 'app/views/tenderBoard/tenderBoardListItem';
import {TenderBoardListItemSkeleton} from 'app/views/tenderBoard/tenderBoardListItemSkeleton';
import {TenderBoardPreviewPanel} from 'app/views/tenderBoard';
import {changeSingleFilter} from 'app/state/filter';
import newsChoose from 'app/static/images/news.svg';
import noResultsNews from 'app/static/images/noResultsNews.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const tendersSortOptionsI18n = {
    [TendersSortOptions.none]: 'tenderBoard.tenderBoardContainer.sortRelevance',
    [TendersSortOptions.nameAZ]: 'tenderBoard.tenderBoardContainer.sortNameAToZ',
    [TendersSortOptions.nameZA]: 'tenderBoard.tenderBoardContainer.sortNameZToA',
    [TendersSortOptions.companyAZ]: 'tenderBoard.tenderBoardContainer.sortCompanyAToZ',
    [TendersSortOptions.companyZA]: 'tenderBoard.tenderBoardContainer.sortCompanyZToA',
    [TendersSortOptions.newToOld]: 'tenderBoard.tenderBoardContainer.sortNewToOld',
    [TendersSortOptions.oldToNew]: 'tenderBoard.tenderBoardContainer.sortOldToNew',
};

const sortList: ISortOption[] = Object.values(TendersSortOptions).map(
    (option: TendersSortOptions): ISortOption => ({
        name: tendersSortOptionsI18n[option],
        value: option,
    })
);

export const TenderBoardContainer = () => {
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector, shallowEqual);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(itemsSelector, shallowEqual);
    const areTenderBoardItemsLoading = useSelector(areTendersLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(areMoreTendersAvailableSelector, shallowEqual);
    const filters = useSelector(filtersSelector, shallowEqual);
    const totalItemsCount: number = useSelector(totalItemsCountSelector);
    const currentSort = useSelector(tendersSortSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(tendersErrorCodeSelector);

    const selectedItem: ITenderItem | null = useMemo(() => {
        const item = items.find(i => `${i.id}` === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetTendersState());
        dispatch(searchTenders(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetTenderBoardTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Tender,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!areTenderBoardItemsLoading && areMoreResultsAvailable) {
            dispatch(searchTenders(searchQuery));
        }
    }, [areMoreResultsAvailable, areTenderBoardItemsLoading, dispatch, searchQuery]);

    const onSelect = useCallback(
        (tender: ITenderItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: tender.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onSortChange = useCallback(
        sortBy => {
            dispatch(setTendersSortBy(sortBy));
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.Tender,
                    filters: selectedFilters,
                })
            );
            dispatch(applyTenderFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filterLabelMapper = (name: string): string => {
        if (name === TendersFiltersOptions.TypeId) {
            return t('tenderBoard.tenderBoardFilters.filterTypes');
        }
        if (name === TendersFiltersOptions.StatusId) {
            return t('tenderBoard.tenderBoardFilters.filterStatus');
        }
        if (name === TendersFiltersOptions.CompanyId) {
            return t('tenderBoard.tenderBoardFilters.filterCompanies');
        }
        if (name === TendersFiltersOptions.Date) {
            return t('tenderBoard.tenderBoardFilters.filterDate');
        }
        return t(name);
    };

    const filter = {
        filterProps: filters,
        onApplyFilters,
        filterLabelMapper,
    };
    const list = {
        itemComponentRender: TenderBoardListItem,
        itemSkeletonRender: TenderBoardListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };
    const sorting = {
        sortList,
        onSortChange,
        value: currentSort,
    };

    return (
        <SearchResultLayout
            isLoading={areTenderBoardItemsLoading}
            filter={filter}
            list={list}
            totalItemsCount={totalItemsCount}
            sorting={sorting}
            selectedItem={selectedItem}
            previewComponent={TenderBoardPreviewPanel}
            noResultImageUrl={noResultsNews}
            chooseItemImageUrl={newsChoose}
            translateRoot={'tenderBoard'}
        />
    );
};
