import React, {MouseEvent} from 'react';
import ReactPlayer from 'react-player';
import styles from './newsPreviewPanel.module.scss';
import {INewsItem} from 'app/state/news';
import {HtmlContent} from 'app/views/components/htmlContent';
import {getFullDate} from 'app/utils';
import {useAppContext, useChannelContext, useLocalizationContext} from 'app/hooks';
import {AppMode} from 'app/contexts';
import {PreviewContentLayout} from 'app/layouts';
import {size} from 'lodash';

export const NewsPreviewPanel = ({data}: {data: INewsItem}) => {
    const {t} = useLocalizationContext();
    const {mode} = useAppContext();
    const isWebView = mode === AppMode.WebView;
    const {onOpenLink} = useChannelContext();

    const linkClickHandler = (e: MouseEvent, absoluteURL: string) => {
        if (isWebView) {
            e.preventDefault();
            onOpenLink({absoluteURL});
        }
    };

    const {company, date, title, imageBase64, body, videoUrl, originalUrl} = data;
    return (
        <PreviewContentLayout>
            <div className={styles.header}>
                <div>{company}</div>
                <div className={styles.date}>{getFullDate(date)}</div>
            </div>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.linkWrapper}>
                {size(data) ? (
                    <a
                        onClick={e => linkClickHandler(e, originalUrl)}
                        href={isWebView ? undefined : originalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('news.readMoreLink')}
                    </a>
                ) : null}
            </div>
            {!!imageBase64 && <img className={styles.image} src={imageBase64} alt={title} />}
            <HtmlContent className={styles.text} content={body} />
            {videoUrl && (
                <div className={styles.videoContainer}>
                    <ReactPlayer url={videoUrl} height="340px" className={styles.videoplayer} />
                </div>
            )}
        </PreviewContentLayout>
    );
};
