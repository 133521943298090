import React, {FC} from 'react';
import {useMediaQuery} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

import scssVariables from 'app/variables.module.scss';
import {FilterPanel} from 'app/views/components';
import styles from './filtersLayout.module.scss';
import {IFilterPanelProps} from 'app/views/components/filterPanel/filterPanel.types';

export const FiltersLayout: FC<IFilterPanelProps> = props => {
    const {onApplyFilters, ...filterProps} = props;
    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`);

    const filterPanel = (
        <div className={styles.filterPanel}>
            <FilterPanel
                onApplyFilters={filters => {
                    if (onApplyFilters) onApplyFilters(filters);
                }}
                {...filterProps}
            />
        </div>
    );

    if (isSmall) {
        return <Fade in>{filterPanel}</Fade>;
    }

    return filterPanel;
};
