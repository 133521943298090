import React, {FormEvent} from 'react';
import commonStyles from 'app/views/components/filterPanel/styles/filterPanel.module.scss';
import styles from './filterTextField.module.scss';
import {useLocalizationContext} from 'app/hooks';
import {ITextFieldFilterProps} from './filterTextField.types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const FilterTextField = ({
    filter,
    allFilters,
    arrowIconsStyles,
    toggle,
    checkFilterIsActiveToggle,
    filterLabelMapper,
    setValue,
    onSubmit,
}: ITextFieldFilterProps) => {
    const {t} = useLocalizationContext();

    const isFilterOpen = checkFilterIsActiveToggle(filter.Name);

    const getValue = (refinementValue: string): string => {
        const currentFilter = allFilters.find(
            f => f.filter === filter.Name && f.refinementValue === refinementValue
        );
        return currentFilter?.value || '';
    };

    const submitHandler = (e: FormEvent): void => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <div className={styles.textFieldFilter}>
            {filter.Entries.length > 1 && (
                <span onClick={() => toggle(filter.Name)} className={commonStyles.property}>
                    {filterLabelMapper ? filterLabelMapper(filter.Name) : t(filter.Name)}
                    {isFilterOpen ? (
                        <ExpandLessIcon style={arrowIconsStyles} />
                    ) : (
                        <ExpandMoreIcon style={arrowIconsStyles} />
                    )}
                </span>
            )}
            {(isFilterOpen || filter.Entries.length === 1) &&
                filter.Entries.map(entry => (
                    <div key={entry.RefinementName} className={styles.textFieldEntry}>
                        <span className={styles.textFieldEntryLabel}>
                            {t(entry.RefinementName)}
                        </span>
                        <div className={styles.fieldWrapper}>
                            <form onSubmit={submitHandler}>
                                <input
                                    value={getValue(entry.RefinementValue)}
                                    onChange={e =>
                                        setValue(filter.Name, entry.RefinementValue, e.target.value)
                                    }
                                    type="text"
                                    className={styles.field}
                                    maxLength={120}
                                />
                            </form>
                        </div>
                    </div>
                ))}
        </div>
    );
};
