import {all, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {RootState} from 'app/state';
import {
    IGetCorrespondenceAttachments,
    IGetCorrespondenceTracking,
    ITransformedCorrespondenceResponse,
} from 'app/infrastructure/search/correspondence/searchService.correspondence.types';
import {
    changeCorrespondenceFilters,
    changeCorrespondenceFiltersFailure,
    changeCorrespondenceFiltersSuccess,
    getCorrespondenceAttachments as getCorrespondenceAttachmentsAction,
    getCorrespondenceAttachmentsFailure,
    getCorrespondenceAttachmentsSuccess,
    getCorrespondenceTracking as getCorrespondenceTrackingAction,
    getCorrespondenceTrackingFailure,
    getCorrespondenceTrackingSuccess,
    resetCorrespondenceItems,
    resetCorrespondenceState,
    resetCorrespondenceTab as resetCorrespondenceTabAction,
    searchCorrespondence as searchCorrespondenceAction,
    searchCorrespondenceFailure,
    searchCorrespondenceSuccess,
    setCorrespondenceFilters as setCorrespondenceFiltersAction,
} from './correspondence.actions';
import {correspondenceFiltersSelector} from './correspondence.selectors';
import {searchQuerySelector} from '../router';
import {isFilterSelected} from 'app/utils/filterUtils';

export function* searchCorrespondence({
    payload,
}: ReturnType<typeof searchCorrespondenceAction>): any {
    const logger = yield getContext('logger');
    try {
        const state: RootState = yield select();
        const searchService = yield getContext('searchService');

        const correspondence: ITransformedCorrespondenceResponse = yield searchService.correspondence
            .get()
            .searchCorrespondence(
                payload,
                state.correspondence.items.length,
                state.correspondence.filters
            );
        if (!correspondence.hasErrors) {
            yield put(searchCorrespondenceSuccess(correspondence));
        } else {
            yield put(searchCorrespondenceFailure());
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchCorrespondenceFailure(error));
    }
}

export function* setCorrespondenceFilters({
    payload,
}: ReturnType<typeof changeCorrespondenceFilters>) {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);

        yield put(resetCorrespondenceItems());
        yield put(setCorrespondenceFiltersAction(payload));
        yield put(searchCorrespondenceAction(query));
        yield put(changeCorrespondenceFiltersSuccess());
    } catch (error) {
        yield logger.get().error(error);
        yield put(changeCorrespondenceFiltersFailure(error));
    }
}

export function* getCorrespondenceAttachments({
    payload,
}: ReturnType<typeof getCorrespondenceAttachmentsAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const attachments: IGetCorrespondenceAttachments = yield searchService.correspondence
            .get()
            .getCorrespondenceAttachments(payload);
        if (!attachments.hasErrors) {
            yield put(getCorrespondenceAttachmentsSuccess(attachments));
        } else {
            yield put(getCorrespondenceAttachmentsFailure());
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(getCorrespondenceAttachmentsFailure(error));
    }
}

export function* getCorrespondenceTracking({
    payload,
}: ReturnType<typeof getCorrespondenceTrackingAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const tracking: IGetCorrespondenceTracking = yield searchService.correspondence
            .get()
            .getCorrespondenceTracking(payload);
        if (!tracking.hasErrors) {
            yield put(getCorrespondenceTrackingSuccess(tracking));
        } else {
            yield put(getCorrespondenceTrackingFailure());
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(getCorrespondenceTrackingFailure(error));
    }
}

function* resetCorrespondenceTab() {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(correspondenceFiltersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetCorrespondenceState());
            const query = yield select(searchQuerySelector);
            yield put(searchCorrespondenceAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* correspondenceSaga() {
    yield all([
        yield takeLatest(String(searchCorrespondenceAction), searchCorrespondence),
        yield takeLatest(String(changeCorrespondenceFilters), setCorrespondenceFilters),
        yield takeLatest(String(getCorrespondenceAttachmentsAction), getCorrespondenceAttachments),
        yield takeLatest(String(getCorrespondenceTrackingAction), getCorrespondenceTracking),
        yield takeLatest(String(resetCorrespondenceTabAction), resetCorrespondenceTab),
    ]);
}
