import React, {useCallback, useState} from 'react';
import {useAppContext, useChannelContext, useInjection, useLocalizationContext} from 'app/hooks';
import {AppMode} from 'app/contexts';
import {CorrespondenceAttachmentsView} from '@one-hub/onehub-web-lib';
import {
    ICorrespondenceAttachment,
    ICorrespondenceAttachmentsProps,
} from './correspondenceAttachments.types';
import {DocumentPreview} from '../../../components/documentPreview/documentPreview';
import {checkIfSafariOrIOS, downloadDocumentWithLink, handleBlob} from 'app/utils';
import {toastrDownloadError, toastrDownloadInfo} from 'app/views/components/toastr/toastrDownload';
import {ILogger} from 'app/infrastructure/logging';
import {downloadFileErrorMessage} from 'app/utils/downloadFileErrorMessage';
import {useDispatch} from 'react-redux';
import {IoC} from 'app/ioc';
import styles from '../correspondencePreviewPanel/correspondencePreviewPanel.module.scss';
import {urlToPath} from 'app/utils/urlUtils';
import {ISearchServiceCorrespondence} from 'app/infrastructure/search/correspondence';
import type {ToastrBaseType} from 'app/views/components/toastr/toastrBaseComponent';

export const CorrespondenceAttachments = ({attachments}: ICorrespondenceAttachmentsProps) => {
    const {t} = useLocalizationContext();
    const {mode} = useAppContext();
    const searchService = useInjection<ISearchServiceCorrespondence>(
        IoC.SearchServiceCorrespondence
    );
    const dispatch = useDispatch();
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const isWebView = mode === AppMode.WebView;
    const {onDownloadLink, onDocumentPreviewMobile} = useChannelContext();

    const [documentPreview, setDocumentPreview] = useState<
        | undefined
        | {
              previewUrl: string;
              downloadUrl: string;
              name: string;
              id: string | number;
              type: string;
              title: string;
              extension: string | 'pdf';
          }
    >();
    const [documentLoading, setDocumentLoading] = useState<boolean>(false);
    const [documentError, setDocumentError] = useState<string | undefined>();

    const onAttachmentPreview = async (attachment: ICorrespondenceAttachment) => {
        let name = `${attachment?.title}.${attachment.extension}`;
        try {
            const previewUrl = (await searchService.getCorrespondenceAttachmentLinkPreview(
                attachment.id.toString(),
                name,
                true,
                false
            )) as {url: {url: string}};
            const downloadUrl = (await searchService.getCorrespondenceAttachmentLinkDownload(
                attachment.id.toString(),
                name,
                false,
                false
            )) as {url: {url: string}};
            setDocumentPreview({
                previewUrl: previewUrl.url.url,
                downloadUrl: downloadUrl.url.url,
                name,
                id: attachment.id,
                type: 'attachment',
                title: attachment?.title,
                extension: attachment.extension,
            });

            if (documentPreview && (isWebView || checkIfSafariOrIOS())) {
                let _downloadUrl = urlToPath(downloadUrl.url.url);

                onDocumentPreviewMobile({
                    downloadUrl: _downloadUrl,
                    previewUrl: previewUrl.url.url,
                });
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const onDownload = () => {
        downloadAttachment({id: documentPreview?.id, name: documentPreview?.name});
    };

    const downloadAttachment = useCallback(
        ({id, name}: any) => {
            if (mode === AppMode.WebView || checkIfSafariOrIOS()) {
                downloadAttachmentWithLink(searchService, id, name, logger, mode, onDownloadLink);
            } else {
                const toastr = toastrDownloadInfo(dispatch, {documentName: name, mode});

                downloadAttachmentWithBlob(searchService, id, name, logger, toastr);
            }
        },
        [mode, searchService, logger, onDownloadLink]
    );

    const downloadAttachmentWithBlob = async (
        searchService: ISearchServiceCorrespondence,
        id: string,
        name: string,
        logger: ILogger,
        toastr: ToastrBaseType
    ) => {
        try {
            const response = await searchService.getCorrespondenceAttachment(id, name);
            if (response.status === 200) {
                const arrayBuffer = await response.arrayBuffer();

                handleBlob(arrayBuffer, 'octet/stream', true, name);

                toastr.destroy();

                // TODO Check It !!!
                // const text = downloadFileErrorMessage(1);
                // toastrDownloadError(dispatch, {text, mode});
            } else {
                let data;

                toastr.destroy({force: true});

                try {
                    data = await response.json();
                    const text = downloadFileErrorMessage(data?.ExceptionType);

                    toastrDownloadError(dispatch, {text, mode});
                } catch (e) {
                    const text = downloadFileErrorMessage(data?.ExceptionType);

                    toastrDownloadError(dispatch, {text, mode});
                }
            }
            toastr.destroy();
        } catch (error) {
            logger.error(error);
        }
    };

    const downloadAttachmentWithLink = async (
        searchService: ISearchServiceCorrespondence,
        id: string,
        name: string,
        logger: ILogger,
        mode: AppMode,
        onDownloadLink: ({absoluteURL}: any) => void
    ) => {
        try {
            // const {url} = await searchService.getCorrespondenceAttachmentLink(
            //     id,
            //     name,
            //     false,
            //     false
            // );
            const downloadUrl = (await searchService.getCorrespondenceAttachmentLinkPreview(
                id,
                name,
                false,
                false
            )) as {url: {url: string}};

            if (mode === AppMode.WebView) {
                onDownloadLink({absoluteURL: downloadUrl.url.url});
            } else {
                downloadDocumentWithLink(downloadUrl.url.url, name);
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const onFullScreenClose = () => {
        setDocumentPreview(undefined);
    };

    return (
        <>
            <CorrespondenceAttachmentsView
                attachments={attachments}
                noAttachmentsTitle={t('correspondence.correspondenceAttachments.noAttachments')}
                isWidget={mode !== AppMode.FullScreen}
                onItemClick={(e: MouseEvent, attachment: ICorrespondenceAttachment) =>
                    onAttachmentPreview(attachment)
                }
            />
            {documentPreview && !isWebView && (
                <div className={styles.attachmentPreviewContainer}>
                    <DocumentPreview
                        documentURL={documentPreview.previewUrl}
                        documentName={documentPreview.title}
                        onFullScreenClose={onFullScreenClose}
                        isFullScreen={true}
                        docLoading={documentLoading}
                        setDocLoading={setDocumentLoading}
                        docError={documentError}
                        setDocError={setDocumentError}
                        fileType={documentPreview.extension}
                        onDownloadClick={onDownload}
                    />
                </div>
            )}
        </>
    );
};
