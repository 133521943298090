import {createActions} from 'redux-actions';
import {DistributionsSortOptions} from '../../containers/distribution/distribution.types';

export const {
    searchDistributions,
    searchDistributionsSuccess,
    searchDistributionsFailure,
    resetDistributionsState,
    resetDistributionTab,
    resetDistributionItems,
    getDistributionDetails,
    getDistributionDetailsSuccess,
    getDistributionDetailsFailure,
    applyDistributionFilters,
    resetDistributionDetails,
    setDistributionsSortBy,
    setDistributionsSortByError,
} = createActions({
    SEARCH_DISTRIBUTIONS: (query: string) => query,
    SEARCH_DISTRIBUTIONS_SUCCESS: (payload: any) => payload,
    SEARCH_DISTRIBUTIONS_FAILURE: (error: any) => error,
    RESET_DISTRIBUTIONS_STATE: () => {},
    RESET_DISTRIBUTION_TAB: () => {},
    GET_DISTRIBUTION_DETAILS: (payload: any) => payload,
    GET_DISTRIBUTION_DETAILS_SUCCESS: (details: any) => details,
    GET_DISTRIBUTION_DETAILS_FAILURE: (error: any) => error,
    RESET_DISTRIBUTION_DETAILS: () => {},
    RESET_DISTRIBUTION_ITEMS: () => {},
    APPLY_DISTRIBUTION_FILTERS: () => {},
    SET_DISTRIBUTIONS_SORT_BY: (sortOption: DistributionsSortOptions) => sortOption,
    SET_DISTRIBUTIONS_SORT_BY_ERROR: () => {},
});
