import {handleActions} from 'redux-actions';
import {
    getProjectDetails,
    getProjectDetailsFailure,
    getProjectDetailsSuccess,
    resetProjectsItems,
    resetProjectsState,
    searchProjects,
    searchProjectsFailure,
    searchProjectsSuccess,
    setProjectsSortBy,
} from 'app/state/projects/projects.actions';
import {IProjectItem} from './projects.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {ProjectsSortOptions} from 'app/containers/projects/projects.types';

export interface IProjectsState {
    items: IProjectItem[];
    isLoading: boolean;
    areMoreResultsAvailable: boolean;
    hasErrors: boolean;
    hasErrorCode?: number;
    totalCount: number;
    sortBy: ProjectsSortOptions;
}

const initialState: IProjectsState = {
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
    totalCount: 0,
    sortBy: ProjectsSortOptions.none,
};

export default handleActions(
    {
        [String(searchProjects)]: state => ({...state, isLoading: true}),
        [String(searchProjectsSuccess)]: (
            state,
            {payload}: {payload: {items: IProjectItem[]; totalCount: number}}
        ) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload.items],
            areMoreResultsAvailable: payload.items.length === DEFAULT_PAGE_SIZE,
            totalCount: payload.totalCount,
        }),
        [String(searchProjectsFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetProjectsState)]: () => initialState,
        [String(resetProjectsItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
            totalCount: 0,
        }),
        [String(getProjectDetails)]: state => state,
        [String(getProjectDetailsSuccess)]: (state, {payload: {projectId, details}}: any) => ({
            ...state,
            items: state.items.map(item => {
                if (item.projectId === projectId || item.id === projectId)
                    return {...item, details};
                return item;
            }),
        }),
        [String(getProjectDetailsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(setProjectsSortBy)]: (state, {payload}: any) => ({
            ...state,
            sortBy: payload as ProjectsSortOptions,
        }),
    },
    initialState
);
