import {handleActions} from 'redux-actions';
import {IDetailsFilter, ISupplierItem} from './suppliers.types';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';

import {
    getSuppliersDetails,
    getSuppliersDetailsFailure,
    getSuppliersDetailsSuccess,
    resetSuppliersDetails,
    resetSuppliersItems,
    resetSuppliersState,
    searchSuppliers,
    searchSuppliersFailure,
    searchSuppliersSuccess,
    setSupplierFilters,
    setSuppliersDetailsFilters,
} from './suppliers.actions';

export interface ISuppliersState {
    items: ISupplierItem[];
    isLoading: boolean;
    areMoreResultsAvailable: boolean;
    hasErrors: boolean;
    hasErrorCode?: number;
    filters: IFilterRefiner[];
    detailsFilters: IDetailsFilter[];
}

const initialState: ISuppliersState = {
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
    filters: [],
    detailsFilters: [],
};

export default handleActions(
    {
        [String(searchSuppliers)]: state => ({...state, isLoading: true}),
        [String(searchSuppliersSuccess)]: (
            state,
            {
                payload,
            }: {
                payload: {
                    suppliers: ISupplierItem[];
                    filters: IFilterRefiner[];
                    detailsFilters: IDetailsFilter[];
                };
            }
        ) => ({
            ...state,
            isLoading: false,
            items: [...state.items, ...payload.suppliers],
            filters: payload.filters,
            detailsFilters: payload.detailsFilters,
            areMoreResultsAvailable: false,
        }),
        [String(searchSuppliersFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
        [String(resetSuppliersState)]: () => initialState,
        [String(resetSuppliersItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
        }),
        [String(resetSuppliersDetails)]: state => ({
            ...state,
            items: state.items.map(item => ({
                ...item,
                details: null,
            })),
            isLoading: true,
        }),
        [String(setSupplierFilters)]: (state, {payload}: any) => ({
            ...state,
            filters: payload,
        }),
        [String(setSuppliersDetailsFilters)]: (state, {payload}: any) => ({
            ...state,
            detailsFilters: payload,
        }),
        [String(getSuppliersDetails)]: state => state,
        [String(getSuppliersDetailsSuccess)]: (state, {payload: {supplierId, details}}: any) => ({
            ...state,
            items: state.items.map(item => {
                if (item.id === supplierId) return {...item, details};
                return item;
            }),
        }),
        [String(getSuppliersDetailsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrorCode: payload.responseStatus,
        }),
    },
    initialState
);
