import {
    endOfWeek,
    format,
    parse,
    startOfWeek,
    endOfMonth,
    startOfMonth,
    startOfDay,
} from 'date-fns';

const utcDate = 'yyyy-MM-dd';
const backEndDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";
const SlashDateFormat = 'dd/MM/yyyy';

const getFormattedDate = (
    value: string | number | Date,
    dateFormat: string = backEndDateFormat
): string => {
    if (!value) return '';
    return format(new Date(value), dateFormat);
};

const getParsedDate = (
    value: string,
    dateFormat: string,
    parseFormat: string = backEndDateFormat
) => getFormattedDate(parse(value, parseFormat, new Date()), dateFormat);

const getFullDate = (value: string): string => getFormattedDate(value, 'd LLL, yyyy, h:mm a');
const getDate = (value: string): string => getFormattedDate(value, 'd LLL, yyyy');
const getSlashDate = (value: string): string => getFormattedDate(value, SlashDateFormat);
const getMonthYear = (value: string): string => getFormattedDate(value, 'LLLL yyyy');
const getShortMonthYear = (value: string): string => getFormattedDate(value, 'LLL yy');

const getToday = () => getFormattedDate(startOfDay(Date.now()), utcDate);
const getStartOfWeek = () => getFormattedDate(startOfWeek(Date.now()), utcDate);

const getEndOfWeek = () => getFormattedDate(endOfWeek(Date.now()), utcDate);

const getStartOfMonth = () => getFormattedDate(startOfMonth(Date.now()), utcDate);
const getEndOfMonth = () => getFormattedDate(endOfMonth(Date.now()), utcDate);

const getFormattedCorrespondenceDate = (value: string): string =>
    getFormattedDate(value, SlashDateFormat);

const replaceAllDatesInString = (str: string, formatter: Function): string => {
    return str
        .split(' ')
        .map((item: any) => {
            if (!isNaN(item) || isNaN(Date.parse(item))) return item;
            return formatter(item);
        })
        .join(' ');
};

export {
    getFormattedDate,
    getFullDate,
    getDate,
    getSlashDate,
    getMonthYear,
    getShortMonthYear,
    getParsedDate,
    getToday,
    getStartOfWeek,
    getEndOfWeek,
    getStartOfMonth,
    getEndOfMonth,
    replaceAllDatesInString,
    getFormattedCorrespondenceDate,
};
