import {AppMode} from 'app/contexts';
import {isSupportingDownload} from 'app/utils/isSupportingDownload';
import {downloadDocumentWithLink} from 'app/utils';

interface IGetDocumentLink {
    url: string;
    hasErrors?: boolean;
}

const getCorrespondenceDocumentLink = (
    id: string,
    shouldDownload: boolean,
    getCorrespondenceDocumentLinkFromProps: (
        id: string,
        name?: string,
        isFullScreen?: boolean,
        isMain?: boolean
    ) => Promise<IGetDocumentLink>,
    onDownloadLink: (args?: any) => void,
    onPreviewLink: (args?: any) => void,
    mode: AppMode
) => {
    const name = `${id}.pdf`;
    const isPreview = !shouldDownload;

    return getCorrespondenceDocumentLinkFromProps(id, name, isPreview, true)
        .then(data => {
            if (mode === AppMode.WebView && shouldDownload) {
                onDownloadLink({absoluteURL: data.url});
            } else if (shouldDownload || !isSupportingDownload()) {
                downloadDocumentWithLink(data.url, id, 'pdf');
            } else {
                const a = document.createElement('a');
                a.setAttribute('href', data.url);
                a.setAttribute('target', '_blank');
                a.click();
            }
            return data;
        })
        .catch(() => {});
};

export default getCorrespondenceDocumentLink;
