import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {useChannelContext, useLocalizationContext} from 'app/hooks';
import {EntityType} from 'app/contexts';
import {find, size} from 'lodash';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {changeSingleFilter} from 'app/state/filter';

import {
    applyNewsFilters,
    areMoreNewsAvailableSelector,
    areNewsLoadingSelector,
    filtersSelector,
    getNewsDetails,
    INewsItem,
    newsCurrentItemSelector,
    newsErrorCodeSelector,
    newsSelector,
    resetNewsCurrentItem,
    resetNewsState,
    resetNewsTab,
    searchNews,
} from 'app/state/news';

import {NewsFiltersOptions} from './news.types';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {NewsListItem} from 'app/views/news/components/newsListItem';
import {NewsListItemSkeleton} from 'app/views/news/components/newsListItemSkeleton';
import {NewsPreviewPanel} from 'app/views/news/components/newsPreviewPanel';
import newsChoose from 'app/static/images/news.svg';
import noResultsNews from 'app/static/images/noResultsNews.svg';
import {searchSameValueSelector} from 'app/state/search/search.selectors';

export const NewsContainer = () => {
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector);
    const searchWords = useSelector(searchWordsSelector);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(newsSelector, shallowEqual);
    const areNewsLoading = useSelector(areNewsLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(areMoreNewsAvailableSelector, shallowEqual);
    const filters = useSelector(filtersSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(newsErrorCodeSelector);
    const currentItem = useSelector(newsCurrentItemSelector, shallowEqual);

    const selectedItem: INewsItem | null = useMemo(() => {
        const item = find(items, i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetNewsState());
        dispatch(searchNews(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        if (selectedItemId) {
            dispatch(getNewsDetails(selectedItemId));
        }
    }, [dispatch, selectedItemId]);

    useEffect(() => {
        dispatch(resetNewsCurrentItem());
    }, [location, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetNewsTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.News,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!areNewsLoading && areMoreResultsAvailable) {
            dispatch(searchNews(searchQuery));
        }
    }, [areMoreResultsAvailable, areNewsLoading, dispatch, searchQuery]);

    const onSelect = useCallback(
        (news: INewsItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: news.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.News,
                    filters: selectedFilters,
                })
            );
            dispatch(resetNewsState());
            dispatch(applyNewsFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filterLabelMapper = useCallback(
        (name: string): string => {
            switch (name) {
                case NewsFiltersOptions.Company:
                    return t('news.newsFilters.filterCompany');
                case NewsFiltersOptions.Date:
                    return t('news.newsFilters.filterDate');
                default:
                    return t(name);
            }
        },
        [t]
    );

    const filter = {
        filterProps: filters,
        onApplyFilters,
        filterLabelMapper,
    };

    const list = {
        itemComponentRender: NewsListItem,
        itemSkeletonRender: NewsListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    return (
        <SearchResultLayout
            isLoading={areNewsLoading}
            filter={filter}
            list={areNewsLoading && !size(list) ? {...list, itemsData: []} : list}
            selectedItem={selectedItem ? currentItem : null}
            previewComponent={NewsPreviewPanel}
            noResultImageUrl={noResultsNews}
            chooseItemImageUrl={newsChoose}
            translateRoot={'news'}
            isPreviewLoading={areNewsLoading}
        />
    );
};
