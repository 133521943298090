import React from 'react';
import classNames from 'classnames';
import styles from './sortPanel.module.scss';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ISortPanelProps, ISortOption} from './sortPanel.types';
import {useLocalizationContext} from 'app/hooks';
import {RectSkeleton} from 'app/views/components/skeleton';

export const SortPanel = (
    {
        sortList = [],
        isLoading,
        onSortChange,
        value,
        className,
    }: ISortPanelProps
) => {
    const {t} = useLocalizationContext();

    const handleChange = (
        {
            target: {value},
        }: any
    ) => {
        if (onSortChange) onSortChange(value);
    };

    if (isLoading) {
        return <RectSkeleton width={80}/>;
    }

    const containerClassName = classNames(styles.container, className);

    return (
        <div className={containerClassName}>
            <div className={styles.sorting}>
                <span className={styles.label}> Sort by: </span>
                <FormControl>
                    <Select
                        value={value}
                        onChange={handleChange}
                        disableUnderline
                        displayEmpty
                        classes={{
                            root: styles.selectRoot,
                        }}
                        IconComponent={ExpandMoreIcon}
                        className={styles.selectMenu}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        inputProps={{
                            classes: {
                                icon: styles.icon,
                            },
                        }}
                    >
                        {sortList.map(({name, value}: ISortOption) => (
                            <MenuItem className={styles.menuItem} key={value} value={value}>
                                {t(name)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};
