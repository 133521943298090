import React, {FC} from 'react';
import styles from './correspondenceListItem.module.scss';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import {getFormattedCorrespondenceDate} from 'app/utils/convertDate';
import {ICorrespondenceItem} from 'app/state/correspondence/correspondence.types';

export const CorrespondenceListItem: FC<{item: ICorrespondenceItem; searchWords: string[]}> = ({
    item: {isReply, status, reference, unit, subject, date, isHighPriority, hasAttachments},
    searchWords = [],
}) => {
    return (
        <div className={styles.item}>
            <div className={styles.header}>
                {isReply && <span className={styles.reply} />}
                <span className={styles.status}>{status}</span>
                <Highlighter
                    className={styles.reference}
                    highlightClassName="highlight"
                    searchWords={searchWords}
                    textToHighlight={reference}
                    autoEscape
                />
            </div>
            <span className={styles.unit}>{unit}</span>
            <Highlighter
                className={styles.subject}
                highlightClassName="highlight"
                searchWords={searchWords}
                textToHighlight={subject}
                autoEscape
            />
            <div className={styles.extraInfo}>
                <span className={styles.date}>{getFormattedCorrespondenceDate(date)}</span>
                <div className={styles.icons}>
                    {isHighPriority && <span className={styles.isHighPriority} />}
                    {hasAttachments && <span className={styles.hasAttachments} />}
                </div>
            </div>
        </div>
    );
};
