export enum TendersSortOptions {
    none = 'Relevance',
    nameAZ = 'Name ASC',
    nameZA = 'Name DESC',
    companyAZ = 'Company ASC',
    companyZA = 'Company DESC',
    newToOld = 'Latest DESC',
    oldToNew = 'Latest ASC',
}

export enum TendersFiltersOptions {
    TypeId = 'TypeId',
    StatusId = 'StatusId',
    CompanyId = 'CompanyId',
    Date = 'Date',
}
