import React from 'react';
import {getColorsList} from 'app/utils/getColor';
import styles from './pieChart.module.scss';
import {PieChartBase} from 'app/views/components/charts/pieChartBase';

export const PieChart = ({
    data,
}: {
    data: {
        label: string;
        value: number;
    }[];
}) => {
    const colors: string[] = getColorsList(data.length);
    const chartLabels: string[] = [];
    const chartData: number[] = [];

    data.forEach(({label, value}) => {
        chartLabels.push(label);
        chartData.push(value);
    });

    const sum: number = chartData.reduce((sum, item) => sum + item, 0);
    const chartDataPercentage = chartData.map(item => Math.round((item / sum) * 10000) / 100 || 0);

    const chartConfig = {
        data: {
            labels: chartLabels,
            datasets: [
                {
                    data: chartDataPercentage,
                    backgroundColor: colors,
                },
            ],
        },
        legend: {
            display: false,
        },
        options: {
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function ({index, datasetIndex}: any, {datasets}: any) {
                        const dataset = datasets[datasetIndex];
                        const value = dataset.data[index];

                        return `${value} %`;
                    },
                },
            },
        },
        width: 100,
        height: 100,
    };

    return (
        <PieChartBase
            chartConfig={chartConfig}
            data={data}
            chartDataPercentage={chartDataPercentage}
            styles={styles}
        />
    );
};
