import {EntityType} from 'app/contexts';
import {SupportedEntityTypes} from 'app/api/searchAPI/history/searchAPI.history.types';

import {ICorrespondenceItem} from 'app/state/correspondence/correspondence.types';
import {IDocumentItem} from 'app/state/documents/documents.types';
import {IPeopleItem} from 'app/state/people';
import {INewsItem} from 'app/state/news';
import {ITenderItem} from 'app/state/tenders';
import {IMailItem} from 'app/state/mail/mail.types';
import {ITrainingItem} from 'app/state/trainings';
import {ISupplierItem} from 'app/state/suppliers';
import {ICustomerItem} from 'app/state/customers';
import {IServiceItem} from 'app/state/serviceNow';
import {IProjectItem} from 'app/state/projects';
import {IDistributionItem} from 'app/state/distribution';

export const transformEntityToNotification = (
    entityType: EntityType,
    entity:
        | IMailItem
        | ITrainingItem
        | IDocumentItem
        | ICorrespondenceItem
        | IPeopleItem
        | INewsItem
        | ITenderItem
        | IProjectItem
        | ISupplierItem
        | ICustomerItem
        | IServiceItem
        | IDistributionItem
): SupportedEntityTypes => {
    if (entityType === EntityType.Document) {
        const selectedItem = entity as IDocumentItem;
        return {
            id: selectedItem.id,
            documentName: selectedItem.documentName,
            documentExtention: selectedItem.documentExtention,
            documentAuthors: selectedItem.documentAuthors,
            documentUpdatedDate: selectedItem.documentUpdatedDate,
        };
    }

    if (entityType === EntityType.Correspondence) {
        const selectedItem = entity as ICorrespondenceItem;
        return {
            id: selectedItem.id,
            isReply: selectedItem.isReply,
            status: selectedItem.status,
            reference: selectedItem.reference,
            unit: selectedItem.unit,
            subject: selectedItem.subject,
            hasAttachments: selectedItem.hasAttachments,
            date: selectedItem.date,
        };
    }

    if (entityType === EntityType.Email) {
        const selectedItem = entity as IMailItem;
        return {
            id: selectedItem.id,
            address: selectedItem.address,
            // avatarSrc: selectedItem.avatarSrc,
            senderName: selectedItem.senderName,
            dateSent: selectedItem.dateSent,
            subject: selectedItem.subject,
            hasAttachments: selectedItem.hasAttachments,
            description: selectedItem.description,
        };
    }

    if (entityType === EntityType.Employee) {
        const selectedItem = entity as IPeopleItem;
        return {
            id: selectedItem.id,
            unit: selectedItem.unit,
            givenName: selectedItem.givenName,
            surname: selectedItem.surname,
            jobtitle: selectedItem.jobtitle,
            // TODO email or emailAddress or address : check this
            pictureurl: selectedItem.pictureurl,
            city: selectedItem.city,
            workEmail: selectedItem.workEmail,
            companyName: selectedItem.companyName,
            department: selectedItem.department,
        };
    }

    if (entityType === EntityType.Training) {
        const selectedItem = entity as ITrainingItem;
        return {
            id: selectedItem.id,
            courseShortName: selectedItem.courseShortName,
            courseFullName: selectedItem.courseFullName,
            courseProvider: selectedItem.courseProvider,
            courseIcon_Base64: selectedItem.courseIcon_Base64,
        };
    }

    if (entityType === EntityType.News) {
        const selectedItem = entity as INewsItem;
        return {
            id: selectedItem.id,
            title: selectedItem.title,
            image: selectedItem.imageBase64 || '',
            date: selectedItem.date,
            tags: selectedItem.tags,
            company: selectedItem.company,
            originalUrl: selectedItem.originalUrl,
        };
    }

    if (entityType === EntityType.Tender) {
        const selectedItem = entity as ITenderItem;
        return {
            id: selectedItem.id,
            name: selectedItem.name,
            category: selectedItem.category,
            type: selectedItem.type,
            date: selectedItem.date,
        };
    }

    if (entityType === EntityType.Suppliers) {
        const selectedItem = entity as ISupplierItem;
        return {
            id: selectedItem.id,
            text: selectedItem.text,
            term: selectedItem.term,
            entityLabel: selectedItem.entityLabel,
            entityStyle: selectedItem.entityStyle,
            entityBadge: selectedItem.entityBadge,
        };
    }

    if (entityType === EntityType.Customers) {
        const selectedItem = entity as ICustomerItem;
        return {
            id: selectedItem.id,
            text: selectedItem.text,
            term: selectedItem.term,
            label: selectedItem.label,
            style: selectedItem.style,
        };
    }

    if (entityType === EntityType.Service) {
        const selectedItem = entity as IServiceItem;
        return {
            id: selectedItem.id,
            description: selectedItem.summary || selectedItem.shortDescription || '',
            number: selectedItem.number || null,
            category: selectedItem.ticketType || selectedItem.category,
        };
    }

    if (entityType === EntityType.Projects) {
        const selectedItem = entity as IProjectItem;
        return {
            id: selectedItem.id,
            projectName: selectedItem.projectName,
            projectCompanyName: selectedItem.projectCompanyName,
            projectCode: selectedItem.projectCode,
        };
    }

    if (entityType === EntityType.Distribution) {
        const selectedItem = entity as IDistributionItem;
        return {
            id: selectedItem.id,
            name: selectedItem.name,
            status: selectedItem.status,
        };
    }

    return entity as SupportedEntityTypes;
};
