import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import styles from './projectsListItem.module.scss';
import {IProjectItem} from 'app/state/projects';
import {ColorLabel} from 'app/views/components/colorLabel/colorLabel';
import {useLocalizationContext} from 'app/hooks';
import classnames from 'classnames';

export const ProjectsListItem: FC<{item: IProjectItem; searchWords: string[]}> = props => {
    const {
        item: {
            projectCompanyName,
            projectName,
            irr,
            npv,
            minorDelay,
            mayorDelay,
            onSchedule,
            onBehindSchedule,
            onBudget,
            overBudget,
            projectStage,
            projectCode,
        },
        searchWords = [],
    } = props;

    const {t} = useLocalizationContext();

    let stage = projectStage;
    if (stage === 'Execute') stage = 'Exec.';

    return (
        <div className={styles.item}>
            <div className={styles.row}>
                <Highlighter
                    className={styles.company}
                    highlightClassName="highlight"
                    searchWords={searchWords}
                    textToHighlight={projectCompanyName || ''}
                />
                <ColorLabel color="gray" className={styles.serialNumber}>
                    <Highlighter
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={projectCode.split(' | ')[0] || ''}
                    />
                </ColorLabel>
            </div>

            <Highlighter
                className={styles.title}
                highlightClassName="highlight"
                searchWords={searchWords}
                textToHighlight={projectName || ''}
            />

            <div className={classnames(styles.row, styles.labelWrapper)}>
                <ColorLabel title={projectStage} color="gray">
                    {stage}
                </ColorLabel>
                {minorDelay === 1 && (
                    <ColorLabel title={t('projects.preview.minorDelay')} color="orange">
                        {t('projects.list.minorDelay')}
                    </ColorLabel>
                )}
                {mayorDelay === 1 && (
                    <ColorLabel title={t('projects.preview.majorDelay')} color="brightRed">
                        {t('projects.list.majorDelay')}
                    </ColorLabel>
                )}
                {onSchedule === 1 && (
                    <ColorLabel title={t('projects.preview.onSchedule')} color="blue">
                        {t('projects.list.onSchedule')}
                    </ColorLabel>
                )}
                {onBehindSchedule === 1 && (
                    <ColorLabel title={t('projects.preview.behindCompletion')} color="red">
                        {t('projects.list.behindCompletion')}
                    </ColorLabel>
                )}
                {onBudget === 1 && (
                    <ColorLabel title={t('projects.preview.onBudget')} color="green">
                        {t('projects.list.onBudget')}
                    </ColorLabel>
                )}
                {overBudget === 1 && (
                    <ColorLabel title={t('projects.preview.overBudget')} color="red">
                        {t('projects.list.overBudget')}
                    </ColorLabel>
                )}
                <ColorLabel color="gray">{`IRR: ${irr} | NPV:${npv}`}</ColorLabel>
            </div>
        </div>
    );
};
