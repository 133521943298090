import React, {useState} from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

import styles from '../projectsPreviewPanel.module.scss';
import scStyles from './sCurveChart.module.scss';
import {getShortMonthYear} from 'app/utils';

import {IProjectDetails} from 'app/state/projects/projects.types';
import {TabButtons} from './tabButtons';
import {SCurveChart, chartDataProps} from './sCurveChart';
import {m$FormatterShort, numberFormatter} from 'app/utils';
import {IfDataAvailable} from './ifDataAvailable';

type item = {
    date: string;
    plannedProgress: number;
    revisedProgress: number;
    actualProgress: number;
};

const getChartData = (data: item[]) => {
    const orderedData = data.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const chartData: chartDataProps = {
        labels: [],
        actualProgress: [],
        plannedProgress: [],
        revisedProgress: [],
    };

    orderedData.forEach(({actualProgress, plannedProgress, revisedProgress, date}) => {
        chartData.labels.push(getShortMonthYear(date.toString()));
        chartData.actualProgress.push(actualProgress);
        chartData.plannedProgress.push(plannedProgress);
        chartData.revisedProgress.push(revisedProgress);
    });

    return chartData;
};

export const SCurve = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {sCurveProgress, sCurveExpenditure},
        isSmall,
    } = props;

    const height = isSmall ? 100 : 40;

    const legend = (
        <div className={scStyles.legend}>
            <div>
                <div className={scStyles.planed}>{t('projects.preview.chartPlanned')}</div>
                <div className={scStyles.actual}>{t('projects.preview.chartActual')}</div>
                <div className={scStyles.revised}>{t('projects.preview.chartRevised')}</div>
            </div>
            <div>
                <div className={scStyles.cumulativePlaned}>
                    {t('projects.preview.chartCumulativePlanned')}
                </div>
                <div className={scStyles.cumulativeActual}>
                    {t('projects.preview.chartCumulativeActual')}
                </div>
                <div className={scStyles.cumulativeRevised}>
                    {t('projects.preview.chartCumulativeRevised')}
                </div>
            </div>
        </div>
    );

    const tabs = [
        {
            title: t('projects.preview.progress'),
            name: 'open',
            content: (
                <IfDataAvailable
                    isData={!!sCurveProgress.length}
                    name={t('projects.preview.progress')}
                >
                    <SCurveChart
                        axisLabel={`${t('projects.preview.chartMonthlyProgress')}, %`}
                        axisLabelCumulative={`${t('projects.preview.chartCumulativeProgress')}, %`}
                        axisFormatter={v => `${Math.round(v * 10) / 10}`}
                        labelFormatter={v => `${Math.round(v * 100) / 100}%`}
                        chartData={getChartData(sCurveProgress)}
                        height={height}
                    />
                    {legend}
                </IfDataAvailable>
            ),
        },
        {
            title: t('projects.preview.expenditure'),
            name: 'closed',
            content: (
                <IfDataAvailable
                    isData={!!sCurveExpenditure.length}
                    name={t('projects.preview.expenditure')}
                >
                    <SCurveChart
                        axisLabel={`${t('projects.preview.chartMonthlyExpenditure')}, M$`}
                        axisLabelCumulative={`${t(
                            'projects.preview.chartCumulativeExpenditure'
                        )}, M$`}
                        axisFormatter={v => numberFormatter(Math.round(v / 100000) / 10)}
                        labelFormatter={v => m$FormatterShort(Math.round(v / 10000) / 100)}
                        chartData={getChartData(sCurveExpenditure)}
                        height={height}
                    />
                    {legend}
                </IfDataAvailable>
            ),
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);

    return (
        <div
            className={classNames(styles.itemContainer, {
                [scStyles.small]: isSmall,
            })}
        >
            <div className={styles.containerTitle}>
                <h3>{t('projects.preview.sCurve')}</h3>
                <div className={styles.tabsContainer}>
                    <TabButtons
                        tabs={tabs.map(tab => ({
                            ...tab,
                            active: selectedTab === tab.name,
                        }))}
                        onSelect={setSelectedTab}
                    />
                </div>
            </div>

            {tabs.find(({name}) => name === selectedTab)?.content}
        </div>
    );
};
