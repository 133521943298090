import {EntityType} from 'app/contexts';
import {RootState} from 'app/state/store';

export const newsSelector = ({news: {items}}: RootState) => items;
export const newsCurrentItemSelector = ({news: {currentItem}}: RootState) => currentItem;
export const areNewsLoadingSelector = ({news: {isLoading}}: RootState) => isLoading;
export const areMoreNewsAvailableSelector = ({news: {areMoreResultsAvailable}}: RootState) =>
    areMoreResultsAvailable;
export const newsErrorCodeSelector = ({news: {hasErrorCode}}: RootState) => hasErrorCode;

export const filtersSelector = (state: RootState) =>
    state.filter.repository[EntityType.News].filters;
