export function toMoneyFormat(value: number) {
    return Math.floor(+value || 0).toLocaleString('en-US', {minimumFractionDigits: 0});
}

const round = (n: number) => Math.round(n * 100) / 100;
export const m$Formatter = (value: number) => `${round(value).toLocaleString('en-US')} M$`;
export const m$FormatterShort = (value: number) => `${round(value).toLocaleString('en-US')}M$`;
export const numberFormatter = (value: number) => round(value).toLocaleString('en-US');
export const usdFormatter = (value: number) => `${round(value).toLocaleString('en-US')}$`;
export const toMillions = (value: number) => `${round(value / 1000000).toLocaleString('en-US')}`;
