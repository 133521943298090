import {format} from 'date-fns';

const to = new Date();
const from = new Date();
const currentYear = new Date().getFullYear();
from.setFullYear(from.getFullYear() - 1);

const twelveMonthsBack = {
    from: format(from, 'yyyyMM'),
    to: format(to, 'yyyyMM'),
};

const fiveLastYears = {
    from: `${currentYear - 4}01`,
    to: `${currentYear}12`,
};

const getYearPeriod = (year: number) => ({
    name: `${year}`,
    value: {
        from: `${year}01`,
        to: `${year}12`,
    },
});

export const periods: {
    name: string;
    localizationKey?: string;
    value: {
        from: string;
        to: string;
    };
}[] = [
    {
        name: 'twelveMonthsBack',
        localizationKey: 'twelveMonthsBack',
        value: twelveMonthsBack,
    },
    getYearPeriod(currentYear),
    getYearPeriod(currentYear - 1),
    getYearPeriod(currentYear - 2),
    getYearPeriod(currentYear - 3),
    {
        name: 'fiveLastYears',
        localizationKey: 'fiveLastYears',
        value: fiveLastYears,
    },
];
