import {TFunction} from 'i18next';
import {
    getEndOfMonth,
    getEndOfWeek,
    getStartOfMonth,
    getStartOfWeek,
    getToday,
} from 'app/utils/convertDate';

export const getFilters = (t: TFunction) => [
    {
        Name: t('correspondence.correspondenceContainer.filterDocumentType'),
        Entries: [
            {
                RefinementName: t('correspondence.correspondenceContainer.filterIncoming'),
                RefinementValue: "DocumentType eq 'Incoming'",
                RefinementValue2: 'Incoming',
            },
            {
                RefinementName: t('correspondence.correspondenceContainer.filterOutgoing'),
                RefinementValue: "DocumentType eq 'Outgoing'",
                RefinementValue2: 'Outgoing',
            },
            {
                RefinementName: t('correspondence.correspondenceContainer.filterInternal'),
                RefinementValue: "DocumentType eq 'Internal'",
                RefinementValue2: 'Internal',
            },
        ],
    },
    {
        Name: t('correspondence.correspondenceContainer.filterDocumentDate'),
        Entries: [
            {
                RefinementName: t('correspondence.correspondenceContainer.filterToday'),
                RefinementValue: `FromDate eq '${getToday()}' and ToDate eq '${getToday()}'`,
                RefinementValue2: {
                    FromDate: `${getToday()}`,
                    ToDate: `${getToday()}`,
                },
            },
            {
                RefinementName: t('correspondence.correspondenceContainer.filterThisWeek'),
                RefinementValue: `FromDate eq '${getStartOfWeek()}' and ToDate eq '${getEndOfWeek()}'`,
                RefinementValue2: {
                    FromDate: `${getStartOfWeek()}`,
                    ToDate: `${getEndOfWeek()}`,
                },
            },
            {
                RefinementName: t('correspondence.correspondenceContainer.filterThisMonth'),
                RefinementValue: `FromDate eq '${getStartOfMonth()}' and ToDate eq '${getEndOfMonth()}'`,
                RefinementValue2: {
                    FromDate: `${getStartOfMonth()}`,
                    ToDate: `${getEndOfMonth()}`,
                },
            },
        ],
    },
];
