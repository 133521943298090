import {EntityType} from 'app/contexts';
import {RootState} from 'app/state/store';

export const serviceItemsSelector = ({serviceNow: {items}}: RootState) => items;
export const areServiceItemsLoadingSelector = ({serviceNow: {isLoading}}: RootState) => isLoading;
export const areMoreServiceItemsAvailableSelector = ({
    serviceNow: {areMoreResultsAvailable},
}: RootState) => areMoreResultsAvailable;
export const filtersSelector = ({filter}: RootState) =>
    filter.repository[EntityType.Service].filters;
export const serviceStatusesSelector = ({filter}: RootState) =>
    filter.repository[EntityType.Service].additionalData?.Status;
export const serviceErrorCodeSelector = ({serviceNow: {hasErrorCode}}: RootState) => hasErrorCode;
