import React from 'react';
import {useLocalizationContext} from 'app/hooks';

import styles from '../projectsPreviewPanel.module.scss';
import contractorStyles from './contractor.module.scss';
import {IProjectDetails} from 'app/state/projects/projects.types';
import {ColorLabel, colors} from 'app/views/components/colorLabel/colorLabel';
import {m$FormatterShort} from 'app/utils';

const performanceColors: {[key: string]: colors} = {
    Marginal: 'brightRed',
    Satisfactory: 'orange',
    Unsatisfactory: 'red',
    'Very Good': 'green',
    Exceptional: 'gray',
};

export const Contractor = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {pmcContractor, tpaContractor, epcContractor},
        isSmall,
    } = props;

    const renderHeader = (type: string, {contractorPerformance, contractorName}: any) => (
        <div>
            <div className={contractorStyles.title}>
                <h4>{t(`projects.preview.${type}`)}</h4>
                <ColorLabel color={performanceColors[contractorPerformance]}>
                    {contractorPerformance}
                </ColorLabel>
            </div>
            <div className={contractorStyles.name}>{contractorName ? contractorName : t('NA')}</div>
        </div>
    );

    const renderInfo = (type: string, {initialCost, estimatedFinalCost}: any) => (
        <div>
            <div className={contractorStyles.details}>
                <div className={contractorStyles.label}>
                    {type === 'pmc'
                        ? t('projects.preview.initialCost')
                        : t('projects.preview.awardedCost')}
                    :
                </div>
                <div className={contractorStyles.value}>{m$FormatterShort(initialCost)}</div>
            </div>
            <div className={contractorStyles.details}>
                <div className={contractorStyles.label}>{t('projects.preview.contractorEFC')}:</div>
                <div className={contractorStyles.value}>{m$FormatterShort(estimatedFinalCost)}</div>
            </div>
        </div>
    );

    return (
        <div className={styles.itemContainer}>
            <h3>{t('projects.preview.contractor')}</h3>

            {isSmall ? (
                <div key="mobile">
                    <div className={contractorStyles.smallContainer}>
                        {renderHeader('pmc', pmcContractor)}
                        {renderInfo('pmc', pmcContractor)}
                    </div>
                    <div className={contractorStyles.smallContainer}>
                        {renderHeader('tpa', tpaContractor)}
                        {renderInfo('tpa', tpaContractor)}
                    </div>
                    <div className={contractorStyles.smallContainer}>
                        {renderHeader('epc', epcContractor)}
                        {renderInfo('epc', epcContractor)}
                    </div>
                </div>
            ) : (
                <div key="desktop">
                    <div className={styles.threeColumns}>
                        {renderHeader('pmc', pmcContractor)}
                        {renderHeader('tpa', tpaContractor)}
                        {renderHeader('epc', epcContractor)}
                    </div>
                    <div className={styles.threeColumns}>
                        {renderInfo('pmc', pmcContractor)}
                        {renderInfo('tpa', tpaContractor)}
                        {renderInfo('epc', epcContractor)}
                    </div>
                </div>
            )}
        </div>
    );
};
