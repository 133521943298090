import {AbortControllerProjects, ISearchProjectsOptions} from './searchAPICaller.projects.types';
import {fetchData} from 'app/utils';
import {ProjectsRoutes} from './routes.projects';

export const abortControllers: AbortControllerProjects = {
    PROJECTS: null,
};

export abstract class SearchAPICallerProjects {
    public static async searchProjects(
        body: ISearchProjectsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.PROJECTS?.abort();
        abortControllers.PROJECTS = new AbortController();

        return fetchData(
            ProjectsRoutes.projects(searchAPIURL),
            accessToken,
            'POST',
            body,
            null,
            null,
            abortControllers.PROJECTS.signal
        );
    }

    public static async searchProjectFilters(searchAPIURL: string, accessToken: string) {
        return fetchData(
            ProjectsRoutes.projectsFilters(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }

    public static async getProjectDetails(
        projectId: string,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(`${ProjectsRoutes.projects(searchAPIURL)}/${projectId}`);

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }
}
