import React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import classNames from 'classnames';
import styles from './periodToggler.module.scss';
import {useLocalizationContext} from '../../../../../hooks';

export const PeriodToggler = ({
    className,
    period,
    togglePeriod,
}: {
    className?: string;
    period: string;
    togglePeriod: (event: React.MouseEvent<HTMLElement>, value: any) => void;
}) => {
    const {t} = useLocalizationContext();

    return (
        <ToggleButtonGroup
            value={period}
            exclusive
            onChange={togglePeriod}
            aria-label="period"
            className={classNames(className, styles.toggleGroup)}
        >
            <ToggleButton
                className={classNames(styles.toggleBtn, period === 'quarter' && styles.selected)}
                value="quarter"
                aria-label="quarter"
            >
                {t('distribution.preview.quarter')}
            </ToggleButton>
            <ToggleButton
                className={classNames(styles.toggleBtn, period === 'month' && styles.selected)}
                value="month"
                aria-label="month"
            >
                {t('distribution.preview.month')}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
