import React from 'react';
import styles from '../../customersPreviewPanel.module.scss';

import {Overview} from '../overview';
import {Value} from '../value';
import {Volumes} from '../volumes';
import {SalesShares} from './salesShares';
import {SalesTrend} from '../salesTrend';
import {VolumeTrend} from '../volumeTrend';
import {SalesBreakdown} from './salesBreakdown';
import {useAppContext} from '../../../../../hooks';
import {AppMode} from '../../../../../contexts';
import classNames from 'classnames';

export const CountryPreview = ({data, width}: any) => {
    const {mode} = useAppContext();
    const isWidget = mode === AppMode.Widget;
    const cardsContainerClass = classNames(
        styles.cardsContainer,
        isWidget && styles.cardsContainerWidget
    );
    return (
        <>
            <div className={styles.cardsWrapper}>
                <Overview data={data} className={cardsContainerClass} />
                <Value data={data} className={cardsContainerClass} />
                <Volumes
                    data={data}
                    className={classNames(cardsContainerClass, isWidget && styles.fullWidth)}
                />
            </div>
            <div className={styles.twoColumns}>
                <SalesShares data={data} className={styles.itemContainer} />
                <SalesTrend data={data} className={styles.itemContainer} />
            </div>
            <VolumeTrend data={data} className={styles.itemContainer} width={width} />
            <SalesBreakdown data={data} className={styles.itemContainer} />
        </>
    );
};
