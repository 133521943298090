import React from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import style from './noData.module.scss';

type PropsType = {
    isData: boolean;
    name: string;
    className?: string;
    children: any;
};

export const IfDataAvailable = (props: PropsType) => {
    const {children, isData, className, name} = props;

    const {t} = useLocalizationContext();

    return isData ? (
        <>{children}</>
    ) : (
        <div className={classNames(style.message, className)}>
            {t('projects.preview.noDataAvailable', {
                name,
            })}
        </div>
    );
};
