import React from 'react';
import {DetailsList as DetailsListComponent} from 'app/views/components/detailsList/detailsList';

type propsTypes = {
    data: {[key: string]: any};
    fields: (
        | string
        | {
              name: string;
              renderer: (f: any, d?: any, n?: string) => any;
          }
    )[];
};

export const DetailsList = (props: propsTypes) => (
    <DetailsListComponent localizationPath={'serviceNow.preview'} {...props} />
);
