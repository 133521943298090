import React from 'react';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import {IFilter} from '../../filterPanel.types';

const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 40,
            height: 20,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 3,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(20px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#1859A9',
                    borderColor: '#51A3D6',
                    borderWidth: '2px',
                },
            },
        },
        thumb: {
            width: 13,
            height: 13,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    })
)(Switch);

type CustomizedSwitchesType = {
    label: string;
    helperTextOff: string;
    helperTextOn: string;
    styles: any;
    entry: IFilter;
    onChange: (refinementName: string) => void;
    getFilterIsCheckedValue: (filterName: string) => boolean;
};

export default function CustomizedSwitches({
    label,
    helperTextOff,
    helperTextOn,
    styles,
    entry: {RefinementName, isChecked},
    onChange,
    getFilterIsCheckedValue,
}: CustomizedSwitchesType) {
    const [state, setState] = React.useState({
        [RefinementName]: isChecked,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    return (
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel
                    classes={{
                        root: styles.switchLabelRoot,
                        label: styles.switchLabel,
                    }}
                    control={
                        <AntSwitch
                            checked={getFilterIsCheckedValue(RefinementName)}
                            onChange={event => {
                                handleChange(event);
                                onChange(RefinementName);
                            }}
                            name={RefinementName}
                        />
                    }
                    label={label}
                />
            </FormGroup>
            {helperTextOff && helperTextOn && (
                <FormHelperText className={styles.switchHelperText}>
                    {!state[RefinementName] ? helperTextOff : helperTextOn}
                </FormHelperText>
            )}
        </FormControl>
    );
}
