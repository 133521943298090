import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {routeParams} from 'app/routes';
import {parse, stringify} from 'querystring';
import {useChannelContext} from 'app/hooks';
import {EntityType} from 'app/contexts';

import {
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {
    customersSelector,
    areCustomersLoadingSelector,
    periodSelector,
    customersErrorCodeSelector,
    ICustomerItem,
    getCustomerDetails,
    resetCustomersState,
    searchCustomers,
    resetCustomersTab,
} from 'app/state/customers';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {CustomersPreviewPanel} from 'app/views/customers/customersPreviewPanel/customersPreviewPanel';
import {CustomersListItem} from 'app/views/customers/customersListItem';
import {CustomersListItemSkeleton} from 'app/views/customers/customersListItemSkeleton';
import newsChoose from 'app/static/images/news.svg';
import noResultsNews from 'app/static/images/noResultsNews.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const CustomersContainer = () => {
    const dispatch = useDispatch();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(customersSelector, shallowEqual);
    const areCustomersLoading = useSelector(areCustomersLoadingSelector, shallowEqual);
    const period = useSelector(periodSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(customersErrorCodeSelector);

    const selectedItem: ICustomerItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetCustomersState());
        dispatch(searchCustomers(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetCustomersTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Customers,
            entity: selectedItem,
        }
    );

    useEffect(() => {
        if (selectedItem && !selectedItem.details) {
            dispatch(
                getCustomerDetails({
                    customerId: selectedItem.id,
                    customerType: selectedItem.type,
                    period,
                })
            );
        }
    }, [dispatch, selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelect = useCallback(
        (customer: ICustomerItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: customer.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const list = {
        itemComponentRender: CustomersListItem,
        itemSkeletonRender: CustomersListItemSkeleton,
        itemsData: items,
        onSelect,
        searchWords,
        selectedItemId: selectedItem?.id,
        errorCode,
    };

    return (
        <SearchResultLayout
            isLoading={areCustomersLoading}
            isPreviewLoading={selectedItem && !selectedItem.details}
            list={list}
            selectedItem={selectedItem}
            previewComponent={CustomersPreviewPanel}
            noResultImageUrl={noResultsNews}
            chooseItemImageUrl={newsChoose}
            translateRoot={'customers'}
        />
    );
};
