import {RootState} from '../store';

export const isDocumentLoadingSelector = (state: RootState) => state.documents.isLoading;
export const documentsFiltersSelector = (state: RootState) => state.documents.filters;
export const documentsListSelector = (state: RootState) => state.documents.items;
export const documentsSortSelector = (state: RootState) => state.documents.sortBy;
export const documentsErrorsSelector = (state: RootState) => state.documents.hasErrors;
export const documentsErrorCodeSelector = (state: RootState) => state.documents.hasErrorCode;
export const documentsMoreResultsAvailableSelector = (state: RootState) =>
    state.documents.areMoreResultsAvailable;
export const documentsTotalCountSelector = (state: RootState) => state.documents.totalCount;
