import React, {useRef, useState} from 'react';
import {IMailAttachmentProps, IMailAttachmentsProps} from './mailAttachments.types';
import {getDocumentIcon} from 'app/utils';
import last from 'lodash/last';
import classNames from 'classnames';
import {useOnClickOutside} from 'app/hooks/useOnClickOutside';
import styles from './mailAttachments.module.scss';
import {useLocalizationContext} from 'app/hooks';
import {ReactComponent as EyeSvg} from 'app/static/images/eyeIcon.svg';
import {ReactComponent as DownloadSvg} from 'app/static/images/downloadIcon.svg';
import {Link} from '../../../components';
import {SUPPORTED_DOCUMENTS_FILE_TYPES} from 'app/constants';

const getKilobytesFromBytes = (bytes: number) => (bytes / 1024).toFixed(1);

const Attachment = ({attachment, onDownloadClick, onViewClick}: IMailAttachmentProps) => {
    const {t} = useLocalizationContext();
    const extension = last(attachment.name.split('.')) as string;
    const canPreview = SUPPORTED_DOCUMENTS_FILE_TYPES.includes(extension);
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setOpen(false));

    return (
        <div key={attachment.id} className={styles.mailAttachment}>
            <div
                className={styles.mailAttachmentInfo}
                onClick={() => {
                    canPreview ? onViewClick(attachment) : onDownloadClick(attachment);
                }}
            >
                <img
                    className={styles.thumbnail}
                    src={getDocumentIcon(extension)}
                    alt={attachment.name}
                />
                <div className={styles.info}>
                    <span className={styles.name}>{attachment.name}</span>
                    <span className={styles.size}>
                        {getKilobytesFromBytes(attachment.size)}{' '}
                        {t('mail.mailAttachments.kilobytes')}
                    </span>
                </div>
            </div>
            <div className={styles.actions}>
                <button className={styles.actionsButton} onClick={() => setOpen(true)} />
                <div
                    className={classNames(styles.actionsPopup, {
                        [styles.open]: open,
                    })}
                    ref={ref}
                >
                    <button
                        onClick={() => {
                            onViewClick(attachment);
                            setOpen(false);
                        }}
                        disabled={!canPreview}
                    >
                        <EyeSvg className={styles.eyeIcon} />
                        <span className={styles.actionsText}>
                            {t('documents.documentPreviewInfo.viewDocument')}
                        </span>
                    </button>
                    <button
                        onClick={() => {
                            onDownloadClick(attachment);
                            setOpen(false);
                        }}
                    >
                        <DownloadSvg className={styles.downloadIcon} />
                        <span className={styles.actionsText}>
                            {t('documents.documentPreviewInfo.download')}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export const MailAttachments = ({
    attachments,
    onDownloadClick,
    onViewClick,
}: IMailAttachmentsProps) => {
    const {t} = useLocalizationContext();
    const listClasses = classNames(styles.attachmentsList);
    const [moreAttachmentsShown, setMoreAttachmentsShown] = useState<boolean>(false);
    const toggleShowMoreAttachments = () => setMoreAttachmentsShown(!moreAttachmentsShown);
    const visibleAttachmentsCount = 2;
    const visibleAttachments = moreAttachmentsShown
        ? attachments
        : attachments.slice(0, visibleAttachmentsCount);

    return (
        <>
            <div className={listClasses}>
                {visibleAttachments.map(attachment => {
                    return (
                        <Attachment
                            attachment={attachment}
                            onDownloadClick={onDownloadClick}
                            onViewClick={onViewClick}
                            key={attachment.id}
                        />
                    );
                })}
            </div>
            {attachments.length > visibleAttachmentsCount && (
                <Link
                    onClick={toggleShowMoreAttachments}
                    component="button"
                    className={styles.link}
                >
                    {moreAttachmentsShown
                        ? t('mail.mailAttachments.showLess')
                        : `${t('mail.mailAttachments.showMore')} (${
                              attachments?.length - visibleAttachmentsCount
                          })`}
                </Link>
            )}
        </>
    );
};
