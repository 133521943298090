import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {ISearchServiceServices} from './searchServices.services.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {SearchAPICallerServices} from 'app/api/searchAPI/services/searchAPICaller.services';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {createServicesFilters} from '../searchService.transformer';
import {
    IGetServiceDetailsOptions,
    ISearchServicesOptions,
    IService,
} from 'app/api/searchAPI/services/searchAPICaller.services.types';

@injectable()
export class SearchServiceServices implements ISearchServiceServices {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchServices(options: ISearchServicesOptions): Promise<IService[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerServices.searchServices(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse([]) as IService[];
                }
            }
        );
    }

    public async getServiceDetails(options: IGetServiceDetailsOptions): Promise<IService[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerServices.getServiceDetails(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse([]) as IService[];
            }
        );
    }

    public async searchServiceNowFilters() {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const filters = await SearchAPICallerServices.searchServicesFilters(
                    appConfig.skey_38,
                    accessToken
                );
                return {
                    filters: createServicesFilters(filters),
                    filtersOptions: filters,
                };
            },
            (e: any) => {
                this.logger.error(e);
                return null;
            }
        );
    }
}
