import {createActions} from 'redux-actions';
import {AvailableSuggestions, ISuggestionCategory} from './suggestions.types';

export const {
    resetSuggestionsState,
    searchSuggestions,
    searchSuggestionsWithLightApps,
    searchSuggestionsSuccess,
    searchSuggestionsFailure,
    searchHistory,
    searchHistorySuccess,
    searchHistoryFailure,
    setSuggestionsLoading,
} = createActions({
    RESET_SUGGESTIONS_STATE: () => {},
    SEARCH_SUGGESTIONS: (query: string) => query,
    SEARCH_SUGGESTIONS_WITH_LIGHT_APPS: (query: string) => query,
    SEARCH_SUGGESTIONS_SUCCESS: (suggestions: ISuggestionCategory<AvailableSuggestions>[]) =>
        suggestions,
    SEARCH_SUGGESTIONS_FAILURE: () => {},
    SEARCH_HISTORY: () => {},
    SEARCH_HISTORY_SUCCESS: (suggestions: ISuggestionCategory<AvailableSuggestions>[]) =>
        suggestions,
    SEARCH_HISTORY_FAILURE: () => {},
    SET_SUGGESTIONS_LOADING: (areLoading: boolean) => areLoading,
});
