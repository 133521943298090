import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IoC} from 'app/ioc';
import {useAppContext, useChannelContext, useInjection, useLocalizationContext} from 'app/hooks';
import {AppMode} from 'app/contexts';
import {ITabsProps, Tabs} from 'app/views/components/tabs';
import {CorrespondencePreview} from '../correspondencePreview';
import {CorrespondenceAttachments} from '../correspondenceAttachments';
import {CorrespondenceTracking} from '../correspondenceTracking';
import {checkIfIOS} from 'app/utils/checkIfIOS';
import {ICorrespondenceItem} from 'app/state/correspondence/correspondence.types';
import correspondenceSvg from 'app/static/images/pleaseChooseCorrespondence.svg';
import {
    correspondenceAttachmentsErrorsSelector,
    correspondenceTrackingErrorsSelector,
    getCorrespondenceAttachments,
    getCorrespondenceTracking,
} from 'app/state/correspondence';
import styles from './correspondencePreviewPanel.module.scss';
import {DownloadIcon, EyeIcon} from './icons';

import getCorrespondenceDocumentLinkHandler from './getCorrespondenceDocumentLink';
import getCorrespondenceDocumentHandler from './getCorrespondenceDocument';
import {PreviewContentLayout} from 'app/layouts';
import {ILogger} from 'app/infrastructure/logging';
import {DocumentPreview} from '../../../components/documentPreview/documentPreview';
import {ISearchServiceCorrespondence} from 'app/infrastructure/search/correspondence';
import {urlToPath} from 'app/utils/urlUtils';
import {Error} from '../../../components/errors/error';

export const CorrespondencePreviewPanel = ({data: correspondence}: {data: ICorrespondenceItem}) => {
    const {t} = useLocalizationContext();
    const searchService = useInjection<ISearchServiceCorrespondence>(
        IoC.SearchServiceCorrespondence
    );
    const dispatch = useDispatch();
    const {mode} = useAppContext();
    const {onDownloadLink, onPreviewLink, onDocumentPreviewMobile} = useChannelContext();
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const [documentURL, setDocumentURL] = useState<string>('');
    const [documentPreview, setDocumentPreview] = useState<boolean>(false);
    const [documentLoading, setDocumentLoading] = useState<boolean>(false);
    const [documentError, setDocumentError] = useState<string | undefined>();

    const [hasDocumentError, setHasDocumentError] = useState(false);
    const hasAttachmentsError = useSelector(correspondenceAttachmentsErrorsSelector);
    const hasTrackingError = useSelector(correspondenceTrackingErrorsSelector);
    const isFullScreen = mode === AppMode.FullScreen;
    const isWebView = mode === AppMode.WebView;
    const [fullScreenPreview, setFullScreenPreview] = useState<boolean>(false);

    const getAdditionalInfo = useCallback(() => {
        if (!correspondence) return undefined;
        const {tracking, attachments, id} = correspondence;
        if (!tracking && !attachments) {
            dispatch(getCorrespondenceAttachments(id));
            dispatch(getCorrespondenceTracking(id));
        }
    }, [dispatch, correspondence]);

    useEffect(() => {
        setDocumentError(undefined);
        setDocumentLoading(true);
        setDocumentPreview(false);
        setFullScreenPreview(true);
        getMainDocumentURL();
    }, [correspondence]);

    useEffect(() => {
        getAdditionalInfo();
    }, [getAdditionalInfo]);

    const getMainDocumentURL = async () => {
        let name = `${documentId}.pdf`;
        try {
            const previewUrl = (await searchService.getCorrespondenceAttachmentLinkPreview(
                documentId.toString(),
                name,
                true,
                true
            )) as {url: {url: string}};
            // let url = response.url;
            setDocumentURL(previewUrl.url.url);
        } catch (error) {
            logger.error(error);
        }
    };

    const getCorrespondenceDocument = useCallback(
        (id: string, preview: boolean = false) => {
            setHasDocumentError(false);

            return searchService.getCorrespondenceDocument(id, `${id}.pdf`, preview);
        },
        [searchService]
    );

    const getCorrespondenceDocumentLink = useCallback(
        (id: string, name?: string, isFullScreen?: boolean, isMain?: boolean) =>
            searchService.getCorrespondenceAttachmentLinkPreview(id, name, isFullScreen, isMain),
        [searchService]
    );

    const onRetry = useCallback(() => {
        if (correspondence?.id) getCorrespondenceDocument(correspondence?.id);
        getAdditionalInfo();
    }, [correspondence, getCorrespondenceDocument, getAdditionalInfo]);

    const errorTab = {
        component: Error,
        props: {
            imageSrc: correspondenceSvg,
            onButtonClick: onRetry,
            extraStyles: styles.errorExtraStyles,
            errorCode: hasDocumentError,
            isHideBackButton: true,
        },
    };

    const correspondenceTab = !hasDocumentError
        ? {
              label: t('correspondence.correspondencePreviewPanel.tabs.documentTab'),
              component: CorrespondencePreview,
              props: {correspondence, getCorrespondenceDocument, setHasDocumentError},
          }
        : {
              ...errorTab,
              label: t('correspondence.correspondencePreviewPanel.tabs.documentTab'),
          };

    const attachmentsCount = correspondence?.attachments ? correspondence.attachments.length : 0;

    const attachmentsTab = !hasAttachmentsError
        ? {
              label: `${t('correspondence.correspondencePreviewPanel.tabs.attachmentsTab')} ${
                  !!attachmentsCount ? `(${attachmentsCount})` : ``
              }`,
              component: CorrespondenceAttachments,
              props: {attachments: correspondence?.attachments},
          }
        : {
              ...errorTab,
              label: t('correspondence.correspondencePreviewPanel.tabs.attachmentsTab'),
          };

    const trackingTab = !hasTrackingError
        ? {
              label: t('correspondence.correspondencePreviewPanel.tabs.trackingTab'),
              component: CorrespondenceTracking,
              props: {tracking: correspondence?.tracking},
          }
        : {
              ...errorTab,
              label: t('correspondence.correspondencePreviewPanel.tabs.trackingTab'),
          };

    const tabOptions: ITabsProps = {
        name: t('correspondence.correspondencePreviewPanel.tabs.name'),
        ariaLabel: t('correspondence.correspondencePreviewPanel.tabs.ariaLabel'),
        tabs: [correspondenceTab, attachmentsTab, trackingTab],
        customClasses: {
            tabsContainer: styles.tabsContainer,
            appBarRoot: styles.tabsMuiAppBarRoot,
            tabPanelActive: styles.tabPanelActive,
        },
    };

    const documentId = correspondence?.id;
    const shouldDownloadWithLinks = mode === AppMode.WebView || checkIfIOS();

    const onDocumentPreview = async () => {
        getMainDocumentURL();
        setDocumentPreview(true);

        if (documentURL && isWebView) {
            let _downloadUrl = await searchService.getCorrespondenceAttachmentLinkDownload(
                documentId,
                `${documentId}.pdf`
            );
            let _previewUrl = await searchService.getCorrespondenceAttachmentLinkPreview(
                documentId,
                `${documentId}.pdf`
            );

            // onDocumentPreviewMobile({downloadUrl, previewUrl});

            onDocumentPreviewMobile({
                // @ts-ignore
                downloadUrl: urlToPath(_downloadUrl?.url?.url),
                // @ts-ignore
                previewUrl: urlToPath(_previewUrl?.url?.url),
            });
        }
    };

    const downloadDocument = useCallback(() => {
        if (!documentId) return;

        if (shouldDownloadWithLinks) {
            getCorrespondenceDocumentLinkHandler(
                documentId,
                true,
                // @ts-ignore
                getCorrespondenceDocumentLink,
                onDownloadLink,
                onPreviewLink,
                mode
            );
        } else {
            getCorrespondenceDocumentHandler(
                documentId,
                true,
                mode,
                dispatch,
                getCorrespondenceDocument
            );
        }
    }, [
        documentId,
        shouldDownloadWithLinks,
        mode,
        getCorrespondenceDocument,
        getCorrespondenceDocumentLink,
        isFullScreen,
        onPreviewLink,
    ]);

    const onFullScreenClose = () => {
        setDocumentPreview(false);
    };

    return (
        <PreviewContentLayout layoutAttributes={{className: styles.correspondenceContainer}}>
            <Tabs {...tabOptions} />
            <div className={styles.footer}>
                <button onClick={onDocumentPreview} className={styles.button}>
                    <EyeIcon width="15" height="16" />
                    <span className={styles.text}>
                        {t('correspondence.correspondencePreview.viewDocument')}
                    </span>
                </button>
                <button onClick={() => downloadDocument()} className={styles.button}>
                    <DownloadIcon width="15" height="16" />
                    <span className={styles.text}>
                        {t('correspondence.correspondencePreview.download')}
                    </span>
                </button>
            </div>
            {documentPreview && !isWebView && (
                <DocumentPreview
                    documentURL={documentURL}
                    documentName={`${documentId}.pdf`}
                    onFullScreenClose={onFullScreenClose}
                    isFullScreen={fullScreenPreview}
                    docLoading={documentLoading}
                    setDocLoading={setDocumentLoading}
                    docError={documentError}
                    setDocError={setDocumentError}
                    fileType="pdf"
                    onDownloadClick={downloadDocument}
                />
            )}
        </PreviewContentLayout>
    );
};
