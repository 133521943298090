import {EntityType} from 'app/contexts';

export const HistoryRoutes = {
    history: (origin: string) => (entity: string) => {
        switch (entity) {
            case EntityType.Correspondence:
                return `${origin}/history/correspondence`;
            case EntityType.Training:
                return `${origin}/history/training`;
            case EntityType.Email:
                return `${origin}/history/email`;
            case EntityType.Employee:
                return `${origin}/history/employee`;
            case EntityType.News:
                return `${origin}/history/news`;
            case EntityType.Document:
                return `${origin}/history/document`;
            case EntityType.Tender:
                return `${origin}/history/tender`;
            case EntityType.Suppliers:
                return `${origin}/history/suppliers`;
            case EntityType.Customers:
                return `${origin}/history/customers`;
            case EntityType.Service:
                return `${origin}/history/service-now`;
            case EntityType.Projects:
                return `${origin}/history/projects`;
            case EntityType.Distribution:
                return `${origin}/history/distribution`;
            default:
                return 'HistoryService_error';
        }
    },
};
