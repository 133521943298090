import './marker.scss';
import {get} from 'lodash';
import {ConsoleLogger} from 'app/infrastructure/logging';

export const Gmarker = (map: {}, result: any) => {
    const google = get(window, 'google', null);
    const logger = new ConsoleLogger();

    // Example of default red marker with dot
    // icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
    // icon: 'https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png',
    const iconUrl =
        'https://www.google.com.ua/maps/vt/icon/name=assets/icons/spotlight/spotlight_pin_v4_outline-2-medium.png,assets/icons/spotlight/spotlight_pin_v4-2-medium.png,assets/icons/spotlight/spotlight_pin_v4_dot-2-medium.png&highlight=c5221f,ea4335,b31412?scale=';

    if (!google) return false;

    try {
        new google.maps.Marker({
            map,
            position: result.geometry.location,
            labelClass: 'Gmarker__labels',
            label: {
                className: 'Gmarker__label',
                text: result.name,
                color: 'rgb(202,38,47)',
                fontSize: '14px',
                maxWidth: '20px',
                textAlign: 'right',
            },
            icon: iconUrl,
            cursor: 'pointer',
            title: result.name,
            animation: google.maps.Animation.DROP,
        });
    } catch (e) {
        logger.log(e);
    }
};
