import {useLocalizationContext, useCurrency} from 'app/hooks';
import React, {FC} from 'react';

import styles from './Commitments.module.scss';

export const Commitments: FC<{
    items: {name: string; value: string}[];
    yearFrom: number;
    yearTo: number;
}> = ({items, yearFrom, yearTo}) => {
    const {t} = useLocalizationContext();
    const [selectedCurrency] = useCurrency();
    return (
        <div className={styles.commitments}>
            <header className={styles.commitmentsHeader}>
                <span className={styles.commitmentsTitle}>
                    {t('suppliers.previewPanel.commitments.title')}
                </span>
                <span className={styles.badge}>{selectedCurrency === 'usd' ? 'USD' : 'AED'}</span>
                {yearFrom && yearTo && (
                    <span className={styles.badge}>
                        {yearFrom} - {yearTo}
                    </span>
                )}
            </header>
            <div className={styles.itemsContainer}>
                {items.map((item, i) => (
                    <div className={styles.item} key={i}>
                        <div className={styles.itemTitle}>{item.name}</div>
                        <div className={styles.itemValue}>{item.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
