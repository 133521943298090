import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import styles from './tenderBoardListItem.module.scss';
import {format, parseISO} from 'date-fns';
import {TextAvatar} from 'app/views/components/textAvatar';
import {ITenderItem} from 'app/state/tenders';

export const TenderBoardListItem: FC<{item: ITenderItem; searchWords: string[]}> = ({
    item: {id, documentId, name, category, type, date, submitionTotal},
    searchWords = [],
}) => {
    return (
        <div className={styles.item}>
            <div className={styles.avatar}>
                <TextAvatar title={name || 'ID'} avatarProps={{className: styles.avatarImage}} />
            </div>
            <div className={styles.info}>
                <div className={styles.header}>
                    {name ? (
                        <Highlighter
                            highlightClassName="highlight"
                            searchWords={searchWords}
                            textToHighlight={name || ''}
                            className={styles.name}
                        />
                    ) : (
                        <div className={styles.name}>#{id}</div>
                    )}
                    <Highlighter
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={`${documentId}`}
                        className={styles.docId}
                    />
                </div>

                <div className={styles.type}>
                    <Highlighter
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={type}
                    />
                    <span className={styles.timeAgo}>{format(parseISO(date), 'd MMM')}</span>
                </div>
                <div className={styles.category}>{category}</div>
                <div className={styles.submitionTotal}>{submitionTotal.usd}</div>
            </div>
        </div>
    );
};
