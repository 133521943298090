import {put, takeLatest, all, getContext} from 'redux-saga/effects';
import {
    getMyProfile as getMyProfileAction,
    getMyProfileSuccess,
    getMyProfileFailure,
} from './myProfile.actions';
import {SessionStorageProtocol} from '../../infrastructure/auth/sessionStorageProtocol';

export function* getMyProfile() {
    const logger: any = yield getContext('logger');

    try {
        const searchService: any = yield getContext('searchService');
        const data: any = yield searchService.get().fetchMyProfile();

        if (data.hasErrors) {
            yield put(getMyProfileFailure());
        } else {
            SessionStorageProtocol.setItem('userEmail', data.mail);

            yield put(getMyProfileSuccess(data));
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(getMyProfileFailure());
    }
}

export function* myProfileSaga() {
    yield all([yield takeLatest(String(getMyProfileAction), getMyProfile)]);
}
