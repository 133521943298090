import React from 'react';
import classNames from 'classnames';
import styles from './tabButtons.module.scss';

export const TabButtons = ({
    tabs,
    onSelect,
}: {
    tabs: {title: string; name: string; active: boolean}[];
    onSelect: (tabName: string) => void;
}) => {
    return (
        <div className={styles.container}>
            {tabs.map(tab => (
                <button
                    key={tab.name}
                    className={classNames(styles.button, {
                        [styles.active]: tab.active,
                    })}
                    onClick={() => {
                        onSelect(tab.name);
                    }}
                >
                    {tab.title}
                </button>
            ))}
        </div>
    );
};
