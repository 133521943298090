import React from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import {DetailsList} from './detailsList';
import {getFullDate} from 'app/utils';
import styles from '../servicePreviewPanel.module.scss';

export const CommonDetails = ({data, twoColumn}: any) => {
    const {t} = useLocalizationContext();

    return (
        <div
            className={classNames({
                [styles.twoColumnContainer]: twoColumn,
            })}
        >
            <DetailsList
                data={data}
                fields={[
                    'category',
                    {
                        name: 'ticketType',
                        renderer: (value: string) => t(`serviceNow.ticketType.${value}`),
                    },
                    {
                        name: 'created',
                        renderer: getFullDate,
                    },
                ]}
            />
            <DetailsList data={data} fields={['state', 'assignmentGroup', 'groupCompany']} />
        </div>
    );
};
