import {get} from 'lodash';
import {ConsoleLogger} from 'app/infrastructure/logging';

export const Map = (mapRef: any, errorHandler: (data: any) => void) => {
    const defaultMapPosition = {lat: 24.466667, lng: 54.366669};
    const google = get(window, 'google', null);
    const logger = new ConsoleLogger();

    if (!mapRef && mapRef.current) {
        return null;
    }

    try {
        const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;

        return new google.maps.Map(mapRef.current, {
            center: defaultMapPosition,
            zoom: 12,
            minZoom: 1,
            panControl: false,
            zoomControl: false,
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            gestureHandling: 'cooperative',
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            duplicateMarkers: false,
            styles: [
                {
                    featureType: 'poi.business',
                    stylers: [{visibility: 'off'}],
                },
            ],
            restriction: {
                latLngBounds: {north: maxLat, south: -maxLat, west: -180, east: 180},
                strictBounds: true,
            },
        });
    } catch (e) {
        logger.log(e);

        errorHandler(e);
        return null;
    }
};
