import {FilterType, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';

export enum MailFilterTypes {
    Attachments = 'Has attachments',
    Date = 'Date',
    Sender = 'Sender',
}

export enum MailAttachmentFilterValues {
    HasAttachment = 'hasAttachment',
}

export const getInitialFilters = (): IFilterRefiner[] => {
    const dateFilterConfig: IFilterRefiner = {
        Name: MailFilterTypes.Date,
        type: FilterType.DateRange,
        Entries: [
            {
                RefinementName: 'From',
                RefinementValue: 'from',
            },
            {
                RefinementName: 'To',
                RefinementValue: 'to',
            },
        ],
    };

    const attachmentsFilter: IFilterRefiner = {
        Name: MailFilterTypes.Attachments,
        type: FilterType.BooleanOption,
        Entries: [
            {
                RefinementName: '_',
                RefinementValue: MailAttachmentFilterValues.HasAttachment,
                isChecked: false,
            },
        ],
    };

    const senderFilter: IFilterRefiner = {
        Name: MailFilterTypes.Sender,
        type: FilterType.TextField,
        Entries: [
            {
                RefinementName: 'From',
                RefinementValue: 'fromSender',
                value: '',
            },
            {
                RefinementName: 'To',
                RefinementValue: 'toSender',
                value: '',
            },
        ],
    };

    return [dateFilterConfig, attachmentsFilter, senderFilter];
};
