import React, {FC} from 'react';
import Highlighter from 'react-highlight-words';

import {map, unescape} from 'lodash';

interface IHighlighterComponent {
    className?: string;
    highlightClassName?: string;
    searchWords: string[];
    textToHighlight: string;
    autoEscape?: boolean;
}

export const HighlighterComponent: FC<IHighlighterComponent> = ({
    className,
    highlightClassName,
    searchWords,
    textToHighlight,
    autoEscape = true,
    ...rest
}) => {
    const _searchWords = map(searchWords, word => {
        const exactMatchSymbols = [`"`, `'`];

        // replace all ios/iphoneX weirdness ie:’‘”“
        let transformedWord = unescape(word)
            .replace('”', exactMatchSymbols[0])
            .replace('“', exactMatchSymbols[0])
            .replace('’', exactMatchSymbols[1])
            .replace('‘', exactMatchSymbols[1])
            .replace(/[\u2018\u2019]/g, exactMatchSymbols[1])
            .replace(/[\u201C\u201D]/g, exactMatchSymbols[0]);

        // Remove exactMatchSymbols from start of word for true comparison
        if (
            transformedWord.startsWith(exactMatchSymbols[0]) ||
            transformedWord.startsWith(exactMatchSymbols[1])
        ) {
            transformedWord = transformedWord.slice(1);
        }

        // Remove exactMatchSymbols from end of word for true comparison
        if (
            transformedWord.endsWith(exactMatchSymbols[0]) ||
            transformedWord.endsWith(exactMatchSymbols[1])
        ) {
            transformedWord = transformedWord.slice(0, transformedWord.length - 1);
        }

        return transformedWord;
    });

    return (
        <Highlighter
            className={className}
            highlightClassName={highlightClassName}
            searchWords={_searchWords}
            textToHighlight={textToHighlight}
            autoEscape={autoEscape}
            {...rest}
        />
    );
};
