export interface ICustomerItem {
    id: string;
    text: string;
    term: string;
    label: string;
    style: string;
    type: CustomerType;
    details?: any;
}

export enum CustomerType {
    Customer = 'customer',
    Holding = 'holding',
    Country = 'country',
}
