export const isInViewport = (element: HTMLElement, clientRoot: HTMLElement) => {
    if (!element) return false;

    const elementRect: DOMRect = element.getBoundingClientRect();
    const clientViewRect: DOMRect = clientRoot.getBoundingClientRect();

    return (
        (elementRect.bottom > clientViewRect.top && elementRect.top < clientViewRect.bottom) ||
        (elementRect.bottom < clientViewRect.top && elementRect.top > clientViewRect.bottom)
    );
};
