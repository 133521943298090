import {handleActions} from 'redux-actions';
import {
    resetTrainingsItems,
    resetTrainingsState,
    searchTrainings,
    searchTrainingsFailure,
    searchTrainingsSuccess,
    setTrainingsSortBy,
} from 'app/state/trainings/trainings.actions';
import {ITransformedTrainingsResponse} from 'app/infrastructure/search/eLearning/searchService.eLearning.types';
import {TrainingsSortOptions} from 'app/containers/trainings';
import {ITrainingItem} from './trainings.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';

export interface ITrainingsState {
    items: ITrainingItem[];
    sortBy: TrainingsSortOptions;
    isLoading: boolean;
    areMoreResultsAvailable: boolean;
    hasErrors: boolean;
    totalCount: number;
    hasErrorCode?: number;
}

const initialState: ITrainingsState = {
    sortBy: TrainingsSortOptions.Feature,
    items: [],
    isLoading: false,
    areMoreResultsAvailable: false,
    hasErrors: false,
    hasErrorCode: undefined,
    totalCount: 0,
};

export default handleActions(
    {
        [String(searchTrainings)]: state => ({...state, isLoading: true}),
        [String(searchTrainingsSuccess)]: (
            state,
            {payload: {courses, hasErrors, totalCount}}: {payload: ITransformedTrainingsResponse}
        ) => ({
            ...state,
            totalCount,
            isLoading: false,
            items: [...state.items, ...courses],
            areMoreResultsAvailable: courses.length === DEFAULT_PAGE_SIZE,
            hasErrors,
        }),
        [String(searchTrainingsFailure)]: (state, {payload}: any) => ({
            ...state,
            items: [],
            isLoading: false,
            totalCount: 0,
            hasErrors: true,
            hasErrorCode: payload.responseStatus,
        }),
        [String(setTrainingsSortBy)]: (state, {payload}: any) => ({
            ...state,
            sortBy: payload as TrainingsSortOptions,
        }),
        [String(resetTrainingsState)]: () => initialState,
        [String(resetTrainingsItems)]: state => ({
            ...state,
            items: [],
            isLoading: true,
            totalCount: 0,
        }),
    },
    initialState
);
