import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {EntityType} from 'app/contexts';
import {useChannelContext, useLocalizationContext} from 'app/hooks';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {
    applyDistributionFilters,
    areDistributionsLoadingSelector,
    areMoreDistributionsAvailableSelector,
    distributionsErrorCodeSelector,
    distributionsSelector,
    distributionsSortSelector,
    distributionsTotalCountSelector,
    filtersSelector,
    getDistributionDetails,
    IDistributionItem,
    resetDistributionsState,
    resetDistributionTab,
    searchDistributions,
    setDistributionsSortBy,
} from 'app/state/distribution';

import chooseDistribution from '../../static/images/chooseDistribution.svg';
import noResultsDistributions from '../../static/images/noResultsDistributions.svg';
import {SearchResultLayout} from '../../layouts/searchResultLayout/searchResultLayout';
import {DistributionListItem} from '../../views/distribution/components/distributionListItem/distributionListItem';
import {DistributionListItemSkeleton} from '../../views/distribution/components/distributionListItemSkeleton/distributionListItemSkeleton';
import {changeSingleFilter} from 'app/state/filter';
import {DistributionPreviewPanel} from '../../views/distribution/components/distributionPreviewPanel';
import {searchSameValueSelector} from 'app/state/search/search.selectors';
import {DistributionsFiltersOptions, DistributionsSortOptions} from './distribution.types';
import {ISortOption} from '../../views/components/sortPanel/sortPanel.types';

export const distributionsSortOptionsI18n = {
    [DistributionsSortOptions.nameAZ]: 'distribution.container.sortNameAToZ',
    [DistributionsSortOptions.nameZA]: 'distribution.container.sortNameZToA',
    [DistributionsSortOptions.ratingDesc]: 'distribution.container.sortRatingDesc',
};

const sortList: ISortOption[] = Object.values(DistributionsSortOptions).map(
    (option: DistributionsSortOptions): ISortOption => ({
        name: distributionsSortOptionsI18n[option],
        value: option,
    })
);

export const DistributionContainer = () => {
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector);
    const searchWords = useSelector(searchWordsSelector);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(distributionsSelector, shallowEqual);
    const areDistributionsLoading = useSelector(areDistributionsLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(
        areMoreDistributionsAvailableSelector,
        shallowEqual
    );
    const filters = useSelector(filtersSelector, shallowEqual);
    const currentSort = useSelector(distributionsSortSelector, shallowEqual);
    const totalCount = useSelector(distributionsTotalCountSelector);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(distributionsErrorCodeSelector);

    const selectedItem: IDistributionItem | null = useMemo(() => {
        const item = items.find((i: {id: any}) => String(i.id) === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetDistributionsState());
        dispatch(searchDistributions(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetDistributionTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Distribution,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!areDistributionsLoading && areMoreResultsAvailable) {
            dispatch(searchDistributions(searchQuery));
        }
    }, [areMoreResultsAvailable, areDistributionsLoading, dispatch, searchQuery]);

    const isPreviewLoading = Boolean(selectedItem) && !selectedItem?.details;

    useEffect(() => {
        if (selectedItem && !selectedItem.details) {
            dispatch(
                getDistributionDetails({
                    distributionId: selectedItem.id,
                })
            );
        }
    }, [dispatch, selectedItem]);

    const onSelect = useCallback(
        (distribution: IDistributionItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: distribution.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onSortChange = useCallback(
        sortBy => {
            dispatch(setDistributionsSortBy(sortBy));
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.Distribution,
                    filters: selectedFilters,
                })
            );
            dispatch(applyDistributionFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filterLabelMapper = (name: string): string => {
        if (name === DistributionsFiltersOptions.cityNames) {
            return t('distribution.distributionFilters.cityNames');
        }
        if (name === DistributionsFiltersOptions.stationStatuses) {
            return t('distribution.distributionFilters.stationStatuses');
        }
        if (name === DistributionsFiltersOptions.services) {
            return t('distribution.distributionFilters.services');
        }
        if (name === DistributionsFiltersOptions.ratings) {
            return t('distribution.distributionFilters.ratings');
        }
        return t(name);
    };

    const filtersWithSortedRating = (filters: any) => {
        for (let filter of filters) {
            if (filter.Name === 'ratings') {
                filter.Entries.sort((a: any, b: any) => b.RefinementValue - a.RefinementValue);
            }
        }
        return filters;
    };

    const filter = {
        filterProps: filtersWithSortedRating(filters),
        onApplyFilters,
        filterLabelMapper,
    };

    const list = {
        itemComponentRender: DistributionListItem,
        itemSkeletonRender: DistributionListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    const sorting = {
        sortList,
        onSortChange,
        value: currentSort,
    };

    return (
        <SearchResultLayout
            isLoading={areDistributionsLoading}
            list={list}
            totalItemsCount={totalCount}
            selectedItem={selectedItem}
            previewComponent={DistributionPreviewPanel}
            isPreviewLoading={isPreviewLoading}
            sorting={sorting}
            filter={filter}
            noResultImageUrl={noResultsDistributions}
            chooseItemImageUrl={chooseDistribution}
            translateRoot={'distribution'}
        />
    );
};
