import React, {useState, useEffect} from 'react';
import {IAuthProvider} from 'app/infrastructure/auth';
import {IoC} from 'app/ioc';
import {useInjection} from 'app/hooks';

const AuthorizationGuard = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const authProvider = useInjection<IAuthProvider>(IoC.AuthProvider);

    useEffect(() => {
        const login = async () => {
            await authProvider.init();
            const isLoggedIn: boolean = authProvider.isLoggedIn();
            if (!isLoggedIn) {
                authProvider.loginForIdToken().then(() => setIsLoggedIn(true));
            } else {
                setIsLoggedIn(isLoggedIn);
            }
        };

        login();
    }, [authProvider]);

    return <>{isLoggedIn && props?.children}</>;
};

export default AuthorizationGuard;
