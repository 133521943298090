import React from 'react';

const Icon = props => (
    <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.00098 1.5V9.5"
            stroke="#1859A9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.00098 6.5L8.00098 9.5L11.001 6.5"
            stroke="#1859A9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.501 9.5V10.5C15.501 11.6046 14.6055 12.5 13.501 12.5H2.50098C1.39641 12.5 0.500977 11.6046 0.500977 10.5V9.5"
            stroke="#1859A9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default Icon;
