import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {TextAvatar} from 'app/views/components/textAvatar';
import {ITender} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';

export const Tender: FC<ISuggestionComponent<ITender>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, name, type, searchString, historyDate} = item;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_tender, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <TextAvatar title={name || ''} avatarProps={{className: styles.textAvatar}} />
            <div className={styles.info}>
                <div className={styles.title}>{name}</div>
                <div className={styles.subTitle}>{type}</div>
            </div>
        </div>
    );
};
