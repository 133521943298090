import {SuppliersDetailsFilters} from 'app/containers/suppliers';
import {IDetailsFilter} from './suppliers.types';

export const mapCompaniesToDetailFilters = (
    companies: [{dropDownName: string; name: string; orderNr: number}]
): IDetailsFilter => {
    return {
        name: SuppliersDetailsFilters.Company,
        items: companies.map((c, i) => ({
            id: c.name,
            title: c.dropDownName,
            active: i === 0,
        })),
    };
};

export const createShareDetailsFilters = (): IDetailsFilter => ({
    name: SuppliersDetailsFilters.Share,
    items: [
        {
            id: '100',
            title: 'Share 100 %',
            active: true,
        },
        {
            id: '49',
            title: 'Share 49 %',
            active: false,
        },
    ],
});
