import React from 'react';
import dataLabels from 'chartjs-plugin-datalabels';
import {getColorsList} from 'app/utils/getColor';
import styles from './lineChart.module.scss';
import {LineChartBase} from 'app/views/components/charts/lineChartBase';

export const LineChart = ({
    className,
    labels,
    data,
    formatter = v => v.toString(),
    height,
    dataLabelsVisible,
    colorIndexes,
}: {
    className?: string;
    labels?: string[];
    data: {
        label: string;
        values: number[];
    }[];
    formatter?: (value: number) => string;
    height?: number;
    dataLabelsVisible: boolean;
    colorIndexes?: number[];
}) => {
    const colors: string[] = colorIndexes ? getColorsList(undefined) : getColorsList(data.length);
    const color = (index: number): string => {
        if (colorIndexes && colors[colorIndexes[index]]) {
            return colors[colorIndexes[index]];
        }

        return colors[index];
    };
    const datasets: {
        label: string;
        data: number[];
        fill: boolean;
        backgroundColor: string;
        borderColor: string;
    }[] = data.map(({label, values}, index) => ({
        label,
        data: values,
        fill: false,
        lineTension: 0,
        backgroundColor: color(index),
        borderColor: color(index),
        borderWidth: 1,
        datalabels: {
            align: 'end',
            anchor: 'center',
        },
    }));

    const chartConfig = {
        key: datasets.map(d => d.label).join(),
        plugins: [dataLabels],
        data: {
            labels,
            datasets,
        },
        legend: {
            display: false,
        },
        options: {
            plugins: {
                datalabels: {
                    display: dataLabelsVisible,
                    formatter: Math.round,
                    backgroundColor: null,
                    color: '#000',
                    padding: 0,
                    font: {
                        size: 10,
                        weight: 'normal',
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            borderDash: [1, 1],
                            color: '#E6E6E6',
                        },
                        ticks: {
                            callback: formatter,
                            maxTicksLimit: 6,
                            fontSize: 10,
                        },
                        offset: true,
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            fontSize: 10,
                        },
                        offset: true,
                    },
                ],
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function ({index, datasetIndex}: any, {datasets}: any) {
                        const dataset = datasets[datasetIndex];
                        const label = dataset.label;
                        const value = dataset.data[index];

                        return `${label}: ${formatter(value)}`;
                    },
                },
            },
            maintainAspectRatio: false,
        },
        width: 100,
    };

    return (
        <LineChartBase
            className={className}
            chartConfig={chartConfig}
            labels={labels}
            datasets={datasets}
            data={data}
            height={height}
            colorIndexes={colorIndexes}
            styles={styles}
        />
    );
};
