import React, {FC, SyntheticEvent, useCallback, useEffect, useState} from 'react';
import Fade from '@material-ui/core/Fade';
import {useMediaQuery} from '@material-ui/core';
import {useAppContext, useChannelContext, useInjection, useLocalizationContext} from 'app/hooks';
import {Tabs} from 'app/views/components/tabs';
import {DocumentPreviewInfo} from 'app/views/documents';
import {DocumentPreview} from 'app/views/documents/components/documentPreview';
import {
    DocumentProperties,
    IDocumentPropertiesProps,
} from 'app/views/documents/components/documentProperties';
import styles from './documentPreviewPanel.module.scss';
import {IDocumentItem} from 'app/state/documents/documents.types';
import scssVariables from 'app/variables.module.scss';
import {PreviewContentLayout} from 'app/layouts';
import {ISearchServiceDocuments} from 'app/infrastructure/search/documents/searchService.documents.types';
import {ILogger} from 'app/infrastructure/logging';
import {toastrDownloadError, toastrDownloadInfo} from 'app/views/components/toastr/toastrDownload';
import {checkIfSafariOrIOS, downloadDocumentWithLink, handleBlob} from '../../../../utils';
import {downloadFileErrorMessage} from 'app/utils/downloadFileErrorMessage';
import {IoC} from 'app/ioc';
import {AppMode} from 'app/contexts';
import {useDispatch} from 'react-redux';
import {SUPPORTED_DOCUMENTS_FILE_TYPES} from 'app/constants';

export const DocumentPreviewPanel: FC<{data: IDocumentItem}> = ({data: document}) => {
    const {t} = useLocalizationContext();
    const SearchServiceDocuments = useInjection<ISearchServiceDocuments>(
        IoC.SearchServiceDocuments
    );
    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`, {noSsr: true});
    const {mode} = useAppContext();
    const isWebView = mode === AppMode.WebView;
    const dispatch = useDispatch();
    const {onDownloadLink, onDocumentPreviewMobile} = useChannelContext();
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);

    const [isPreviewInfoShown, setIsPreviewInfoShown] = useState<boolean>(false);
    const [fs, setFs] = useState<boolean>(false);

    const [docLoading, setDocLoading] = useState<boolean>(false);
    const [docError, setDocError] = useState<string | undefined>();

    useEffect(() => {
        setDocError(undefined);
        setDocLoading(true);
        setFs(false);
    }, [document]);

    const onPreviewClick = () => {
        if (isWebView || checkIfSafariOrIOS()) {
            let {documentURL, documentSiteName} = document;
            let previewUrl = SearchServiceDocuments.previewDocumentLink(
                documentURL,
                documentSiteName
            );
            let downloadUrl = SearchServiceDocuments.downloadDocumentLink(
                documentURL,
                documentSiteName
            );

            onDocumentPreviewMobile({downloadUrl, previewUrl});
        } else {
            setFs(true);
        }
    };

    const downloadWithBlob = async (
        SearchServiceDocuments: ISearchServiceDocuments,
        documentURL: string,
        documentSiteName: string,
        documentName: string,
        logger: ILogger
    ) => {
        let toastr = toastrDownloadInfo(dispatch, {documentName, mode});

        try {
            const response = await SearchServiceDocuments.downloadDocument(
                documentURL,
                documentSiteName
            );
            if (response.status === 200) {
                const arrayBuffer = await response.arrayBuffer();
                const fileName = response.headers
                    .get('content-disposition')
                    .match(/filename=(.*);/)[1]
                    .replace(/"/g, '');
                handleBlob(arrayBuffer, 'octet/stream', true, fileName);
                toastr.destroy();
            } else {
                let data;
                try {
                    data = await response.json();
                } catch (e) {}

                const text = downloadFileErrorMessage(data?.ExceptionType);
                toastr.destroy({force: true});
                toastrDownloadError(dispatch, {text, mode});
            }
        } catch (error) {
            logger.error(error);
        }
    };

    const downloadWithLink = useCallback(
        async (
            SearchServiceDocuments: ISearchServiceDocuments,
            documentURL: string,
            documentSiteName: string,
            documentName: string,
            logger: ILogger
        ) => {
            try {
                const downloadURL = SearchServiceDocuments.downloadDocumentLink(
                    documentURL,
                    documentSiteName
                );
                if (isWebView || checkIfSafariOrIOS()) {
                    // TODO DO NOT USE!
                    // const _url = urlToOneHubProxy(
                    //     appConfig.skey_43,
                    //     urlToPath(url),
                    //     'onehub-search'
                    // );
                    // const {downloadUrl} = getDocumentBlobUrl(documentURL, documentSiteName) as {
                    //     downloadUrl: string;
                    // };

                    // Send PostMessgae
                    onDownloadLink({absoluteURL: downloadURL});
                } else {
                    // Download file by emulate link click
                    downloadDocumentWithLink(downloadURL, documentName);
                }
            } catch (error) {
                logger.error(error);
            }
        },
        [mode, onDownloadLink]
    );

    const onDownloadClick = (e: SyntheticEvent) => {
        e.preventDefault();
        onDownload();
    };

    const onDownloadDocumentFromPreview = () => {
        onDownload();
    };

    const onDownload = useCallback(() => {
        const {documentName, documentURL, documentSiteName} = document;

        if (isWebView || checkIfSafariOrIOS()) {
            downloadWithLink(
                SearchServiceDocuments,
                documentURL,
                documentSiteName,
                documentName,
                logger
            );
        } else {
            downloadWithBlob(
                SearchServiceDocuments,
                documentURL,
                documentSiteName,
                documentName,
                logger
            );
        }
    }, [document, mode, downloadWithLink, SearchServiceDocuments, logger]);

    const documentPropertiesProps: IDocumentPropertiesProps = {
        location: document?.documentURL || '',
        size: document?.documentSize || '',
        fileType: document?.documentType || '',
        MIMEType: '',
        created: document?.documentCreatedDate || '',
        modified: document?.documentUpdatedDate || '',
        title: document?.documentName || '',
        authors: document?.documentAuthors || [''],
        language: document?.documentCulture || '',
    };

    const isSupportedFileType = SUPPORTED_DOCUMENTS_FILE_TYPES.includes(document?.documentType);

    const tabsOptions = {
        name: t('documents.documentPreviewPanel.tabs.name'),
        ariaLabel: t('documents.documentPreviewPanel.tabs.ariaLabel'),
        renderAll: true,
        tabs: [
            {
                label: t('documents.documentPreviewPanel.tabs.previewTab'),
                component: DocumentPreview,
                props: {
                    ...document,
                    isFullScreen: fs,
                    onFullScreenClose: () => {
                        setFs(false);
                    },
                    docLoading,
                    setDocLoading,
                    docError,
                    setDocError,
                    isSupportedFileType,
                    onDownloadClick: onDownloadDocumentFromPreview,
                },
            },
            {
                label: t('documents.documentPreviewPanel.tabs.propertiesTab'),
                component: DocumentProperties,
                props: documentPropertiesProps,
            },
        ],
        currentTab: fs ? 0 : undefined,
        customClasses: {
            tabsContainer: styles.tabsContainer,
            appBarRoot: styles.tabsMuiAppBarRoot,
        },
    };

    const onExpandPreviewInfoClick = useCallback(() => {
        setIsPreviewInfoShown(!isPreviewInfoShown);
    }, [isPreviewInfoShown]);

    const preview = (
        <PreviewContentLayout layoutAttributes={{className: styles.container}}>
            <DocumentPreviewInfo
                document={document}
                onExpandPreviewInfoClick={onExpandPreviewInfoClick}
                shouldShowPreviewInfo={isPreviewInfoShown}
                isSmall={isSmall}
                onPreviewClick={onPreviewClick}
                canPreview={isSupportedFileType && !docError && !docLoading}
                onDownloadClick={onDownloadClick}
            />
            <div className={styles.description}>
                <Tabs {...tabsOptions} />
            </div>
        </PreviewContentLayout>
    );

    if (isSmall) {
        return <Fade in>{preview}</Fade>;
    }

    return preview;
};
