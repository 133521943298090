import {all, takeLatest, put, select} from 'redux-saga/effects';
import {push, LOCATION_CHANGE, LocationChangeAction} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {clearPreviousSelectedItem as clearPreviousSelectedItemAction} from './router.actions';
import {locationSelector} from './router.selectors';
import {routeParams} from 'app/routes';
import {sendMessage} from 'app/infrastructure/onehub';
import {ClientType, EventType} from 'app/contexts/channelContext/channelContext.types';

function* clearPreviousSelectedItem(): any {
    const location = yield select(locationSelector);
    const query = parse(location.search.substring(1));
    delete query[routeParams.SELECTED_ID];

    yield put(push(location.pathname + '?' + stringify(query)));
}

/**
 * @@router/LOCATION_CHANGE fires twice using <React.StrictMode>
 * https://github.com/supasate/connected-react-router/issues/4
 *
 * not reproduced after building production version of app
 */
function* handlePushTask({payload: {location}}: LocationChangeAction): any {
    yield sendMessage(
        {
            eventType: EventType.Nav,
            eventData: {
                link: location.pathname + location.search,
            },
        },
        ClientType.OneHub
    );
}

export function* routerSagas(): any {
    yield all([
        yield takeLatest(String(clearPreviousSelectedItemAction), clearPreviousSelectedItem),
        yield takeLatest(String(LOCATION_CHANGE), handlePushTask),
    ]);
}
