import {handleActions} from 'redux-actions';
import {getLightApps, getLightAppsFailure, getLightAppsSuccess} from './lightApps.actions';

export interface ILightApp {
    id: string;
    title: string;
    description: string;
    icon: string;
    isNew: boolean;
    openInNewTab: boolean;
    url: string;
    isInternal: boolean;
}

export interface ILightAppWithQueryParts extends ILightApp {
    titleQueryParts: string[];
    descriptionQueryParts: string[];
}

export interface ILightAppsState {
    categories: ILightApp[];
    isLoading: boolean;
    hasErrors: boolean;
}

const initialState: ILightAppsState = {
    categories: [],
    isLoading: false,
    hasErrors: false,
};

export default handleActions(
    {
        [String(getLightApps)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(getLightAppsSuccess)]: (state, {payload}: any) => ({
            ...state,
            categories: [...payload],
            isLoading: false,
            hasErrors: payload.hasErrors,
        }),
        [String(getLightAppsFailure)]: (state, {payload}: any) => ({
            ...state,
            isLoading: false,
            hasErrors: true,
        }),
    },
    initialState
);
