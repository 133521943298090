import React from 'react';

import {List} from 'app/views/components/list';
import {SuppliersListItem} from '../suppliersListItem';
import {SuppliersListItemSkeleton} from '../suppliersListItemSkeleton';
import {ISupplierItem} from 'app/state/suppliers';
import {IOnListItemSelect} from 'app/views/components/list/list.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const SuppliersList = ({
    items,
    onSelect,
    searchWords,
    isLoading,
}: {
    items: ISupplierItem[];
    onSelect: IOnListItemSelect<any>;
    searchWords: string[];
    isLoading: boolean;
}) => {
    return isLoading ? (
        <List
            itemComponentRender={SuppliersListItemSkeleton}
            itemsData={skeletons}
            searchWords={[]}
        />
    ) : (
        <List
            itemComponentRender={SuppliersListItem}
            itemsData={items}
            onSelect={onSelect}
            searchWords={searchWords}
        />
    );
};
