import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import styles from './mailListItem.module.scss';
import {TextAvatar} from 'app/views/components/textAvatar';
import {format, parseISO} from 'date-fns';
import {IMailItem} from 'app/state/mail/mail.types';
import {useUserAvatar} from 'app/hooks/useUserAvatar';

export const MailListItem: FC<{item: IMailItem; searchWords: string[]}> = ({
    item: {address, senderName, dateSent, subject, hasAttachments, description},
    searchWords,
}) => {
    const avatar = useUserAvatar(address);

    return (
        <div className={styles.mailListItem}>
            <TextAvatar
                title={senderName || ''}
                avatarProps={{className: styles.avatar, src: avatar}}
            />
            <div className={styles.info}>
                <div className={styles.sendInfo}>
                    <span className={styles.senderName}>
                        <Highlighter
                            highlightClassName="highlight"
                            searchWords={searchWords}
                            textToHighlight={senderName || ''}
                        />
                    </span>
                    <span className={styles.dateSent}>{format(parseISO(dateSent), 'd MMM Y')}</span>
                </div>
                <div className={styles.mainInfo}>
                    <span className={styles.subject}>
                        <Highlighter
                            highlightClassName="highlight"
                            searchWords={searchWords}
                            textToHighlight={subject || ''}
                        />
                    </span>
                    {hasAttachments ? <span className={styles.hasAttachments} /> : null}
                </div>
                <span className={styles.description}>
                    <Highlighter
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={description || ''}
                    />
                </span>
            </div>
        </div>
    );
};
