import React from 'react';

import {List} from 'app/views/components/list';
import {TenderBoardListItem} from 'app/views/tenderBoard/tenderBoardListItem';
import {TenderBoardListItemSkeleton} from 'app/views/tenderBoard/tenderBoardListItemSkeleton';
import {ITenderBoardListProps} from './tenderBoardList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const TenderBoardList = ({
    tenders,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: ITenderBoardListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    itemComponentRender={TenderBoardListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    itemComponentRender={TenderBoardListItem}
                    itemsData={tenders}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    loadMoreHandler={loadMoreHandler}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
