import {createActions} from 'redux-actions';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';

export const {
    searchCorrespondence,
    searchCorrespondenceSuccess,
    searchCorrespondenceFailure,
    resetCorrespondenceState,
    resetCorrespondenceItems,
    setCorrespondenceFilters,
    changeCorrespondenceFilters,
    changeCorrespondenceFiltersSuccess,
    changeCorrespondenceFiltersFailure,
    getCorrespondenceAttachments,
    getCorrespondenceAttachmentsSuccess,
    getCorrespondenceAttachmentsFailure,
    getCorrespondenceTracking,
    getCorrespondenceTrackingSuccess,
    getCorrespondenceTrackingFailure,
    resetCorrespondenceTab,
} = createActions({
    SEARCH_CORRESPONDENCE: (query: string) => query,
    SEARCH_CORRESPONDENCE_SUCCESS: ({areMoreResultsAvailable, items, hasErrors, totalCount}) => ({
        areMoreResultsAvailable,
        items,
        hasErrors,
        totalCount,
    }),
    SEARCH_CORRESPONDENCE_FAILURE: error => error,
    GET_CORRESPONDENCE_ATTACHMENTS: (id: string) => id,
    GET_CORRESPONDENCE_ATTACHMENTS_SUCCESS: ({attachments, id}) => ({attachments, id}),
    GET_CORRESPONDENCE_ATTACHMENTS_FAILURE: error => error,
    GET_CORRESPONDENCE_TRACKING: (id: string) => id,
    GET_CORRESPONDENCE_TRACKING_SUCCESS: ({tracking, id}) => ({tracking, id}),
    GET_CORRESPONDENCE_TRACKING_FAILURE: error => error,
    SET_CORRESPONDENCE_FILTERS: resultFilters => resultFilters,
    RESET_CORRESPONDENCE_STATE: () => {},
    RESET_CORRESPONDENCE_ITEMS: () => {},
    CHANGE_CORRESPONDENCE_FILTERS: (filters: IFilterRefiner[]) => filters,
    CHANGE_CORRESPONDENCE_FILTERS_SUCCESS: () => {},
    CHANGE_CORRESPONDENCE_FILTERS_FAILURE: (error: any) => error,
    RESET_CORRESPONDENCE_TAB: () => {},
});
