import {handleActions} from 'redux-actions';
import {
    searchGraphFederated,
    searchGraphFederatedSuccess,
    searchGraphFederatedFailure,
} from './searchAll.actions';
import {IMSGraphBatchResponse} from 'app/api/searchAPI/searchAPI.MSGraph.types';

export type ISearchAllState = IMSGraphBatchResponse | {};
const initialState: ISearchAllState = {};

export default handleActions(
    {
        [String(searchGraphFederated)]: state => state,
        [String(searchGraphFederatedSuccess)]: (state, {payload}) => payload,
        [String(searchGraphFederatedFailure)]: () => ({}),
    },
    initialState
);
