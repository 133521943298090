import React from 'react';

const Icon = props => (
    <svg
        width="17"
        height="12"
        viewBox="0 0 17 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99989 1.50056C5.31255 1.45523 2.53322 3.33323 0.785885 5.25656C0.406007 5.6783 0.406007 6.31883 0.785885 6.74056C2.49522 8.62323 5.26655 10.5446 7.99989 10.4986C10.7332 10.5446 13.5052 8.62323 15.2159 6.74056C15.5958 6.31883 15.5958 5.6783 15.2159 5.25656C13.4666 3.33323 10.6872 1.45523 7.99989 1.50056Z"
            stroke="#1859A9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 5.99993C10.4996 7.38056 9.38018 8.49951 7.99956 8.49927C6.61893 8.49902 5.49988 7.37967 5.5 5.99905C5.50012 4.61842 6.61937 3.49927 8 3.49927C8.66321 3.49909 9.2993 3.76252 9.7682 4.23154C10.2371 4.70056 10.5004 5.33672 10.5 5.99993V5.99993Z"
            stroke="#1859A9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default Icon;
