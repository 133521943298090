import React from 'react';
import {CircleSkeleton, RectSkeleton} from 'app/views/components/skeleton';
import styles from './styles/skeleton.module.scss';

const SkeletonItem = () => (
    <div className={styles.skeletonItem}>
        <div className={styles.avatar}>
            <CircleSkeleton size={32} />
        </div>
        <div className={styles.info}>
            <div className={styles.title}>
                <RectSkeleton width={144} />
            </div>
            <div className={styles.subTitle}>
                <RectSkeleton width={102} />
            </div>
        </div>
    </div>
);

export const Skeleton = () => (
    <div className={styles.skeleton}>
        <div className={styles.mainTitle}>
            <RectSkeleton width={144} />
        </div>
        <div className={styles.items}>
            <SkeletonItem />
            <SkeletonItem />
        </div>
    </div>
);
