import React from 'react';
import classNames from 'classnames';

import {HorizontalScroll} from 'app/views/components/horizontalScroll/horizontalScroll';
import styles from './ticketState.module.scss';

export const TicketState = ({
    ticketStatuses,
    state,
}: {
    ticketStatuses: {
        order: string;
        name: string;
    }[];
    state: string;
}) => {
    const orderedStatuses = ticketStatuses.sort((a, b) => parseInt(a.order) - parseInt(b.order));
    const currentIndex = orderedStatuses.findIndex(({name}) => name === state);

    return (
        <HorizontalScroll
            leftButtonClassname={styles.leftButton}
            rightButtonClassName={styles.rightButton}
        >
            <div className={styles.statesContainer}>
                {ticketStatuses.map(({name}, index) => (
                    <div
                        key={name}
                        className={classNames(styles.stateItem, {
                            [styles.current]: index === currentIndex,
                            [styles.done]: index < currentIndex,
                        })}
                    >
                        <div className={styles.icon} />
                        <div className={styles.stateName}>{name}</div>
                    </div>
                ))}
            </div>
        </HorizontalScroll>
    );
};
