import {ResponseError} from "./responseError";

type ResponseHandlerType = (response: Response) => Promise<any>;

const defaultResponseHandler = (response: Response, parseJSON: boolean) => {
    if (parseJSON) {
        return response.json().then(response => JSON.parse(response));
    }
    return response.json();
};

export const fetchData = async (
    url: string,
    accessToken: string,
    method: string = 'GET',
    body: any = null,
    parseJSON?: boolean | null,
    responseHandler?: ResponseHandlerType | null,
    signal?: AbortSignal
) => {
    const response = await fetch(url, {
        method,
        body: body && JSON.stringify(body),
        signal,
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'content-type': 'application/json',
        },
    });

    if (response.status >= 400) {
        throw new ResponseError(response);
    } else if (responseHandler) {
        return responseHandler(response);
    } else {
        return defaultResponseHandler(response, Boolean(parseJSON));
    }
};
