import {CustomersRoutes} from './routes.customers';
import {
    AbortControllerCustomers,
    IGetCountryDetailsOptions,
    IGetCustomerDetailsOptions,
    ISearchCustomersOptions,
} from './searchAPICaller.customers.types';
import {fetchData} from 'app/utils';

export const abortControllers: AbortControllerCustomers = {
    CUSTOMERS: null,
};

export abstract class SearchAPICallerCustomers {
    public static async searchCustomers(
        body: ISearchCustomersOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.CUSTOMERS?.abort();
        abortControllers.CUSTOMERS = new AbortController();

        return fetchData(
            CustomersRoutes.customers(searchAPIURL),
            accessToken,
            'POST',
            body,
            null,
            null,
            abortControllers.CUSTOMERS.signal
        );
    }

    public static async getCustomerDetails(
        {customerId, from, to, entityType}: IGetCustomerDetailsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${CustomersRoutes.customers(
                searchAPIURL
            )}/customerDetails/${customerId}?from=${from}&to=${to}&entityType=${entityType}`
        );

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }

    public static async getCountryDetails(
        {countryId, from, to}: IGetCountryDetailsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${CustomersRoutes.customers(
                searchAPIURL
            )}/countryDetails/${countryId}?from=${from}&to=${to}`
        );

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }
}
