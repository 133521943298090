export const MailRoutes = {
    emails: (origin: string) => `${origin}/search/email`,
    // emailAvatars: (origin: string) => `${origin}/search/avatar`,
    emailBody: (origin: string, id: string) => `${origin}/email/${encodeURIComponent(id)}`,
    emailAttachments: (origin: string, id: string) =>
        `${origin}/email/attachment/${encodeURIComponent(id)}`,
    emailAttachmentLinkDownload: (origin: string, emailId: string, id: string) => {
        return `${origin}/email/${encodeURIComponent(emailId)}/attachment/${encodeURIComponent(
            id
        )}/download`;
    },
    emailAttachmentLinkPreview: (origin: string, emailId: string, id: string) => {
        return `${origin}/email/${encodeURIComponent(emailId)}/attachment/${encodeURIComponent(
            id
        )}/preview`;
    },
    emailAttachmentLink: (
        origin: string,
        emailId: string,
        id: string,
        name: string,
        fileFormat?: string
    ) => {
        let link = `${origin}/email/${encodeURIComponent(emailId)}/attachment/${encodeURIComponent(
            id
        )}/link?name=${name}`;

        if (fileFormat) link += `&fileFormat=${fileFormat}`;

        return link;
    },
};
