import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';

import {useLocalizationContext, useChannelContext} from 'app/hooks';
import {EntityType} from 'app/contexts';

import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';
import {
    serviceItemsSelector,
    areServiceItemsLoadingSelector,
    filtersSelector,
    areMoreServiceItemsAvailableSelector,
    serviceErrorCodeSelector,
    IServiceItem,
    applyServiceFilters,
    searchServices,
    getServiceDetails,
    resetServicesState,
    resetServiceNowTab,
} from 'app/state/serviceNow';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';

import {ServiceListItem, ServiceListItemSkeleton} from 'app/views/services';
import {ServicePreviewPanel} from 'app/views/services/components/servicePreviewPanel/servicePreviewPanel';
import {changeSingleFilter} from 'app/state/filter';
import newsChoose from 'app/static/images/news.svg';
import noResultsNews from 'app/static/images/noResultsNews.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const ServiceNowContainer = () => {
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector, shallowEqual);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(serviceItemsSelector, shallowEqual);
    const areServiceItemsLoading = useSelector(areServiceItemsLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(areMoreServiceItemsAvailableSelector, shallowEqual);
    const filters = useSelector(filtersSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(serviceErrorCodeSelector);

    const selectedItem: IServiceItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetServicesState());
        dispatch(searchServices(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetServiceNowTab());
        };
    }, [dispatch]);

    const loadMoreHandler = useCallback(() => {
        if (!areServiceItemsLoading && areMoreResultsAvailable) {
            dispatch(searchServices(searchQuery));
        }
    }, [areMoreResultsAvailable, areServiceItemsLoading, dispatch, searchQuery]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Service,
            entity: selectedItem,
        }
    );

    useEffect(() => {
        if (selectedItem && !selectedItem.details) {
            dispatch(getServiceDetails(selectedItem));
        }
    }, [dispatch, selectedItem]);

    const onSelect = useCallback(
        (serviceNowItem: IServiceItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: serviceNowItem.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.Service,
                    filters: selectedFilters,
                })
            );
            dispatch(applyServiceFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filterLabelMapper = (filter: string): string => {
        if (filter === 'Category') {
            return t('serviceNow.serviceNowFilters.filterCategories');
        }
        if (filter === 'Status') {
            return t('serviceNow.serviceNowFilters.filterStatus');
        }
        if (filter === 'CompanyID') {
            return t('serviceNow.serviceNowFilters.filterCompanies');
        }
        if (filter === 'ContactType') {
            return t('serviceNow.serviceNowFilters.filterContactType');
        }
        if (filter === 'Priority') {
            return t('serviceNow.serviceNowFilters.filterPriority');
        }
        return t(filter);
    };

    const filter = {
        filterProps: filters,
        onApplyFilters,
        filterLabelMapper,
    };

    const list = {
        itemComponentRender: ServiceListItem,
        itemSkeletonRender: ServiceListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    return (
        <SearchResultLayout
            isLoading={areServiceItemsLoading}
            isPreviewLoading={selectedItem && !selectedItem.details}
            filter={filter}
            list={list}
            selectedItem={selectedItem}
            previewComponent={ServicePreviewPanel}
            noResultImageUrl={noResultsNews}
            chooseItemImageUrl={newsChoose}
            translateRoot={'serviceNow'}
        />
    );
};
