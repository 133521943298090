import {handleActions} from 'redux-actions';
import {
    resetSuggestionsState,
    searchHistory,
    searchHistoryFailure,
    searchHistorySuccess,
    searchSuggestions,
    searchSuggestionsFailure,
    searchSuggestionsSuccess,
    setSuggestionsLoading,
} from './suggestions.actions';
import {AvailableSuggestions, ISuggestionsState} from './suggestions.types';

const initialState: ISuggestionsState<AvailableSuggestions> = {
    items: [],
    isLoading: false,
    hasErrors: false,
};

export default handleActions(
    {
        [String(resetSuggestionsState)]: () => initialState,
        [String(searchSuggestions)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(searchSuggestionsSuccess)]: (state, {payload}: any) => ({
            ...state,
            items: [...payload],
            isLoading: false,
        }),
        [String(searchSuggestionsFailure)]: state => ({
            ...state,
            isLoading: false,
            hasErrors: true,
        }),
        [String(searchHistory)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(searchHistorySuccess)]: (state, {payload}: any) => ({
            ...state,
            items: [...payload],
            isLoading: false,
        }),
        [String(searchHistoryFailure)]: state => ({
            ...state,
            isLoading: false,
            hasErrors: true,
        }),
        [String(setSuggestionsLoading)]: (state, {payload}: any) => ({
            ...state,
            isLoading: payload,
        }),
    },
    initialState
);
