import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {DetailsList} from '../detailsList';

import styles from '../../servicePreviewPanel.module.scss';

export const CatalogItem = ({data}: any) => {
    const {t} = useLocalizationContext();
    return (
        <div className={styles.catalogItemContainer}>
            {data.picture && (
                <div className={styles.catalogImage}>
                    <img src={`data:image/png;base64, ${data.picture}`} alt="" />
                </div>
            )}
            <div className={styles.catalogDescription}>
                <DetailsList
                    data={data}
                    fields={[
                        'name',
                        'category',
                        'catalogs',
                        {
                            name: 'link',
                            renderer: (value: string) =>
                                value ? (
                                    <a
                                        className={styles.link}
                                        href={value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={value}
                                    >
                                        {value}
                                    </a>
                                ) : (
                                    t('serviceNow.preview.NA')
                                ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
