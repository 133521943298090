import {all, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {
    applyTrainingsFilters as applyTrainingsFiltersAction,
    changeTrainingsSortBy,
    resetTrainingsItems,
    resetTrainingsState,
    resetTrainingsTab as resetTrainingsTabAction,
    searchTrainings as searchTrainingsAction,
    searchTrainingsFailure,
    searchTrainingsSuccess,
    setTrainingsSortBy as setTrainingsSortByAction,
    setTrainingsSortByFailure,
    setTrainingsSortBySuccess,
} from 'app/state/trainings/trainings.actions';
import {ISearchTrainingsOptions} from 'app/infrastructure/search/eLearning/searchService.eLearning.types';
import {getSearchTrainingsOptions} from 'app/state/trainings/trainings.operations';
import {RootState} from 'app/state';
import {trainingFiltersSelector, trainingsSortSelector} from './trainings.selectors';
import {TrainingsSortOptions} from 'app/containers/trainings';
import {searchQuerySelector} from 'app/state/router';
import {isFilterSelected} from 'app/utils/filterUtils';
import {resetSingleFilter} from '../filter';
import {EntityType} from 'app/contexts';

export function* setTrainingsSortBy({payload}: ReturnType<typeof changeTrainingsSortBy>): any {
    const logger = yield getContext('logger');
    try {
        yield put(resetTrainingsItems());
        yield put(setTrainingsSortByAction(payload));
        const query = yield select(searchQuerySelector);
        yield put(searchTrainingsAction(query));
        yield put(setTrainingsSortBySuccess());
    } catch (error) {
        yield logger.get().error(error);
        yield put(setTrainingsSortByFailure(error));
    }
}

export function* searchTrainings({payload}: ReturnType<typeof searchTrainingsAction>): any {
    const logger = yield getContext('logger');
    try {
        const state: RootState = yield select();
        const SearchServiceELearning = yield getContext('searchService');
        const filters = yield select(trainingFiltersSelector);
        const options: ISearchTrainingsOptions = getSearchTrainingsOptions(payload, state, filters);
        const trainings = yield SearchServiceELearning.eLearning.get().searchTrainings(options);
        yield put(searchTrainingsSuccess(trainings));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchTrainingsFailure(error));
    }
}

export function* applyTrainingsFilters(): any {
    const logger = yield getContext('logger');
    try {
        yield put(resetTrainingsItems());
        const query = yield select(searchQuerySelector);
        yield put(searchTrainingsAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetTrainingsTab(): any {
    const logger = yield getContext('logger');
    try {
        const sort = yield select(trainingsSortSelector);
        const filters = yield select(trainingFiltersSelector);

        if (sort !== TrainingsSortOptions.Feature || isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Training}));
            yield put(resetTrainingsState());
            const query = yield select(searchQuerySelector);
            yield put(searchTrainingsAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* trainingsSaga(): any {
    yield all([
        yield takeLatest(String(searchTrainingsAction), searchTrainings),
        yield takeLatest(String(changeTrainingsSortBy), setTrainingsSortBy),
        yield takeLatest(String(applyTrainingsFiltersAction), applyTrainingsFilters),
        yield takeLatest(String(resetTrainingsTabAction), resetTrainingsTab),
    ]);
}
