import {createActions} from 'redux-actions';
import {ISupplierItem} from './suppliers.types';

// Tab, List actions
export const {
    searchSuppliers,
    setSupplierFilters,
    searchSuppliersSuccess,
    searchSuppliersFailure,
    resetSuppliersState,
    resetSuppliersItems,
    resetSuppliersTab,
} = createActions({
    SEARCH_SUPPLIERS: (query: string) => query,
    SET_SUPPLIER_FILTERS: (filters: any) => filters,
    SEARCH_SUPPLIERS_SUCCESS: servicesResponse => servicesResponse,
    SEARCH_SUPPLIERS_FAILURE: (error: any) => error,
    RESET_SUPPLIERS_STATE: () => {},
    RESET_SUPPLIERS_ITEMS: () => {},
    RESET_SUPPLIERS_TAB: () => {},
});

// Supplier details actions
export const {
    setSuppliersDetailsFilters,
    resetSuppliersDetails,

    getSuppliersDetails,
    getSuppliersDetailsSuccess,
    getSuppliersDetailsFailure,
} = createActions({
    SET_SUPPLIERS_DETAILS_FILTERS: (filters: any) => filters,
    RESET_SUPPLIERS_DETAILS: () => {},

    GET_SUPPLIERS_DETAILS: (
        supplier: ISupplierItem,
        companyId: string,
        selectedShareId: string
    ) => ({supplier, companyId, selectedShareId}),
    GET_SUPPLIERS_DETAILS_SUCCESS: (details: any) => details,
    GET_SUPPLIERS_DETAILS_FAILURE: (error: any) => error,
});
