import React, {Component} from 'react';
import {Error} from 'app/views/components/errors/error';
import {ConsoleLogger, ILogger} from 'app/infrastructure/logging';

interface IErrorBoundaryState {
    applicationErrors: boolean;
}

export class ErrorBoundaryGlobal extends Component<any, IErrorBoundaryState> {
    private readonly logger: ILogger;

    constructor(props: any) {
        super(props);
        this.state = {
            applicationErrors: false,
        };

        this.logger = new ConsoleLogger();
    }

    componentDidCatch(error: any) {
        try {
            this.logger.error(error);
        } catch (e) {
            console.warn(e);
        }

        this.setState({applicationErrors: true});
    }

    render() {
        if (this.state.applicationErrors) {
            return <Error fetchErrors={false} />;
        }

        return this.props.children;
    }
}
