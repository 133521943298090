import React, {useRef, useCallback, KeyboardEvent, useState, useEffect} from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import {locationSelector} from 'app/state/router';
import styles from './searchInput.module.scss';
import {useSelector} from 'react-redux';
import {routes} from 'app/routes';
import {EmployeeGuide} from './employeeGuide';
import {useOnClickOutside} from 'app/hooks/useOnClickOutside';

interface ISearchInput {
    value: string;
    onChange: Function;
    onSearch: Function;
    onClear?: Function;
    onFocus?: Function;
    onBlur?: Function;
    styleClasses?: string;
    onKeyDown?: Function;
    selectedI?: number;
}

const userGuides = {
    [routes.search_employee]: <EmployeeGuide />,
};

const SearchInput = ({
    value,
    styleClasses,
    onChange,
    onSearch,
    onClear,
    onFocus,
    onBlur,
    onKeyDown,
    selectedI,
}: ISearchInput) => {
    const {t} = useLocalizationContext();
    const {pathname} = useSelector(locationSelector);
    const [openGuide, setOpenGuide] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(ref, () => setOpenGuide(false));

    useEffect(() => {
        setOpenGuide(false);
    }, [pathname]);

    const searchInputRef = useRef<HTMLInputElement>(null);

    const classes = classNames(styleClasses, styles.searchInput);

    const onClearHandler = useCallback(() => {
        searchInputRef.current && searchInputRef.current.focus();
        if (onClear) onClear();
    }, [onClear]);

    const onKeyDownHandler = useCallback(
        (e: KeyboardEvent) => {
            if (onKeyDown && (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter')) {
                e.preventDefault();
                onKeyDown(e.key);
            } else if (onSearch && e.key === 'Enter' && !selectedI) {
                onSearch();
            }
        },
        [onKeyDown]
    );

    const showClearButton = !!value.length;
    const showGuide = !!userGuides[pathname];

    return (
        <div className={classes}>
            <input
                ref={searchInputRef}
                className={styles.input}
                name="search-bar-input"
                placeholder={t('pageContent.searchBar.searchInputPlaceholder')}
                value={value}
                autoComplete="off"
                onChange={onChange as any}
                onFocus={onFocus as any}
                onBlur={onBlur as any}
                onKeyDown={onKeyDownHandler}
            />
            {showGuide && (
                <div className={styles.guide}>
                    <div
                        className={styles.guideButton}
                        onClick={() => {
                            setOpenGuide(true);
                        }}
                    />
                    {openGuide && (
                        <div className={styles.guidePopup} ref={ref}>
                            <div
                                className={styles.closeButton}
                                onClick={() => {
                                    setOpenGuide(false);
                                }}
                            />
                            {userGuides[pathname]}
                        </div>
                    )}
                </div>
            )}
            {showClearButton && (
                <div
                    className={classNames(styles.clear, {
                        [styles.closeShift]: showGuide,
                    })}
                    onClick={onClearHandler}
                />
            )}
            <div className={styles.icon} onClick={onSearch as any} />
        </div>
    );
};

export {SearchInput};
