import React, {useState} from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import {LineChart} from './charts/lineChart';
import style from './volumeTrend.module.scss';
import {numberFormatter} from 'app/utils';

export const VolumeTrend = ({
    data,
    className,
    width,
}: {
    data: any;
    className?: string;
    width: number;
}) => {
    const {t} = useLocalizationContext();
    const {
        details: {volumeTrendItems = []},
    } = data;

    const labels: string[] = [];

    type datasetItem = {
        label: string;
        values: number[];
        display: boolean;
    };

    const datasets: datasetItem[] = [];

    volumeTrendItems.forEach(
        ({
            materialSubgroupName,
            yearMonthShort,
            quantityNumber,
        }: {
            materialSubgroupName: string;
            yearMonthShort: string;
            quantityNumber: number;
        }) => {
            let labelIndex = labels.indexOf(yearMonthShort);
            let datasetIndex = datasets.findIndex(
                ({label}: datasetItem) => label === materialSubgroupName
            );
            if (labelIndex === -1) {
                const newLabelsLength = labels.push(yearMonthShort);
                labelIndex = newLabelsLength - 1;
            }
            if (datasetIndex === -1) {
                const newDatasetLength = datasets.push({
                    label: materialSubgroupName,
                    values: [],
                    display: true,
                });
                datasetIndex = newDatasetLength - 1;
            }
            datasets[datasetIndex].values[labelIndex] = quantityNumber;
        }
    );

    const [showDatasets, setShowDatasets] = useState(datasets);

    const toggleDataset = (index: number) => {
        setShowDatasets(
            showDatasets.map((item, i) =>
                i === index
                    ? {
                          ...item,
                          display: !item.display,
                      }
                    : item
            )
        );
    };

    if (labels.length === 0) {
        return null;
    }

    let aspectRatio = 0.25;
    if (width < 1000) aspectRatio = 0.3;
    if (width < 740) aspectRatio = 0.5;

    const chartDatasets = showDatasets.filter(d => d.display);

    return (
        <div className={classNames(className)}>
            <h3>{t('customers.preview.volumeTrend')}</h3>
            <div className={style.toggleWrapper}>
                {showDatasets.map((d, i) => (
                    <button
                        className={classNames(style.toggleButton, {
                            [style.hidden]: !d.display,
                        })}
                        key={i}
                        onClick={() => {
                            toggleDataset(i);
                        }}
                    >
                        {d.label}
                    </button>
                ))}
            </div>
            <div className={style.chartWrapper}>
                <LineChart
                    labels={labels}
                    data={chartDatasets}
                    aspectRatio={aspectRatio}
                    key={aspectRatio}
                    formatter={numberFormatter}
                />
            </div>
        </div>
    );
};
