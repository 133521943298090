import {
    IEmailBetaSourceHandled,
    ISearchMailResponseHandled,
    ISearchMails,
} from '../searchAPI.MSGraph.types';
import {
    AbortControllerMails,
    IMailAttachmentsResponse,
    IMailBody,
    ISearchMailOptions,
} from './searchAPI.mails.types';
import {fetchData} from 'app/utils';
import {MailRoutes} from './routes.mails';

export const abortControllers: AbortControllerMails = {
    MAIL: null,
};

export abstract class SearchAPICallerMails {
    private static handleSearchMailResponse(response: ISearchMails): ISearchMailResponseHandled {
        const areMoreResultsAvailable = response.moreResultsAvailable;
        const items = response.emails.map(
            ({
                id,
                dateSent,
                emailAddress,
                hasAttachments,
                description,
                senderName,
                subject,
                webLink,
                internetMessageId,
                replyTo,
                ccRecipients,
            }): IEmailBetaSourceHandled => ({
                id,
                address: emailAddress,
                avatarSrc: '',
                senderName,
                dateSent,
                subject,
                hasAttachments,
                description,
                webLink,
                internetMessageId,
                replyTo,
                ccRecipients,
            })
        );
        return {
            areMoreResultsAvailable,
            items,
            hasErrors: false,
        };
    }

    public static async searchMail(
        options: ISearchMailOptions,
        searchAPIURL: string,
        accessToken: string
    ): Promise<ISearchMailResponseHandled> {
        const {
            query,
            page = 0,
            perPage = 10,
            hasAttachments,
            fromSender,
            toSender,
            from,
            to,
        } = options;

        const requestParams: {[key: string]: any} = {
            query,
            page,
            perPage,
        };

        if (typeof hasAttachments === 'boolean') {
            requestParams.hasAttachments = hasAttachments;
        }
        if (typeof fromSender === 'string') {
            requestParams.fromSender = fromSender;
        }
        if (typeof toSender === 'string') {
            requestParams.toSender = toSender;
        }
        if (typeof from === 'string') {
            requestParams.from = from;
        }
        if (typeof to === 'string') {
            requestParams.to = to;
        }

        abortControllers.MAIL?.abort();
        abortControllers.MAIL = new AbortController();

        return await fetchData(
            MailRoutes.emails(searchAPIURL),
            accessToken,
            'POST',
            requestParams,
            null,
            null,
            abortControllers.MAIL.signal
        ).then(SearchAPICallerMails.handleSearchMailResponse);
    }

    public static async getMailBody(
        emailId: string,
        searchAPIURL: string,
        accessToken: string
    ): Promise<IMailBody> {
        return fetchData(MailRoutes.emailBody(searchAPIURL, emailId), accessToken, 'GET');
    }

    public static async getMailAttachments(
        internetMessageId: string,
        searchAPIURL: string,
        accessToken: string
    ): Promise<IMailAttachmentsResponse> {
        return fetchData(
            MailRoutes.emailAttachments(searchAPIURL, internetMessageId),
            accessToken,
            'GET'
        );
    }

    /**
     *
     * @param internetMessageId
     * @param attachmentId
     * @param searchAPIURL
     * @param accessToken
     */
    public static async getMailAttachmentDownload(
        internetMessageId: string,
        attachmentId: string,
        searchAPIURL: string,
        accessToken: string
    ) {
        return fetch(
            MailRoutes.emailAttachmentLinkDownload(searchAPIURL, internetMessageId, attachmentId),
            {
                method: 'GET',
                headers: {
                    accept: 'application/pdf',
                    Authorization: `Bearer ${accessToken}`,
                    'content-type': 'application/pdf',
                },
            }
        );
    }

    /**
     *
     * @param internetMessageId
     * @param attachmentId
     * @param searchAPIURL
     */
    public static async getMailAttachmentLinkDownload(
        internetMessageId: string,
        attachmentId: string,
        searchAPIURL: string
    ) {
        return MailRoutes.emailAttachmentLinkDownload(
            searchAPIURL,
            internetMessageId,
            attachmentId
        );
    }

    /**
     *
     * @param internetMessageId
     * @param attachmentId
     * @param searchAPIURL
     */
    public static async getMailAttachmentLinkPreview(
        internetMessageId: string,
        attachmentId: string,
        searchAPIURL: string
    ) {
        return MailRoutes.emailAttachmentLinkPreview(searchAPIURL, internetMessageId, attachmentId);
    }

    public static async getMailAttachmentLink(
        internetMessageId: string,
        attachmentId: string,
        searchAPIURL: string,
        accessToken: string,
        name: string,
        fileFormat?: string
    ) {
        return fetchData(
            MailRoutes.emailAttachmentLink(
                searchAPIURL,
                internetMessageId,
                attachmentId,
                encodeURIComponent(name),
                fileFormat
            ),
            accessToken,
            'GET'
        );
    }
}
