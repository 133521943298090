import {fetchData} from 'app/utils';
import {
    IGetHistory,
    IGetHistoryResponse,
    IGetSuggestionResponse,
    IGetSuggestions,
    mapSuggestionsToEntity,
    SuggestionResponse,
} from './suggestionsAPICaller.types';
import {isEntityEnabled} from 'app/utils/isEntityEnabled';
import {EntityType} from 'app/contexts';

// TODO - Must be checked all query by encodeURIComponent requirement
const routes = {
    suggestionTraining: (origin: string, query: string) =>
        `${origin}/suggest/training?searchText=${encodeURIComponent(query)}`,
    suggestionEmployee: (origin: string, query: string) =>
        `${origin}/suggest/employee?searchText=${encodeURIComponent(query)}`,
    suggestionDocument: (origin: string, query: string) =>
        `${origin}/suggest/document?searchText=${encodeURIComponent(query)}`,
    suggestionEmail: (origin: string, query: string) =>
        `${origin}/suggest/email?searchText=${encodeURIComponent(query)}`,
    suggestionCorrespondence: (origin: string, query: string) =>
        `${origin}/suggest/correspondence?searchText=${encodeURIComponent(query)}`,
    suggestionNews: (origin: string, query: string) =>
        `${origin}/suggest/news?searchText=${encodeURIComponent(query)}`,
    suggestionTender: (origin: string, query: string) =>
        `${origin}/suggest/tender?searchText=${encodeURIComponent(query)}`,
    suggestionSupplier: (origin: string, query: string) =>
        `${origin}/suggest/suppliers?searchText=${encodeURIComponent(query)}`,
    suggestionCustomer: (origin: string, query: string) =>
        `${origin}/suggest/customers?searchText=${encodeURIComponent(query)}`,
    suggestionService: (origin: string, query: string) =>
        `${origin}/suggest/service-now?searchText=${encodeURIComponent(query)}`,
    suggestionProject: (origin: string, query: string) =>
        `${origin}/suggest/projects?searchText=${encodeURIComponent(query)}`,
    suggestionDistribution: (origin: string, query: string) =>
        `${origin}/suggest/distribution?searchText=${encodeURIComponent(query)}`,
    history: (origin: string) => `${origin}/history`,
};

const getSuggestionsRoutes = (
    origin: string,
    query: string
): Array<{type: SuggestionResponse; route: string; abortController: AbortController}> =>
    [
        {type: SuggestionResponse.Training, route: routes.suggestionTraining(origin, query)},
        {type: SuggestionResponse.Employee, route: routes.suggestionEmployee(origin, query)},
        {type: SuggestionResponse.Document, route: routes.suggestionDocument(origin, query)},
        {type: SuggestionResponse.Email, route: routes.suggestionEmail(origin, query)},
        {
            type: SuggestionResponse.Correspondence,
            route: routes.suggestionCorrespondence(origin, query),
        },
        {type: SuggestionResponse.News, route: routes.suggestionNews(origin, query)},
        {type: SuggestionResponse.Tender, route: routes.suggestionTender(origin, query)},
        {type: SuggestionResponse.Supplier, route: routes.suggestionSupplier(origin, query)},
        {type: SuggestionResponse.Customer, route: routes.suggestionCustomer(origin, query)},
        {type: SuggestionResponse.Service, route: routes.suggestionService(origin, query)},
        {type: SuggestionResponse.Project, route: routes.suggestionProject(origin, query)},
        {
            type: SuggestionResponse.Distribution,
            route: routes.suggestionDistribution(origin, query),
        },
    ].map(routes => ({...routes, abortController: new AbortController()}));

export abstract class SuggestionsAPICaller {
    static getSuggestions({
        apiURL,
        accessToken,
        query,
        responsesNumber,
    }: IGetSuggestions): Promise<IGetSuggestionResponse> {
        const suggestionsRoutes = getSuggestionsRoutes(apiURL, query).filter(({type}) =>
            isEntityEnabled(mapSuggestionsToEntity[type] as EntityType)
        );
        const result: IGetSuggestionResponse = {};

        // it is just simple iterator
        let suggestionCountLeft =
            suggestionsRoutes.length < responsesNumber ? suggestionsRoutes.length : responsesNumber;

        return new Promise((resolve, reject) => {
            suggestionsRoutes.forEach(({type, route, abortController}) => {
                fetchData(route, accessToken, 'GET', null, null, null, abortController.signal)
                    .then(data => {
                        // we expect array from any of Entities
                        if (!data) return;

                        result[type] = data;
                        suggestionCountLeft--;

                        /**
                         * if there are all needed suggestions in the result,
                         * then we abort another suggestions and return the result
                         */
                        if (suggestionCountLeft === 0) {
                            suggestionsRoutes.forEach(({abortController}) => {
                                if (!abortController.signal.aborted) {
                                    abortController.abort();
                                }
                            });
                            resolve(result);
                        }
                    })
                    .catch(e => {
                        console.error(e);
                    });
            });

            setTimeout(() => reject({}), 5000);
        });
    }

    static getHistory({apiURL, accessToken}: IGetHistory): Promise<IGetHistoryResponse> {
        return fetchData(routes.history(apiURL), accessToken, 'GET');
    }
}
