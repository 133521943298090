interface Config {
    skey_35: string;
    skey_36: string;
    skey_38: string;
    skey_43: string;
    skey_44: string;
    skey_41: string;
    skey_42?: string[];
    keys: {
        skey_47: string;
        skey_48: string;
    };
    skey_49: string;
    feature: any;
}

declare global {
    interface Window {
        SEARCH_APP: Config;
        ReactNativeWebView?: any;
        google?: any;
    }
}

export const appConfig: Config = window.SEARCH_APP;
