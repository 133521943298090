import React from 'react';
import {UserInfoPopup} from './userInfoPopup';
import informationCircle from 'app/static/images/informationCircle.svg';
import styles from '../servicePreviewPanel.module.scss';

export const userInfoRenderer = (name: any, data: any, key: any) => {
    const userInfo = data[`${key}User`];

    return (
        <div className={styles.userInfo}>
            {name ? name : 'N/A'}
            {!!(userInfo && userInfo.displayName) && (
                <UserInfoPopup user={userInfo}>
                    <img src={informationCircle} alt="info" />
                </UserInfoPopup>
            )}
        </div>
    );
};
