import React, {FC} from 'react';
import {useLocalizationContext} from 'app/hooks';

import styles from './OwnerInformation.module.scss';
import {Table} from 'app/views/components/table';

export const OwnerInformation: FC<{supplierOwners: any[]}> = ({supplierOwners}) => {
    const {t} = useLocalizationContext();
    return (
        <div className={styles.ownerInformation}>
            <header className={styles.ownerInformationTitle}>
                {t('suppliers.previewPanel.ownerInfo.header')}
            </header>
            <Table
                titles={[
                    t('suppliers.previewPanel.ownerInfo.name'),
                    t('suppliers.previewPanel.ownerInfo.nameEng'),
                    t('suppliers.previewPanel.ownerInfo.title'),
                    t('suppliers.previewPanel.ownerInfo.nationality'),
                    t('suppliers.previewPanel.ownerInfo.share'),
                ]}
                values={supplierOwners.map(s => [
                    s.partnerNameAr,
                    s.partnerNameEn,
                    <span className={styles.status}>{s.partnerTitle}</span>,
                    s.partnerNationality,
                    s.partnerSharePercentage,
                ])}
            />
        </div>
    );
};
