import {createActions} from 'redux-actions';

export const {
    fetchInitialFilters,
    fetchInitialFiltersSuccess,
    changeSingleFilter,
    resetSingleFilter,
    resetFilters,
} = createActions({
    FETCH_INITIAL_FILTERS: () => {},
    FETCH_INITIAL_FILTERS_SUCCESS: repository => repository,
    CHANGE_SINGLE_FILTER: payload => payload,
    RESET_SINGLE_FILTER: payload => payload,
    RESET_FILTERS: () => {},
});
