import React from 'react';
import {LocalizationFuncType} from 'app/contexts';
import {useLocalizationContext} from 'app/hooks';
import {getFullDate} from 'app/utils';
import styles from './documentProperties.module.scss';
import sharePointIcon from 'app/static/images/sharePoint.svg';
import oneDriveIcon from 'app/static/images/oneDrive.svg';
import {IDocumentPropertiesProps} from './documentProperties.types';

const transferBytesInKilobytes = (value: string) => {
    return value && String((+value / 1024).toFixed(2));
};

interface IRow {
    cellName: string;
    cellValue: string;
    imageOptions?: IPropertiesImage | null;
}

interface IPropertiesImage {
    src: string;
    classes?: string;
}
interface IPropertyDescriptor {
    propertyName: string;
    propertyValue: string;
    propertiesImage?: IPropertiesImage;
}

const prepareAuthorsValue = (value: string[]) => {
    return value?.[0] || '';
};

export const renderGroup = (propertiesToRender: IPropertyDescriptor[], t: LocalizationFuncType) => {
    const allPropertiesHaveNoValues = propertiesToRender.every(
        (e: IPropertyDescriptor) =>
            e.propertyValue === '' && (e.propertiesImage?.src === '' || true)
    );

    return !allPropertiesHaveNoValues ? (
        <div className={styles.properties}>
            {propertiesToRender.map((property: IPropertyDescriptor) => {
                const imageOptions = property.propertiesImage?.src
                    ? property.propertiesImage
                    : null;

                return (
                    <Row
                        cellName={t(`documents.documentProperties.${property.propertyName}`)}
                        cellValue={property.propertyValue}
                        imageOptions={imageOptions}
                        key={property.propertyName}
                    />
                );
            })}
        </div>
    ) : null;
};

const Row = ({cellName, cellValue, imageOptions}: IRow) => {
    const src = imageOptions?.src;
    const classes = imageOptions?.classes;

    if (cellValue || src) {
        return (
            <div className={styles.row}>
                <div className={styles.leftColumn}>
                    <span>{cellName}</span>
                </div>
                <div className={styles.rightColumn}>
                    {src ? (
                        <span className={classes} style={{backgroundImage: `url(${src})`}} />
                    ) : (
                        <span>{cellValue}</span>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

const getDocumentLocationIconByPath = (path: string) => {
    const documentPath = path.split('/')[3] || '';

    switch (documentPath) {
        case 'sites':
            return sharePointIcon;
        case 'personal':
            return oneDriveIcon;
        default:
            return '';
    }
};

export const DocumentProperties = ({
    location,
    size,
    fileType,
    MIMEType,
    created,
    modified,
    title,
    authors,
    language,
}: IDocumentPropertiesProps) => {
    const {t} = useLocalizationContext();

    return (
        <div className={styles.description}>
            {renderGroup(
                [
                    {
                        propertyName: 'preview',
                        propertyValue: '',
                    },
                ],
                t
            )}
            {renderGroup(
                [
                    {
                        propertyName: 'location',
                        propertyValue: '',
                        propertiesImage: {
                            src: getDocumentLocationIconByPath(location),
                            classes: styles.locationIcon,
                        },
                    },
                    {propertyName: 'url', propertyValue: location},
                    {
                        propertyName: 'size',
                        propertyValue: size
                            ? t('documents.documentProperties.kilobytes', {
                                  value: transferBytesInKilobytes(size),
                              })
                            : '',
                    },
                ],
                t
            )}

            {renderGroup(
                [
                    {propertyName: 'fileType', propertyValue: fileType},
                    {propertyName: 'MIMEType', propertyValue: MIMEType},
                ],
                t
            )}

            {renderGroup(
                [
                    {propertyName: 'created', propertyValue: getFullDate(created)},
                    {propertyName: 'modified', propertyValue: getFullDate(modified)},
                ],
                t
            )}

            {renderGroup(
                [
                    {propertyName: 'title', propertyValue: title},
                    {propertyName: 'author', propertyValue: prepareAuthorsValue(authors)},
                    {propertyName: 'language', propertyValue: language},
                ],
                t
            )}
        </div>
    );
};
