import {createActions} from 'redux-actions';
import {TrainingsSortOptions} from 'app/containers/trainings';

export const {
    searchTrainings,
    searchTrainingsSuccess,
    searchTrainingsFailure,
    setTrainingsSortBy,
    setTrainingsSortBySuccess,
    setTrainingsSortByFailure,
    resetTrainingsState,
    resetTrainingsItems,
    changeTrainingsSortBy,
    applyTrainingsFilters,
    resetTrainingsTab,
} = createActions({
    SEARCH_TRAININGS: (query: string) => query,
    SEARCH_TRAININGS_SUCCESS: (trainings: any) => trainings,
    SEARCH_TRAININGS_FAILURE: (error: any) => error,
    RESET_TRAININGS_STATE: () => {},
    RESET_TRAININGS_ITEMS: () => {},
    SET_TRAININGS_SORT_BY: (sortOption: TrainingsSortOptions) => sortOption,
    SET_TRAININGS_SORT_BY_SUCCESS: () => {},
    SET_TRAININGS_SORT_BY_FAILURE: (error: any) => error,
    CHANGE_TRAININGS_SORT_BY: (sortBy: TrainingsSortOptions) => sortBy,
    APPLY_TRAININGS_FILTERS: () => {},
    RESET_TRAININGS_TAB: () => {},
});
