import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {TicketTypes} from 'app/state/serviceNow';
import {useLocalizationContext} from 'app/hooks';
import {IService} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';

const mapTicketType = (type: string, t: (key: string) => string): string => {
    if (Object.values(TicketTypes).includes(type)) return t(`serviceNow.ticketType.${type}`);

    return type;
};

export const Service: FC<ISuggestionComponent<IService>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, description, searchString, category: ticketType, number, historyDate} = item;
    const {t} = useLocalizationContext();
    const _searchString = searchString?.length ? searchString : number;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_service, id, _searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.info}>
                {ticketType && (
                    <span className={styles.status}>{mapTicketType(ticketType, t)}</span>
                )}
                <span className={styles.reference}>{number}</span>
                <div className={styles.title}>{description}</div>
            </div>
        </div>
    );
};
