import {all, getContext, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {EntityType} from 'app/contexts';
import {isFilterSelected} from 'app/utils/filterUtils';
import {
    ISearchServicesOptions,
    IService,
} from 'app/api/searchAPI/services/searchAPICaller.services.types';

import {RootState} from 'app/state';
import {
    applyServiceFilters,
    getServiceDetails as getServiceDetailsAction,
    getServiceDetailsFailure,
    getServiceDetailsSuccess,
    resetServiceNowTab as resetServiceNowTabAction,
    resetServicesItems,
    resetServicesState,
    searchServices as searchServicesAction,
    searchServicesFailure,
    searchServicesSuccess,
} from './serviceNow.actions';
import {filtersSelector} from './serviceNow.selectors';
import {getSearchServicesOptions} from './serviceNow.operations';
import {IServiceItem} from './serviceNow.types';

import {searchQuerySelector} from '../router';
import {resetSingleFilter} from '../filter';

function* searchServices({payload}: ReturnType<typeof searchServicesAction>): any {
    const logger = yield getContext('logger');
    try {
        const state: RootState = yield select();
        const searchService = yield getContext('searchService');
        const filters = yield select(filtersSelector);

        const options: ISearchServicesOptions = getSearchServicesOptions(payload, state, filters);
        const servicesResponse: IService[] = yield searchService.services
            .get()
            .searchServices(options);
        const services: IServiceItem[] = servicesResponse.map(s => ({
            ...s,
            id: s.sysId,
        }));

        yield put(searchServicesSuccess(services));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchServicesFailure(error));
    }
}

export function* getServiceDetails({payload}: ReturnType<typeof getServiceDetailsAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');

        let details = {};
        const {category, sysId, number, ticketType} = payload;

        if (ticketType) {
            details = yield searchService.services.get().getServiceDetails({
                category,
                sysId,
                ticketNumber: number,
                ticketType,
            });
        } else {
            details = {
                ...payload,
                ticketType: category,
                state: false,
            };
        }

        yield put(getServiceDetailsSuccess({sysId, details}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getServiceDetailsFailure(error));
    }
}

function* applyFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetServicesItems());
        yield put(searchServicesAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetServiceNowTab(): any {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(filtersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Service}));
            yield put(resetServicesState());
            const query = yield select(searchQuerySelector);
            yield put(searchServicesAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* serviceNowSaga(): any {
    yield all([
        yield takeLatest(String(searchServicesAction), searchServices),
        yield takeLatest(String(applyServiceFilters), applyFilters),
        yield takeLatest(String(resetServiceNowTabAction), resetServiceNowTab),
        yield takeEvery(String(getServiceDetailsAction), getServiceDetails),
    ]);
}
