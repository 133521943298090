import some from 'lodash/some';
import {
    FilterType,
    IAllFilters,
    IFilterRefiner,
} from 'app/views/components/filterPanel/filterPanel.types';

export function isFilterSelected(filters: IFilterRefiner[]): boolean {
    return filters.some(({Entries}: IFilterRefiner) => some(Entries, 'isChecked'));
}

export const getResultFilters = (selectedFilters: IAllFilters[], filters: IFilterRefiner[]) =>
    filters.map(({Name, Entries, type}: IFilterRefiner) => ({
        Name,
        type,
        Entries: Entries.map(entry => {
            const currentEntry = selectedFilters.find(
                ({filter, refinementName, refinementValue}: IAllFilters) =>
                    filter === Name &&
                    entry.RefinementName === refinementName &&
                    entry.RefinementValue === refinementValue
            );

            /**
             * Force reset DateRange and TextField filters `value` because we keep the value of all filters
             * even after cleaning
             *
             * TODO: add "clear" callback to FilterPanel, dispatch clearing in store (not in FilterPanel component)
             */
            if (type === FilterType.DateRange || type === FilterType.TextField) {
                const isChecked = Boolean(currentEntry);
                return {
                    ...entry,
                    isChecked,
                    value: isChecked ? currentEntry?.value : null,
                };
            }

            return {
                ...entry,
                isChecked: Boolean(currentEntry),
                value: currentEntry ? currentEntry.value : entry.value,
            };
        }),
    }));
