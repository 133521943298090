import {ConsoleLogger} from 'app/infrastructure/logging';
import {ISessionStorageProtocol} from './SessionStorageProtocol.types';

export const SessionStorageProtocol: ISessionStorageProtocol = {
    logger: new ConsoleLogger(),
    getItem: (key: string) => {
        if (!key) {
            SessionStorageProtocol.logger.warn('key is required field');

            return false;
        }

        return sessionStorage.getItem(key);
    },
    setItem: (key: string, value: string) => {
        if (!key || !value) {
            SessionStorageProtocol.logger.warn('key and value are required fields');

            return false;
        }

        return sessionStorage.setItem(key, value);
    },
};
