import {inject, injectable} from 'inversify';
import {ISearchServiceNews} from './searchService.news.types';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {INews, ISearchNewsOptions} from 'app/api/searchAPI/news/searchAPICaller.news.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {SearchAPICallerNews} from 'app/api/searchAPI/news/searchAPICaller.news';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {createNewsFilters} from '../searchService.transformer';

@injectable()
export class SearchServiceNews implements ISearchServiceNews {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchNews(options: ISearchNewsOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerNews.searchNews(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse([]) as INews[];
                }
            }
        );
    }

    public async getNewsDetails(id: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return await SearchAPICallerNews.getNewsDetails(
                    {
                        id,
                    },
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse();
            }
        );
    }

    public async searchNewsFilters(): Promise<IFilterRefiner[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const companies = await SearchAPICallerNews.searchNewsCompanies(
                    appConfig.skey_38,
                    accessToken
                );
                return createNewsFilters({companies});
            },
            (e: any) => {
                this.logger.error(e);
                return [];
            }
        );
    }
}
