import {handleActions} from 'redux-actions';
import {EntityType} from 'app/contexts';
import {RepositoryFilters, RepositoryEntity, IFilterState} from './filter.types';

import {
    fetchInitialFiltersSuccess,
    changeSingleFilter,
    resetSingleFilter,
    resetFilters,
} from './filter.actions';
import {getResultFilters} from 'app/utils/filterUtils';

const initialRepository: RepositoryEntity = {
    initialFilters: null,
    filters: [],
    additionalData: null,
};

const initialState: IFilterState = {
    initialized: false,
    repository: {
        [EntityType.News]: {...initialRepository},
        [EntityType.Employee]: {...initialRepository},
        [EntityType.Email]: {...initialRepository},
        [EntityType.Tender]: {...initialRepository},
        [EntityType.Training]: {...initialRepository},
        [EntityType.Projects]: {...initialRepository},
        [EntityType.Service]: {...initialRepository},
        [EntityType.Distribution]: {...initialRepository},
    },
};

export default handleActions(
    {
        [String(fetchInitialFiltersSuccess)]: (state, {payload}: any) => ({
            ...state,
            initialized: true,
            repository: {
                ...state.repository,
                ...payload,
            },
        }),
        [String(changeSingleFilter)]: (
            state,
            {payload}: {payload: {key: RepositoryFilters; filters: any[]}}
        ) => ({
            ...state,
            repository: {
                ...state.repository,
                [payload.key]: {
                    ...state.repository[payload.key],
                    filters: getResultFilters(
                        payload.filters,
                        state.repository[payload.key].filters
                    ),
                },
            },
        }),
        [String(resetSingleFilter)]: (state, {payload}: {payload: {key: RepositoryFilters}}) => ({
            ...state,
            repository: {
                ...state.repository,
                [payload.key]: {
                    ...state.repository[payload.key],
                    filters: state.repository[payload.key].initialFilters,
                },
            },
        }),
        [String(resetFilters)]: state => {
            const repository = state.repository;

            Object.keys(repository).forEach(value => {
                const key = value as RepositoryFilters;
                if (repository[key] && repository[key].initialFilters) {
                    repository[key].filters = repository[key]
                        .initialFilters as RepositoryEntity['filters'];
                }
            });

            if (repository[EntityType.News].initialFilters) {
                repository[EntityType.News].filters = repository[EntityType.News]
                    .initialFilters as any;
            }
            return {
                ...state,
                repository,
            };
        },
    },
    initialState
);
