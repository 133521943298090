import {assignIn, camelCase, map} from 'lodash';
import {RootState} from 'app/state';
import {
    IELearningSelectedFilters,
    IFilter,
    IFilterRefiner,
} from 'app/views/components/filterPanel/filterPanel.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';

const generateFilters = (filters: IFilterRefiner[]): IELearningSelectedFilters => {
    const selectedFilters = {};

    map(filters, filter => {
        assignIn(selectedFilters, {
            [camelCase(filter.Name)]: filter.Entries.reduce(
                (acc: string[], entry: IFilter) =>
                    entry.isChecked ? [...acc, entry.RefinementValue] : acc,
                []
            ),
        });
    });

    return selectedFilters;
};

export const getSearchTrainingsOptions = (
    query: string,
    state: RootState,
    filters: IFilterRefiner[]
) => {
    const perPage = DEFAULT_PAGE_SIZE;
    const nextPage = state.trainings.items.length / DEFAULT_PAGE_SIZE;
    const sortBy = state.trainings.sortBy;
    const generatedFilters = generateFilters(filters);

    return {
        perPage,
        query,
        filter: generatedFilters,
        orderBy: sortBy,
        page: nextPage,
    };
};
