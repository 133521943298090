import {ISearchProjectResponse, ISearchServiceProjects} from './searchService.projects.types';
import {
    IProject,
    ISearchProjectsOptions,
} from 'app/api/searchAPI/projects/searchAPICaller.projects.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {SearchAPICallerProjects} from 'app/api/searchAPI/projects/searchAPICaller.projects';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {createProjectFilters} from '../searchService.transformer';
import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';

@injectable()
export class SearchServiceProjects implements ISearchServiceProjects {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchProjects(options: ISearchProjectsOptions): Promise<ISearchProjectResponse> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerProjects.searchProjects(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse({count: 0, projects: []});
                }
            }
        );
    }

    public async searchProjectFilters(): Promise<IFilterRefiner[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const filters = await SearchAPICallerProjects.searchProjectFilters(
                    appConfig.skey_38,
                    accessToken
                );
                return createProjectFilters(filters);
            },
            (e: any) => {
                this.logger.error(e);
                return [];
            }
        );
    }

    public async getProjectDetails(projectId: string): Promise<IProject> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerProjects.getProjectDetails(
                    projectId,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse();
            }
        );
    }
}
