import React, {FC} from 'react';
import {ServiceListItem} from 'app/views/services/components/serviceListItem';
import {ServiceListItemSkeleton} from 'app/views/services/components/serviceListItemSkeleton';
import {List} from 'app/views/components/list';
import {IServiceListProps} from './serviceList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const ServiceList: FC<IServiceListProps> = ({
    services,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}) => {
    return (
        <>
            {isLoading ? (
                <List
                    key="skeleton"
                    itemComponentRender={ServiceListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    key="list"
                    itemComponentRender={ServiceListItem}
                    itemsData={services}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    loadMoreHandler={loadMoreHandler}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
