export interface IServiceItem {
    id: string;
    sysId: string;
    ticketType?: string;
    category: string;
    created?: string;
    number?: string;
    summary?: string;
    description: string;
    priority?: string;
    urgency?: string;
    groupCompany: string;
    state?: string;
    shortDescription?: string;
    details?: any;
}

export enum TicketTypes {
    'sn_hr_core_case',
    'incident',
    'change_request',
    'change_task',
    'problem',
    'sc_req_item',
    'sc_task',
    'facilities_request',
}
