import {EntityType} from 'app/contexts';
import {RootState} from '../store';

export const peopleItemsSelector = (state: RootState) => state.people.items;
export const peopleSkipAmountSelector = (state: RootState) => state.people.skipAmount;
export const arePeopleLoadingSelector = (state: RootState) => state.people.isLoading;
export const isEmployeeProfileLoadingSelector = (state: RootState) =>
    state.people.isLoadingEmployeeProfile;
export const employeeProfileSelector = (state: RootState) => state.people.employeeProfile;
export const peopleErrorsSelector = (state: RootState) => state.people.hasErrors;
export const peopleErrorCodeSelector = (state: RootState) => state.people.hasErrorCode;
export const areMorePeopleResultsAvailableSelector = (state: RootState) =>
    state.people.areMoreResultsAvailable;
export const peopleFiltersSelector = ({filter: {repository}}: RootState) =>
    repository[EntityType.Employee].filters;
export const selectEmployeeActivity = (state: RootState) => state.people.employeeActivity;
export const selectIsActivityLoading = (state: RootState) => state.people.isActivityLoading;
