import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {useChannelContext, useLocalizationContext} from 'app/hooks';
import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';
import {EntityType} from 'app/contexts';
import {
    applyEmployeeFilters,
    areMorePeopleResultsAvailableSelector,
    arePeopleLoadingSelector,
    employeeProfileSelector,
    IEmployeeProfile,
    IPeopleItem,
    peopleErrorCodeSelector,
    peopleFiltersSelector,
    peopleItemsSelector,
    resetEmployeeTab,
    resetPeopleState,
    searchPeople,
} from 'app/state/people';
import {changeSingleFilter} from 'app/state/filter';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {PeopleListItem} from 'app/views/people/components/peopleListItem/peopleListItem';
import {PeopleListItemSkeleton} from 'app/views/people/components/peopleListItemSkeleton/peopleListItemSkeleton';
import {PeoplePreviewPanel} from 'app/views/people/components/peoplePreviewPanel';
import {PeopleFiltersOptions} from './people.types';
import noResultsEmployees from 'app/static/images/noResultsEmployees.svg';
import peopleLoading from 'app/static/images/people.svg';
import {mapEmployeeToProfileFormat} from 'app/state/people/people.utils';
import {searchSameValueSelector} from '../../state/search/search.selectors';
// import {selectMyProfileUserPrincipalName} from 'app/state/myProfile/myProfile.selectors';

export const PeopleContainer = () => {
    const {usePreviewItem, onEmployeeHintShow} = useChannelContext();
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();

    const searchQuery: string = useSelector(searchQuerySelector);
    const searchWords: string[] = useSelector(searchWordsSelector);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    // const userPrincipalName = useSelector(selectMyProfileUserPrincipalName);
    const selectedProfile = useSelector(employeeProfileSelector);
    const isLoading: boolean = useSelector(arePeopleLoadingSelector);
    const items: IPeopleItem[] = useSelector(peopleItemsSelector);
    const areMoreResultsAvailable: boolean = useSelector(areMorePeopleResultsAvailableSelector);
    const filters = useSelector(peopleFiltersSelector);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(peopleErrorCodeSelector);

    const selectedItem: IPeopleItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        onEmployeeHintShow({isVisible: true});
        return () => {
            onEmployeeHintShow({isVisible: false});
        };
    }, [onEmployeeHintShow]);

    useEffect(() => {
        dispatch(resetPeopleState());
        dispatch(searchPeople(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetEmployeeTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Employee,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!isLoading && areMoreResultsAvailable) {
            dispatch(searchPeople(searchQuery));
        }
    }, [isLoading, dispatch, searchQuery, areMoreResultsAvailable]);

    // TODO Need remove
    // useEffect(() => {
    //     if (selectedItem) {
    //         dispatch(
    //             loadEmployeeProfile({
    //                 id: selectedItem.id,
    //                 email: selectedItem.workEmail,
    //             })
    //         );
    //
    //         if (userPrincipalName && userPrincipalName === selectedItem.workEmail) {
    //             dispatch(loadEmployeeActivity());
    //         } else {
    //             dispatch(clearEmployeeActivity());
    //         }
    //     } else {
    //         dispatch(clearEmployeeActivity());
    //     }
    // }, [dispatch, userPrincipalName, selectedItem]);

    const onSelect = useCallback(
        (item: IPeopleItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: item.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    /**
     * Removed query-syntax special symbols
     * https://docs.microsoft.com/en-us/azure/search/query-simple-syntax
     */
    const msQuerySearchWords = useMemo<string[]>(() => {
        return searchWords.map(word => word.replace(/[-+|"()'\\~*]/g, ''));
    }, [searchWords]);

    const list = {
        itemComponentRender: PeopleListItem,
        itemSkeletonRender: PeopleListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords: msQuerySearchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    const filterLabelMapper = (filter: string): string => {
        if (filter === PeopleFiltersOptions.Companies) {
            return t('people.peopleFilters.filterCompany');
        }
        if (filter === PeopleFiltersOptions.JobTitles) {
            return t('people.peopleFilters.filterJob');
        }
        if (filter === PeopleFiltersOptions.ExactMatch) {
            return t('components.filterPanel.exactSwitcherLabel');
        }
        return t(filter);
    };

    const onApplyFilters = useCallback(
        selectedFilters => {
            dispatch(
                changeSingleFilter({
                    key: EntityType.Employee,
                    filters: selectedFilters,
                })
            );
            dispatch(applyEmployeeFilters());
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch]
    );

    const filter = {
        filterProps: filters.filter(f => f.Entries.length),
        filterLabelMapper,
        onApplyFilters,
    };

    /**
     * ADNOCOVE-845 Basic view of the employee's profile card on the Employees tab
     */
    const fallbackProfile = useMemo<IEmployeeProfile | null>(() => {
        return selectedItem && mapEmployeeToProfileFormat(selectedItem);
    }, [selectedItem]);

    return (
        <SearchResultLayout
            isLoading={isLoading}
            list={list}
            filter={filter}
            selectedItem={selectedItem ? selectedProfile || fallbackProfile : undefined}
            previewComponent={PeoplePreviewPanel}
            noResultImageUrl={noResultsEmployees}
            chooseItemImageUrl={peopleLoading}
            translateRoot={'people'}
        />
    );
};
