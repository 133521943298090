import {EntityType} from '../common.types';
import {AppMode, MobileParams} from './appContext.types';
import {asEnumValue} from 'app/utils';
import {routeParams} from 'app/routes';
import MobileDetect from 'mobile-detect';

export default function parse(queryString: string): IInitParams {
    const searchParams = new URLSearchParams(queryString);
    const appMode: AppMode =
        asEnumValue<AppMode>(searchParams.get(routeParams.MODE), Object.values(AppMode)) ||
        AppMode.FullScreen;
    const query = searchParams.get(routeParams.QUERY) || undefined;
    const token = searchParams.get(routeParams.TOKEN) || undefined;

    // TODO: remove unnesesary
    const entityType: EntityType | undefined = asEnumValue<EntityType>(
        searchParams.get('entitytype'),
        Object.values(EntityType)
    );
    // TODO: remove unnesesary
    const entityID = searchParams.get('entityid') || undefined;
    const md = new MobileDetect(window.navigator.userAgent);
    const mobile = md.mobile();

    const params: IInitParams = {
        mode: appMode,
        query: query,
        entityType: entityType,
        entityID: entityID,
        token: token,
        mobileParams: {
            mobile: mobile,
        },
    };

    return params;
}

export interface IInitParams {
    mode: AppMode;
    query?: string;
    token?: string;
    entityType?: EntityType;
    entityID?: string;
    mobileParams: MobileParams;
}
