import {createActions} from 'redux-actions';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {DocumentsSortOptions} from 'app/containers/documents';

export const {
    searchDocuments,
    resetDocumentsState,
    resetDocumentsItems,
    searchDocumentsSuccess,
    searchDocumentsFailure,
    setDocumentsSortBy,
    setDocumentsSortByError,
    setDocumentsFilters,
    setDocumentsFiltersError,
    resetDocumentsTab,
} = createActions({
    SEARCH_DOCUMENTS: (query: string, startFrom: number = 0, isLoadingMore) => ({
        query,
        startFrom,
        isLoadingMore,
    }),
    RESET_DOCUMENTS_STATE: () => {},
    RESET_DOCUMENTS_ITEMS: () => {},
    SEARCH_DOCUMENTS_SUCCESS: (result: any) => result,
    SEARCH_DOCUMENTS_FAILURE: (error: any) => error,
    SET_DOCUMENTS_SORT_BY: (sortOption: DocumentsSortOptions) => sortOption,
    SET_DOCUMENTS_SORT_BY_ERROR: () => {},
    SET_DOCUMENTS_FILTERS: (resultFilters: IFilterRefiner[]) => resultFilters,
    SET_DOCUMENTS_FILTERS_ERROR: () => {},
    RESET_DOCUMENTS_TAB: () => {},
});
