import {IAuthProvider} from 'app/infrastructure/auth';
import {IoC} from 'app/ioc';
import {inject, injectable} from 'inversify';
import {SearchAPICaller} from 'app/api/searchAPI/searchAPICaller';
import {OneHubAPICaller} from 'app/api/oneHubAPI/oneHubAPICaller';
import {ISearchService} from './searchService.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {ILogger} from '../logging';
import {appConfig} from 'app/config';

import {ResponseError} from '../../utils/responseError';

@injectable()
export class SearchService implements ISearchService {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchOneHubEmployeesPhotos(email: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return OneHubAPICaller.getEmployeeAvatar({
                    email,
                    accessToken,
                    apiURL: appConfig.skey_43,
                });
            },
            (e: any) => {
                this.logger.error(e);
                return '';
            }
        );
    }

    public async getLightApps() {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return await SearchAPICaller.apiGetLightApps({
                    apiURL: appConfig.skey_43,
                    accessToken,
                });
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse({items: []});
                }
            }
        );
    }

    public async getOneHubAuthToken() {
        return await this.authProvider.getAccessToken();
    }

    public async fetchMyProfile() {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                return await SearchAPICaller.apiGetMyProfile({
                    apiURL: appConfig.skey_43,
                    accessToken,
                });
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse();
                }
            }
        );
    }
}
