import React from 'react';
import {ProjectsListItem} from 'app/views/projects/components/projectsListItem';
import {ProjectsListItemSkeleton} from 'app/views/projects/components/projectsListItemSkeleton';
import {List} from 'app/views/components/list';
import {IProjectsListProps} from 'app/views/projects/components/projectsList/projectsList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const ProjectsList = ({
    projects,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: IProjectsListProps) => {
    return (
        <>
            {isLoading ? (
                <List
                    itemComponentRender={ProjectsListItemSkeleton}
                    itemsData={skeletons}
                    searchWords={[]}
                />
            ) : (
                <List
                    itemComponentRender={ProjectsListItem}
                    itemsData={projects}
                    selectedItemId={selectedItemId}
                    onSelect={onSelect}
                    loadMoreHandler={loadMoreHandler}
                    searchWords={searchWords}
                />
            )}
        </>
    );
};
