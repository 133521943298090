import React, {forwardRef, PropsWithChildren} from 'react';
import classnames from 'classnames';
import styles from './previewContentLayout.module.scss';
import {PreviewContentLayoutProps} from './previewContentLayout.types';

export const PreviewContentLayout = forwardRef<
    HTMLDivElement,
    PropsWithChildren<PreviewContentLayoutProps>
>(({children, layoutAttributes = {}}, ref) => {
    return (
        <div className={styles.layoutWrapper}>
            <div
                {...layoutAttributes}
                className={classnames(styles.layout, layoutAttributes.className)}
                ref={ref}
            >
                {children}
            </div>
        </div>
    );
});
