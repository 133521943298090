import React from 'react';
import styles from './smallCard.module.scss';
export const SmallCard = ({title, data, total, className}: any) => {
    return (
        <div className={className}>
            <div className={styles.title}>{title}</div>
            <div className={styles.container}>
                {data.map(
                    ({name, unitName, value}: {name: string; unitName: string; value: string}) => (
                        <div key={name} className={styles.row}>
                            <div className={styles.label}>{name}</div>
                            <div className={styles.unit}>{unitName && unitName}</div>
                            <div className={styles.value}>{value}</div>
                        </div>
                    )
                )}
            </div>
            {total && (
                <div className={styles.total}>
                    <div className={styles.label}>{total.label}</div>
                    <div className={styles.value}>{total.value}</div>
                </div>
            )}
        </div>
    );
};
