import * as Msal from '@azure/msal-browser';
import {IoC} from 'app/ioc';
import {IAuthProvider} from './authProvider.types';
import {ILogger} from 'app/infrastructure/logging';
import {inject, injectable} from 'inversify';
import {appScopes, authConfig} from './authProviderConfig';
import {parseJWT} from '../../utils/parseJWT';

@injectable()
class AuthProvider implements IAuthProvider {
    @inject(IoC.Logger) private readonly logger!: ILogger;
    private accessToken: string | undefined;
    private authContext: Msal.PublicClientApplication;
    // Because TS wants NodeJS timeouts.
    private timeouts: any[] = [];

    constructor() {
        this.authContext = new Msal.PublicClientApplication(authConfig);
    }

    public init(): Promise<Msal.AuthenticationResult | null> {
        return this.authContext.handleRedirectPromise();
    }

    public getAccessToken(): Promise<string> {
        if (!this.accessToken) {
            return this.login();
        }

        try {
            const email = parseJWT(this.accessToken)?.email;
            if (email) {
                this.logger.setUserEmail(email);
            }
        } catch (e) {
            console.error(e);
        }

        return Promise.resolve(this.accessToken);
    }

    setAccessToken(token: string) {
        return token;
    }

    public isLoggedIn(): boolean {
        return !!this.getAccount();
    }

    public loginForIdToken(): Promise<void> {
        return new Promise(resolve => {
            if (this.isLoggedIn()) {
                resolve();
            } else {
                this.login();
            }
        });
    }

    private getAccount(): Msal.AccountInfo {
        const currentAccounts = this.authContext.getAllAccounts();
        return currentAccounts[0];
    }

    private login(): Promise<string> {
        let context = this.authContext;
        let authenticationParameters = {
            scopes: appScopes,
        };

        const account = this.getAccount();

        return new Promise(resolve => {
            if (account) {
                context
                    .acquireTokenSilent({
                        ...authenticationParameters,
                        account,
                    })
                    .then((response: Msal.AuthenticationResult) => {
                        if (response.accessToken) {
                            this.setReloginTimeout(response);
                            this.accessToken = response.accessToken;

                            try {
                                const email = parseJWT(response.accessToken)?.email;
                                if (email) {
                                    this.logger.setUserEmail(email);
                                }
                            } catch (e) {
                                console.error(e);
                            }

                            resolve(response.accessToken);
                        }
                    })
                    .catch(() => {
                        return context.acquireTokenRedirect(authenticationParameters);
                    });
            } else {
                context.loginRedirect(authenticationParameters);
            }
        });
    }

    private setReloginTimeout({expiresOn}: Msal.AuthenticationResult) {
        if (!expiresOn) return;
        const minuteMilliseconds = 1000 * 60;
        const expiresIn = expiresOn.valueOf() - Date.now() - minuteMilliseconds;

        this.timeouts.push(
            setTimeout(() => {
                this.timeouts.forEach(clearTimeout);
                this.timeouts.length = 0;

                try {
                    this.login();
                } catch (error) {
                    this.logger.error(error);
                }
            }, expiresIn)
        );
    }
}

export default AuthProvider;
