import {inject, injectable} from 'inversify';
import {
    IGetCorrespondenceAttachments,
    IGetCorrespondenceTracking,
    ISearchServiceCorrespondence,
    ITransformedCorrespondenceResponse,
} from './searchService.correspondence.types';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {appConfig} from 'app/config';
import {execute, hasErrorsResponse} from 'app/utils';
import {SearchAPICallerCorrespondence} from 'app/api/searchAPI/correspondence/searchAPICaller.correspondence';
import {IFilter} from '../searchService.types';
import {
    correspondenceAttachmentsTransformer,
    correspondenceTrackingTransformer,
    getSearchCorrespondenceOptions,
    searchCorrespondenceResultsTransformer,
} from '../searchService.transformer';
import {ResponseError} from 'app/utils/responseError';
import {HTTPS_STATUS_CODES} from 'app/constants';

@injectable()
export class SearchServiceCorrespondence implements ISearchServiceCorrespondence {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async getCorrespondenceDocument(id: string, fileName: string, isPreview?: boolean) {
        let url = `${appConfig.skey_38}/correspondence/attachment/${id}/download?name=${fileName}&isMain=true&isFullScreen=false`;
        if (isPreview) {
            // TODO Check isPreview param
            url = `${appConfig.skey_38}/correspondence/attachment/${id}/preview?name=${fileName}&isMain=true&isFullScreen=false`;
        }

        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const response = await SearchAPICallerCorrespondence.getCorrespondenceDocument(
                    url,
                    accessToken
                );

                if (response.status !== HTTPS_STATUS_CODES.OK) {
                    return response;
                }

                const arrayBuffer = await response.arrayBuffer();

                return {
                    status: HTTPS_STATUS_CODES.OK,
                    arrayBuffer,
                    hasErrors: false,
                };
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse({arrayBuffer: ''});
            }
        );
    }

    public async getCorrespondenceAttachmentLinkPreview(
        id: string,
        name?: string,
        isFullScreen?: boolean,
        isMain: boolean = true
    ) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                const url = await SearchAPICallerCorrespondence.getCorrespondenceAttachmentLinkPreview(
                    {
                        isMain,
                        id,
                        name,
                        isFullScreen,
                    },
                    accessToken,
                    appConfig.skey_38
                );

                return {
                    url,
                    hasErrors: false,
                };
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse({url: ''});
            }
        );
    }

    public async getCorrespondenceAttachmentLinkDownload(
        id: string,
        name?: string,
        isFullScreen?: boolean,
        isMain: boolean = true
    ) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();

                const url = await SearchAPICallerCorrespondence.getCorrespondenceAttachmentLinkDownload(
                    {
                        isMain,
                        id,
                        name,
                        isFullScreen,
                    },
                    accessToken,
                    appConfig.skey_38
                );

                return {
                    url,
                    hasErrors: false,
                };
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse({url: ''});
            }
        );
    }

    public async getCorrespondenceAttachment(id: string, fileName: string) {
        const accessToken = await this.authProvider.getAccessToken();
        let url = `${appConfig.skey_38}/correspondence/attachment/${id}/download?isMain=false&isFullScreen=false&name=${fileName}`;
        return SearchAPICallerCorrespondence.getCorrespondenceDocument(url, accessToken);
    }

    public async searchCorrespondence(query: string, itemsCount: number, filters: IFilter[]) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const options = getSearchCorrespondenceOptions(query, itemsCount, filters);
                const fetchCorrespondence = await SearchAPICallerCorrespondence.searchCorrespondence(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
                return searchCorrespondenceResultsTransformer(fetchCorrespondence, itemsCount);
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse({
                        areMoreResultsAvailable: false,
                        items: [],
                    }) as ITransformedCorrespondenceResponse;
                }
            }
        );
    }

    public async getCorrespondenceAttachments(id: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const attachments = await SearchAPICallerCorrespondence.getCorrespondenceAttachments(
                    Number(id),
                    appConfig.skey_38,
                    accessToken
                );

                return {
                    id,
                    attachments: correspondenceAttachmentsTransformer(attachments),
                    hasErrors: false,
                } as IGetCorrespondenceAttachments;
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse({
                    id,
                    attachments: [],
                }) as IGetCorrespondenceAttachments;
            }
        );
    }

    public async getCorrespondenceTracking(id: string) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const tracking = await SearchAPICallerCorrespondence.getCorrespondenceTracking(
                    Number(id),
                    appConfig.skey_38,
                    accessToken
                );

                return {
                    id,
                    tracking: correspondenceTrackingTransformer(tracking),
                    hasError: false,
                } as IGetCorrespondenceTracking;
            },
            (e: any) => {
                this.logger.error(e);
                return hasErrorsResponse({
                    id,
                    tracking: [],
                }) as IGetCorrespondenceTracking;
            }
        );
    }
}
