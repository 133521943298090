import React from 'react';
import classNames from 'classnames';
import styles from './requestTimeoutError.module.scss';
import {useLocalizationContext} from 'app/hooks';

export const RequestTimeoutError = (className: {className?: string}) => {
    const {t} = useLocalizationContext();
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.image} />
            <div className={styles.title}>{t('components.errors.timeout.title')}</div>
            <div className={styles.description}>{t('components.errors.timeout.description')}</div>
        </div>
    );
};
