import React from 'react';
import {SmallCard} from './smallCard';
import {useLocalizationContext} from '../../../../../hooks';
import {toMillions} from '../../../../../utils';

export const RevenueCard = ({
    className,
    type,
    period,
    currentPeriod,
    previousPeriod,
}: {
    className?: string;
    type: string;
    period: string;
    currentPeriod: any;
    previousPeriod: any;
}) => {
    const {t} = useLocalizationContext();

    let currency = '';
    let variance = 'N/A / N/A';
    let curRevenueFormatted = 'N/A';
    let variancePercentage = 'N/A';
    let prevRevenueFormatted = 'N/A';

    if (currentPeriod && previousPeriod && type) {
        const curRevenue = currentPeriod[type];
        const prevRevenue = previousPeriod[type];

        if (+curRevenue >= 0) {
            currency = '$';
            curRevenueFormatted = `${toMillions(curRevenue)}M`;
        }

        if (+prevRevenue >= 0) {
            prevRevenueFormatted = `${toMillions(prevRevenue)}M${currency}`;
        }

        if (curRevenue && prevRevenue && +prevRevenue > 0) {
            const varianceValue = curRevenue - prevRevenue;

            variancePercentage = `${Math.abs(Math.round((100 / prevRevenue) * varianceValue))}%`;
            variance = `${toMillions(varianceValue)}M$ / ${variancePercentage}`;
        }
    }

    const additionalData = [
        {
            name:
                period === 'month' ? previousPeriod?.monthYearValue : previousPeriod?.quarterLabel,
            value: prevRevenueFormatted,
        },
        {
            name: t('distribution.preview.variance'),
            value: variance,
        },
    ];

    return (
        <SmallCard
            className={className}
            title={t(`distribution.preview.${type}`)}
            subtitle={
                period === 'month' ? currentPeriod?.monthYearValue : currentPeriod?.quarterLabel
            }
            total={curRevenueFormatted}
            currency={currency}
            data={additionalData}
            type={type}
        />
    );
};
