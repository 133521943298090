import {detect} from 'detect-browser';

export const checkIfIOS = () => {
    const browserInfo = detect();

    return (
        browserInfo?.name === 'ios' ||
        browserInfo?.name === 'ios-webview' ||
        browserInfo?.os === 'iOS'
    );
};
