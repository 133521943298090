import React from 'react';
import {Avatar} from '@material-ui/core';
import {ITrainingsThumbnailProps} from 'app/views/trainings/components/trainingsThumbnail/trainingsThumbnail.types';

import defaultThumbnail1 from 'app/static/images/tranings_no_image_1.svg';
import defaultThumbnail2 from 'app/static/images/tranings_no_image_2.svg';
import defaultThumbnail3 from 'app/static/images/tranings_no_image_3.svg';

export const TrainingsAvatarSource = {
    defaultThumbnail1,
    defaultThumbnail2,
    defaultThumbnail3,
};

export const TrainingsThumbnail = ({alt, src, className}: ITrainingsThumbnailProps) => (
    <Avatar variant="rounded" alt={alt} src={src} className={className} />
);
