import React, {useEffect, useState} from 'react';
import styles from 'app/views/components/filterPanel/styles/filterPanel.module.scss';
import {DatePicker} from '@one-hub/onehub-web-lib';
import {useLocalizationContext} from 'app/hooks';
import {
    FilterRangeValue,
    IFilterDatesRangeProps,
} from 'app/views/components/filterPanel/filterDatesRange/filterDatesRange.types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const FilterDatesRange = ({
    filter,
    getFilterDateValue,
    setFilterDateValue,
    arrowIconsStyles,
    toggle,
    checkFilterIsActiveToggle,
    filterLabelMapper,
}: IFilterDatesRangeProps) => {
    const {t} = useLocalizationContext();
    const [min, setMin] = useState<number | null>(null);
    const [max, setMax] = useState<number | null>(null);

    useEffect(() => {
        const currentMin = getFilterDateValue(filter.Name, FilterRangeValue.Min);
        const currentMax = getFilterDateValue(filter.Name, FilterRangeValue.Max);

        setMin(currentMin);
        setMax(currentMax);
    }, [filter.Entries, filter.Name, getFilterDateValue]);

    const isFilterOpen = checkFilterIsActiveToggle(filter.Name);

    return (
        <div className={styles.datePickerFilter}>
            <span onClick={() => toggle(filter.Name)} className={styles.property}>
                {filterLabelMapper ? filterLabelMapper(filter.Name) : t(filter.Name)}
                {isFilterOpen ? (
                    <ExpandLessIcon style={arrowIconsStyles} />
                ) : (
                    <ExpandMoreIcon style={arrowIconsStyles} />
                )}
            </span>
            {isFilterOpen &&
                filter.Entries.map(entry => (
                    <div key={entry.RefinementName} className={styles.datePickerEntry}>
                        <span className={styles.datePickerEntryLabel}>{entry.RefinementName}</span>
                        <DatePicker
                            getDateValue={() =>
                                getFilterDateValue(filter.Name, entry.RefinementValue)
                            }
                            onSelect={date => setFilterDateValue(filter, entry, date)}
                            shouldSelectEndOfDay={entry.RefinementValue === FilterRangeValue.Max}
                            minDate={
                                (entry.RefinementValue === FilterRangeValue.Max && min) || null
                            }
                            maxDate={
                                (entry.RefinementValue === FilterRangeValue.Min && max) || null
                            }
                        />
                    </div>
                ))}
        </div>
    );
};
