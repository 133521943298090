import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {LineChart} from './lineChart';
import styles from './trend.module.scss';
import {numberFormatter} from '../../../../../utils';
import classNames from 'classnames';
import {useMediaQuery} from '@material-ui/core';
import scssVariables from 'app/variables.module.scss';

export const CustomerInteractionsTrend = ({
    className,
    width,
    data,
    labels,
}: {
    className?: string;
    width: number;
    data: any[];
    labels: string[];
}) => {
    const {t} = useLocalizationContext();

    const chartData = [
        {
            label: 'Enquiry',
            values: data.map(d => d.enquiresCount),
        },
        {
            label: 'Suggestion',
            values: data.map(d => d.totalSuggestions),
        },
        {
            label: 'Compliant',
            values: data.map(d => d.totalComplaints),
        },
    ];

    const isSmall = useMediaQuery(`(${scssVariables.mediaSmall.slice(1, -1)})`);
    const isExtraSmall = useMediaQuery(`(${scssVariables.mediaExtraSmall.slice(1, -1)})`);
    let height = isSmall ? 224 : 240;
    if (isExtraSmall) {
        height = 224;
    }

    return (
        <div className={className}>
            <div className={styles.trendHeader}>
                <h3 className={styles.trendTitle}>
                    {t('distribution.preview.customerInteractions')}
                </h3>
            </div>
            <LineChart
                colorIndexes={[0, 4, 2]}
                className={classNames(styles.lineChart, styles.center)}
                labels={labels}
                formatter={numberFormatter}
                height={height}
                dataLabelsVisible={false}
                data={chartData}
            />
        </div>
    );
};
