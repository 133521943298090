import {Container} from 'inversify';

export class DIContainerConfig {
    private init: (container: IDIContainer) => void;

    constructor(initFn: (container: IDIContainer) => void) {
        this.init = initFn;
    }

    apply(container: IDIContainer) {
        this.init(container);
    }
}

export const IoC = {
    SearchService: Symbol.for('SearchService'),
    SearchServiceELearning: Symbol.for('SearchServiceELearning'),
    SearchServiceDocuments: Symbol.for('SearchServiceDocuments'),
    SearchServiceMails: Symbol.for('SearchServiceMails'),
    SearchServiceCorrespondence: Symbol.for('SearchServiceCorrespondence'),
    SearchServiceDistributions: Symbol.for('SearchServiceDistributions'),
    SearchServiceEmployee: Symbol.for('SearchServiceEmployee'),
    SearchServiceNews: Symbol.for('SearchServiceNews'),
    SearchServiceTenders: Symbol.for('SearchServiceTenders'),
    SearchServiceProjects: Symbol.for('SearchServiceProjects'),
    SearchServiceSuppliers: Symbol.for('SearchServiceSuppliers'),
    SearchServiceCustomers: Symbol.for('SearchServiceCustomers'),
    SearchServiceServices: Symbol.for('SearchServiceServices'),
    SuggestionsService: Symbol.for('SuggestionsService'),
    AuthProvider: Symbol.for('AuthProvider'),
    Logger: Symbol.for('Logger'),
    HistoryService: Symbol.for('HistoryService'),
};

export interface IDIContainer {
    init(config: DIContainerConfig): void;

    bind<I>(key: symbol, to: new () => I, scope?: Scope): void;

    bindToValue<T>(key: symbol, instance: T): void;

    get<T>(key: symbol): T;
}

export enum Scope {
    Singleton,
    Transient,
}

class DIContainer implements IDIContainer {
    private container = new Container();
    private initialized: boolean = false;

    bind<T>(key: symbol, to: new () => T, scope: Scope = Scope.Transient): void {
        this.execute(() => {
            switch (scope) {
                case Scope.Transient:
                    this.container.bind<T>(key).to(to).inTransientScope();
                    break;
                case Scope.Singleton:
                    this.container.bind<T>(key).to(to).inSingletonScope();
                    break;
            }
        });
    }

    bindToValue<T>(key: symbol, instance: T) {
        this.execute(() => {
            this.container.bind<T>(key).toConstantValue(instance);
        });
    }

    get<T>(key: symbol): T {
        return this.container.get<T>(key);
    }

    init(config: DIContainerConfig) {
        config.apply(this);
        this.initialized = true;
    }

    private execute(fn: Function) {
        if (this.initialized) {
            throw new Error('DI container was initialized.');
        }
        fn();
    }
}

export const container = new DIContainer();
