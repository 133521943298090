import React, {useState, useEffect} from 'react';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import MTabs from '@material-ui/core/Tabs';
import MTab from '@material-ui/core/Tab';
import scssVariables from 'app/variables.module.scss';

import {ITabsProps} from './tabs.types';
import styles from './tabs.module.scss';

function a11yProps(index: number, name: string) {
    return {
        id: `${name}-${index}`,
        'aria-controls': `${name}-tabpanel-${index}`,
    };
}

const theme = createMuiTheme({
    overrides: {
        MuiTouchRipple: {
            root: {
                color: scssVariables.tabRippleColor,
            },
        },
    },
});

function TabPanel(props: any) {
    const {children, value, selectedTab, name, activeTabClasses, renderAll, ...other} = props;
    const activeClass = selectedTab === value ? activeTabClasses : null;
    return (
        <div
            className={activeClass}
            role="tabpanel"
            hidden={selectedTab !== value}
            id={`${name}-tabpanel-${value}`}
            aria-labelledby={`${name}-tab-${value}`}
            {...other}
        >
            {(selectedTab === value || renderAll) && children}
        </div>
    );
}

export const Tabs = ({
    onChange,
    tabs,
    name,
    ariaLabel,
    isHiddenOnMobile,
    currentTab,
    customClasses,
    renderAll = false,
}: ITabsProps) => {
    const [selectedTab, setSelectedTab] = useState(currentTab || 0);
    useEffect(() => {
        setSelectedTab(currentTab || 0);
    }, [currentTab]);

    const tabsBarClassNames = classNames(
        {
            'mobile-hidden': isHiddenOnMobile,
        },
        styles.tabsBar
    );
    const tabsContainerClassNames = classNames(styles.tabs, customClasses?.tabsContainer);
    const appBarRootClassNames = classNames(styles.root, customClasses?.appBarRoot);
    const activeTabClassNames = classNames(styles.tabPanelActive, customClasses?.tabPanelActive);

    const handleChange = (event: any, newValue: any) => {
        onChange?.(newValue);
        setSelectedTab(newValue);
    };

    const mTabClasses = {
        wrapper: styles.wrapper,
        root: styles.tabRoot,
        textColorInherit: styles.textColorInherit,
        selected: styles.selected,
    };

    const enabledTabs = tabs.filter(({enabled}) => (typeof enabled === 'boolean' ? enabled : true));

    return (
        <div className={tabsContainerClassNames}>
            <div className={tabsBarClassNames}>
                <AppBar
                    position="static"
                    classes={{
                        root: appBarRootClassNames,
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <MTabs
                            value={enabledTabs.length && selectedTab}
                            onChange={handleChange}
                            classes={{
                                indicator: styles.indicator,
                                root: styles.tabsRoot,
                            }}
                            variant="scrollable"
                            scrollButtons="on"
                            TabScrollButtonProps={{
                                classes: {
                                    root: styles.scrollButton,
                                },
                            }}
                            aria-label={ariaLabel}
                        >
                            {enabledTabs.map(({label, tabName}, i) => (
                                <MTab
                                    key={label}
                                    classes={mTabClasses}
                                    label={label}
                                    value={tabName ? tabName : i}
                                    {...a11yProps(i, name)}
                                />
                            ))}
                        </MTabs>
                    </ThemeProvider>
                </AppBar>
            </div>
            {enabledTabs.map(({component: Component, label, tabName, props: tabProps}, i) => (
                <TabPanel
                    key={label}
                    selectedTab={selectedTab}
                    value={tabName ? tabName : i}
                    name={name}
                    activeTabClasses={activeTabClassNames}
                    renderAll={renderAll}
                >
                    <Component {...tabProps} />
                </TabPanel>
            ))}
        </div>
    );
};
