import React, {useState, useCallback, ChangeEvent, MouseEvent, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import {useLocalizationContext} from 'app/hooks';
import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    selectedItemIdSelector,
} from 'app/state/router';
import {BackButton} from 'app/views/pageContent/backButton';
import {SearchInput} from 'app/views/pageContent/searchInput';
import styles from './searchBar.module.scss';
import ADNOCIcon from 'app/static/images/ADNOCIcon.svg';
import {routeParams, routes} from 'app/routes';
import {stringify, parse} from 'querystring';
import {triggerSameValueSearch} from 'app/state/search/search.actions';
import welcomePageStyles from 'app/pages/welcomePage/welcomePage.module.scss';

export const SearchBar = () => {
    const {t} = useLocalizationContext();
    const dispatch = useDispatch();
    const location = useSelector(locationSelector);
    const searchQuery = useSelector(searchQuerySelector);
    const [value, setValue] = useState<string>(searchQuery);
    const selectedItem = !!useSelector(selectedItemIdSelector);

    useEffect(() => {
        if (searchQuery !== value) {
            setValue(searchQuery);
        }
    }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    const dispatchSearch = useCallback(
        (value: string) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.QUERY]: value,
            };
            delete query[routeParams.SELECTED_ID];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.search, location.pathname]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(debounce(dispatchSearch, 800), [dispatchSearch]);

    const onSearch = () => {
        dispatch(triggerSameValueSearch());
    };

    const onChange = useCallback(
        ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
            setValue(value);
            debouncedSearch(value);
        },
        [debouncedSearch]
    );

    const onClear = () => {
        setValue('');
        debouncedSearch('');
    };

    const resetSelectedItem = useCallback(() => {
        dispatch(clearPreviousSelectedItem());
    }, [dispatch]);

    const onLogoClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            dispatch(push(routes.welcome));
        },
        [dispatch]
    );

    return (
        <div className={styles.header}>
            <div className={classnames({'mobile-hidden': !selectedItem}, styles.leftSide)}>
                <div className={styles.mainTitleDesktop}>
                    {t('pageContent.searchBar.mainTitle')}
                </div>

                <div className={styles.backButton}>
                    <div className={styles.button}>
                        <BackButton onClick={resetSelectedItem} />
                    </div>
                    <div className={styles.title}>{t('pageContent.searchBar.defaultTitle')}</div>
                </div>
            </div>

            <div className={classnames({'mobile-hidden': selectedItem}, styles.mainTitleMobile)}>
                {t('pageContent.searchBar.mainTitle')}
            </div>
            <SearchInput
                value={value}
                styleClasses={classnames({'mobile-hidden': selectedItem})}
                onChange={onChange}
                onSearch={onSearch}
                onClear={onClear}
            />
            <div className={classnames({'mobile-hidden': !selectedItem}, styles.rightSide)}>
                <a href="/" className={styles.logo} onClick={onLogoClick}>
                    <img
                        className={welcomePageStyles.icon}
                        src={ADNOCIcon}
                        alt={t('pageContent.searchBar.ADNOCIconAlt')}
                    />
                </a>
            </div>
        </div>
    );
};
