import styles from './lightAppsSuggestions.module.scss';
import {Link} from '../link';
import React, {MutableRefObject, useEffect, useState} from 'react';
import {appConfig} from '../../../config';
import {ILightAppWithQueryParts} from '../../../state/lightApps';
import {useAppContext, useInjection, useLocalizationContext} from '../../../hooks';
import {IAuthProvider} from '../../../infrastructure/auth';
import {IoC} from '../../../ioc';
import {AppMode} from '../../../contexts';
import {ISelectedResult} from '../../../utils/mainSearch';
import classNames from 'classnames';

export const LightAppsSuggestions = ({
    lightAppsSuggestions,
    selected,
    allLightAppsItemRef,
    lightAppsItemRefs,
}: {
    lightAppsSuggestions: ILightAppWithQueryParts[];
    selected: ISelectedResult;
    allLightAppsItemRef: MutableRefObject<any>;
    lightAppsItemRefs: MutableRefObject<any>;
}) => {
    const [accessToken, setAccessToken] = useState('');
    const authProvider = useInjection<IAuthProvider>(IoC.AuthProvider);
    const {mode} = useAppContext();
    const isWebView = mode === AppMode.WebView;

    useEffect(() => {
        authProvider.getAccessToken().then(token => setAccessToken(token));
    }, [authProvider]);
    const {skey_44} = appConfig;
    const {t} = useLocalizationContext();
    const label = t('components.lightAppsSuggestions.new').toUpperCase();

    const originURL = window.location.origin.replace('search', 'onehub');
    const allLightAppsLink = `${originURL}/eservices`;
    const lightAppLink = (itemURL: string) => {
        const isItemURLhasSearchParam = itemURL.includes('?');

        return `${allLightAppsLink}/${encodeURIComponent(
            `${itemURL}${
                isItemURLhasSearchParam ? '&' : '?'
            }token=${accessToken}&isMobile=${isWebView}`
        )}`;
    };

    useEffect(() => {
        let selectedEl;

        if (selected.allLightAppsSelected) {
            selectedEl = allLightAppsItemRef.current;
        } else if (selected.lightAppsSelectedI !== undefined) {
            selectedEl = lightAppsItemRefs.current[selected.lightAppsSelectedI];
        }

        selectedEl?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, [selected]);

    return (
        <div className={styles.container}>
            {lightAppsSuggestions.map((item, i) => (
                <Link
                    key={`lightAppSearchItem${i}`}
                    href={lightAppLink(item['url'])}
                    target="_blank"
                    component="a"
                    className={i === selected.lightAppsSelectedI ? styles.selected : undefined}
                    linkRef={el => {
                        lightAppsItemRefs.current[i] = el;
                    }}
                >
                    <div className={styles.item}>
                        <div className={styles.icon}>
                            <img src={`${skey_44}${item.icon}`} />
                        </div>
                        {item.isNew && <div className={styles.label}>{label}</div>}
                        <div className={styles.content}>
                            <div className={styles.title}>
                                {item.titleQueryParts.map((part, i) =>
                                    i % 2 === 1 ? <span>{part}</span> : <>{part}</>
                                )}
                            </div>
                            <div className={styles.description}>
                                {item.descriptionQueryParts.map((part, i) =>
                                    i % 2 === 1 ? <span>{part}</span> : <>{part}</>
                                )}
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
            <Link
                href={allLightAppsLink}
                target="_blank"
                component="a"
                className={classNames(
                    styles.showAll,
                    selected.allLightAppsSelected ? styles.selected : undefined
                )}
                linkRef={allLightAppsItemRef}
            >
                {t('components.lightAppsSuggestions.showAllLightApps')}
            </Link>
        </div>
    );
};
