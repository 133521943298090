import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {ColorLabel, colors} from 'app/views/components/colorLabel/colorLabel';
import {ICustomer} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';

const typeColors: {[key: string]: colors} = {
    CUSTOMER: 'whiteBlue',
    COUNTRY: 'whiteGreen',
    HOLDING: 'whiteBrightOrange',
};

export const Customer: FC<ISuggestionComponent<ICustomer>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, text, searchString, label = '', historyDate} = item;
    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_customers, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.info}>
                <ColorLabel color={typeColors[label]}>{label}</ColorLabel>
                <div className={styles.title}>{text}</div>
            </div>
        </div>
    );
};
