import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';

import {createRootReducer} from './rootReducer';
import rootSaga from './rootSaga';
import {container, IoC} from 'app/ioc';
import {ISearchService} from 'app/infrastructure/search';
import {ISearchServiceDocuments} from '../infrastructure/search/documents';
import {ISearchServiceMails} from '../infrastructure/search/mails';
import {ISuggestionsService} from 'app/infrastructure/suggestions';
import {ILogger} from 'app/infrastructure/logging';
import {ISearchServiceCorrespondence} from '../infrastructure/search/correspondence';
import {ISearchTrainings} from '../infrastructure/search/eLearning';
import {ISearchServiceDistributions} from '../infrastructure/search/distributions';
import {ISearchServiceEmployee} from '../infrastructure/search/employee';
import {ISearchServiceNews} from '../infrastructure/search/news';
import {ISearchServiceTenders} from '../infrastructure/search/tenders';
import {ISearchServiceProjects} from '../infrastructure/search/projects';
import {ISearchServiceCustomers} from '../infrastructure/search/customers';
import {ISearchServiceServices} from '../infrastructure/search/services';

const sagaMiddleware = createSagaMiddleware({
    context: {
        searchService: {
            get() {
                return container.get<ISearchService>(IoC.SearchService);
            },
            documents: {
                get() {
                    return container.get<ISearchServiceDocuments>(IoC.SearchServiceDocuments);
                },
            },
            mails: {
                get() {
                    return container.get<ISearchServiceMails>(IoC.SearchServiceMails);
                },
            },
            correspondence: {
                get() {
                    return container.get<ISearchServiceCorrespondence>(
                        IoC.SearchServiceCorrespondence
                    );
                },
            },
            eLearning: {
                get() {
                    return container.get<ISearchTrainings>(IoC.SearchServiceELearning);
                },
            },
            distributions: {
                get() {
                    return container.get<ISearchServiceDistributions>(
                        IoC.SearchServiceDistributions
                    );
                },
            },
            employee: {
                get() {
                    return container.get<ISearchServiceEmployee>(IoC.SearchServiceEmployee);
                },
            },
            news: {
                get() {
                    return container.get<ISearchServiceNews>(IoC.SearchServiceNews);
                },
            },
            tenders: {
                get() {
                    return container.get<ISearchServiceTenders>(IoC.SearchServiceTenders);
                },
            },
            projects: {
                get() {
                    return container.get<ISearchServiceProjects>(IoC.SearchServiceProjects);
                },
            },
            suppliers: {
                get() {
                    return container.get<ISearchServiceProjects>(IoC.SearchServiceSuppliers);
                },
            },
            customers: {
                get() {
                    return container.get<ISearchServiceCustomers>(IoC.SearchServiceCustomers);
                },
            },
            services: {
                get() {
                    return container.get<ISearchServiceServices>(IoC.SearchServiceServices);
                },
            },
        },
        suggestionsService: {
            get() {
                return container.get<ISuggestionsService>(IoC.SuggestionsService);
            },
        },
        logger: {
            get() {
                return container.get<ILogger>(IoC.Logger);
            },
        },
    },
});

export const history = createBrowserHistory();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export default store;
