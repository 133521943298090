import React from 'react';

import {StationRating} from '../stationRating';
import classNames from 'classnames';
import styles from './distributionMap.module.scss';

type DistributionMapPanelCardTypes = {
    data: any;
    gData: any;
};

export const DistributionMapPanelCard = ({data, gData}: DistributionMapPanelCardTypes) => {
    if (!gData) return null;

    return (
        <div className={styles.placeDiv}>
            <div className={styles.placecard__container}>
                <div className={styles.placecard__left}>
                    <p className={styles['placecard__business-name']}>{gData?.name}</p>
                    <p className={styles.placecard__info}>{gData?.formatted_address}</p>
                    <div className={styles.placecard__rating}>
                        {!!data?.rating && (
                            <span
                                className={classNames(
                                    styles.placecard__ratingNumber,
                                    styles.placecard__info
                                )}
                            >
                                {data?.rating}
                            </span>
                        )}

                        <StationRating rating={gData?.rating} />
                    </div>
                </div>
            </div>
        </div>
    );
};
