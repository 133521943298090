import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {ELearningRoutes} from './routes.eLearning';
import {AbortControllerELearning, ITrainingsFilters} from './searchAPI.eLearning.types';
import {IFilter, IFilterRefiner} from '../../../views/components/filterPanel/filterPanel.types';
import {
    ISearchTrainingsOptions,
    ITrainingsResponse,
    ITransformedTrainingsResponse,
} from 'app/infrastructure/search/eLearning/searchService.eLearning.types';
import {camelCaseToSentenceCase, fetchData} from 'app/utils';
import {assignIn} from 'lodash';
import {transformStringToObject} from 'app/utils/transformStringToObject';

export const abortControllers: AbortControllerELearning = {
    TRAININGS: null,
};

export abstract class SearchAPICallerELearning {
    public static async searchTrainingsFilters(searchAPIURL: string, accessToken: string) {
        const filters = await fetchData(
            ELearningRoutes.learningFilters(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );

        return SearchAPICallerELearning.transformFilters(filters);
    }

    public static async searchTrainings(
        options: ISearchTrainingsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        return await SearchAPICallerELearning.getTrainings(options, searchAPIURL, accessToken);
    }

    public static async getTrainings(
        {
            query = '',
            page = 0,
            perPage = DEFAULT_PAGE_SIZE,
            filter,
            orderBy,
        }: ISearchTrainingsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = `${ELearningRoutes.trainings(searchAPIURL)}/search`;
        const data = {
            searchString: query,
            page,
            perPage,
        };

        if (filter) {
            assignIn(data, {filterOptions: filter});
        }

        if (orderBy) {
            assignIn(data, {sortOptions: transformStringToObject(orderBy)});
        }

        abortControllers.TRAININGS?.abort();
        abortControllers.TRAININGS = new AbortController();

        return await fetchData(
            String(url),
            accessToken,
            'POST',
            data,
            null,
            null,
            abortControllers.TRAININGS.signal
        ).then(SearchAPICallerELearning.transformTrainingsToListFormat);
    }

    private static transformTrainingsToListFormat(
        trainings: ITrainingsResponse
    ): ITransformedTrainingsResponse {
        return {
            ...trainings,
            courses: trainings.courses.map(course => ({...course, id: course.courseShortName})),
            hasErrors: false,
            totalCount: trainings.recordsCount,
        };
    }

    private static transformFilters(filters: ITrainingsFilters): IFilterRefiner[] {
        return Object.keys(filters)
            .map(
                (key): IFilterRefiner => ({
                    Name: camelCaseToSentenceCase(key),
                    Entries: filters[key]?.map(filter =>
                        SearchAPICallerELearning.getEntryData(filter, key)
                    ),
                })
            )
            .filter(({Entries}) => Entries.some(({RefinementValue}: IFilter) => RefinementValue));
    }

    private static generateFilterValue(id: number | string, name: string): string {
        return `${typeof id === 'number' ? id : `${id}`}`;
    }

    private static getEntryData(filter: any, name: string): IFilter {
        const values: IFilter = {
            RefinementCount: '',
            RefinementToken: '',
            RefinementName: '',
            RefinementValue: '',
        };

        const refinementName = filter[`${name}Name`];
        const refinementValue = filter[`${name}Id`];

        if (refinementValue !== undefined && refinementName !== undefined) {
            return {
                ...values,
                RefinementName: refinementName,
                RefinementValue: SearchAPICallerELearning.generateFilterValue(
                    refinementValue,
                    name
                ),
            };
        }

        return values;
    }
}
