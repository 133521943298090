import React from 'react';
import {ToastrBaseComponent} from './toastrBaseComponent';
import toastrInfoIcon from 'app/static/images/toastrInfoIcon.svg';
import toastrErrorIcon from 'app/static/images/toastrErrorIcon.svg';

type ToastrType = {
    text?: string;
    documentName?: string;
    mode: string;
    className?: string;
};

export const toastrDownloadInfo = (dispatch: any, {documentName, mode, className}: ToastrType) => {
    return ToastrBaseComponent(dispatch, {
        type: 'info',
        mode,
        className: `downloadInfo ${className}`,
        iconSrc: toastrInfoIcon,
        params: {timeOut: 36000},
        component: React.createElement(() => <div>The file is downloading</div>),
    });
};

export const toastrDownloadError = (dispatch: any, {text, mode, className}: ToastrType) => {
    const message =
        text || 'An error occurred during the file processing. Try to download the file later.';

    return ToastrBaseComponent(dispatch, {
        type: 'error',
        mode,
        className: `downloadError ${className}`,
        iconSrc: toastrErrorIcon,
        component: React.createElement(() => <div>{message}</div>),
    });
};
