import React, {useCallback} from 'react';
import {ICorrespondencePreview} from './correspondencePreview.types';
import {useAppContext} from 'app/hooks';
import {AppMode} from 'app/contexts';
import {CorrespondencePreviewView} from '@one-hub/onehub-web-lib';

import getCorrespondenceDocumentHandler from 'app/views/correspondence/components/correspondencePreviewPanel/getCorrespondenceDocument';
import {useDispatch} from 'react-redux';
import {HTTPS_STATUS_CODES} from 'app/constants';
import {get} from 'lodash';

export const CorrespondencePreview = ({
    correspondence,
    getCorrespondenceDocument: getCorrespondenceDocumentFromProps,
    setHasDocumentError: setHasDocumentErrorFromProps,
}: ICorrespondencePreview) => {
    const {mode} = useAppContext();
    const dispatch = useDispatch();
    const getCorrespondenceDocumentCallback = useCallback(
        async (id: string, shouldDownload: boolean) => {
            const result = await getCorrespondenceDocumentHandler(
                id,
                shouldDownload,
                mode,
                dispatch,
                getCorrespondenceDocumentFromProps
            );

            // @ts-ignore
            if (result.status !== HTTPS_STATUS_CODES.OK && setHasDocumentErrorFromProps) {
                setHasDocumentErrorFromProps(get(result, 'status', true));
                return Promise.reject();
            }

            return result;
        },
        [mode, dispatch, getCorrespondenceDocumentFromProps, setHasDocumentErrorFromProps]
    );

    return (
        <CorrespondencePreviewView
            correspondence={correspondence}
            getCorrespondenceDocument={getCorrespondenceDocumentCallback}
            isFullscreen={mode === AppMode.FullScreen}
        />
    );
};
