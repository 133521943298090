import React, {FC, useCallback} from 'react';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import MTabs from '@material-ui/core/Tabs';
import MTab from '@material-ui/core/Tab';
import scssVariables from 'app/variables.module.scss';

import {ITabsBarProps} from './tabsBar.types';
import styles from './tabsBar.module.scss';

function a11yProps(index: number, name: string) {
    return {
        id: `${name}-${index}`,
        'aria-controls': `${name}-tabpanel-${index}`,
    };
}

const theme = createMuiTheme({
    overrides: {
        MuiTouchRipple: {
            root: {
                color: scssVariables.tabRippleColor,
            },
        },
    },
});

export const TabsBar: FC<ITabsBarProps> = ({
    tabs,
    name,
    ariaLabel,
    isHiddenOnMobile,
    currentTab = 0,
    onChange = () => {},
}) => {
    const mTabClasses = {
        wrapper: styles.wrapper,
        root: styles.tabRoot,
        textColorInherit: styles.textColorInherit,
        selected: styles.selected,
    };

    const onMTabChange = useCallback(
        (e: any, id: any) => {
            if (id !== currentTab) onChange(id);
        },
        [onChange, currentTab]
    );

    return (
        <div className={classNames({'mobile-hidden': isHiddenOnMobile})}>
            <AppBar
                position="static"
                classes={{
                    root: styles.root,
                }}
            >
                <ThemeProvider theme={theme}>
                    <MTabs
                        value={tabs.length && currentTab}
                        onChange={onMTabChange}
                        classes={{
                            indicator: styles.indicator,
                            root: styles.tabsRoot,
                        }}
                        variant="scrollable"
                        scrollButtons="on"
                        TabScrollButtonProps={{
                            classes: {
                                root: styles.scrollButton,
                            },
                        }}
                        aria-label={ariaLabel}
                    >
                        {tabs.map(({label, id}, i) => (
                            <MTab
                                key={label}
                                classes={mTabClasses}
                                label={label}
                                value={id ? id : i}
                                {...a11yProps(i, name)}
                            />
                        ))}
                    </MTabs>
                </ThemeProvider>
            </AppBar>
        </div>
    );
};
