import React from 'react';
import {Bar} from 'react-chartjs-2';
import styles from './barChart.module.scss';

export const BarChart = ({
    data,
    formatter = v => v.toString(),
}: {
    data: {
        label: string;
        value: number;
    }[];
    formatter?: (value: number) => string;
}) => {
    const chartLabels: string[] = [];
    const chartData: number[] = [];

    data.forEach(({label, value}) => {
        chartLabels.push(label);
        chartData.push(value);
    });

    const chartConfig = {
        data: {
            labels: chartLabels,
            datasets: [
                {
                    backgroundColor: '#0075C9',
                    data: chartData,
                },
            ],
        },
        legend: {
            display: false,
        },
        options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: formatter,
                        },
                    },
                ],
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function ({index, datasetIndex}: any, {datasets}: any) {
                        const dataset = datasets[datasetIndex];
                        const value = dataset.data[index];

                        return formatter(value);
                    },
                },
            },
        },
        width: 100,
        height: 60,
    };

    return (
        <div className={styles.chart}>
            <Bar {...chartConfig} />
        </div>
    );
};
