import React, {useRef, useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import useDimensions from 'react-cool-dimensions';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import {CustomerType} from 'app/state/customers';
import {CountryPreview} from './components/country/countryPreview';
import {CustomerPreview} from './components/customer/customerPreview';
import styles from './customersPreviewPanel.module.scss';
import {Select} from 'app/views/components';
import {periods} from 'app/utils/periodsUtil';

import {periodSelector, setPeriod} from 'app/state/customers';
import {PreviewContentLayout} from 'app/layouts';

export const CustomersPreviewPanel = ({data}: any) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const {width} = useDimensions({ref: containerRef});

    const {t} = useLocalizationContext();
    const dispatch = useDispatch();
    const period = useSelector(periodSelector, shallowEqual);

    const periodItems = periods.map(({name, localizationKey}, index) => ({
        name: localizationKey ? t(localizationKey) : name,
        value: index,
    }));

    const onSelectPeriod = useCallback(
        p => {
            dispatch(setPeriod(p));
        },
        [dispatch]
    );

    const Preview = data.type === CustomerType.Country ? CountryPreview : CustomerPreview;
    const {details} = data;

    let title = '';
    const overview = details.overview;

    if (overview) {
        if (data.type === CustomerType.Country) {
            const {countryIso, countryChartName} = overview;
            title = countryIso && countryChartName ? `${countryIso} - ${countryChartName}` : '';
        } else {
            title = overview.customerName || '';
        }
    }

    return (
        <PreviewContentLayout
            layoutAttributes={{
                className: classNames(styles.container, {[styles.medium]: width < 720}),
            }}
            key={data.id}
            ref={containerRef}
        >
            <div className={styles.title}>
                <h2>{title}</h2>
                <Select value={period} onChange={onSelectPeriod} items={periodItems} />
            </div>
            <Preview data={data} width={width} />
        </PreviewContentLayout>
    );
};
