import {routes} from 'app/routes';
import {isEntityEnabled} from 'app/utils/isEntityEnabled';
import {EntityType} from 'app/contexts';
import {useLocalizationContext} from 'app/hooks';

export const SearchPageTabs = () => {
    const {t} = useLocalizationContext();

    return [
        {
            id: routes.search,
            label: t('tabs.all'),
            enabled: true,
        },
        {
            id: routes.search_employee,
            label: t('tabs.employees'),
            enabled: isEntityEnabled(EntityType.Employee),
        },
        {
            id: routes.search_document,
            label: t('tabs.documents'),
            enabled: isEntityEnabled(EntityType.Document),
        },
        {
            id: routes.search_correspondence,
            label: t('tabs.correspondence'),
            enabled: isEntityEnabled(EntityType.Correspondence),
        },
        {
            id: routes.search_email,
            label: t('tabs.email'),
            enabled: isEntityEnabled(EntityType.Email),
        },
        {
            id: routes.search_training,
            label: t('tabs.trainings'),
            enabled: isEntityEnabled(EntityType.Training),
        },
        {
            id: routes.search_news,
            label: t('tabs.news'),
            enabled: isEntityEnabled(EntityType.News),
        },
        {
            id: routes.search_projects,
            label: t('tabs.projects'),
            enabled: isEntityEnabled(EntityType.Projects),
        },
        {
            id: routes.search_tender,
            label: t('tabs.tenderBoard'),
            enabled: isEntityEnabled(EntityType.Tender),
        },
        {
            id: routes.search_service,
            label: t('tabs.serviceNow'),
            enabled: isEntityEnabled(EntityType.Service),
        },
        {
            id: routes.search_suppliers,
            label: t('tabs.suppliers'),
            enabled: isEntityEnabled(EntityType.Suppliers),
        },
        {
            id: routes.search_customers,
            label: t('tabs.customers'),
            enabled: isEntityEnabled(EntityType.Customers),
        },
        {
            id: routes.search_distribution,
            label: t('tabs.distribution'),
            enabled: isEntityEnabled(EntityType.Distribution),
        },
    ].filter(({enabled}) => (typeof enabled === 'boolean' ? enabled : true));
};
