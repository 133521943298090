import React from 'react';
import classNames from 'classnames';
import {PieChart} from '../charts/pieChart';
import {useLocalizationContext} from 'app/hooks';
import styles from '../../customersPreviewPanel.module.scss';

export const SalesShares = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();

    const {
        details: {productGroupShares = []},
    } = data;

    const chartData = productGroupShares.map(
        ({materialGroupName, amount}: {materialGroupName: string; amount: number}) => ({
            label: materialGroupName,
            value: amount,
        })
    );
    return (
        <div className={classNames(className)}>
            <h3>{t('customers.preview.salesShares')}</h3>
            <div className={styles.containerBody}>
                <PieChart data={chartData} />
            </div>
        </div>
    );
};
