import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import styles from './customersListItem.module.scss';
import {CustomerType, ICustomerItem} from 'app/state/customers';
import {ColorLabel, colors} from 'app/views/components/colorLabel/colorLabel';

const typeColors: {[key: string]: colors} = {
    [CustomerType.Customer]: 'whiteBlue',
    [CustomerType.Country]: 'whiteGreen',
    [CustomerType.Holding]: 'whiteBrightOrange',
};

export const CustomersListItem: FC<{item: ICustomerItem; searchWords: string[]}> = ({
    item: {id, text, label, type},
    searchWords = [],
}) => (
    <div className={styles.item}>
        <div>
            <ColorLabel color={typeColors[type]}>{label}</ColorLabel>
        </div>
        <Highlighter
            className={styles.title}
            highlightClassName="highlight"
            searchWords={searchWords}
            textToHighlight={text || ''}
        />
        <div className={styles.subtitleWrapper}>
            <Highlighter
                className={styles.subtitle}
                highlightClassName="highlight"
                searchWords={searchWords}
                textToHighlight={id || ''}
            />
        </div>
    </div>
);
