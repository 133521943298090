import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {numberFormatter} from 'app/utils';
import {SmallCard} from './smallCard';

export const Value = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();

    const {
        details: {value},
    } = data;
    const valueData = value.map(
        ({materialGroupName, amount}: {materialGroupName: string; amount: number}) => ({
            name: materialGroupName,
            unitName: 'M$',
            value: numberFormatter(amount),
        })
    );

    const total: number = value.reduce(
        (sum: number, {amount}: {amount: number}) => sum + amount,
        0
    );

    return (
        <SmallCard
            className={className}
            title={t('customers.preview.value')}
            data={valueData}
            total={{
                label: t('customers.preview.total'),
                value: numberFormatter(Math.round(total * 100) / 100),
            }}
        />
    );
};
