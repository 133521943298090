import React, {useState, useEffect} from 'react';
import {useLocalizationContext} from 'app/hooks';
import classNames from 'classnames';

import commonStyles from '../projectsPreviewPanel.module.scss';
import styles from './milestones.module.scss';
import {getSlashDate} from 'app/utils';
import {IProjectDetails} from 'app/state/projects/projects.types';
import {ColorLabel} from 'app/views/components/colorLabel/colorLabel';
import {TabButtons} from './tabButtons';
import {IfDataAvailable} from './ifDataAvailable';

const limit = 3;
const prepareMilestones = (
    milestones: {
        description: string;
        type: string;
        praf: string;
        date: string;
    }[]
) => {
    type milestoneType = {
        description: string;
        type: string;
        pr?: Date;
        af?: Date;
    }[];
    const upcoming: milestoneType = [];
    const completed: milestoneType = [];
    const milestonesList: milestoneType = [];

    milestones.forEach(milestone => {
        const {description, type, date, praf} = milestone;
        const index = milestonesList.findIndex(f => f.description === description);
        if (index >= 0) {
            if (praf === 'P/R') {
                milestonesList[index].pr = new Date(date);
            } else {
                milestonesList[index].af = new Date(date);
            }
        } else {
            milestonesList.push({
                description,
                type,
                pr: praf === 'P/R' ? new Date(date) : undefined,
                af: praf === 'A/F' ? new Date(date) : undefined,
            });
        }
    });

    milestonesList.sort(function (a, b) {
        return b.af && a.af ? b.af.getTime() - a.af.getTime() : 1;
    });

    milestonesList.forEach(ml => {
        if (ml.af ? ml.af > new Date() : ml.pr && ml.pr > new Date()) {
            upcoming.push(ml);
        } else {
            completed.push(ml);
        }
    });

    return {
        upcoming,
        completed,
    };
};

export const Milestones = (props: {data: IProjectDetails; isSmall: boolean}) => {
    const {t} = useLocalizationContext();
    const {
        data: {milestones},
    } = props;
    const {completed, upcoming} = prepareMilestones(milestones);

    const [upcomingList, setUpcomingList] = useState(upcoming.map(u => ({...u, collapse: false})));
    const [completedList, setCompletedList] = useState(
        completed.map(c => ({...c, collapse: false}))
    );

    const [showAllUpcoming, setShowAllUpcoming] = useState(false);
    const [showAllCompleted, setShowAllCompleted] = useState(false);
    const [collapseAllState, setCollapseAllState] = useState(false);

    useEffect(() => {
        const {completed, upcoming} = prepareMilestones(milestones);

        setUpcomingList(upcoming.map(u => ({...u, collapse: false})));
        setCompletedList(completed.map(c => ({...c, collapse: false})));
    }, [milestones]);

    const collapseAll = () => {
        setUpcomingList(upcomingList.map(u => ({...u, collapse: true})));
        setCompletedList(completedList.map(c => ({...c, collapse: true})));
        setCollapseAllState(true);
    };

    const expandAll = () => {
        setUpcomingList(upcomingList.map(u => ({...u, collapse: false})));
        setCompletedList(completedList.map(c => ({...c, collapse: false})));
        setCollapseAllState(false);
    };

    const toggleUpcoming = (d: string) => {
        setUpcomingList(
            upcomingList.map(u => ({
                ...u,
                collapse: u.description === d ? !u.collapse : u.collapse,
            }))
        );
    };

    const toggleCompleted = (d: string) => {
        setCompletedList(
            completedList.map(c => ({
                ...c,
                collapse: c.description === d ? !c.collapse : c.collapse,
            }))
        );
    };

    const upcomingTab = (
        <IfDataAvailable
            isData={!!upcomingList.length}
            name={t('projects.preview.upcomingMilestones')}
        >
            {upcomingList.slice(0, showAllUpcoming ? upcomingList.length : limit).map(milestone => (
                <div key={milestone.description} className={styles.milestone}>
                    <div className={styles.milestoneTitle}>
                        <div
                            className={classNames(styles.title, {
                                [styles.collapse]: milestone.collapse,
                            })}
                            onClick={() => {
                                toggleUpcoming(milestone.description);
                            }}
                        >
                            {milestone.description}
                        </div>
                        <ColorLabel color="gray">{milestone.type}</ColorLabel>
                    </div>
                    {!milestone.collapse && (
                        <div className={styles.detailsContainer}>
                            <div className={styles.details}>
                                <div className={styles.label}>{t('projects.preview.planned')}</div>
                                <div className={styles.value}>
                                    {milestone.pr
                                        ? getSlashDate(milestone.pr.toISOString())
                                        : t('NA')}
                                </div>
                            </div>
                            <div className={styles.details}>
                                <div className={styles.label}>{t('projects.preview.forecast')}</div>
                                <div className={styles.value}>
                                    {milestone.af
                                        ? getSlashDate(milestone.af.toISOString())
                                        : t('NA')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
            {upcomingList.length > limit && (
                <button
                    className={styles.button}
                    onClick={() => {
                        setShowAllUpcoming(!showAllUpcoming);
                    }}
                >
                    {showAllUpcoming
                        ? t('projects.preview.showLess')
                        : t('projects.preview.showMore')}
                </button>
            )}
        </IfDataAvailable>
    );

    const completedTab = (
        <IfDataAvailable
            isData={!!completedList.length}
            name={t('projects.preview.completedMilestones')}
        >
            {completedList
                .slice(0, showAllCompleted ? completedList.length : limit)
                .map(milestone => (
                    <div key={milestone.description} className={styles.milestone}>
                        <div className={styles.milestoneTitle}>
                            <div
                                className={classNames(styles.title, {
                                    [styles.collapse]: milestone.collapse,
                                })}
                                onClick={() => {
                                    toggleCompleted(milestone.description);
                                }}
                            >
                                {milestone.description}
                            </div>
                            <ColorLabel color="gray">{milestone.type}</ColorLabel>
                        </div>
                        {!milestone.collapse && (
                            <div className={styles.detailsContainer}>
                                <div className={styles.details}>
                                    <div className={styles.label}>
                                        {t('projects.preview.planned')}
                                    </div>
                                    <div className={styles.value}>
                                        {milestone.pr
                                            ? getSlashDate(milestone.pr.toISOString())
                                            : t('NA')}
                                    </div>
                                </div>
                                <div className={styles.details}>
                                    <div className={styles.label}>
                                        {t('projects.preview.actual')}
                                    </div>
                                    <div className={styles.value}>
                                        {milestone.af
                                            ? getSlashDate(milestone.af.toISOString())
                                            : t('NA')}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            {completedList.length > limit && (
                <button
                    className={styles.button}
                    onClick={() => {
                        setShowAllCompleted(!showAllCompleted);
                    }}
                >
                    {showAllCompleted
                        ? t('projects.preview.showLess')
                        : t('projects.preview.showMore')}
                </button>
            )}
        </IfDataAvailable>
    );

    const tabs = [
        {
            title: t('projects.preview.upcoming'),
            name: 'upcoming',
            content: upcomingTab,
        },
        {
            title: t('projects.preview.completed'),
            name: 'completed',
            content: completedTab,
        },
    ];

    const [selectedTab, setSelectedTab] = useState('upcoming');

    return (
        <div className={commonStyles.itemContainer}>
            <div className={commonStyles.containerTitle}>
                <h3>{t('projects.preview.projectMilestones')}</h3>
                {collapseAllState ? (
                    <button className={styles.button} onClick={expandAll}>
                        {t('projects.preview.expandAll')}
                    </button>
                ) : (
                    <button className={styles.button} onClick={collapseAll}>
                        {t('projects.preview.collapseAll')}
                    </button>
                )}
                <div className={commonStyles.tabsContainer}>
                    <TabButtons
                        tabs={tabs.map(tab => ({
                            ...tab,
                            active: selectedTab === tab.name,
                        }))}
                        onSelect={setSelectedTab}
                    />
                </div>
            </div>

            {tabs.find(({name}) => name === selectedTab)?.content}
        </div>
    );
};
