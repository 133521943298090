import {EntityType} from 'app/contexts';
import {RootState} from 'app/state/store';

export const trainingFiltersSelector = ({filter}: RootState) =>
    filter.repository[EntityType.Training].filters;
export const trainingsSelector = ({trainings: {items}}: RootState) => items;
export const trainingsSortSelector = ({trainings: {sortBy}}: RootState) => sortBy;
export const areTrainingsLoadingSelector = ({trainings: {isLoading}}: RootState) => isLoading;
export const trainingsErrorsSelector = ({trainings: {hasErrors}}: RootState) => hasErrors;
export const trainingsErrorCodeSelector = ({trainings: {hasErrorCode}}: RootState) => hasErrorCode;
export const areMoreTrainingsAvailableSelector = ({
    trainings: {areMoreResultsAvailable},
}: RootState) => areMoreResultsAvailable;
export const trainingsTotalCountSelector = ({trainings: {totalCount}}: RootState) => totalCount;
