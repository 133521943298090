import React from 'react';
import MUILink from '@material-ui/core/Link';
import classNames from 'classnames';
import styles from './link.module.scss';
import {ILinkProps} from './link.types';

const Link = ({
    children,
    component,
    href,
    target,
    download,
    className,
    onClick,
    linkRef,
}: ILinkProps) => {
    const classes = classNames([styles.link, className]);
    return (
        <MUILink
            className={classes}
            href={href}
            component={component}
            target={target}
            download={download}
            variant="body2"
            underline="none"
            onClick={onClick as any}
            ref={linkRef}
        >
            {children}
        </MUILink>
    );
};

export {Link};
