export enum ProjectsFiltersOptions {
    projectSearch = 'projectSearch',
    companyNames = 'companyNames',
    stages = 'stages',
    budgets = 'budgets',
    schedules = 'schedules',
    strategicTheme = 'strategicTheme',
    classes = 'classes',
    types = 'types',
}

export enum ProjectsSortOptions {
    none = 'Relevance',
    NameAZ = 'Name ASC',
    NameZA = 'Name DESC',
    companyAZ = 'Code ASC',
    companyZA = 'Code DESC',
    projectStageASC = 'Actual Start Date ASC',
    projectStageDESC = 'Actual Start Date DESC',
}
