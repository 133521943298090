import {IAllFilters, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {ISortOption} from 'app/views/components/sortPanel/sortPanel.types';
import {IDocumentItem} from 'app/state/documents/documents.types';
import {IOnListItemSelect} from 'app/views/components/list/list.types';

export enum DocumentsSortOptions {
    NoSort = 'Relevance',
    NewToOld = 'Latest DESC',
    OldToNew = 'Latest ASC',
}

export enum DocumentsFiltersOptions {
    FileType = 'FileType',
    DisplayAuthor = 'DisplayAuthor',
    SiteTemplateId = 'SiteTemplateId',
    CreatedDate = 'CreatedDate',
}

export enum DocumentsFilterDateEntry {
    From = 'From',
    To = 'To',
}

export interface ILeftColumnProps {
    filters: IFilterRefiner[];
    areDocumentsLoading: boolean;
    isSmall: boolean;
    onApplyFilters: (filters: IAllFilters[]) => void;
    setAreFiltersShown: Function;
    onHideFiltersClick: () => void;
    shouldShowExpandFiltersButton: boolean;
}

export interface ICenterColumnProps {
    hasFilters: boolean;
    hasNoResults: boolean;
    sortList: ISortOption[];
    sortOptionIndex: number;
    areDocumentsLoading: boolean;
    searchQuery: string;
    searchWords: string[];
    items: IDocumentItem[];
    selectedItemId?: string;
    onSortChange: (option: ISortOption) => void;
    setAreFiltersShown: Function;
    loadMoreHandler: () => void;
    onSelect: IOnListItemSelect<IDocumentItem>;
    onShowFiltersClick: () => void;
    totalItemsCount?: number;
}
