import React, {FC} from 'react';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {ColorLabel} from '../../colorLabel/colorLabel';
import {IDistribution} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import {routes} from 'app/routes';
import classNames from 'classnames';

export const Distribution: FC<ISuggestionComponent<IDistribution>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, name, status, searchString, historyDate} = item;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_distribution, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.info}>
                <ColorLabel className={classNames(styles.status, styles[status.toLowerCase()])}>
                    {status}
                </ColorLabel>
                <div className={styles.title}>{name}</div>
            </div>
        </div>
    );
};
