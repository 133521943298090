import React, {useCallback, useEffect, useState} from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useLocalizationContext} from 'app/hooks';
import styles from '../styles/filterPanel.module.scss';
import {FilterEntry} from '../filterEntry';
import {FilterSearch} from '../filterSearch';
import {IFilterProps} from './filter.types';
import {PeopleFiltersOptions} from '../../../../containers/people';
import CustomizedSwitches from './switch/Switch';
import {FilterType} from '../filterPanel.types';

export const Filter = ({
    filter,
    filterLabelMapper,
    checkFilterIsActiveToggle,
    toggle,
    changeFilterIsCheckedValue,
    getFilterIsCheckedValue,
    enableSearchThreshold = 6,
    visibleFiltersBeforeSearch = 5,
    checkedFiltersAmount,
    arrowIconsStyles,
    isStarsFilter,
}: IFilterProps) => {
    const [visibleFiltersAmount, setVisibleFiltersAmount] = useState<number>(
        visibleFiltersBeforeSearch
    );

    const {t} = useLocalizationContext();
    const entries = filter.Entries;
    const sliceTo = Math.max(checkedFiltersAmount, visibleFiltersAmount);
    const isSearchEnabled =
        entries.length - visibleFiltersAmount > 1 && filter.Entries.length > enableSearchThreshold;
    const entriesTopList = isSearchEnabled ? entries.slice(0, sliceTo) : entries;
    const onFilterChange = useCallback(
        (refinementName: string, shouldMoveToTop?: boolean) => {
            const filterValue = filter.Entries.find(e => e.RefinementName === refinementName);
            changeFilterIsCheckedValue(
                refinementName,
                filter.Name,
                Boolean(shouldMoveToTop),
                undefined,
                filterValue?.value
            );
        },
        [changeFilterIsCheckedValue, filter.Name, filter.Entries]
    );
    const onFilterChangeFromDropdown = useCallback(
        (name: string) => {
            onFilterChange(name, true);
            setVisibleFiltersAmount(visibleFiltersAmount + 1);
        },
        [onFilterChange, visibleFiltersAmount]
    );

    useEffect(() => {
        setVisibleFiltersAmount(Math.max(visibleFiltersAmount, checkedFiltersAmount));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedFiltersAmount]);

    if (filter.Name === PeopleFiltersOptions.ExactMatch) {
        return (
            <div className={styles.switchContainer}>
                <CustomizedSwitches
                    label={t('components.filterPanel.exactSwitcherLabel')}
                    helperTextOff={t('components.filterPanel.helperTextOff')}
                    helperTextOn={t('components.filterPanel.helperTextOn')}
                    styles={styles}
                    entry={entriesTopList[0]}
                    onChange={onFilterChange}
                    getFilterIsCheckedValue={getFilterIsCheckedValue}
                />
            </div>
        );
    }

    return (
        <ul className={styles.filtersList}>
            <li>
                <span onClick={() => toggle(filter.Name)} className={styles.property}>
                    {filterLabelMapper ? filterLabelMapper(filter.Name) : t(filter.Name)}
                    {checkFilterIsActiveToggle(filter.Name) ? (
                        <ExpandLessIcon style={arrowIconsStyles} />
                    ) : (
                        <ExpandMoreIcon style={arrowIconsStyles} />
                    )}
                </span>
            </li>
            {checkFilterIsActiveToggle(filter.Name) && (
                <>
                    {entriesTopList.map((entry, index) => (
                        <FilterEntry
                            inputType={'checkbox'}
                            inputName={
                                filter.type === FilterType.Radio ? filter.Name : 'refinersCheckbox'
                            }
                            key={index}
                            entry={entry}
                            onChange={onFilterChange}
                            getFilterIsCheckedValue={getFilterIsCheckedValue}
                            isStarsFilter={isStarsFilter}
                        />
                    ))}
                    {isSearchEnabled && (
                        <FilterSearch
                            changeFilterIsCheckedValue={onFilterChangeFromDropdown}
                            filter={filter}
                            getFilterIsCheckedValue={getFilterIsCheckedValue}
                            visibleFiltersBeforeSearch={sliceTo}
                        />
                    )}
                </>
            )}
        </ul>
    );
};
