import React from 'react';
import {DetailsList} from '../detailsList';

import styles from '../../servicePreviewPanel.module.scss';
import {CommonDetails} from '../commonDetails';
import {userInfoRenderer} from '../userInfoRenderer';

export const RequestTask = ({data}: any) => (
    <>
        <div className={styles.twoColumnContainer}>
            <div>
                <DetailsList
                    data={data}
                    fields={[
                        'number',
                        {
                            name: 'requestedFor',
                            renderer: userInfoRenderer,
                        },
                    ]}
                />
            </div>
            <div>
                <DetailsList
                    data={data}
                    fields={[
                        {
                            name: 'openBy',
                            renderer: userInfoRenderer,
                        },
                        {
                            name: 'assignedTo',
                            renderer: userInfoRenderer,
                        },
                    ]}
                />
            </div>
        </div>
        <hr />
        <CommonDetails data={data} twoColumn />
    </>
);
