import React, {FC, useEffect, useState} from 'react';
import {PreviewPanelProps, PreviewPanelState} from './previewPanel.types';
import {DefaultPreview} from './defaultPreview';
import {CountryPreview} from './countryPreview';
import {PreviewContentLayout} from 'app/layouts';
import {useAppContext, useChannelContext, useInjection, useLocalizationContext} from 'app/hooks';
import {ISearchServiceSuppliers} from 'app/infrastructure/search/suppliers';
import {IoC} from 'app/ioc';
import {ILogger} from 'app/infrastructure/logging';
import {useDispatch} from 'react-redux';
import {AppMode} from 'app/contexts';
import {downloadWithBlob, getFileUrl, initialPanelPreviewState} from './utils';
import {urlToPath} from 'app/utils/urlUtils';
import styles from './previewPanel.module.scss';
import {Loader} from 'app/views/components';
import CannotBePreviewed from 'app/static/images/cannotBePreviewed.svg';
import {DocViewerError} from 'app/views/components/DocViewer/DocViewerError';
import {DocViewer} from 'app/views/components/DocViewer/DocViewer';

export const PreviewPanel: FC<PreviewPanelProps> = props => {
    const {
        data: {entityLabel, id},
    } = props;
    const {t} = useLocalizationContext();
    const SearchServiceSuppliers = useInjection<ISearchServiceSuppliers>(
        IoC.SearchServiceSuppliers
    );
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const {mode} = useAppContext();
    const {onDownloadLink, onDocumentPreviewMobile} = useChannelContext();
    const dispatch = useDispatch();
    const isWebView = mode === AppMode.WebView;
    const [documentState, setDocumentState] = useState<PreviewPanelState>(initialPanelPreviewState);

    useEffect(() => {
        setDocumentState(initialPanelPreviewState);
    }, [id]);

    const handlePreviewDocument = () => {
        if (isWebView) {
            const previewUrl = urlToPath(SearchServiceSuppliers.getPreviewDocumentUrl(id));
            const downloadUrl = SearchServiceSuppliers.getDownloadDocumentUrl(
                encodeURIComponent(id)
            );

            onDocumentPreviewMobile({downloadUrl, previewUrl});
        } else {
            setDocumentState((prevState: PreviewPanelState) => ({...prevState, loading: true, id}));
        }
    };

    const onDownloadPreviewDocument = () => {
        const {id} = documentState;
        if (id) {
            downloadWithBlob({
                service: SearchServiceSuppliers,
                id,
                mode,
                logger,
                mobileHandler: onDownloadLink,
                dispatch,
            });
        }
    };

    const onDocumentLoad = (loading: boolean) => {
        setDocumentState(prevState => ({...prevState, loading}));
    };

    const onDocumentError = (error: string | undefined) => {
        setDocumentState(prevState => ({...prevState, error}));
    };

    const onClosePreview = () => {
        setDocumentState(prevState => ({...prevState, id: undefined}));
    };

    const PreviewComponent = entityLabel === 'COUNTRY' ? CountryPreview : DefaultPreview;

    if (documentState.id && !isWebView) {
        return (
            <div className={styles.documentPreviewContainer}>
                {documentState.loading && (
                    <div className={styles.loading}>
                        <Loader />
                    </div>
                )}

                {documentState.error ? (
                    <div className={styles.error}>
                        <DocViewerError
                            onClose={onClosePreview}
                            onDownload={onDownloadPreviewDocument}
                            title={t('documents.documentPreview.documentCannotBePreviewed')}
                            description={t('documents.documentPreview.pleaseDownloadToView')}
                            imageSrc={CannotBePreviewed}
                            imageClasses={styles.noResultsIcon}
                            noResultsContainerClasses={styles.noResultsContainer}
                        />
                    </div>
                ) : (
                    <DocViewer
                        url={getFileUrl(SearchServiceSuppliers, id)}
                        fileName={id}
                        onLoad={onDocumentLoad}
                        onDownload={onDownloadPreviewDocument}
                        onError={onDocumentError}
                        className={styles.documentPreview}
                        onClose={onClosePreview}
                        isFullScreen={true}
                    />
                )}
            </div>
        );
    }

    return (
        <PreviewContentLayout>
            <PreviewComponent
                {...props}
                {...documentState}
                onPreviewDocument={handlePreviewDocument}
            />
        </PreviewContentLayout>
    );
};
