import React from 'react';
import classNames from 'classnames';
import styles from './list.module.scss';
import {IIdObject, IListProps} from 'app/views/components/list/list.types';

enum Keycodes {
    Space = 32,
    Enter = 13,
}

const onGenericItemKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.keyCode === Keycodes.Enter || event.keyCode === Keycodes.Space) {
        event.preventDefault();
    }
};

const onGenericItemKeyUp = <Item extends IIdObject>(
    event: React.KeyboardEvent<HTMLLIElement>,
    item: Item,
    callback?: (item: Item) => void
) => {
    if (event.keyCode === Keycodes.Enter || event.keyCode === Keycodes.Space) {
        event.preventDefault();
        callback?.(item);
    }
};

export const List = <Item extends IIdObject>({
    itemComponentRender,
    itemsData,
    selectedItemId,
    onSelect,
    searchWords = [],
    dividers,
    className,
    listRef,
    onScroll,
    noPaddings,
    hideHighlightSelectedItem,
}: IListProps<Item>) => {
    const ItemComponent = itemComponentRender;
    return (
        <ul className={classNames(styles.list, className)} ref={listRef} onScroll={onScroll}>
            {itemsData.map((data: Item) => {
                let itemClasses = classNames(styles.item);

                if (!hideHighlightSelectedItem) {
                    itemClasses = classNames(
                        {
                            [styles.active]: selectedItemId && selectedItemId === data.id,
                        },
                        styles.item
                    );
                }

                let itemContainerClasses = classNames(
                    {
                        [styles.noPaddingsItem]: noPaddings,
                        [styles.dividers]: dividers,
                    },
                    styles.itemContainer
                );

                return (
                    <li
                        key={data.id}
                        role="button"
                        tabIndex={0}
                        className={itemClasses}
                        onClick={() => {
                            onSelect?.(data);
                        }}
                        onKeyDown={onGenericItemKeyDown}
                        onKeyUp={event => {
                            onGenericItemKeyUp<Item>(event, data, onSelect);
                        }}
                    >
                        <div className={itemContainerClasses}>
                            <ItemComponent item={data} searchWords={searchWords} />
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};
