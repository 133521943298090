import {EntityType} from 'app/contexts/common.types';

export interface ISuggestion {
    id: string;
    type: string;
    title: string;
    subTitle: string;
    extension?: string;
    icon?: string;
}

export interface IGetSuggestionsCategory {
    items: ISuggestion[];
    name: string;
}

export interface IGetSuggestions {
    apiURL: string;
    accessToken: string;
    query: string;
    responsesNumber: number;
}

export interface IGetHistory {
    apiURL: string;
    accessToken: string;
}

export interface ICorrespondence {
    date: string;
    fromStructure: string;
    id: string;
    isReply: string;
    reference: string;
    searchString: string;
    status: string;
    subject: string;
    historyDate?: string;
    hasAttachments: boolean;
}

export interface IDocument {
    fileName: string;
    icon: string;
    id: string;
    modifiedBy: string;
    searchString: string;
    updateDate: string;
    historyDate?: string;
}

export interface IEmail {
    date: string;
    // TODO email or emailAddress or address
    emailAddress: string;
    hasAttachments: string;
    id: string;
    name: string;
    searchString: string;
    subject: string;
    trailer: string;
    historyDate?: string;
}

export interface IEmployee {
    city: string;
    // TODO email or emailAddress or address
    email: string;
    id: string;
    name: string;
    searchString: string;
    title: string;
    unit: string;
    historyDate?: string;
}

export interface ITraining {
    icon: string;
    id: string;
    name: string;
    provider: string;
    searchString: string;
    historyDate?: string;
}

export interface INews {
    id: string;
    title: string;
    image: string;
    date: string;
    tags: string[];
    company: string;
    originalUrl: string;
    searchString: string;
    historyDate?: string;
}

export interface ITender {
    id: string;
    searchString: string;
    name: string;
    type: string;
    category: string;
    submitionTotal: {
        usd: string;
        aed: string;
    };
    date: string;
    historyDate?: string;
}

export interface ISupplier {
    id: string;
    searchString: string;
    text: string;
    term: string;
    entityLabel: string;
    entityStyle: string;
    entityBadge: string;
    historyDate?: string;
}

export interface ICustomer {
    id: string;
    searchString: string;
    text: string;
    term: string;
    label: string;
    style: string;
    historyDate?: string;
}

export interface IService {
    id: string;
    searchString: string;
    description: string;
    number: string;
    category: string;
    historyDate?: string;
}

export interface IProject {
    id: string;
    searchString: string;
    projectCompanyName: string;
    projectName: string;
    projectCode: string;
    historyDate?: string;
}

export interface IDistribution {
    id: string;
    name: string;
    status: string;
    searchString: string;
    historyDate?: string;
}

export enum SuggestionResponse {
    Correspondence = 'correspondence',
    Document = 'document',
    Email = 'email',
    Employee = 'employee',
    Training = 'training',
    News = 'news',
    Tender = 'tender',
    Supplier = 'supplier',
    Customer = 'customer',
    Service = 'service',
    Project = 'project',
    Distribution = 'distributions',
}

export const mapSuggestionsToEntity: Partial<{[key in SuggestionResponse]: EntityType}> = {
    [SuggestionResponse.Correspondence]: EntityType.Correspondence,
    [SuggestionResponse.Document]: EntityType.Document,
    [SuggestionResponse.Email]: EntityType.Email,
    [SuggestionResponse.Employee]: EntityType.Employee,
    [SuggestionResponse.Training]: EntityType.Training,
    [SuggestionResponse.News]: EntityType.News,
    [SuggestionResponse.Tender]: EntityType.Tender,
    [SuggestionResponse.Supplier]: EntityType.Suppliers,
    [SuggestionResponse.Customer]: EntityType.Customers,
    [SuggestionResponse.Service]: EntityType.Service,
    [SuggestionResponse.Project]: EntityType.Projects,
    [SuggestionResponse.Distribution]: EntityType.Distribution,
};

export interface IGetSuggestionResponse {
    [SuggestionResponse.Correspondence]?: ICorrespondence[];
    [SuggestionResponse.Document]?: IDocument[];
    [SuggestionResponse.Email]?: IEmail[];
    [SuggestionResponse.Employee]?: IEmployee[];
    [SuggestionResponse.Training]?: ITraining[];
    [SuggestionResponse.News]?: INews[];
    [SuggestionResponse.Tender]?: ITender[];
    [SuggestionResponse.Supplier]?: ISupplier[];
    [SuggestionResponse.Customer]?: ICustomer[];
    [SuggestionResponse.Service]?: IService[];
    [SuggestionResponse.Project]?: IProject[];
    [SuggestionResponse.Distribution]?: IDistribution[];
}

export interface IGetHistoryResponse {
    correspondence: ICorrespondence[];
    document: IDocument[];
    email: IEmail[];
    employee: IEmployee[];
    training: ITraining[];
    news: INews[];
    tenders: ITender[];
    suppliers: ISupplier[];
    customers: ICustomer[];
    tickets: IService[];
    projects: IProject[];
    distributions: IDistribution[];
}
