import React, {FC} from 'react';
import classnames from 'classnames';
import {TextAvatar} from 'app/views/components/textAvatar';
import {useLocalizationContext} from 'app/hooks';

import styles from './SupplierInfo.module.scss';
import {InfoTable} from 'app/views/components/infoTable';
import {ColorLabel, colors} from 'app/views/components/colorLabel/colorLabel';

const Badge: FC<{name: string; count: number}> = ({name, count}) => (
    <div className={styles.supplierItem}>
        <div className={styles.alignCenter}>
            <div className={styles.avatarContainer}>
                {count > 0 ? (
                    <TextAvatar
                        title={name}
                        avatarProps={{className: styles.avatar}}
                        colorThemeNumber={0}
                    />
                ) : (
                    <TextAvatar
                        title={name}
                        avatarProps={{
                            className: classnames(styles.avatar, styles.avatarDisabled),
                        }}
                    />
                )}
                <div className={styles.count}>{count}</div>
            </div>
        </div>
        <div className={styles.avatarTitle}>{name}</div>
    </div>
);

const statusColorMap: {[key: string]: colors} = {
    ACT: 'green',
    INAC: 'lightGray',
    SUS: 'orange',
    TEMP: 'brightRed',
    REJ: 'red',
    REG: 'darkBlue',
    PREG: 'blue',
    'N/A': 'gray',
};
const statusTextKeys: {[key: string]: string} = {
    ACT: 'active',
    INAC: 'inactive',
    SUS: 'suspended',
    TEMP: 'temporary',
    REJ: 'rejected',
    REG: 'registered',
    PREG: 'preRegistered',
    'N/A': 'n_a',
};

const getStatusColor = (status: string): colors => {
    if (Object.keys(statusColorMap).includes(status)) {
        return statusColorMap[status];
    }
    return statusColorMap['N/A'];
};

const getStatusName = (status: string, t: (key: string) => string) => {
    if (Object.keys(statusTextKeys).includes(status)) {
        return t(`suppliers.previewPanel.supplierInfo.statuses.${statusTextKeys[status]}`);
    }
    return t('suppliers.previewPanel.supplierInfo.statuses.n_a');
};

export const SupplierInfo: FC<{vendorStats: any; vendorProfile: any}> = ({
    vendorStats,
    vendorProfile,
}) => {
    const {t} = useLocalizationContext();

    const {
        providedPgsNr,
        supplierContactsNr,
        supplierLicensesNr,
        supplierAssociatesNr,
        supplierPrincipalsNr,
        providedWgsNr,
    } = vendorStats;

    const badges: {name: string; count: number}[] = [
        {
            name: t('suppliers.previewPanel.supplierInfo.badges.licences'),
            count: supplierLicensesNr,
        },
        {
            name: t('suppliers.previewPanel.supplierInfo.badges.contracts'),
            count: supplierContactsNr,
        },
    ];
    const contractorBadges: {name: string; count: number}[] = [
        {
            name: t('suppliers.previewPanel.supplierInfo.badges.associates'),
            count: supplierAssociatesNr,
        },

        {
            name: t('suppliers.previewPanel.supplierInfo.badges.provided'),
            count: providedWgsNr,
        },
    ];
    const vendorBadges: {name: string; count: number}[] = [
        {
            name: t('suppliers.previewPanel.supplierInfo.badges.principals'),
            count: supplierPrincipalsNr,
        },
        {
            name: t('suppliers.previewPanel.supplierInfo.badges.providedPGs'),
            count: providedPgsNr,
        },
    ];

    const {
        supplierNameTx,
        supplierContractorStatusTx,
        supplierVendorStatusTx,
        supplierManufacturerStatusTx,
        supplierFunctionTx,
        supplierCityTx,
        supplierStreetTx,
        supplierPoBoxTx,
        supplierEmailTx,
        supplierFaxTx,
        supplierPhoneTx,
        supplierUid,
    } = vendorProfile;

    const divider = ' / ';
    const roles: string[] = supplierFunctionTx.split(divider);

    const roleContractorExists: boolean = roles.includes('CONTRACTOR');
    const roleVendorExists: boolean = roles.includes('VENDOR');
    const roleManufacturerExists: boolean = roles.includes('MANUFACTURER');
    const anotherRole: boolean = [
        roleContractorExists,
        roleVendorExists,
        roleManufacturerExists,
    ].every(role => !role);

    return (
        <div className={styles.supplierInfo}>
            <header className={styles.supplierInfoTitle}>
                {t('suppliers.previewPanel.supplierInfo.header')}
            </header>
            <div className={styles.panel}>
                <div className={styles.left}>
                    {roleContractorExists && (
                        <div className={styles.role}>
                            <div className={classnames(styles.icon, styles.iconContractor)} />
                            <div className={styles.statuses}>
                                <div className={styles.status}>
                                    {t('suppliers.previewPanel.supplierInfo.roles.contractor')}
                                </div>
                                <ColorLabel color={getStatusColor(supplierContractorStatusTx)}>
                                    {getStatusName(supplierContractorStatusTx, t)}
                                </ColorLabel>
                            </div>
                        </div>
                    )}
                    {roleVendorExists && (
                        <div className={styles.role}>
                            <div className={classnames(styles.icon, styles.iconVendor)} />
                            <div className={styles.statuses}>
                                <div className={styles.status}>
                                    {t('suppliers.previewPanel.supplierInfo.roles.vendor')}
                                </div>
                                <ColorLabel color={getStatusColor(supplierVendorStatusTx)}>
                                    {getStatusName(supplierVendorStatusTx, t)}
                                </ColorLabel>
                            </div>
                        </div>
                    )}
                    {roleManufacturerExists && (
                        <div className={styles.role}>
                            <div className={classnames(styles.icon, styles.iconManufacturer)} />
                            <div className={styles.statuses}>
                                <div className={styles.status}>
                                    {t('suppliers.previewPanel.supplierInfo.roles.manufacturer')}
                                </div>
                                <ColorLabel color={getStatusColor(supplierManufacturerStatusTx)}>
                                    {getStatusName(supplierManufacturerStatusTx, t)}
                                </ColorLabel>
                            </div>
                        </div>
                    )}
                    {anotherRole && (
                        <div className={styles.role}>
                            <div className={classnames(styles.icon, styles.iconVendor)} />
                            <div className={styles.statuses}>
                                <div className={styles.status}>
                                    {t('suppliers.previewPanel.supplierInfo.roles.another')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.right}>
                    {badges.map((b, i) => (
                        <Badge key={`default-${i}-${b.count}`} name={b.name} count={b.count} />
                    ))}

                    {roleVendorExists &&
                        vendorBadges.map((b, i) => (
                            <Badge key={`vendor-${i}-${b.count}`} name={b.name} count={b.count} />
                        ))}

                    {roleContractorExists &&
                        contractorBadges.map((b, i) => (
                            <Badge
                                key={`contractor-${i}-${b.count}`}
                                name={b.name}
                                count={b.count}
                            />
                        ))}
                </div>
            </div>
            <div className={styles.hr} />
            <h3 className={styles.tableTitle}>{supplierNameTx}</h3>
            <InfoTable
                column1={[
                    {
                        title: t('suppliers.previewPanel.supplierInfo.city'),
                        value: supplierCityTx,
                    },
                    {
                        title: t('suppliers.previewPanel.supplierInfo.street'),
                        value: supplierStreetTx,
                    },
                    {
                        title: t('suppliers.previewPanel.supplierInfo.po'),
                        value: supplierPoBoxTx,
                    },
                ]}
                column2={[
                    {
                        title: t('suppliers.previewPanel.supplierInfo.mail'),
                        value: supplierEmailTx,
                    },
                    {
                        title: t('suppliers.previewPanel.supplierInfo.fax'),
                        value: supplierFaxTx,
                    },
                    {
                        title: t('suppliers.previewPanel.supplierInfo.phone'),
                        value: supplierPhoneTx,
                    },
                    {
                        title: t('suppliers.previewPanel.supplierInfo.id'),
                        value: supplierUid,
                    },
                ]}
            />
        </div>
    );
};
