import React, {createContext, FC, useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import Fade from '@material-ui/core/Fade';
import {AppMode, IAppContext} from 'app/contexts';
import {useAppContext} from 'app/hooks';
import {locationSelector, selectedItemIdSelector} from 'app/state/router';
import {routeParams} from 'app/routes';
import {fetchInitialFilters, filterInitializedSelector} from 'app/state/filter';
import {documentsErrorsSelector} from 'app/state/documents';
import {mailErrorsSelector} from 'app/state/mail';
import {peopleErrorsSelector} from 'app/state/people';
import {trainingsErrorsSelector} from 'app/state/trainings';
import {correspondenceErrorsSelector} from 'app/state/correspondence';
import {sendMessage} from 'app/infrastructure/onehub';
import {EventType} from 'app/contexts/channelContext/channelContext.types';
import {SearchPageContent} from './components/content';

type SearchPageContextType = {
    isTabNavigationVisible: boolean;
    setTabNavigationVisibility: any;
};

export const SearchPageContext = createContext({
    isTabNavigationVisible: true,
    setTabNavigationVisibility: (b: boolean) => {},
});

export const SearchPage: FC = ({children}) => {
    const context: IAppContext = useAppContext();

    const getDocumentsErrors = useSelector(documentsErrorsSelector, shallowEqual);
    const getMailErrors = useSelector(mailErrorsSelector, shallowEqual);
    const getPeopleErrors = useSelector(peopleErrorsSelector, shallowEqual);
    const getTrainingsErrors = useSelector(trainingsErrorsSelector, shallowEqual);
    const getCorrespondenceErrors = useSelector(correspondenceErrorsSelector, shallowEqual);

    // TODO: check errors from all entities
    const throwFetchError =
        getDocumentsErrors &&
        getMailErrors &&
        getPeopleErrors &&
        getTrainingsErrors &&
        getCorrespondenceErrors;

    const isFullscreenMode = context.mode === AppMode.FullScreen;
    const location = useSelector(locationSelector);
    const [isTabNavigationVisible, setTabNavigationVisibility] = useState<boolean>(true);
    const isPreviewMode = !!useSelector(selectedItemIdSelector);
    const isFiltersInitialized = useSelector(filterInitializedSelector);
    const shouldHideTabsBarOnMobile =
        isFullscreenMode && (isPreviewMode || !isTabNavigationVisible);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isFiltersInitialized) {
            dispatch(fetchInitialFilters());
        }
    }, [dispatch, isFiltersInitialized]);

    /**
     * Navigation enabling after each @@router/LOCATION_CHANGE
     * Reproduced on small screens
     */
    useEffect(() => {
        if (!isTabNavigationVisible) {
            setTabNavigationVisibility(true);
        }
    }, [location.pathname, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    const onTabChange = useCallback(
        (id: string) => {
            // for example ?q=some%20value&id=228 ===> { q: 'some value', id: 228 }
            const query = parse(location.search.substring(1));
            delete query[routeParams.SELECTED_ID];
            dispatch(push(id + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const searchPageContextValue: SearchPageContextType = {
        isTabNavigationVisible,
        setTabNavigationVisibility,
    };

    const appContext: IAppContext = useAppContext();

    const handleSearchClick = useCallback(() => {
        if (appContext.mode !== AppMode.FullScreen) {
            sendMessage({
                eventType: EventType.Hint,
                eventData: 'onSearchClick',
            });
        }
    }, [appContext.mode]);

    const Content = (
        <SearchPageContent
            isFullscreenMode={isFullscreenMode}
            handleSearchClick={handleSearchClick}
            onTabChange={onTabChange}
            throwFetchError={throwFetchError}
            location={location}
            shouldHideTabsBarOnMobile={shouldHideTabsBarOnMobile}
        >
            {children}
        </SearchPageContent>
    );

    return (
        <SearchPageContext.Provider value={searchPageContextValue}>
            {isFullscreenMode ? (
                <Fade in timeout={500}>
                    {Content}
                </Fade>
            ) : (
                Content
            )}
        </SearchPageContext.Provider>
    );
};
