import React from 'react';
import styles from '../searchPage.module.scss';
import {TopBar} from 'app/views/components/topBar';
import {SearchBar} from 'app/views/pageContent';
import {TabsBar} from 'app/views/components/tabsBar';
import {SearchPageTabs} from './tabs';
import {ErrorBoundaryContainer} from 'app/views/components';
import {useLocalizationContext} from 'app/hooks';

interface ISearchPageContent {
    isFullscreenMode: boolean;
    handleSearchClick: () => void;
    onTabChange: (id: string) => void;
    children: React.ReactNode;
    throwFetchError: any;
    location: any;
    shouldHideTabsBarOnMobile: boolean;
}

export const SearchPageContent = ({
    isFullscreenMode,
    handleSearchClick,
    onTabChange,
    children,
    throwFetchError,
    location,
    shouldHideTabsBarOnMobile,
}: ISearchPageContent) => {
    const {t} = useLocalizationContext();

    return (
        <div onClick={handleSearchClick} className={styles.searchPage}>
            {isFullscreenMode && (
                <TopBar>
                    <SearchBar />
                </TopBar>
            )}
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <TabsBar
                        name={t('tabs.name')}
                        ariaLabel={t('tabs.ariaLabel')}
                        currentTab={location.pathname}
                        isHiddenOnMobile={shouldHideTabsBarOnMobile}
                        onChange={onTabChange}
                        tabs={SearchPageTabs()}
                    />
                    <div className={styles.content} role="tabpanel">
                        <ErrorBoundaryContainer fetchErrors={throwFetchError}>
                            {children}
                        </ErrorBoundaryContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};
