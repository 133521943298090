import React from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import styles from './address.module.scss';
import {InfoTable} from 'app/views/components/infoTable';

export const Address = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();

    const {
        details: {address},
    } = data;

    if (!address) return null;

    const {
        completeAddress,
        phoneNumber,
        phoneNumberExtension,
        mobilePhoneNumber,
        faxNumber,
        faxNumberExtension,
        emailAddress,
        businessPartner,
    } = address;

    const r = (value: any) => (value ? value : t('customers.preview.NA'));

    return (
        <div className={classNames(className)}>
            <h3>{t('customers.preview.customerAddress')}</h3>

            <div className={styles.tableContainer}>
                <InfoTable
                    column1={[
                        {
                            title: t('customers.preview.address'),
                            value: r(completeAddress),
                        },
                        {
                            title: t('customers.preview.email'),
                            value: r(emailAddress),
                        },
                        {
                            title: t('customers.preview.fax'),
                            value: `${r(faxNumber)} ${t('customers.preview.extension')} ${r(
                                faxNumberExtension
                            )}`,
                        },
                    ]}
                    column2={[
                        {
                            title: t('customers.preview.phone'),
                            value: `${r(phoneNumber)} ${t('customers.preview.extension')} ${r(
                                phoneNumberExtension
                            )}`,
                        },
                        {
                            title: t('customers.preview.mobilePhone'),
                            value: r(mobilePhoneNumber),
                        },
                        {
                            title: t('customers.preview.adnocUID'),
                            value: r(businessPartner),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
