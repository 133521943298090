import {RootState} from 'app/state';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {ISearchDistributionsOptions} from 'app/api/searchAPI/distributions/searchAPICaller.distributions.types';
import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {DistributionsSortOptions} from '../../containers/distribution/distribution.types';

const getFilters = (filters: IFilterRefiner[]): {field: string; values: any[]}[] => {
    const listFilters = [...filters];

    return listFilters
        .map(f => ({
            field: f.Name,
            values: f.Entries.filter(entry => entry.isChecked).map(entry => entry.RefinementValue),
        }))
        .filter(f => f.values.length > 0);
};

export const getSort = (sortField: string): {orderBy: string; order: string} | {} => {
    switch (sortField) {
        case DistributionsSortOptions.nameAZ:
            return {
                orderBy: 'Name',
                order: 'asc',
            };
        case DistributionsSortOptions.nameZA:
            return {
                orderBy: 'Name',
                order: 'desc',
            };
        case DistributionsSortOptions.ratingDesc:
            return {
                orderBy: 'Rating',
                order: 'desc',
            };

        default:
            return {};
    }
};

export const getSearchDistributionsOptions = (
    key: string,
    state: RootState,
    sort: DistributionsSortOptions,
    filters: IFilterRefiner[]
): ISearchDistributionsOptions => {
    const pageSize = DEFAULT_PAGE_SIZE;
    const nextPage = Math.floor((state.distributions.items.length + pageSize) / pageSize);

    return {
        key,
        pageSize,
        pageNumber: nextPage,
        filters: getFilters(filters),
        ...getSort(sort),
    };
};
