import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {useChannelContext, useLocalizationContext} from 'app/hooks';
import {EntityType} from 'app/contexts';
import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';
import {
    isDocumentLoadingSelector,
    documentsListSelector,
    documentsFiltersSelector,
    documentsSortSelector,
    documentsErrorCodeSelector,
    searchDocuments,
    setDocumentsSortBy,
    setDocumentsFilters,
    resetDocumentsState,
    resetDocumentsTab,
} from 'app/state/documents';
import {IDocumentItem} from 'app/state/documents/documents.types';
import {IAllFilters, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {ISortOption} from 'app/views/components/sortPanel/sortPanel.types';
import {DocumentsFiltersOptions, DocumentsSortOptions} from './documents.types';
import {
    documentsMoreResultsAvailableSelector,
    documentsTotalCountSelector,
} from 'app/state/documents';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {DocumentPreviewPanel} from 'app/views/documents/components/documentPreviewPanel';
import {DocumentListItem} from 'app/views/documents/components/documentListItem';
import {DocumentListItemSkeleton} from 'app/views/documents/components/documentListItemSkeleton/documentListItemSkeleton';
import documentsLoading from 'app/static/images/documentsLoading.svg';
import noResultsDocuments from 'app/static/images/noResultsDocuments.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const DocumentsSortOptionsI18n = {
    [DocumentsSortOptions.NoSort]: 'documents.documentsContainer.sortRelevance',
    [DocumentsSortOptions.NewToOld]: 'documents.documentsContainer.sortNewToOld',
    [DocumentsSortOptions.OldToNew]: 'documents.documentsContainer.sortOldToNew',
};

const sortList: ISortOption[] = Object.values(DocumentsSortOptions).map(
    (option: DocumentsSortOptions): ISortOption => ({
        name: DocumentsSortOptionsI18n[option],
        value: option,
    })
);

export const DocumentsContainer = () => {
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();

    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector, shallowEqual);
    const searchWords = useSelector(searchWordsSelector, shallowEqual);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const filters = useSelector(documentsFiltersSelector, shallowEqual);
    const items = useSelector(documentsListSelector, shallowEqual);
    const currentSort = useSelector(documentsSortSelector, shallowEqual);
    const areDocumentsLoading = useSelector(isDocumentLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(
        documentsMoreResultsAvailableSelector,
        shallowEqual
    );
    const totalItemsCount: number = useSelector(documentsTotalCountSelector);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(documentsErrorCodeSelector);

    const selectedItem: IDocumentItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetDocumentsState());
        dispatch(searchDocuments(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetDocumentsTab());
        };
    }, [dispatch]);

    const loadMoreHandler = useCallback(() => {
        if (!areDocumentsLoading && areMoreResultsAvailable) {
            dispatch(searchDocuments(searchQuery, items.length, true));
        }
    }, [areDocumentsLoading, areMoreResultsAvailable, dispatch, searchQuery, items.length]);

    const onSelect = useCallback(
        (document: IDocumentItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: document.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const onSortChange = useCallback(
        value => {
            dispatch(setDocumentsSortBy(value));
        },
        [dispatch]
    );

    const onApplyFilters = useCallback(
        (selectedFilters: IAllFilters[]) => {
            const resultFilters: IFilterRefiner[] = filters.map(
                ({Name, Entries, type}: IFilterRefiner) => {
                    return {
                        Name,
                        type,
                        Entries: Entries.map(entry => {
                            const currentEntry = selectedFilters.find(
                                ({filter, refinementName, refinementValue}: IAllFilters) =>
                                    filter === Name &&
                                    entry.RefinementName === refinementName &&
                                    entry.RefinementValue === refinementValue
                            );

                            return {
                                ...entry,
                                isChecked: Boolean(currentEntry),
                                value: currentEntry?.value,
                            };
                        }),
                    };
                }
            );

            dispatch(setDocumentsFilters(resultFilters));
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch, filters]
    );

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Document,
            entity: selectedItem,
        }
    );

    const filterLabelMapper = useCallback(
        (name: string): string => {
            switch (name) {
                case DocumentsFiltersOptions.FileType:
                    return t('documents.documentsFilters.filterFileType');
                case DocumentsFiltersOptions.DisplayAuthor:
                    return t('documents.documentsFilters.filterAuthor');
                case DocumentsFiltersOptions.SiteTemplateId:
                    return t('documents.documentsFilters.filterSource');
                case DocumentsFiltersOptions.CreatedDate:
                    return t('documents.documentsFilters.filterCreatedDate');
                default:
                    return t(name);
            }
        },
        [t]
    );

    const filter = {
        filterProps: filters,
        onApplyFilters,
        filterLabelMapper,
    };
    const list = {
        itemComponentRender: DocumentListItem,
        itemSkeletonRender: DocumentListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };
    const sorting = {
        sortList,
        onSortChange,
        value: currentSort,
    };

    return (
        <SearchResultLayout
            isLoading={areDocumentsLoading}
            filter={filter}
            list={list}
            totalItemsCount={totalItemsCount}
            sorting={sorting}
            selectedItem={selectedItem}
            previewComponent={DocumentPreviewPanel}
            noResultImageUrl={noResultsDocuments}
            chooseItemImageUrl={documentsLoading}
            translateRoot={'documents'}
        />
    );
};
