import React, {FC} from 'react';

import {toMoneyFormat} from 'app/utils';

import {Tabs} from 'app/views/components/tabs';
import {Table, TableProps} from 'app/views/components/table';

import styles from './TabsTable.module.scss';
import {TabsTableProps} from './TabsTable.types';

import {useLocalizationContext, useCurrency} from 'app/hooks';

const TabComponent: FC<TableProps> = props => {
    return (
        <div className={styles.tabComponent}>
            <Table {...props} />
        </div>
    );
};

export const TabsTable: FC<TabsTableProps> = ({
    contractTableItems,
    tenderTableItems,
    invoiceTableItems,
}) => {
    const {t} = useLocalizationContext();
    const [selectedCurrency] = useCurrency();

    //#region Contact
    const contractTitles = [
        t('suppliers.previewPanel.tabsTable.contractTabValues.contractNumber'),
        t('suppliers.previewPanel.tabsTable.contractTabValues.company'),
        t('suppliers.previewPanel.tabsTable.contractTabValues.description'),
        t('suppliers.previewPanel.tabsTable.contractTabValues.documentType'),
        t('suppliers.previewPanel.tabsTable.contractTabValues.status'),
        t('suppliers.previewPanel.tabsTable.contractTabValues.totalCost'),
    ];
    const contractValues = contractTableItems
        .sort((val1, val2) => (val1.period < val2.period ? 1 : -1))
        .sort((val1, val2) => (val1.company > val2.company ? 1 : -1))
        .map(item => [
            item.contractNumber || '-',
            item.company || '-',
            item.description || '-',
            item.documentType || '-',
            item.status ? <span className={styles.status}>{item.status}</span> : '-',
            selectedCurrency === 'usd'
                ? `$ ${toMoneyFormat(item.totalCost)}`
                : `${toMoneyFormat(item.totalCostAed)} AED`,
        ]);
    //#endregion Contact

    //#region Tender
    const tenderTitles = [
        t('suppliers.previewPanel.tabsTable.tenderTabValues.rfqNumber'),
        t('suppliers.previewPanel.tabsTable.tenderTabValues.company'),
        t('suppliers.previewPanel.tabsTable.tenderTabValues.description'),
        t('suppliers.previewPanel.tabsTable.tenderTabValues.status'),
        t('suppliers.previewPanel.tabsTable.tenderTabValues.estCost'),
        t('suppliers.previewPanel.tabsTable.tenderTabValues.lineCost'),
    ];
    const tenderValues = tenderTableItems
        .sort((val1, val2) => (val1.period < val2.period ? 1 : -1))
        .sort((val1, val2) => (val1.company > val2.company ? 1 : -1))
        .map(item => [
            item.rfqNumber || '-',
            item.company || '-',
            item.description || '-',
            item.status ? <span className={styles.status}>{item.status}</span> : '-',
            selectedCurrency === 'usd'
                ? `$ ${toMoneyFormat(item.estCost)}`
                : `${toMoneyFormat(item.estCostAed)} AED`,
            selectedCurrency === 'usd'
                ? `$ ${toMoneyFormat(item.lineCost)}`
                : `${toMoneyFormat(item.lineCostAed)} AED`,
        ]);
    //#endregion Tender

    //#region Invoice
    const invoiceTitles = [
        t('suppliers.previewPanel.tabsTable.invoiceTabValues.invoiceNumber'),
        t('suppliers.previewPanel.tabsTable.invoiceTabValues.company'),
        t('suppliers.previewPanel.tabsTable.invoiceTabValues.description'),
        t('suppliers.previewPanel.tabsTable.invoiceTabValues.status'),
        t('suppliers.previewPanel.tabsTable.invoiceTabValues.receiveDate'),
        t('suppliers.previewPanel.tabsTable.invoiceTabValues.invoiceValue'),
    ];
    const invoiceValues = invoiceTableItems
        .sort((val1, val2) => (val1.period < val2.period ? 1 : -1))
        .sort((val1, val2) => (val1.company > val2.company ? 1 : -1))
        .map(item => [
            item.invoiceNumber || '-',
            item.company || '-',
            item.description || '-',
            item.status ? <span className={styles.status}>{item.status}</span> : '-',
            item.receiveDate || '-',
            selectedCurrency === 'usd'
                ? `$ ${toMoneyFormat(item.invoiceValue)}`
                : `${toMoneyFormat(item.invoiceValueAed)} AED`,
        ]);
    //#endregion Invoice

    const tabs: any = [];

    tenderValues.length &&
        tabs.push({
            label: t('suppliers.previewPanel.tabsTable.tabs.tenderTab'),
            component: TabComponent,
            props: {
                titles: tenderTitles,
                values: tenderValues,
            },
        });
    invoiceValues.length &&
        tabs.push({
            label: t('suppliers.previewPanel.tabsTable.tabs.invoiceTab'),
            component: TabComponent,
            props: {
                titles: invoiceTitles,
                values: invoiceValues,
            },
        });
    contractValues.length &&
        tabs.push({
            label: t('suppliers.previewPanel.tabsTable.tabs.contractTab'),
            component: TabComponent,
            props: {
                titles: contractTitles,
                values: contractValues,
            },
        });

    const tabsOptions = {
        name: 'name',
        ariaLabel: 'ariaLabel',
        tabs,
        customClasses: {
            tabsContainer: styles.tabsContainer,
            appBarRoot: styles.tabsMuiAppBarRoot,
        },
    };

    return <Tabs {...tabsOptions} />;
};
