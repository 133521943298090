import {all, getContext, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    getCustomerDetails as getCustomerDetailsAction,
    getCustomerDetailsFailure,
    getCustomerDetailsSuccess,
    resetCustomerDetails,
    searchCustomers as searchCustomersAction,
    searchCustomersFailure,
    searchCustomersSuccess,
    setPeriod as setPeriodAction,
} from './customers.actions';
import {CustomerType, ICustomerItem} from '../customers';
import {periods} from 'app/utils/periodsUtil';

function* searchCustomers({payload}: ReturnType<typeof searchCustomersAction>): any {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');

        const customers = yield searchService.customers.get().searchCustomers({
            searchString: payload,
        });
        yield put(
            searchCustomersSuccess({
                customers: customers.rows.map((customer: ICustomerItem) => ({
                    ...customer,
                    type:
                        customer.label === 'COUNTRY'
                            ? CustomerType.Country
                            : customer.label === 'HOLDING'
                            ? CustomerType.Holding
                            : CustomerType.Customer,
                })),
            })
        );
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchCustomersFailure(error));
    }
}

export function* getCustomerDetails({
    payload: {customerId, period, customerType},
}: ReturnType<typeof getCustomerDetailsAction>) {
    const logger = yield getContext('logger');
    try {
        let details;
        const {from, to} = periods[period].value;
        const searchService = yield getContext('searchService');
        if (customerType === CustomerType.Country) {
            details = yield searchService.customers.get().getCountryDetails({
                countryId: customerId,
                from,
                to,
            });
        } else {
            details = yield searchService.customers.get().getCustomerDetails({
                customerId,
                from,
                to,
                entityType: customerType,
            });
        }

        yield put(getCustomerDetailsSuccess({customerId, details}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getCustomerDetailsFailure(error));
    }
}

function* setPeriod(): any {
    const logger = yield getContext('logger');
    try {
        yield put(resetCustomerDetails());
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* customersSaga() {
    yield all([
        yield takeLatest(String(searchCustomersAction), searchCustomers),
        yield takeLatest(String(setPeriodAction), setPeriod),
        yield takeEvery(String(getCustomerDetailsAction), getCustomerDetails),
    ]);
}
