import {all, getContext, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    applyProjectsFilters as applyProjectsFiltersAction,
    getProjectDetails as getProjectDetailsAction,
    getProjectDetailsFailure,
    getProjectDetailsSuccess,
    resetProjectsItems,
    resetProjectsState,
    resetProjectsTab as resetProjectsTabAction,
    searchProjects as searchProjectsAction,
    searchProjectsFailure,
    searchProjectsSuccess,
    setProjectsSortBy as setProjectsSortByAction,
    setProjectsSortByError,
} from 'app/state/projects/projects.actions';
import {ISearchProjectsOptions} from 'app/api/searchAPI/projects/searchAPICaller.projects.types';
import {
    getSearchProjectsOptions,
    transformProjectItems,
} from 'app/state/projects/projects.operations';
import {RootState} from 'app/state';
import {searchQuerySelector} from '../router';
import {projectFiltersSelector, projectsSortSelector} from './projects.selectors';
import {isFilterSelected} from 'app/utils/filterUtils';
import {resetSingleFilter} from '../filter';
import {EntityType} from 'app/contexts';
import {ISearchProjectResponse} from 'app/infrastructure/search/projects/searchService.projects.types';
import {ProjectsSortOptions} from 'app/containers/projects/projects.types';

function* searchProjects({payload}: ReturnType<typeof searchProjectsAction>): any {
    const logger = yield getContext('logger');
    try {
        const state: RootState = yield select();
        const searchService = yield getContext('searchService');
        const filters = yield select(projectFiltersSelector);
        const sort = yield select(projectsSortSelector);

        const options: ISearchProjectsOptions = getSearchProjectsOptions(
            payload,
            state,
            filters,
            sort
        );
        const {
            count,
            projects,
        }: ISearchProjectResponse = yield searchService.projects.get().searchProjects(options);

        yield put(
            searchProjectsSuccess({
                items: transformProjectItems(projects),
                totalCount: count,
            })
        );
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchProjectsFailure(error));
    }
}

export function* getProjectDetails({payload}: ReturnType<typeof getProjectDetailsAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const projectId = payload;
        const details = yield searchService.projects.get().getProjectDetails(projectId);

        yield put(
            getProjectDetailsSuccess({
                projectId,
                details,
            })
        );
    } catch (error) {
        yield logger.get().error(error);
        yield put(getProjectDetailsFailure(error));
    }
}

function* applyProjectsFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetProjectsItems());
        yield put(searchProjectsAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetProjectsTab(): any {
    const logger = yield getContext('logger');
    try {
        const sort = yield select(projectsSortSelector);
        const filters = yield select(projectFiltersSelector);

        if (sort !== ProjectsSortOptions.none || isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Projects}));
            yield put(resetProjectsState());
            const query = yield select(searchQuerySelector);
            yield put(searchProjectsAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* setProjectsSortBy(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetProjectsItems());
        yield put(searchProjectsAction(query));
    } catch (error) {
        yield logger.get().error(error);
        yield put(setProjectsSortByError());
    }
}

export function* projectsSaga(): any {
    yield all([
        yield takeLatest(String(searchProjectsAction), searchProjects),
        yield takeLatest(String(applyProjectsFiltersAction), applyProjectsFilters),
        yield takeLatest(String(setProjectsSortByAction), setProjectsSortBy),
        yield takeLatest(String(resetProjectsTabAction), resetProjectsTab),
        yield takeEvery(String(getProjectDetailsAction), getProjectDetails),
    ]);
}
