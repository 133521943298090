import React, {useCallback, useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useChannelContext} from 'app/hooks';
import {EntityType, CurrencyContextProvider} from 'app/contexts';
import {push} from 'connected-react-router';
import {routeParams} from 'app/routes';
import {
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';

import {
    suppliersSelector,
    areSuppliersLoadingSelector,
    areMoreSuppliersAvailableSelector,
    suppliersErrorCodeSelector,
    searchSuppliers,
    ISupplierItem,
    getSuppliersDetails,
    detailsFiltersSelector,
    setSuppliersDetailsFilters,
    resetSuppliersState,
    resetSuppliersTab,
} from 'app/state/suppliers';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {SuppliersListItem} from 'app/views/suppliers/components/suppliersListItem';
import {SuppliersListItemSkeleton} from 'app/views/suppliers/components/suppliersListItemSkeleton';
import {PreviewPanel} from 'app/views/suppliers/components/previewPanel';
import {SuppliersDetailsFilters} from './suppliers.types';
import DetailsCompanyFilterContext from './detailsCompanyFilterContext';
import DetailsShareFilterContext from './detailsShareFilterContext';
import {parse, stringify} from 'querystring';
import newsChoose from 'app/static/images/news.svg';
import noResultsNews from 'app/static/images/noResultsNews.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';

export const SuppliersContainer = () => {
    const dispatch = useDispatch();
    const {usePreviewItem} = useChannelContext();

    const searchQuery = useSelector(searchQuerySelector);
    const searchWords = useSelector(searchWordsSelector);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const items = useSelector(suppliersSelector, shallowEqual);
    const areProjectsLoading = useSelector(areSuppliersLoadingSelector, shallowEqual);
    const areMoreResultsAvailable = useSelector(areMoreSuppliersAvailableSelector, shallowEqual);
    const detailsFilters = useSelector(detailsFiltersSelector, shallowEqual);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(suppliersErrorCodeSelector);

    const selectedItem: ISupplierItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetSuppliersState());
        dispatch(searchSuppliers(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetSuppliersTab());
        };
    }, [dispatch]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Suppliers,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!areProjectsLoading && areMoreResultsAvailable) {
            dispatch(searchSuppliers(searchQuery));
        }
    }, [areMoreResultsAvailable, areProjectsLoading, dispatch, searchQuery]);

    const isPreviewLoading = Boolean(selectedItem) && !selectedItem?.details;

    const companiesFilter = detailsFilters.find(f => f.name === SuppliersDetailsFilters.Company);
    const companies = useMemo(() => companiesFilter?.items || [], [companiesFilter]);
    const shareFilter = detailsFilters.find(f => f.name === SuppliersDetailsFilters.Share);
    const share = useMemo(() => shareFilter?.items || [], [shareFilter]);

    useEffect(() => {
        if (selectedItem && !selectedItem.details) {
            if (!companiesFilter) return;

            const selectedCompany = companiesFilter.items.find(company => company.active);
            if (!selectedCompany) return;

            const selectedShare = shareFilter?.items.find(s => s.active);
            const selectedShareId = selectedShare?.id || 100;

            dispatch(getSuppliersDetails(selectedItem, selectedCompany.id, selectedShareId));
        }
    }, [dispatch, selectedItem, companiesFilter, shareFilter]);

    const onSelect = useCallback(
        (supplier: ISupplierItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: supplier.id,
            };
            delete query[routeParams.DISABLE_HISTORY_ONCE];
            dispatch(push(location.pathname + '?' + stringify(query)));
        },
        [dispatch, location.pathname, location.search]
    );

    const list = {
        itemComponentRender: SuppliersListItem,
        itemSkeletonRender: SuppliersListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    const toggleCompany = useCallback(
        (id: string): void => {
            if (!companiesFilter || !companies.length) return;

            // if the company is already active (selected)
            if (companies.find(c => c.id === id)?.active) return;

            const newCompanies = companies.map(i => ({...i, active: i.id === id}));

            // spread immutable array to standard array
            const newDetailsFilters = [...detailsFilters];
            // update previous company filter
            newDetailsFilters[detailsFilters.indexOf(companiesFilter)] = {
                ...companiesFilter,
                items: newCompanies,
            };

            dispatch(setSuppliersDetailsFilters(newDetailsFilters));
        },
        [companies, companiesFilter, detailsFilters, dispatch]
    );
    const toggleShare = useCallback(
        (id: string): void => {
            if (!shareFilter || !share.length) return;

            // if the company is already active (selected)
            if (share.find(c => c.id === id)?.active) return;

            const newShare = share.map(i => ({...i, active: i.id === id}));

            // spread immutable array to standard array
            const newDetailsFilters = [...detailsFilters];
            // update previous company filter
            newDetailsFilters[detailsFilters.indexOf(shareFilter)] = {
                ...shareFilter,
                items: newShare,
            };

            dispatch(setSuppliersDetailsFilters(newDetailsFilters));
        },
        [share, shareFilter, detailsFilters, dispatch]
    );

    return (
        <CurrencyContextProvider>
            <DetailsCompanyFilterContext.Provider value={[companies, toggleCompany]}>
                <DetailsShareFilterContext.Provider value={[share, toggleShare]}>
                    <SearchResultLayout
                        isLoading={areProjectsLoading}
                        list={list}
                        selectedItem={selectedItem}
                        previewComponent={PreviewPanel}
                        isPreviewLoading={isPreviewLoading}
                        noResultImageUrl={noResultsNews}
                        chooseItemImageUrl={newsChoose}
                        translateRoot={'suppliers'}
                    />
                </DetailsShareFilterContext.Provider>
            </DetailsCompanyFilterContext.Provider>
        </CurrencyContextProvider>
    );
};
