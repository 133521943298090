import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push, replace} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams} from 'app/routes';
import {IAllFilters, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {ClientType, EventType} from 'app/contexts/channelContext/channelContext.types';
import {
    clearPreviousSelectedItem,
    locationSelector,
    searchQuerySelector,
    searchWordsSelector,
    selectedItemIdSelector,
} from 'app/state/router';
import {
    changeCorrespondenceFilters,
    correspondenceErrorCodeSelector,
    correspondenceFiltersSelector,
    correspondenceItemsSelector,
    correspondenceTotalCountSelector,
    isCorrespondenceLoadingSelector,
    isMoreCorrespondenceAvailableSelector,
    resetCorrespondenceState,
    resetCorrespondenceTab,
    searchCorrespondence,
    setCorrespondenceFilters,
} from 'app/state/correspondence';
import {ICorrespondenceItem} from 'app/state/correspondence/correspondence.types';
import {AppMode, EntityType} from 'app/contexts';
import {useAppContext, useChannelContext, useLocalizationContext} from 'app/hooks';
import {getFilters} from './correspondence.filters';

import {SearchResultLayout} from 'app/layouts/searchResultLayout/searchResultLayout';
import {CorrespondencePreviewPanel} from 'app/views/correspondence';
import {CorrespondenceListItem} from 'app/views/correspondence/components/correspondenceListItem';
import {CorrespondenceListItemSkeleton} from 'app/views/correspondence/components/correspondenceListItemSkeleton';
import {getResultFilters} from 'app/utils/filterUtils';
import {sendMessage} from 'app/infrastructure/onehub';

import noResultsCorrespondence from 'app/static/images/noResultsCorrespondence.svg';
import documentsLoading from 'app/static/images/documentsLoading.svg';
import {searchSameValueSelector} from '../../state/search/search.selectors';
import {SearchPageContext} from '../../pages/searchPage/searchPage';

export const CorrespondenceContainer = () => {
    const {t} = useLocalizationContext();
    const {mode, mobileParams} = useAppContext();
    const {usePreviewItem} = useChannelContext();
    const {setTabNavigationVisibility} = useContext(SearchPageContext);
    const dispatch = useDispatch();

    const searchQuery: string = useSelector(searchQuerySelector);
    const searchWords: string[] = useSelector(searchWordsSelector);
    const selectedItemId = useSelector(selectedItemIdSelector);
    const location = useSelector(locationSelector);

    const isLoading: boolean = useSelector(isCorrespondenceLoadingSelector);
    const items: ICorrespondenceItem[] = useSelector(correspondenceItemsSelector);
    const areMoreResultsAvailable: boolean = useSelector(isMoreCorrespondenceAvailableSelector);
    const totalItemsCount: number = useSelector(correspondenceTotalCountSelector);
    const filters = useSelector(correspondenceFiltersSelector);
    const searchSameValueCount = useSelector(searchSameValueSelector);
    const errorCode = useSelector(correspondenceErrorCodeSelector);

    const isWebView = mode === AppMode.WebView;

    const selectedItem: ICorrespondenceItem | null = useMemo(() => {
        const item = items.find(i => i.id === selectedItemId);
        return item || null;
    }, [selectedItemId, items]);

    useEffect(() => {
        dispatch(resetCorrespondenceState());
        dispatch(searchCorrespondence(searchQuery));
    }, [searchQuery, searchSameValueCount, dispatch]);

    useEffect(() => {
        dispatch(setCorrespondenceFilters(getFilters(t)));

        return () => {
            dispatch(resetCorrespondenceTab());
        };
    }, [dispatch, t]);

    usePreviewItem(
        selectedItem && {
            entityType: EntityType.Correspondence,
            entity: selectedItem,
        }
    );

    const loadMoreHandler = useCallback(() => {
        if (!isLoading && areMoreResultsAvailable) {
            dispatch(searchCorrespondence(searchQuery));
        }
    }, [isLoading, areMoreResultsAvailable, dispatch, searchQuery]);

    const sendMessageToRN = useCallback(id => {
        sendMessage(
            {
                eventType: EventType.OpenCorrespondenceDetails,
                eventData: {
                    documentId: id,
                },
            },
            ClientType.RN
        );
    }, []);

    const onSelect = useCallback(
        (item: ICorrespondenceItem) => {
            const query = {
                ...parse(location.search.substring(1)),
                [routeParams.SELECTED_ID]: item.id,
            };

            delete query[routeParams.DISABLE_HISTORY_ONCE];

            const selectedItemUrl = location.pathname + '?' + stringify(query);
            dispatch(isWebView ? replace(selectedItemUrl) : push(selectedItemUrl));

            if (mode === AppMode.WebView && mobileParams.mobile && selectedItem?.id) {
                sendMessageToRN(selectedItem?.id);
                setTabNavigationVisibility(true);
            }
        },
        [dispatch, location.pathname, location.search, mode, selectedItem?.id]
    );

    const onApplyFilters = useCallback(
        (selectedFilters: IAllFilters[]) => {
            const resultFilters: IFilterRefiner[] = getResultFilters(selectedFilters, filters);

            dispatch(changeCorrespondenceFilters(resultFilters));
            dispatch(clearPreviousSelectedItem());
        },
        [dispatch, filters]
    );

    const filter = {
        filterProps: filters,
        onApplyFilters,
    };
    const list = {
        itemComponentRender: CorrespondenceListItem,
        itemSkeletonRender: CorrespondenceListItemSkeleton,
        itemsData: items,
        onSelect,
        loadMoreHandler,
        searchWords,
        selectedItemId: selectedItem?.id,
        areMoreResultsAvailable,
        errorCode,
    };

    useEffect(() => {
        if (mode === AppMode.WebView && selectedItem?.id) {
            sendMessageToRN(selectedItem?.id);
        }
    }, [mode, selectedItem, sendMessageToRN]);

    return (
        <SearchResultLayout
            isLoading={isLoading}
            filter={filter}
            list={list}
            totalItemsCount={totalItemsCount}
            selectedItem={mode !== AppMode.WebView ? selectedItem : null}
            previewComponent={CorrespondencePreviewPanel}
            noResultImageUrl={noResultsCorrespondence}
            chooseItemImageUrl={documentsLoading}
            translateRoot={'correspondence'}
        />
    );
};
