import {all, getContext, put, takeLatest} from 'redux-saga/effects';
import {
    getLightApps as getLightAppsAction,
    getLightAppsFailure,
    getLightAppsSuccess,
} from './lightApps.actions';

export function* getLightApps() {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const data = yield searchService.get().getLightApps();
        if (!data.hasErrors) {
            yield put(getLightAppsSuccess(data));
        } else {
            yield put(getLightAppsFailure(data));
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(getLightAppsFailure({}));
    }
}

export function* lightAppsSaga() {
    yield all([yield takeLatest(getLightAppsAction, getLightApps)]);
}
