import {all, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {
    applyTenderFilters as applyTenderFiltersAction,
    resetTenderBoardTab as resetTenderBoardTabAction,
    resetTendersItems,
    resetTendersState,
    searchTenders as searchTendersAction,
    searchTendersFailure,
    searchTendersSuccess,
    setTendersSortBy as setTendersSortByAction,
    setTendersSortByError,
} from './tenders.actions';
import {ISearchTendersOptions} from 'app/api/searchAPI/tenders/searchAPICaller.tenders.types';
import {getSearchTendersOptions} from './tenders.operations';
import {RootState} from 'app/state';
import {searchQuerySelector} from '../router';
import {filtersSelector, tendersSortSelector} from './tender.selectors';
import {TendersSortOptions} from 'app/containers/tenderBoard/tenderBoard.types';
import {isFilterSelected} from 'app/utils/filterUtils';
import {resetSingleFilter} from '../filter';
import {EntityType} from 'app/contexts';

function* searchTenders({payload}: ReturnType<typeof searchTendersAction>): any {
    const logger = yield getContext('logger');
    try {
        const state: RootState = yield select();
        const searchService = yield getContext('searchService');
        const sort = yield select(tendersSortSelector);
        const filters = yield select(filtersSelector);
        const options: ISearchTendersOptions = getSearchTendersOptions(
            payload,
            state,
            sort,
            filters
        );
        const tenderResponse = yield searchService.tenders.get().searchTenders(options);
        yield put(searchTendersSuccess(tenderResponse));
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchTendersFailure(error));
    }
}

function* applyTenderFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetTendersItems());
        yield put(searchTendersAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetTenderBoardTab(): any {
    const logger = yield getContext('logger');
    try {
        const sort = yield select(tendersSortSelector);
        const filters = yield select(filtersSelector);

        if (sort !== TendersSortOptions.newToOld || isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Tender}));
            yield put(resetTendersState());
            const query = yield select(searchQuerySelector);
            yield put(searchTendersAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* setTendersSortBy(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetTendersItems());
        yield put(searchTendersAction(query));
    } catch (error) {
        yield logger.get().error(error);
        yield put(setTendersSortByError());
    }
}

export function* tendersSaga() {
    yield all([
        yield takeLatest(String(searchTendersAction), searchTenders),
        yield takeLatest(String(applyTenderFiltersAction), applyTenderFilters),
        yield takeLatest(String(setTendersSortByAction), setTendersSortBy),
        yield takeLatest(String(resetTenderBoardTabAction), resetTenderBoardTab),
    ]);
}
