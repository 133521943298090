import {Ref, useCallback, useLayoutEffect, useState} from 'react';
import {some} from 'lodash';
import {appConfig} from '../config';

function delay(time: number = 0) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export const useGoogleMap = <T>(
    mapRef: Ref<HTMLDivElement>,
    initMap: any,
    errorHandler: (data: any) => void
): T | null => {
    const [GMAP, setGMAP] = useState(null);
    const ApiKey = appConfig.keys.skey_47;
    const checkRegisteredAPI = useCallback(() => {
        const sc = document.querySelectorAll('script');

        return some(sc, el => el.src.includes('maps.googleapis.com/maps/api/js'));
    }, []);
    const registerAPI = useCallback(async () => {
        let script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}&libraries=places`;
        script.defer = true;

        document.getElementsByTagName('head')[0].appendChild(script);

        script.addEventListener('load', async () => {
            await delay(1000);

            setGMAP(initMap(mapRef, errorHandler));
        });
    }, []);

    useLayoutEffect(() => {
        if (!checkRegisteredAPI()) {
            registerAPI();
        } else {
            setGMAP(initMap(mapRef, errorHandler));
        }
    }, [initMap]);

    return GMAP;
};
