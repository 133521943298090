import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import {useInjection, useLocalizationContext} from 'app/hooks';
import {getDate, getDocumentIcon, getDocumentPath} from 'app/utils';
import {PathArray} from 'app/views/documents/components/pathArray';
import styles from './documentListItem.module.scss';
import {IoC} from 'app/ioc';
import {ILogger} from 'app/infrastructure/logging';
import {IDocumentItem} from 'app/state/documents/documents.types';

export const DocumentListItem: FC<{item: IDocumentItem; searchWords: string[]}> = ({
    item,
    searchWords,
}) => {
    const {
        documentAuthors,
        documentCreatedDate,
        documentUpdatedDate,
        documentName,
        documentExtention,
        documentURL,
    } = item;
    const {t} = useLocalizationContext();
    const logger: ILogger = useInjection<ILogger>(IoC.Logger);
    const {fullPath, pathArray} = getDocumentPath(documentURL, logger);
    const dateCreated = t('documents.listItem.createdOn', {
        date: getDate(documentCreatedDate),
    });
    const dateModified = t('documents.listItem.modifiedOn', {
        person: documentAuthors[documentAuthors.length - 1],
        date: getDate(documentUpdatedDate),
    });
    const altText = t('documents.listItem.docAltIcon');

    return (
        <div className={styles.documentListItem}>
            <img
                className={styles.thumbnail}
                src={getDocumentIcon(documentExtention)}
                alt={altText}
            />
            <div className={styles.info}>
                <div className={styles.title}>
                    <Highlighter
                        className={styles.name}
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={documentName}
                    />
                </div>
                <div className={styles.description}>
                    <div className={styles.path}>
                        <PathArray
                            fullPath={fullPath}
                            pathArray={pathArray}
                            styleClasses={{
                                pathArrayClasses: styles.pathArray,
                            }}
                        />
                    </div>
                    <div className={styles.dateCreated}>{dateCreated}</div>
                    <div className={styles.dateModified} title={dateModified}>
                        {dateModified}
                    </div>
                </div>
            </div>
        </div>
    );
};
