import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {numberFormatter} from 'app/utils';
import {SmallCard} from './smallCard';

export const Volumes = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();

    const {
        details: {volumes},
    } = data;
    const volumesData = volumes.map(
        ({
            materialSubgroupName,
            unitName,
            quantityNumber,
        }: {
            materialSubgroupName: string;
            unitName: string;
            quantityNumber: number;
        }) => ({
            name: materialSubgroupName,
            unitName: unitName,
            value: numberFormatter(quantityNumber),
        })
    );

    return (
        <SmallCard
            className={className}
            title={t('customers.preview.volumes')}
            data={volumesData}
        />
    );
};
