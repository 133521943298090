import React, {useCallback, useEffect, useRef, useState} from 'react';
import WebViewer from '@pdftron/webviewer';
import {IoC} from 'app/ioc';
import {useInjection} from 'app/hooks';
import {IAuthProvider} from 'app/infrastructure/auth';
import rotationIcon from '../../../static/images/rotationIcon.svg';
import downloadIconPdftron from '../../../static/images/downloadIconPdftron.svg';
import closeButton from 'app/static/images/closeButtonDocViewer.svg';

export const DocViewer = ({
    url,
    fileName,
    className,
    onError,
    onLoad,
    onDownload,
    showOnlyDownloadOnError,
    onClose,
    isFullScreen,
}: {
    url: string;
    fileName: string;
    className?: string;
    onLoad: (load: boolean) => void;
    onError: (message?: string) => void;
    onDownload: () => void;
    showOnlyDownloadOnError?: boolean;
    onClose?: () => void;
    isFullScreen: boolean;
}) => {
    const viewer = useRef<HTMLDivElement | null>(null);
    const authProvider = useInjection<IAuthProvider>(IoC.AuthProvider);
    const disabledElements: string[] = [];
    const [viewerInstance, setViewerInstance] = useState<any>(undefined);

    const buildHeaderItems = useCallback(
        (instance: any) => {
            instance.setHeaderItems((header: any) => {
                const items: any[] = header.getItems();
                header.update([
                    ...(isFullScreen
                        ? [
                              {
                                  type: 'actionButton',
                                  img: closeButton,
                                  onClick: onClose,
                              },
                          ]
                        : []),
                    {
                        type: 'spacer',
                    },
                    {
                        type: 'actionButton',
                        img: downloadIconPdftron,
                        onClick: onDownload,
                    },
                    {
                        ...items.find(({dataElement}) => dataElement === 'searchButton'),
                        hidden: undefined,
                    },
                    {
                        type: 'actionButton',
                        img: rotationIcon,
                        dataElement: 'rotationButton',
                        onClick: () => {
                            instance.docViewer.rotateCounterClockwise();
                        },
                    },
                    {
                        ...items.find(({dataElement}) => dataElement === 'zoomOverlayButton'),
                        hiddenOnMobileDevice: undefined,
                    },
                ]);
            });
        },
        [isFullScreen]
    );

    useEffect(() => {
        if (!viewer) {
            return;
        }

        if (viewerInstance) {
            buildHeaderItems(viewerInstance);
        } else {
            onLoad(true);
            onError();
            WebViewer(
                {
                    path: '/webviewer/lib',
                    disabledElements,
                    enableAnnotations: false,
                    isReadOnly: true,
                    disableLogs: true,
                },
                viewer.current as HTMLElement
            ).then(async instance => {
                setViewerInstance(instance);
                const token = await authProvider.getAccessToken();
                const docViewer = instance.docViewer;
                instance.disableElements(['errorModal', 'progressModal']);

                buildHeaderItems(instance);

                docViewer.on('documentLoaded', () => {
                    onLoad(false);
                });

                const hideButtons = (showOnlyDownloadOnError: boolean | undefined) => {
                    if (showOnlyDownloadOnError) {
                        const iframeDoc = instance.iframeWindow.document;
                        const errorModal: HTMLElement | null = iframeDoc.querySelector(
                            '[data-element="errorModal"]'
                        );
                        const header: HTMLElement | null = iframeDoc.querySelector(
                            '[data-element="header"]'
                        );
                        const zoomButton: HTMLElement | null = iframeDoc.querySelector(
                            '[data-element="zoomOverlayButton"]'
                        );
                        const searchButton: HTMLElement | null = iframeDoc.querySelector(
                            '[data-element="searchButton"]'
                        );
                        const rotationButton: HTMLElement | null = iframeDoc.querySelector(
                            '[data-element="rotationButton"]'
                        );
                        if (errorModal && header && zoomButton && searchButton && rotationButton) {
                            header.style.zIndex = '101';
                            header.style.height = '36px';
                            header.style.color = 'white';
                            zoomButton.style.display = 'none';
                            searchButton.style.display = 'none';
                            rotationButton.style.display = 'none';
                        }
                    }
                };

                instance.iframeWindow.addEventListener('loaderror', function (err: any) {
                    onLoad(false);
                    onError(err.detail);
                    hideButtons(showOnlyDownloadOnError);
                });

                instance.loadDocument(url, {
                    filename: fileName,
                    xodOptions: {
                        streaming: true,
                    },
                    extension: 'xod',
                    customHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            });
        }
    }, [url, authProvider, fileName, isFullScreen]);

    return <div key={url} ref={viewer} className={className} />;
};
