import {useState} from 'react';

export const useCardState = (ids: string[]): [string[], (id: string) => void] => {
    const [value, setValue] = useState<{[id: string]: boolean}>(
        Object.fromEntries(ids.map(id => [id, true]))
    );

    const toggle = (id: string): void => {
        setValue({
            ...value,
            [id]: !value[id],
        });
    };

    return [Object.keys(value).filter(key => value[key]), toggle];
};
