import {inject, injectable} from 'inversify';
import {ISearchServiceDistributions} from './searchService.distributions.types';
import {
    IDistribution,
    IGetDistributionDetailsOptions,
    ISearchDistributionsOptions,
} from 'app/api/searchAPI/distributions/searchAPICaller.distributions.types';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';

import {execute, hasErrorsResponse} from 'app/utils';
import {ResponseError} from 'app/utils/responseError';
import {appConfig} from 'app/config';
import {SearchAPICallerDistributions} from 'app/api/searchAPI/distributions/searchAPICaller.distributions';
import {IFilterRefiner} from '../../../views/components/filterPanel/filterPanel.types';
import {createDistributionsFilters} from '../searchService.transformer';

@injectable()
export class SearchServiceDistributions implements ISearchServiceDistributions {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchDistributions(options: ISearchDistributionsOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerDistributions.searchDistributions(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse([]) as IDistribution[];
            }
        );
    }

    public async getDistributionDetails(options: IGetDistributionDetailsOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerDistributions.getDistributionDetails(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);

                if (e instanceof ResponseError) {
                    throw e;
                }

                return hasErrorsResponse(e);
            }
        );
    }

    public async searchDistributionsFilters(): Promise<IFilterRefiner[]> {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                const response = await SearchAPICallerDistributions.searchDistributionsFilters(
                    appConfig.skey_38,
                    accessToken
                );
                return createDistributionsFilters(response);
            },
            (e: any) => {
                this.logger.error(e);
                return [];
            }
        );
    }
}
