import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {ColorLabel} from 'app/views/components/colorLabel/colorLabel';
import {ISupplier} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';

export const Supplier: FC<ISuggestionComponent<ISupplier>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, text, searchString, entityLabel = '', historyDate} = item;
    const color = entityLabel === 'COUNTRY' ? 'whiteGreen' : 'whiteBlue';

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_suppliers, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.info}>
                <ColorLabel color={color}>{entityLabel}</ColorLabel>
                <div className={styles.title}>{text}</div>
            </div>
        </div>
    );
};
