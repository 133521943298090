import {EntityType} from 'app/contexts';
import {
    applyEmployeeFilters as applyEmployeeFiltersActions,
    loadEmployeeActivity as loadEmployeeActivityAction,
    loadEmployeeActivityFailure,
    loadEmployeeActivitySuccess,
    loadEmployeeProfile as loadEmployeeProfileAction,
    loadEmployeeProfileFailure,
    loadEmployeeProfileSuccess,
    resetEmployeeTab as resetEmployeeTabAction,
    resetPeopleItems,
    resetPeopleState,
    searchPeople as searchPeopleAction,
    searchPeopleFailure,
    searchPeopleSuccess,
} from './people.actions';
import {all, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {
    IEmployeeActivity,
    ITransformedPeopleResponse,
} from 'app/infrastructure/search/employee/searchService.employee.types';
import {peopleFiltersSelector, peopleSkipAmountSelector} from 'app/state/people/people.selectors';
import {mapFiltersToPeopleOptions} from './people.operations';
import {searchQuerySelector} from 'app/state/router';
import {resetSingleFilter} from 'app/state/filter';
import {isFilterSelected} from 'app/utils/filterUtils';

function* searchPeople({payload}: ReturnType<typeof searchPeopleAction>): any {
    const logger = yield getContext('logger');
    try {
        const skipAmount = yield select(peopleSkipAmountSelector);
        const searchService = yield getContext('searchService');

        let filters = yield select(peopleFiltersSelector);
        const {exactMatch, companies, jobTitles} = mapFiltersToPeopleOptions(filters);

        const data: ITransformedPeopleResponse = yield searchService.employee
            .get()
            .searchPeople(payload, skipAmount, companies, jobTitles, exactMatch);
        if (!data.hasErrors) {
            if (!data.items?.length) {
                yield put(searchPeopleSuccess(data));
                return;
            }

            yield put(searchPeopleSuccess({...data, items: data.items}));
        } else {
            yield put(searchPeopleFailure(data));
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchPeopleFailure(error));
    }
}

function* loadEmployeeProfile({payload}: ReturnType<typeof loadEmployeeProfileAction>): any {
    const {id, email} = payload;
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');
        const data: ITransformedPeopleResponse = yield searchService.employee
            .get()
            .getEmployeeProfile(email);
        if (!data.hasErrors) {
            yield put(loadEmployeeProfileSuccess({id, ...data}));
        } else {
            yield put(loadEmployeeProfileFailure({}));
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(loadEmployeeProfileFailure({}));
    }
}

function* loadEmployeeActivity(): any {
    const logger = yield getContext('logger');

    try {
        const searchService = yield getContext('searchService');
        const data: IEmployeeActivity = yield searchService.employee.get().getEmployeeActivity();

        if (data.hasErrors) {
            yield put(loadEmployeeActivityFailure());
        } else {
            yield put(loadEmployeeActivitySuccess(data?.rows[0]));
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(loadEmployeeActivityFailure());
    }
}

function* applyEmployeeFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetPeopleItems());
        yield put(searchPeopleAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetEmployeeTab(): any {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(peopleFiltersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Employee}));
            yield put(resetPeopleState());
            const query = yield select(searchQuerySelector);
            yield put(searchPeopleAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* peopleSaga(): any {
    yield all([
        yield takeLatest(String(searchPeopleAction), searchPeople),
        yield takeLatest(String(loadEmployeeProfileAction), loadEmployeeProfile),
        yield takeLatest(String(loadEmployeeActivityAction), loadEmployeeActivity),
        yield takeLatest(String(applyEmployeeFiltersActions), applyEmployeeFilters),
        yield takeLatest(String(resetEmployeeTabAction), resetEmployeeTab),
    ]);
}
