import {createActions} from 'redux-actions';

export const {
    searchCustomers,
    searchCustomersSuccess,
    searchCustomersFailure,
    resetCustomersState,
    resetCustomersTab,
    setPeriod,
    getCustomerDetails,
    getCustomerDetailsSuccess,
    getCustomerDetailsFailure,
    resetCustomerDetails,
} = createActions({
    SEARCH_CUSTOMERS: (query: string) => query,
    SEARCH_CUSTOMERS_SUCCESS: servicesResponse => servicesResponse,
    SEARCH_CUSTOMERS_FAILURE: (error: any) => error,
    RESET_CUSTOMERS_STATE: () => {},
    RESET_CUSTOMERS_TAB: () => {},
    SET_PERIOD: (period: any) => period,
    GET_CUSTOMER_DETAILS: (payload: any) => payload,
    GET_CUSTOMER_DETAILS_SUCCESS: (details: any) => details,
    GET_CUSTOMER_DETAILS_FAILURE: () => {},
    RESET_CUSTOMER_DETAILS: (error: any) => error,
});
