import {handleActions} from 'redux-actions';
import {getMyProfile, getMyProfileSuccess, getMyProfileFailure} from './myProfile.actions';

interface IMyProfile {
    data: {
        id: string;
        userPrincipalName: string;
    };
    isLoading: boolean;
}

const initialState: IMyProfile = {
    data: {
        id: '',
        userPrincipalName: '',
    },
    isLoading: false,
};

export default handleActions(
    {
        [String(getMyProfile)]: state => ({
            ...state,
            isLoading: true,
        }),
        [String(getMyProfileSuccess)]: (state, {payload}: any) => ({
            ...state,
            data: payload,
            isLoading: false,
        }),
        [String(getMyProfileFailure)]: state => ({
            ...state,
            isLoading: false,
        }),
    },
    initialState
);
