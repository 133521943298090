import {createActions} from 'redux-actions';
import {ISearchMailResponse} from 'app/infrastructure/search/searchService.types';
import {IMailAttachment, IMailBody} from 'app/state/mail/mail.types';
import {ISearchMailOptions} from 'app/api/searchAPI/mails/searchAPI.mails.types';

export const {
    resetMailState,
    resetMailItems,
    searchMail,
    searchMailSuccess,
    searchMailFailure,
    getMailBody,
    getMailBodySuccess,
    getMailBodyFailure,
    getMailAttachments,
    getMailAttachmentsSuccess,
    getMailAttachmentsFailure,
    applyMailFilters,
    resetMailTab,
} = createActions({
    RESET_MAIL_STATE: () => {},
    RESET_MAIL_ITEMS: () => {},
    SEARCH_MAIL: (query: string, options?: ISearchMailOptions) => ({query, options}),
    SEARCH_MAIL_SUCCESS: (result: ISearchMailResponse) => result,
    SEARCH_MAIL_FAILURE: (error: any) => error,
    GET_MAIL_BODY: (internetMessageId: string) => internetMessageId,
    GET_MAIL_BODY_SUCCESS: (mailData: {body: IMailBody; internetMessageId: string}) => mailData,
    GET_MAIL_BODY_FAILURE: (error: any) => error,
    GET_MAIL_ATTACHMENTS: (internetMessageId: string) => internetMessageId,
    GET_MAIL_ATTACHMENTS_SUCCESS: (mailAttachments: {
        attachments: IMailAttachment;
        internetMessageId: string;
    }) => mailAttachments,
    GET_MAIL_ATTACHMENTS_FAILURE: (error: any) => error,
    APPLY_MAIL_FILTERS: () => {},
    RESET_MAIL_TAB: () => {},
});
