import {
    AbortControllerServices,
    IGetServiceDetailsOptions,
    ISearchServicesOptions,
} from './searchAPICaller.services.types';
import {fetchData} from 'app/utils';
import {ServicesRoutes} from './routes.services';

export const abortControllers: AbortControllerServices = {
    SERVICES: null,
};

export abstract class SearchAPICallerServices {
    public static async searchServices(
        options: ISearchServicesOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.SERVICES?.abort();
        abortControllers.SERVICES = new AbortController();

        return fetchData(
            ServicesRoutes.services(searchAPIURL),
            accessToken,
            'POST',
            options,
            null,
            null,
            abortControllers.SERVICES.signal
        );
    }

    public static async getServiceDetails(
        options: IGetServiceDetailsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(`${ServicesRoutes.services(searchAPIURL)}/details`);

        return fetchData(String(url), accessToken, 'POST', options, null, null);
    }

    public static async searchServicesFilters(searchAPIURL: string, accessToken: string) {
        return fetchData(
            ServicesRoutes.servicesFilters(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }
}
