import React from 'react';

import styles from './userInfo.module.scss';
import phone from 'app/static/images/contactPhone.svg';
import mail from 'app/static/images/contactMail.svg';
import teams from 'app/static/images/contactTeams.svg';
import {TextAvatar} from 'app/views/components/textAvatar';
import {useUserAvatar} from 'app/hooks/useUserAvatar';

type PropsType = {
    info: {
        name: string | null;
        email: string | null;
        mobile: string | null;
        jobTitle: string | null;
        companyName: string | null;
        department: string | null;
    };
    // avatars: any;
};

export const UserInfo = (props: PropsType) => {
    const {
        info: {department, email, jobTitle, companyName, mobile, name = ''},
        // avatars,
    } = props;

    const avatar = useUserAvatar(email);
    // const key = Object.keys(avatars).find(k => k.toLowerCase() === email?.toLowerCase());
    // const avatar = key ? avatars[key] : {};

    // const avatarBody = avatar?.body;
    // const avatarContentType = avatar?.contentType;
    // const avatarImg =
    //     avatarBody && avatarContentType
    //         ? `data:${avatarContentType};base64, ${avatarBody}`
    //         : undefined;

    return (
        <div className={styles.container}>
            <TextAvatar title={name || ''} avatarProps={{src: avatar, className: styles.avatar}} />
            <div>
                <div className={styles.name}>{name}</div>
                {(jobTitle || department) && (
                    <div className={styles.title}>
                        {jobTitle}
                        {jobTitle && department && ', '}
                        {department}
                    </div>
                )}
                {companyName && <div className={styles.companyName}>{companyName}</div>}
                <div className={styles.contact}>
                    {mobile && (
                        <a href={`tel:${mobile}`} target="_blank" rel="noopener noreferrer">
                            <img src={phone} alt="phone" />
                        </a>
                    )}
                    {email && (
                        <>
                            <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                                <img src={mail} alt="mail" />
                            </a>
                            <a
                                href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={teams} alt="teams" />
                            </a>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
