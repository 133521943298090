import React, {FC} from 'react';
import {routes} from 'app/routes';
import classNames from 'classnames';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {INews} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';

export const News: FC<ISuggestionComponent<INews>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, title, company, image, searchString, historyDate} = item;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_news, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            {image && (
                <div className={classNames(styles.icon, styles.cover)}>
                    <img src={image} alt="" />
                </div>
            )}
            <div className={styles.info}>
                <div className={styles.subTitle}>{company}</div>
                <div className={styles.title}>{title}</div>
            </div>
        </div>
    );
};
