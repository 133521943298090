import React from 'react';
import styles from './DocViewerError.module.scss';
import noResultStyles from '../noResults/noResults.module.scss';
import closeButton from 'app/static/images/closeButtonDocViewer.svg';
import downloadButton from 'app/static/images/downloadIconPdftron.svg';
import type {TDocViewerError} from './DocViewerError.types';
import classNames from 'classnames';

export const DocViewerError = ({
    title,
    description,
    imageSrc,
    imageClasses,
    noResultsContainerClasses,
    onClose,
    onDownload,
    isHideHeader,
}: TDocViewerError) => {
    const containerClassNames = classNames([noResultStyles.noResults], noResultsContainerClasses);
    const imageClassNames = classNames([noResultStyles.image, imageClasses]);

    return (
        <>
            {!isHideHeader ? (
                <div className={styles.Header}>
                    <div className={styles.HeaderItems}>
                        <button className={styles.Button} onClick={onClose}>
                            <img src={closeButton} alt="close" />
                        </button>

                        {onDownload ? (
                            <button className={styles.Button} onClick={onDownload}>
                                <img src={downloadButton} alt="download" />
                            </button>
                        ) : null}
                    </div>
                </div>
            ) : null}

            <div className={containerClassNames}>
                <div style={{backgroundImage: `url(${imageSrc})`}} className={imageClassNames} />
                <div className={noResultStyles.promptText}>{title}</div>
                {description && <div className={noResultStyles.helperText}>{description}</div>}
            </div>
        </>
    );
};
