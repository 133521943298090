import React, {FC} from 'react';
import {useAppContext, useCurrency, useInjection, useLocalizationContext} from 'app/hooks';
import {toMoneyFormat, getDocumentIcon} from 'app/utils';

import {DefaultPreviewPanelProps} from './previewPanel.types';
import styles from './previewPanel.module.scss';

import {Cards} from './components/Cards';
import {Commitments} from './components/Commitments';
import {SupplierInfo} from './components/SupplierInfo';
import {OwnerInformation} from './components/OwnerInformation';
import {Loader, Select} from 'app/views/components';
import {AppMode, Currency} from 'app/contexts';

import {useCompanyFilter} from 'app/containers/suppliers';
import {TabsTable} from './components/TabsTable';
import {RankTable} from './components/RankTable/rankTable';

import {DocumentExtension} from 'app/utils/getDocumentIcon';
import {ISearchServiceSuppliers} from 'app/infrastructure/search/suppliers';
import {IoC} from 'app/ioc';

export const DefaultPreview: FC<DefaultPreviewPanelProps> = ({
    data: {text, details},
    id,
    onPreviewDocument,
}) => {
    const {fromYear, toYear} = details;
    const SearchServiceSuppliers = useInjection<ISearchServiceSuppliers>(
        IoC.SearchServiceSuppliers
    );
    const {t} = useLocalizationContext();
    const [companies, toggleCompany] = useCompanyFilter();
    const [selectedCurrency, setSelectedCurrency] = useCurrency();
    const {mode} = useAppContext();
    const isWebView = mode === AppMode.WebView;

    const estCost =
        selectedCurrency === 'usd'
            ? details.suppliersSoftCommitment?.estCost || 0
            : details.suppliersSoftCommitment?.estCostAed || 0;
    const totalCommit =
        selectedCurrency === 'usd'
            ? details.suppliersTotalCommitment?.totalCommit || 0
            : details.suppliersTotalCommitment?.totalCommitAed || 0;
    const invAmountSpent =
        selectedCurrency === 'usd'
            ? details.suppliersTotalSpent?.invAmountSpent || 0
            : details.suppliersTotalSpent?.invAmountSpentAed || 0;
    const balance = totalCommit - invAmountSpent;

    const commitments = [
        {
            name: t('suppliers.previewPanel.commitments.soft'),
            value: toMoneyFormat(estCost),
        },
        {
            name: t('suppliers.previewPanel.commitments.total'),
            value: toMoneyFormat(totalCommit),
        },
        {
            name: t('suppliers.previewPanel.commitments.totalSpend'),
            value: toMoneyFormat(invAmountSpent),
        },
        {
            name: t('suppliers.previewPanel.commitments.balance'),
            value: toMoneyFormat(balance),
        },
    ];

    const selectedCompany = companies.find(i => i.active);

    const currencySelectItems: Array<{name: string; value: Currency}> = [
        {name: 'USD', value: 'usd'},
        {name: 'AED', value: 'aed'},
    ];

    return (
        <div>
            <div className={styles.title}>
                <span className={styles.titleName}>
                    <span>{text}</span>
                    <img
                        onClick={onPreviewDocument}
                        className={styles.docIcon}
                        src={getDocumentIcon(DocumentExtension.PDF)}
                        alt="Doc icon"
                    />
                </span>

                {!!companies?.length && (
                    <Select
                        value={selectedCompany?.id}
                        onChange={toggleCompany}
                        items={companies.map(c => ({name: c.title, value: c.id}))}
                    />
                )}
                <Select
                    value={selectedCurrency}
                    onChange={setSelectedCurrency}
                    items={currencySelectItems}
                />
            </div>
            <Cards
                suppliersTenderings={details.suppliersTenderings}
                suppliersMaterials={details.suppliersMaterials}
                suppliersInvoices={details.suppliersInvoices}
                suppliersServices={details.suppliersServices}
                yearFrom={fromYear}
                yearTo={toYear}
            />
            <div className={styles.contentWrapper}>
                <Commitments items={commitments} yearFrom={fromYear} yearTo={toYear} />
            </div>
            <div className={styles.contentWrapper}>
                <SupplierInfo
                    vendorStats={details.vendorStats}
                    vendorProfile={details.vendorProfile}
                />
            </div>
            {details?.supplierOwners?.length > 0 && (
                <div className={styles.contentWrapper}>
                    <OwnerInformation supplierOwners={details.supplierOwners} />
                </div>
            )}
            {(details?.contractTableItems?.length > 0 ||
                details?.invoiceTableItems?.length > 0 ||
                details?.tenderTableItems?.length > 0) && (
                <div className={styles.contentWrapper + ' ' + styles.tabsWrapper}>
                    <TabsTable
                        contractTableItems={details.contractTableItems}
                        invoiceTableItems={details.invoiceTableItems}
                        tenderTableItems={details.tenderTableItems}
                    />
                </div>
            )}
            {details?.rankTableItems?.length > 0 && id && (
                <div className={styles.contentWrapper}>
                    <RankTable
                        rankTableItems={details.rankTableItems}
                        companyId={selectedCompany?.id}
                        supplierId={id}
                    />
                </div>
            )}
        </div>
    );
};
