import {inject, injectable} from 'inversify';
import {IoC} from 'app/ioc';
import {IAuthProvider} from '../../auth';
import {ILogger} from '../../logging';
import {
    ISearchTrainings,
    ISearchTrainingsOptions,
    ITrainingsResponse,
} from './searchService.eLearning.types';
import {execute, hasErrorsResponse} from 'app/utils';
import {appConfig} from 'app/config';
import {ResponseError} from 'app/utils/responseError';
import {SearchAPICallerELearning} from '../../../api/searchAPI/eLearning/searchAPICaller.eLearning';

@injectable()
export class SearchServiceELearning implements ISearchTrainings {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async searchTrainings(options: ISearchTrainingsOptions) {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerELearning.searchTrainings(
                    options,
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                if (e instanceof ResponseError) {
                    throw e;
                } else {
                    this.logger.error(e);
                    return hasErrorsResponse({
                        recordsCount: 0,
                        totalPages: 0,
                        currentPage: 1,
                        courses: [],
                    }) as ITrainingsResponse;
                }
            }
        );
    }

    public async searchTrainingsFilters() {
        return execute(
            async () => {
                const accessToken = await this.authProvider.getAccessToken();
                return await SearchAPICallerELearning.searchTrainingsFilters(
                    appConfig.skey_38,
                    accessToken
                );
            },
            (e: any) => {
                this.logger.error(e);
                return [];
            }
        );
    }
}
