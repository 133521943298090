import React, {FC} from 'react';
import {routes} from 'app/routes';
import {getDocumentIcon} from 'app/utils';
import {useLocalizationContext} from 'app/hooks';
import {getDate} from 'app/utils';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {IDocument} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';

export const Document: FC<ISuggestionComponent<IDocument>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {t} = useLocalizationContext();
    const {id, fileName, modifiedBy, icon, updateDate, searchString, historyDate} = item;

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_document, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <div className={styles.icon}>
                <img src={getDocumentIcon(icon || '')} alt="" />
            </div>
            <div className={styles.info}>
                <div className={styles.title}>{fileName}</div>
                <div className={styles.subTitle}>
                    {t('components.suggestionItem.subTitle', {
                        name: modifiedBy?.split(';').slice(-1),
                        date: getDate(updateDate || ''),
                    })}
                </div>
            </div>
        </div>
    );
};
