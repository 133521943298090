import React, {ReactNode} from 'react';
import {useLocalizationContext} from 'app/hooks';
import ErrorIcon from 'app/static/images/error.svg';
import styles from '../error.module.scss';
import classNames from 'classnames';

type OtherErrorsTypes = {
    fetchErrors: boolean;
    children?: ReactNode;
    className?: string;
};

export const OtherErrors = ({fetchErrors, children, className}: OtherErrorsTypes) => {
    const {t} = useLocalizationContext();

    return (
        <>
            <div className={classNames(styles.errorContainer, className)}>
                <div className={styles.error}>
                    <span style={{backgroundImage: `url(${ErrorIcon})`}} className={styles.image} />
                    <span className={styles.title}>{t('components.errors.other.title')}</span>
                    <span className={styles.description}>
                        {t('components.errors.other.description')}
                    </span>
                </div>
            </div>
            {fetchErrors && children}
        </>
    );
};
