import {ILogger} from 'app/infrastructure/logging';

interface IDocumentPath {
    fullPath: string;
    pathArray: string[];
}

const getDocumentPath = (url: string, logger: ILogger, useFullPath = false): IDocumentPath => {
    const noVal = {
        fullPath: '',
        pathArray: [],
    };

    if (!url.length) return noVal;

    let pathname = null;
    try {
        pathname = new URL(url).pathname;
    } catch (error) {
        logger.error(error);
        return noVal;
    }

    let pathArray = decodeURI(pathname).split('/');
    pathArray = pathArray.splice(1, pathArray.length - 2); // remove first (empty el) and last (doc name)

    const fullPath = pathArray.join(' > ');
    if (!useFullPath && pathArray.length > 2) {
        pathArray = [pathArray[0], '...', pathArray[pathArray.length - 1]];
    }

    return {
        pathArray,
        fullPath,
    };
};

export {getDocumentPath};
