import {IFilter, IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {CSSProperties} from 'react';

export enum FilterRangeValue {
    Min = 'min',
    Max = 'max',
}

export interface IFilterDatesRangeProps {
    filter: IFilterRefiner;
    setFilterDateValue: (filter: IFilterRefiner, entry: IFilter, date: number | null) => void;
    getFilterDateValue: (filterName: string, entryName: string) => number | null;
    arrowIconsStyles: CSSProperties;
    checkFilterIsActiveToggle: (filterName: string) => boolean;
    toggle: (filterName: string) => void;
    filterLabelMapper?: (i18nProperty: string) => string;
}
