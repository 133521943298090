import React, {FC} from 'react';
import styles from './distributionListItem.module.scss';
import {IDistributionItem} from 'app/state/distribution';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import {ColorLabel} from 'app/views/components/colorLabel/colorLabel';
import classNames from 'classnames';
import {useAppContext} from 'app/hooks';
import {AppMode} from 'app/contexts';
import {StationRating} from '../distributionPreviewPanel/components/stationRating';
import {map, unescape} from 'lodash';

export const DistributionListItem: FC<{item: IDistributionItem; searchWords: string[]}> = ({
    item: {name, id, cityName, status, rating},
    searchWords,
}) => {
    const {mode} = useAppContext();
    const address = `${id} ${cityName}`;
    const isWebView = mode === AppMode.WebView;
    const _searchWords = map(searchWords, word => decodeURIComponent(unescape(word)));

    return (
        <div className={styles.item}>
            <Highlighter
                className={styles.title}
                highlightClassName="highlight"
                searchWords={_searchWords}
                textToHighlight={name || ''}
            />
            <Highlighter
                className={styles.address}
                highlightClassName="highlight"
                searchWords={_searchWords}
                textToHighlight={address || ''}
            />
            <div className={styles.labels}>
                <ColorLabel
                    className={classNames(styles.statusLabel, styles[status.toLowerCase()])}
                >
                    {status}
                </ColorLabel>
                <StationRating rating={rating} className={isWebView ? styles.rating : ''} />
            </div>
        </div>
    );
};
