import React from 'react';
import {ILocalizationContext} from './localizationContext.types';
import {useTranslation} from 'react-i18next';

export const LocalizationContext = React.createContext({} as ILocalizationContext);

export const Provider = (props: any) => {
    const {t} = useTranslation();
    const context: ILocalizationContext = {
        t(key: string, options?: any): string {
            return t(key, options);
        },
    };
    return (
        <LocalizationContext.Provider value={context}>
            {props?.children}
        </LocalizationContext.Provider>
    );
};
