import {toastrDownloadError, toastrDownloadInfo} from 'app/views/components/toastr/toastrDownload';
import {handleBlob} from 'app/utils';
import {downloadFileErrorMessage} from 'app/utils/downloadFileErrorMessage';
import {AppMode} from 'app/contexts';
import {ISearchServiceSuppliers} from 'app/infrastructure/search/suppliers';
import {ToastrBaseType} from 'app/views/components/toastr/toastrBaseComponent';
import {HTTPS_STATUS_CODES} from 'app/constants';
import {downloadWithBlobParams, PreviewPanelState} from './previewPanel.types';

export const initialPanelPreviewState: PreviewPanelState = {
    id: undefined,
    error: undefined,
    loading: false,
};
export const getFileUrl = (SearchServiceSuppliers: ISearchServiceSuppliers, id: string) => {
    return SearchServiceSuppliers.getPreviewDocumentUrl(id);
};

export const downloadWithBlob = async ({
    service,
    id,
    mode,
    logger,
    dispatch,
}: downloadWithBlobParams) => {
    if (!id) {
        return;
    }

    let toastr: ToastrBaseType = {
        id: '',
        destroy: () => {},
    };

    if (mode !== AppMode.WebView) {
        toastr = toastrDownloadInfo(dispatch, {documentName: id, mode});
    }

    try {
        const response = await service.downloadDocument(id);
        if (response.status === HTTPS_STATUS_CODES.OK) {
            const arrayBuffer = await response.arrayBuffer();
            const fileName = response.headers
                .get('content-disposition')
                .match(/filename=(.*);/)[1]
                .replace(/"/g, '');
            handleBlob(arrayBuffer, 'octet/stream', true, fileName);
            toastr.destroy();
        } else {
            let data;
            try {
                data = await response.json();
            } catch (e) {}

            const text = downloadFileErrorMessage(data?.ExceptionType);
            toastr.destroy({force: true});
            toastrDownloadError(dispatch, {text, mode});
        }
    } catch (error) {
        logger.error(error);
    }
};
