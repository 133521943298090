import {all, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {searchQuerySelector} from 'app/state/router';
import {
    resetDocumentsItems,
    resetDocumentsState,
    resetDocumentsTab as resetDocumentsTabAction,
    searchDocuments as searchDocumentsAction,
    searchDocumentsFailure,
    searchDocumentsSuccess,
    setDocumentsFilters as setDocumentsFiltersAction,
    setDocumentsFiltersError,
    setDocumentsSortBy as setDocumentsSortByAction,
    setDocumentsSortByError,
} from './documents.actions';
import {
    documentsFiltersSelector,
    documentsListSelector,
    documentsSortSelector,
} from './documents.selectors';
import {DocumentsSortOptions} from 'app/containers/documents';
import {isFilterSelected} from 'app/utils/filterUtils';

export function* setDocumentsFilters() {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetDocumentsItems());
        yield put(searchDocumentsAction(query));
    } catch (error) {
        yield logger.get().error(error);
        yield put(setDocumentsFiltersError());
    }
}

export function* setDocumentsSortBy() {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetDocumentsItems());
        yield put(searchDocumentsAction(query));
    } catch (error) {
        yield logger.get().error(error);
        yield put(setDocumentsSortByError());
    }
}

function* searchDocuments({payload}: ReturnType<typeof searchDocumentsAction>) {
    const logger = yield getContext('logger');
    try {
        const sort = yield select(documentsSortSelector);
        const filters = yield select(documentsFiltersSelector);
        const documents = yield select(documentsListSelector);
        const {query, startFrom, isLoadingMore} = payload;

        const searchService = yield getContext('searchService');
        const data = yield searchService.documents.get().searchDocuments({
            query,
            startFrom,
            sort,
            filters,
        });
        if (!data.hasErrors) {
            const isFinalDataPage = isLoadingMore && !data.totalCount;
            const updatedData = {
                ...data,
                filters: isFinalDataPage ? filters : data.filters,
                totalCount: isFinalDataPage ? documents.length : data.totalCount,
                areMoreResultsAvailable:
                    data.totalCount > documents.length + data.items.length &&
                    (!isLoadingMore || data.totalCount),
            };
            yield put(searchDocumentsSuccess(updatedData));
        } else {
            yield put(searchDocumentsFailure(data));
        }
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchDocumentsFailure(error));
    }
}

function* resetDocumentsTab() {
    const logger = yield getContext('logger');
    try {
        const sort = yield select(documentsSortSelector);
        const filters = yield select(documentsFiltersSelector);

        if (sort !== DocumentsSortOptions.NoSort || isFilterSelected(filters)) {
            yield put(resetDocumentsState());
            const query = yield select(searchQuerySelector);
            yield put(searchDocumentsAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* documentsSaga() {
    yield all([yield takeLatest(searchDocumentsAction, searchDocuments)]);
    yield all([yield takeLatest(setDocumentsSortByAction, setDocumentsSortBy)]);
    yield all([yield takeLatest(setDocumentsFiltersAction, setDocumentsFilters)]);
    yield all([yield takeLatest(resetDocumentsTabAction, resetDocumentsTab)]);
}
