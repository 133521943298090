import React, {ReactChild} from 'react';
import styles from './topBar.module.scss';

interface ITopBarProps {
    children?: ReactChild | ReactChild[] | string | string[];
}

export const TopBar = ({children}: ITopBarProps) => {
    return <div className={styles.topBar}>{children}</div>;
};
