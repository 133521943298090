import {AbortControllerDocuments, IDocumentsSearchOptions} from './searchAPI.documents.types';
import {DEFAULT_PAGE_SIZE} from 'app/constants';
import {fetchData} from 'app/utils';
import {DocumentsRoutes} from './routes.documents';

export const abortControllers: AbortControllerDocuments = {
    DOCUMENTS: null,
};

const refinersType = {
    documents: 'FileType,DisplayAuthor',
};

export abstract class SearchAPICallerDocuments {
    public static async searchDocuments(options: IDocumentsSearchOptions) {
        const requestParams = {
            request: {
                Refiners: refinersType.documents,
                RefinementFilters: ['IsDocument:True', ...(options.filtersArray || [])],
                StartRow: options.startFrom,
                SortList: options.sortArray,
                Querytext: `${options.query}`,
                RowLimit: DEFAULT_PAGE_SIZE,
            },
        };

        abortControllers.DOCUMENTS?.abort();
        abortControllers.DOCUMENTS = new AbortController();

        return fetchData(
            DocumentsRoutes.documents(options.searchAPIURL),
            options.accessToken,
            'POST',
            requestParams,
            null,
            null,
            abortControllers.DOCUMENTS.signal
        );
    }

    public static async getDocumentBlob(
        searchAPIURL: string,
        accessToken: string,
        path: string,
        siteName: string
    ) {
        const origin: string = DocumentsRoutes.documentBlob(searchAPIURL);
        const p: string = encodeURIComponent(path);
        const sn: string = encodeURIComponent(siteName);
        const url = `${origin}?path=${p}&siteName=${sn}`;

        return await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response: any) => response);
    }

    public static getDocumentPreviewLink(searchAPIURL: string, path: string, siteName: string) {
        const origin: string = DocumentsRoutes.documentPreviewLink(searchAPIURL);
        const p: string = encodeURIComponent(path);
        const sn: string = encodeURIComponent(siteName);

        return `${origin}?path=${p}&siteName=${sn}`;
    }

    public static getDocumentDownloadLink(searchAPIURL: string, path: string, siteName: string) {
        const origin: string = DocumentsRoutes.documentDownloadLink(searchAPIURL);
        const p: string = encodeURIComponent(path);
        const sn: string = encodeURIComponent(siteName);

        return `${origin}?path=${p}&siteName=${sn}`;
    }
}
