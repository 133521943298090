import React, {useCallback, MouseEvent} from 'react';
import {useLocalizationContext} from 'app/hooks';
import {Link} from 'app/views/components/link';
import {IPortalContentProps} from './portal.types';
import styles from './styles/portalContent.module.scss';

export const PortalContent = ({children, title, inlineStyles, onViewAll}: IPortalContentProps) => {
    const {t} = useLocalizationContext();

    const onClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            if (onViewAll) onViewAll();
        },
        [onViewAll]
    );

    const viewAllLink = t('components.portalContent.viewAll');

    return (
        <>
            <div className={styles.header} style={inlineStyles?.header}>
                <div className={styles.title} style={inlineStyles?.title}>
                    {title}
                </div>
                <div style={inlineStyles?.viewAll}>
                    <Link onClick={onClick} component="button">
                        {viewAllLink}
                    </Link>
                </div>
            </div>
            <div className={styles.content}>{children}</div>
        </>
    );
};
