import React, {forwardRef} from 'react';
import {IPortalProps} from './portal.types';
import {PortalContent} from './portalContent';
import {PortalSkeleton} from './portalSkeleton';
import styles from './styles/portal.module.scss';

export const Portal = forwardRef<HTMLDivElement, IPortalProps>(
    ({children, isLoading, title, inlineStyles, onViewAll}, ref) => {
        return (
            <div className={styles.portal} style={inlineStyles?.portal} ref={ref}>
                {isLoading ? (
                    <PortalSkeleton>{children}</PortalSkeleton>
                ) : (
                    <PortalContent title={title} inlineStyles={inlineStyles} onViewAll={onViewAll}>
                        {children}
                    </PortalContent>
                )}
            </div>
        );
    }
);
