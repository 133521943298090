import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import {format, parseISO} from 'date-fns';
import classNames from 'classnames';

import {useLocalizationContext} from 'app/hooks';

import {IServiceItem} from 'app/state/serviceNow';
import {PriorityLabel, StateLabel, TypeLabel} from '../label/label';
import styles from './serviceListItem.module.scss';

const getTicketTypeLabel = (
    t: (key: string) => string,
    category: string,
    type?: string
): string => {
    if (type) {
        return `${category} ${t(`serviceNow.ticketType.${type}`)}`;
    } else return category;
};

export const ServiceListItem: FC<{item: IServiceItem; searchWords: string[]}> = ({
    item: {
        ticketType,
        category,
        number,
        created,
        summary,
        shortDescription,
        state,
        priority,
        urgency,
    },
    searchWords = [],
}) => {
    const {t} = useLocalizationContext();
    const description = summary || shortDescription || '';
    return (
        <div className={styles.item}>
            <div className={styles.itemHeader}>
                <div>
                    <TypeLabel>{getTicketTypeLabel(t, category, ticketType)}</TypeLabel>
                    <Highlighter
                        className={styles.text}
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={number || ''}
                    />
                </div>
                {created && (
                    <span className={styles.text}>{format(parseISO(created), 'd MMM')}</span>
                )}
            </div>
            <span title={description}>
                <Highlighter
                    className={classNames(styles.title, {
                        [styles.titleLong]: !ticketType,
                    })}
                    highlightClassName="highlight"
                    searchWords={searchWords}
                    textToHighlight={description}
                />
            </span>
            <div>
                {priority && <PriorityLabel>{priority}</PriorityLabel>}
                {!priority && urgency && <PriorityLabel>{urgency}</PriorityLabel>}
                {state && <StateLabel>{state}</StateLabel>}
            </div>
        </div>
    );
};
