import {all, getContext, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    applyDistributionFilters as applyDistributionFiltersAction,
    getDistributionDetails as getDistributionDetailsAction,
    getDistributionDetailsFailure,
    getDistributionDetailsSuccess,
    resetDistributionItems,
    resetDistributionsState,
    resetDistributionTab as resetDistributionTabAction,
    searchDistributions as searchDistributionsAction,
    searchDistributionsFailure,
    searchDistributionsSuccess,
    setDistributionsSortBy as setDistributionsSortByAction,
    setDistributionsSortByError,
} from './distribution.actions';
import {getSearchDistributionsOptions} from './distribution.operations';
import {RootState} from 'app/state';
import {ISearchDistributionsOptions} from 'app/api/searchAPI/distributions/searchAPICaller.distributions.types';
import {searchQuerySelector} from '../router';
import {distributionsSortSelector, filtersSelector} from '../distribution';
import {isFilterSelected} from '../../utils/filterUtils';
import {resetSingleFilter} from '../filter';
import {EntityType} from '../../contexts';

function* searchDistributions({payload}: ReturnType<typeof searchDistributionsAction>): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');

    try {
        const state: RootState = yield select();
        const sort = yield select(distributionsSortSelector);
        const filters = yield select(filtersSelector);
        const options: ISearchDistributionsOptions = getSearchDistributionsOptions(
            payload,
            state,
            sort,
            filters
        );
        const result = yield searchService.distributions.get().searchDistributions(options);
        yield put(
            searchDistributionsSuccess({
                distributions: result.stationDetails,
                totalCount: result.count,
            })
        );
    } catch (error) {
        yield logger.get().error(error);
        yield put(searchDistributionsFailure(error));
    }
}

export function* getDistributionDetails({
    payload: {distributionId},
}: ReturnType<typeof getDistributionDetailsAction>) {
    const logger = yield getContext('logger');
    try {
        const searchService = yield getContext('searchService');

        const details = yield searchService.distributions.get().getDistributionDetails({
            distributionId: distributionId,
        });

        yield put(getDistributionDetailsSuccess({distributionId, details}));
    } catch (error) {
        yield logger.get().error(error);
        yield put(getDistributionDetailsFailure(error));
    }
}

function* setDistributionsSortBy(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetDistributionItems());
        yield put(searchDistributionsAction(query));
    } catch (error) {
        yield logger.get().error(error);
        yield put(setDistributionsSortByError());
    }
}

function* applyDistributionFilters(): any {
    const logger = yield getContext('logger');
    try {
        const query = yield select(searchQuerySelector);
        yield put(resetDistributionItems());
        yield put(searchDistributionsAction(query));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* resetDistributionTab(): any {
    const logger = yield getContext('logger');
    try {
        const filters = yield select(filtersSelector);

        if (isFilterSelected(filters)) {
            yield put(resetSingleFilter({key: EntityType.Distribution}));
            yield put(resetDistributionsState());
            const query = yield select(searchQuerySelector);
            yield put(searchDistributionsAction(query));
        }
    } catch (error) {
        yield logger.get().error(error);
    }
}

export function* distributionsSaga() {
    yield all([
        yield takeLatest(String(searchDistributionsAction), searchDistributions),
        yield takeEvery(String(getDistributionDetailsAction), getDistributionDetails),
        yield takeLatest(String(setDistributionsSortByAction), setDistributionsSortBy),
        yield takeLatest(String(applyDistributionFiltersAction), applyDistributionFilters),
        yield takeLatest(String(resetDistributionTabAction), resetDistributionTab),
    ]);
}
