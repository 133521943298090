import {fetchData} from 'app/utils';
import {
    IEmployeePresenceValue,
    IGetEmployeeAvatar,
    IGetEmployeePresence,
    IGetEmployeeProfile,
    IGetEmployeeProfileResponse,
} from './oneHubAPICaller.types';

// TODO: Please replace this solution with environment variables when devOps have the capacity!
export const ONE_HUB_PROFILE_PROXY_URL = 'api/proxy/v0/profile/onehub/profile';
const EXPORT_PROFILE_ENDPOINT = 'api/proxy/v0/profile/onehub/profile/download';
const EXPORT_TEAM_BALANCE_ENDPOINT = 'api/proxy/v0/profile/onehub/profile/team-balance/download';
const ONE_HUB_PRESENCE_PROXY_URL = 'api/proxy/v0/graph/presence';
// TODO Check new URL according ADNODIB-11803
// const ONE_HUB_AVATAR_PROXY_URL = 'api/proxy/v0/graph/avatars';
const ONE_HUB_AVATAR_PROXY_URL = 'api/proxy/v0/profile/onehub/profile/photo';

const routes = {
    profile: (origin: string, employeeEmail: string) =>
        `${origin}/${ONE_HUB_PROFILE_PROXY_URL}/${employeeEmail}`,
    profileDownload: (origin: string, employeeEmail: string) =>
        `${origin}/${EXPORT_PROFILE_ENDPOINT}/${employeeEmail}`,
    profileTeamBalanceDownload: (origin: string, employeeEmail: string) =>
        `${origin}/${EXPORT_TEAM_BALANCE_ENDPOINT}/${employeeEmail}`,
    presence: (origin: string, params: string) =>
        `${origin}/${ONE_HUB_PRESENCE_PROXY_URL}?${params}`,
    avatar: (origin: string, email: string) => `${origin}/${ONE_HUB_AVATAR_PROXY_URL}/${email}`,
};

export abstract class OneHubAPICaller {
    static getEmployeeProfile({
        apiURL,
        accessToken,
        employeeEmail,
    }: IGetEmployeeProfile): Promise<IGetEmployeeProfileResponse> {
        return fetchData(routes.profile(apiURL, employeeEmail), accessToken, 'GET');
    }

    static getEmployeeProfilePdf({
        apiURL,
        accessToken,
        employeeEmail,
    }: IGetEmployeeProfile): Promise<any> {
        return fetch(routes.profileDownload(apiURL, employeeEmail), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static getTeamBalanceFile({
        apiURL,
        accessToken,
        employeeEmail,
    }: IGetEmployeeProfile): Promise<any> {
        return fetch(routes.profileTeamBalanceDownload(apiURL, employeeEmail), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static async getEmployeePresence({
        apiURL,
        accessToken,
        employeeEmails,
    }: IGetEmployeePresence): Promise<IEmployeePresenceValue[]> {
        const users: string = employeeEmails.reduce(
            (acc, email, i) =>
                i
                    ? `${acc}&user=${encodeURIComponent(email)}`
                    : `${acc}user=${encodeURIComponent(email)}`,
            ''
        );

        let data: IEmployeePresenceValue[];

        try {
            data = await fetchData(routes.presence(apiURL, users), accessToken, 'GET');
        } catch {
            data = [];
        }

        return data;
    }

    static async getEmployeeAvatar({apiURL, accessToken, email}: IGetEmployeeAvatar): Promise<any> {
        let avatar: string = '';

        try {
            avatar = await fetchData(
                routes.avatar(apiURL, encodeURIComponent(email)),
                accessToken,
                'GET',
                null,
                false,
                (response: any) => response.blob()
            );
        } catch (e) {
            console.error(e);
        }

        return avatar;
    }
}
