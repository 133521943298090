import React from 'react';
import {Line} from 'react-chartjs-2';
import classNames from 'classnames';
import baseStyles from './lineChartBase.module.scss';
import {NoResultsCharts} from 'app/layouts/searchResultLayout/noResultsCharts';
import noResultsCharts from 'app/static/images/noResultsCharts.svg';
import {size, some} from 'lodash';
import {getColorsList} from 'app/utils/getColor';
import {ILineChartBase} from './lineChartBase.types';
import {useLocalizationContext} from 'app/hooks';

export const LineChartBase = ({
    className,
    chartConfig,
    labels,
    datasets,
    data,
    height,
    colorIndexes,
    styles,
}: ILineChartBase) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {t} = useLocalizationContext();

    const colors: string[] = colorIndexes ? getColorsList(undefined) : getColorsList(data.length);
    const color = (index: number): string => {
        if (colorIndexes && colors[colorIndexes[index]]) {
            return colors[colorIndexes[index]];
        }

        return colors[index];
    };

    let isEmptyOrBrokenData = false;

    if (
        size(labels) <= 1 &&
        !some(datasets, i => {
            return i.data.length <= 1 && !!i.data[0];
        })
    ) {
        isEmptyOrBrokenData = true;
    }

    return (
        <div
            className={classNames(
                className,
                baseStyles.container,
                isEmptyOrBrokenData ? baseStyles.containerNoData : ''
            )}
        >
            {!isEmptyOrBrokenData ? (
                <>
                    <div className={baseStyles.chartContainer}>
                        <div
                            className={baseStyles.chart}
                            style={{position: 'relative', height: height}}
                        >
                            <Line {...chartConfig} />
                        </div>
                    </div>
                    <div className={classNames(styles.legend, className)}>
                        {data.length > 1 &&
                            data.map(({label}, index) => (
                                <div className={styles.label} key={label}>
                                    <span
                                        className={styles.color}
                                        style={{
                                            backgroundColor: color(index),
                                            color: color(index),
                                        }}
                                    />
                                    {label}
                                </div>
                            ))}
                    </div>
                </>
            ) : (
                <NoResultsCharts
                    noResultImageUrl={noResultsCharts}
                    noResultsTitle={t('components.errors.emptyData.title')}
                    noResultsDescription={t('components.errors.emptyData.description')}
                />
            )}
        </div>
    );
};
