import React from 'react';
import {NoResults} from './noResults';
import {ChooseItem} from './chooseItem';
import {useLocalizationContext} from 'app/hooks';

export const PreviewLayout = (props: any) => {
    const {
        selectedItem,
        noResults,
        isFilterSelected,
        previewComponent,
        areFilterShow,
        onShowFilterClick,
        onShowMobileFilterClick,
        noResultImageUrl,
        chooseItemImageUrl,
        translateRoot,
        previewKey = 'id',
    } = props;
    const PreviewComponent = previewComponent;
    const {t} = useLocalizationContext();

    const noResultsTitle = t(`${translateRoot}.noResultsTitle`);
    const noResultsDescription = t(`${translateRoot}.noResultsDescription`);
    const noResultsDescriptionFilters = t(`${translateRoot}.noResultsDescriptionFilters`);
    const chooseItem = t(`${translateRoot}.chooseItem`);

    if (!selectedItem && !noResults) {
        return <ChooseItem imageUrl={chooseItemImageUrl} title={chooseItem} />;
    }

    if (noResults) {
        return (
            <NoResults
                isFilterSelected={isFilterSelected}
                areFilterShow={areFilterShow}
                onShowFilterClick={onShowFilterClick}
                onShowMobileFilterClick={onShowMobileFilterClick}
                noResultImageUrl={noResultImageUrl}
                noResultsTitle={noResultsTitle}
                noResultsDescription={noResultsDescription}
                noResultsDescriptionFilters={noResultsDescriptionFilters}
            />
        );
    }

    return <PreviewComponent data={selectedItem} key={selectedItem?.[previewKey]} />;
};
