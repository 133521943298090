import styles from '../error.module.scss';
import ErrorIcon from 'app/static/images/error.svg';
import {Button} from 'app/views/components/button';
import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {goBack} from 'connected-react-router';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';

type NotFoundErrorType = {
    className?: string;
    isHideBackButton?: boolean;
};

export const NotFoundError = ({className, isHideBackButton}: NotFoundErrorType) => {
    const dispatch = useDispatch();
    const {t} = useLocalizationContext();
    const onClickHandler = () => {
        dispatch(goBack());
    };

    return (
        <div className={classNames(styles.errorContainer, className)}>
            <div className={styles.error}>
                <span style={{backgroundImage: `url(${ErrorIcon})`}} className={styles.image} />
                <span className={styles.title}>{t('components.errors.notFound.title')}</span>
                <span className={styles.description}>
                    {t('components.errors.notFound.description')}
                </span>

                {!isHideBackButton ? (
                    <Button onClick={onClickHandler} styleClasses={styles.button}>
                        {t('components.errors.notFound.button')}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
