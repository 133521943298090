import React, {ChangeEvent, useCallback, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {stringify} from 'querystring';
import {debounce, get} from 'lodash';
import {useLocalizationContext} from 'app/hooks';
import {TopBar} from 'app/views/components/topBar';
import {Suggestions} from 'app/views/components/suggestions';
import {SearchInput} from 'app/views/pageContent/searchInput';
import ADNOCIcon from 'app/static/images/ADNOCIcon.svg';
import ADNOCTextLogo from 'app/static/images/ADNOCTextLogo.svg';
import {EntityType} from 'app/contexts';
import {
    areSuggestionsLoadingSelector,
    searchHistory,
    searchSuggestionsWithLightApps,
    setSuggestionsLoading,
    suggestionsSelector,
} from 'app/state/suggestions';
import styles from './welcomePage.module.scss';

import {
    CorrespondenceSuggestionItem,
    CustomerSuggestionItem,
    DistributionSuggestionItem,
    DocumentSuggestionItem,
    EmailSuggestionItem,
    EmployeeSuggestionItem,
    NewsSuggestionItem,
    ProjectSuggestionItem,
    ServiceSuggestionItem,
    SupplierSuggestionItem,
    TenderSuggestionItem,
    TrainingSuggestionItem,
} from 'app/views/components/suggestions/suggestionEntityItem';
import {routeParams, routes} from 'app/routes';
import {AvailableSuggestionComponents} from './welcomePage.types';
import {lightAppsListSelector} from '../../state/lightApps/lightApps.selectors';
import {filterLightApps} from '../../utils/filterLightApps';
import {calcMainSearchItemsCount, calcMainSearchSelected} from '../../utils/mainSearch';
import classNames from 'classnames';

const mapSuggestionItems: Record<EntityType, AvailableSuggestionComponents> = {
    [EntityType.Document]: DocumentSuggestionItem,
    [EntityType.Correspondence]: CorrespondenceSuggestionItem,
    [EntityType.Email]: EmailSuggestionItem,
    [EntityType.Employee]: EmployeeSuggestionItem,
    [EntityType.News]: NewsSuggestionItem,
    [EntityType.Service]: ServiceSuggestionItem,
    [EntityType.Tender]: TenderSuggestionItem,
    [EntityType.Suppliers]: SupplierSuggestionItem,
    [EntityType.Customers]: CustomerSuggestionItem,
    [EntityType.Training]: TrainingSuggestionItem,
    [EntityType.Projects]: ProjectSuggestionItem,
    [EntityType.Distribution]: DistributionSuggestionItem,
};

export const WelcomePage = () => {
    const {t} = useLocalizationContext();
    const [searchValue, setSearchValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const dispatch = useDispatch();
    const suggestions = useSelector(suggestionsSelector, shallowEqual);
    const areSuggestionsLoading = useSelector(areSuggestionsLoadingSelector);
    const lightAppsList = useSelector(lightAppsListSelector);
    const [selectedI, setSelectedI] = useState<number>(-1);

    const dispatchSearch = useCallback(
        (value: string) => {
            dispatch(searchSuggestionsWithLightApps(value));
        },
        [dispatch]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((value: string) => dispatchSearch(value), 800),
        [dispatchSearch]
    );

    const onSearch = useCallback(() => {
        if (searchValue.length) {
            const params = stringify({[routeParams.QUERY]: searchValue});
            dispatch(push(`${routes.search}?${params}`));
        }
    }, [searchValue, dispatch]);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | null) => {
            const value = get(e, 'target.value', '');

            dispatch(setSuggestionsLoading(true));
            setSearchValue(value);
            debouncedSearch(value);
        },
        [debouncedSearch, dispatch]
    );

    const onClear = useCallback(() => {
        onChange(null);
    }, [onChange]);

    const onFocus = useCallback(() => {
        setSelectedI(-1);
        setShowSuggestions(true);
        if (searchValue) {
            dispatchSearch(searchValue);
        } else {
            dispatch(searchHistory());
        }
    }, [dispatch, dispatchSearch, searchValue]);

    const onBlur = useCallback(() => {
        setShowSuggestions(false);
    }, []);

    const componentSuggestion = suggestions.map(s => ({
        items: s.items,
        name: s.name,
        component: mapSuggestionItems[s.type],
    }));

    const lightAppsSuggestions = filterLightApps(searchValue, lightAppsList.categories);

    const onEnter = useCallback(() => {
        const selected = calcMainSearchSelected(
            selectedI,
            lightAppsSuggestions,
            suggestions,
            !searchValue
        );
        if (selected.lightAppsSelectedI !== undefined) {
            lightAppsItemRefs.current[selected.lightAppsSelectedI]?.click();
        } else if (selected.allLightAppsSelected) {
            allLightAppsItemRef.current?.click();
        } else if (
            selected.suggestionsSelectedI !== undefined &&
            selected.suggestionsSelectedItemI !== undefined
        ) {
            suggestionItemRefs.current[selected.suggestionsSelectedI][
                selected.suggestionsSelectedItemI
            ]?.click();
        } else if (selected.allSuggestionsSelected) {
            onSearch();
        } else {
            onSearch();
        }
    }, [onSearch, selectedI, lightAppsSuggestions, suggestions]);

    const selected = calcMainSearchSelected(
        selectedI,
        lightAppsSuggestions,
        suggestions,
        !searchValue
    );

    const onKeyDown = useCallback(
        (key: string) => {
            if (key === 'ArrowUp') {
                if (selectedI > -1) {
                    setSelectedI(selectedI - 1);
                }
            }
            if (key === 'ArrowDown') {
                const itemsCount = calcMainSearchItemsCount(
                    lightAppsSuggestions,
                    suggestions,
                    !searchValue
                );
                if (selectedI + 1 < itemsCount) {
                    setSelectedI(selectedI + 1);
                }
                if (selectedI + 1 === itemsCount) {
                    setSelectedI(0);
                }
            }
            if (key === 'Enter') {
                onEnter();
            }
        },
        [selectedI, onEnter, selected]
    );

    const allLightAppsItemRef = useRef<null | HTMLDivElement>(null);
    const lightAppsItemRefs = useRef<HTMLDivElement[]>([]);
    const suggestionItemRefs = useRef<HTMLDivElement[][]>([]);
    const allSuggestionsItemRefs = useRef<null | HTMLDivElement>(null);

    return (
        <>
            <TopBar>
                <div className={styles.topBar}>
                    <div className={styles.mainTitle}>{t('welcomePage.topBar.mainTitle')}</div>
                    <img src={ADNOCIcon} alt={t('welcomePage.logo.ADNOCIconAlt')} />
                </div>
            </TopBar>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <img
                            className={classNames(styles.icon, styles.logoIcon)}
                            src={ADNOCIcon}
                            alt={t('welcomePage.logo.ADNOCIconAlt')}
                        />
                        <img
                            className={styles.logoText}
                            src={ADNOCTextLogo}
                            alt={t('welcomePage.logo.ADNOCIconAlt')}
                        />
                    </div>
                    <div className={styles.suggestionsContainer}>
                        <SearchInput
                            value={searchValue}
                            styleClasses={styles.searchInput}
                            onChange={onChange}
                            onSearch={onSearch}
                            onClear={onClear}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            selectedI={selectedI}
                        />
                        <Suggestions
                            searchQuery={searchValue}
                            suggestions={componentSuggestion}
                            isLoading={areSuggestionsLoading}
                            onSearch={onSearch}
                            showSuggestions={showSuggestions}
                            lightAppsSuggestions={lightAppsSuggestions}
                            selected={selected}
                            allLightAppsItemRef={allLightAppsItemRef}
                            lightAppsItemRefs={lightAppsItemRefs}
                            suggestionItemRefs={suggestionItemRefs}
                            allSuggestionsItemRefs={allSuggestionsItemRefs}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
