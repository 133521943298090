import {IRecipient} from 'app/api/searchAPI/searchAPI.MSGraph.types';
import {FilterType} from 'app/views/components/filterPanel/filterPanel.types';

export interface ISearchService {
    searchOneHubEmployeesPhotos: (email: string) => Promise<any>;
    getOneHubAuthToken: () => Promise<string>;
}

export interface ISearchMailResponse {
    areMoreResultsAvailable: boolean;
    items: IMailListItem[];
    hasErrors: boolean;
}

export interface IMailListItem {
    id: string;
    address: string;
    avatarSrc: string;
    senderName: string;
    dateSent: string;
    subject: string;
    hasAttachments: boolean;
    description: string;
    webLink: string;
    internetMessageId: string;
    replyTo?: IRecipient[];
    ccRecipients?: IRecipient[];
}

export interface IMailAvatarResponseBatch {
    responses: IMailAvatarResponse[];
}

export interface IMailAvatarResponse {
    id: string;
    headers: {
        'Content-Type': string;
    };
    body: string | IMailAvatarError;
    status: number;
}

export interface IMailAvatarError {
    error: any;
}

// Documents
export interface IDocumentsResponse {
    documents: IDocumentResponse[];
    filterResults: IDocumentRefiners[];
    hasErrors?: boolean;
    totalRows: number;
}

export interface IDocumentResponse {
    author: string;
    contentTypeID: string;
    created: string;
    culture: string;
    description: string;
    docID: string;
    fileExtension: string;
    fileName: string;
    fileType: string;
    lastModifiedTime: string;
    originalPath: string;
    path: string;
    pictureThumbnailURL: string;
    serverRedirectedEmbedURL: string;
    size: string;
    title: string;
    uniqueID: string;
    workId: string;
    write: string;
    siteName: string;
}

export enum SharePointConstants {
    Min = 'min',
    Max = 'max',
}

export interface IDocumentRefiners {
    filters: IDocumentRefinersData[];
    name: string;
}

export interface IDocumentRefinersData {
    refinementCount: string;
    refinementName: string;
    refinementValue: string;
    refinementValue2?:
        | string
        | {
              FromDate: string;
              ToDate: string;
          };
    refinementToken: string;
}

export interface IFilterData {
    RefinementCount?: string;
    RefinementName: string;
    RefinementToken?: string;
    RefinementValue: string;
    RefinementValue2?:
        | string
        | {
              FromDate: string;
              ToDate: string;
          };
    isChecked?: boolean;
    value?: any;
}

export interface IFilter {
    Entries: IFilterData[];
    Name: string;
    type?: FilterType;
}

export interface ISortItem {
    Property: string;
    Direction: string | number;
}

export enum FilterRangeValue {
    Min = 'min',
    Max = 'max',
}

export type CreateNewsFilterParams = {
    companies: {[key: string]: string};
};
