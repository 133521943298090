import React, {FC, useEffect, useState} from 'react';
import styles from './tenderBoardPreviewPanel.module.scss';
import {ITenderBoardPreviewPanelProps} from './tenderBoardPreviewPanel.types';
import {IAuthProvider} from 'app/infrastructure/auth';
import {IoC} from 'app/ioc';
import {useInjection} from 'app/hooks';

export const TenderBoardPreviewPanel: FC<ITenderBoardPreviewPanelProps> = ({
    data,
}) => {
    const [accessToken, setAccessToken] = useState('');
    const authProvider = useInjection<IAuthProvider>(IoC.AuthProvider);

    useEffect(() => {
        authProvider.getAccessToken().then(token => setAccessToken(token));
    }, [authProvider, data]);

    return data && accessToken ? (
        <iframe
            key={data.id}
            title={data.name}
            className={styles.container}
            src={`${data.url}?token=${accessToken}`}
        />
    ) : null;
};
