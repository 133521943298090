import {EntityType} from 'app/contexts';
import {isEntityEnabled} from 'app/utils/isEntityEnabled';
import {all, call, getContext, put, takeLatest} from 'redux-saga/effects';
import {filter, size} from 'lodash';
import {getInitialFilters as getInitialMailFilters} from '../mail/utils/filters';
import {
    fetchInitialFilters as fetchInitialFiltersAction,
    fetchInitialFiltersSuccess,
} from './filter.actions';
import {Repository} from './filter.types';

function* fetchNewsFilters(): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const initialFilters = yield searchService.news.get().searchNewsFilters();
        const repository: Partial<Repository> = {
            [EntityType.News]: {
                initialFilters,
                filters: initialFilters,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* fetchEmployeeFilters(): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const initialFilters = yield searchService.employee.get().searchPeopleFilters();
        const repository: Partial<Repository> = {
            [EntityType.Employee]: {
                initialFilters,
                filters: initialFilters,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* fetchTenderFilters(): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const initialFilters = yield searchService.tenders.get().searchTenderFilters();
        const repository: Partial<Repository> = {
            [EntityType.Tender]: {
                initialFilters,
                filters: initialFilters,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* fetchTrainingFilters(): any {
    const logger = yield getContext('logger');
    const SearchServiceELearning = yield getContext('searchService');
    try {
        const initialFilters = yield SearchServiceELearning.eLearning
            .get()
            .searchTrainingsFilters();
        const repository: Partial<Repository> = {
            [EntityType.Training]: {
                initialFilters,
                filters: initialFilters,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* fetchProjectFilters(): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const initialFilters = yield searchService.projects.get().searchProjectFilters();
        const filteredByEmptyInitialFilters = filter(initialFilters, f => size(f.Entries));
        const repository: Partial<Repository> = {
            [EntityType.Projects]: {
                initialFilters: filteredByEmptyInitialFilters,
                filters: filteredByEmptyInitialFilters,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* createMailFilters(): any {
    const initialFilters = getInitialMailFilters();
    const repository: Partial<Repository> = {
        [EntityType.Email]: {
            initialFilters,
            filters: initialFilters,
        },
    };
    yield put(fetchInitialFiltersSuccess(repository));
}

function* fetchServiceFilters(): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const response = yield searchService.services.get().searchServiceNowFilters();
        if (!response) return;

        const {filters: initialFilters, filtersOptions: additionalData} = response;

        const repository: Partial<Repository> = {
            [EntityType.Service]: {
                initialFilters,
                filters: initialFilters,
                additionalData,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* fetchDistributionFilters(): any {
    const logger = yield getContext('logger');
    const searchService = yield getContext('searchService');
    try {
        const initialFilters = yield searchService.distributions.get().searchDistributionsFilters();
        const repository: Partial<Repository> = {
            [EntityType.Distribution]: {
                initialFilters,
                filters: initialFilters,
            },
        };
        yield put(fetchInitialFiltersSuccess(repository));
    } catch (error) {
        yield logger.get().error(error);
    }
}

function* fetchInitialFilters(): any {
    const requests = [
        isEntityEnabled(EntityType.News) && fetchNewsFilters,
        isEntityEnabled(EntityType.Employee) && fetchEmployeeFilters,
        isEntityEnabled(EntityType.Tender) && fetchTenderFilters,
        isEntityEnabled(EntityType.Training) && fetchTrainingFilters,
        isEntityEnabled(EntityType.Email) && createMailFilters,
        isEntityEnabled(EntityType.Projects) && fetchProjectFilters,
        isEntityEnabled(EntityType.Service) && fetchServiceFilters,
        isEntityEnabled(EntityType.Distribution) && fetchDistributionFilters,
    ].filter(r => !!r) as GeneratorFunction[];

    yield all(requests.map(r => call(r)));
}

export function* filterSaga(): any {
    yield all([yield takeLatest(String(fetchInitialFiltersAction), fetchInitialFilters)]);
}
