import React, {useState} from 'react';
import classNames from 'classnames';
import {useLocalizationContext} from 'app/hooks';
import {LineChart} from './charts/lineChart';
import customerStyles from '../customersPreviewPanel.module.scss';
import {TabButtons} from './tabButtons';
import {m$Formatter} from 'app/utils';

export const SalesTrend = ({data, className}: {data: any; className?: string}) => {
    const {t} = useLocalizationContext();
    const {
        details: {salesTrendItems = []},
    } = data;

    const labels = salesTrendItems[0]?.periodWid;
    const chartData = salesTrendItems.map(
        ({materialGroupName, amount}: {materialGroupName: string; amount: number[]}) => ({
            label: materialGroupName,
            values: amount,
        })
    );

    const totalValues: number[] = salesTrendItems.reduce(
        (
            total: number[],
            {
                amount,
            }: {
                amount: number[];
            }
        ) => amount.map((v, i) => v + (total[i] || 0)),
        []
    );

    const tabs = [
        {
            title: t('customers.preview.products'),
            name: 'products',
            content: <LineChart labels={labels} formatter={m$Formatter} data={chartData} />,
        },
        {
            title: t('customers.preview.totalSales'),
            name: 'totalSales',
            content: (
                <LineChart
                    labels={labels}
                    formatter={m$Formatter}
                    data={[
                        {
                            label: t('customers.preview.totalSales'),
                            values: totalValues,
                        },
                    ]}
                />
            ),
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);
    return (
        <div className={classNames(className)}>
            <div className={customerStyles.containerHeader}>
                <h3>{t('customers.preview.salesTrend')}</h3>
                <TabButtons
                    tabs={tabs.map(tab => ({
                        ...tab,
                        active: selectedTab === tab.name,
                    }))}
                    onSelect={setSelectedTab}
                />
            </div>

            {tabs.find(({name}) => name === selectedTab)?.content}
        </div>
    );
};
