import React, {FC} from 'react';
import classnames from 'classnames';

import {OutlookLinkTypes} from './outlookLink.types';
import icon from 'app/static/images/outlook.svg';
import styles from './outlookLink.module.scss';

export const OutlookLink: FC<OutlookLinkTypes> = ({linkProps, children, linkTextHidden}) => {
    const linkClassNames: string = classnames(styles.root, linkProps.className);

    return (
        <a {...linkProps} className={linkClassNames} target="_blank" rel="noopener noreferrer">
            <img src={icon} alt="" />
            {!linkTextHidden && <span>{children || 'Outlook preview'}</span>}
        </a>
    );
};
