import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams, routes} from 'app/routes';
import {useLocalizationContext} from 'app/hooks';
import {EntityType} from 'app/contexts';
import {isEntityEnabled} from 'app/utils/isEntityEnabled';
import {isInViewport} from 'app/utils/isInViewport';

import {locationSelector, searchQuerySelector, searchWordsSelector} from 'app/state/router';

import {
    documentsListSelector,
    IDocumentItem,
    isDocumentLoadingSelector,
    resetDocumentsState,
    searchDocuments,
} from 'app/state/documents';
import {
    IMailItem,
    isMailLoadingSelector,
    mailItemsSelector,
    resetMailState,
    searchMail,
} from 'app/state/mail';
import {
    areTrainingsLoadingSelector,
    ITrainingItem,
    resetTrainingsState,
    searchTrainings,
    trainingsSelector,
} from 'app/state/trainings';
import {
    correspondenceItemsSelector,
    ICorrespondenceItem,
    isCorrespondenceLoadingSelector,
    resetCorrespondenceState,
    searchCorrespondence,
} from 'app/state/correspondence';
import {
    areNewsLoadingSelector,
    INewsItem,
    newsSelector,
    resetNewsState,
    searchNews,
} from 'app/state/news';
import {
    areTendersLoadingSelector,
    itemsSelector as tendersSelector,
    ITenderItem,
    resetTendersState,
    searchTenders,
} from 'app/state/tenders';
import {
    areSuppliersLoadingSelector,
    ISupplierItem,
    resetSuppliersState,
    searchSuppliers,
    suppliersSelector,
} from 'app/state/suppliers';
import {
    areCustomersLoadingSelector,
    customersSelector,
    ICustomerItem,
    resetCustomersState,
    searchCustomers,
} from 'app/state/customers';
import {
    areServiceItemsLoadingSelector,
    IServiceItem,
    resetServicesState,
    searchServices,
    serviceItemsSelector,
} from 'app/state/serviceNow';
import {
    arePeopleLoadingSelector,
    IPeopleItem,
    peopleItemsSelector,
    resetPeopleState,
    searchPeople,
} from 'app/state/people';
import {
    areProjectsLoadingSelector,
    IProjectItem,
    projectsSelector,
    resetProjectsState,
    searchProjects,
} from 'app/state/projects';
import {searchSameValueSelector} from '../../state/search/search.selectors';

import {OneColumnLayout} from 'app/layouts';
import {Portal} from 'app/views/components';
import {NoResults} from 'app/views/components/noResults/noResults';
import noResultsSvg from 'app/static/images/noResultsAll.svg';

import {PeopleList} from 'app/views/people';
import {MailList} from 'app/views/mail';
import {TrainingsList} from 'app/views/trainings';
import {DocumentList} from 'app/views/documents';
import {CorrespondenceList} from 'app/views/correspondence/components/correspondenceList';
import {NewsList} from 'app/views/news/components/newsList/newsList';
import {TenderBoardList} from 'app/views/tenderBoard/tenderBoardList/tenderBoardList';
import {SuppliersList} from 'app/views/suppliers/components/suppliersList/suppliersList';
import {CustomersList} from 'app/views/customers/customersList/customersList';
import {ServiceList} from 'app/views/services';
import {ProjectsList} from 'app/views/projects';

import styles from './all.module.scss';
import {
    areDistributionsLoadingSelector,
    distributionsSelector,
    IDistributionItem,
    resetDistributionsState,
    searchDistributions,
} from '../../state/distribution';
import {DistributionList} from '../../views/distribution';

const initialPreloadState = {
    [EntityType.Employee]: false,
    [EntityType.Document]: false,
    [EntityType.Email]: false,
    [EntityType.Training]: false,
    [EntityType.Correspondence]: false,
    [EntityType.News]: false,
    [EntityType.Tender]: false,
    [EntityType.Service]: false,
    [EntityType.Suppliers]: false,
    [EntityType.Customers]: false,
    [EntityType.Projects]: false,
    [EntityType.Distribution]: false,
};

export const AllContainer = () => {
    const {t} = useLocalizationContext();
    const dispatch = useDispatch();

    const searchQuery = useSelector(searchQuerySelector);
    const searchWords = useSelector(searchWordsSelector);
    const location = useSelector(locationSelector);

    const layoutRef = useRef<any>();

    const peopleRef = useRef<any>();
    const documentRef = useRef<any>();
    const mailRef = useRef<any>();
    const trainingRef = useRef<any>();
    const correspondenceRef = useRef<any>();
    const newsRef = useRef<any>();
    const tenderRef = useRef<any>();
    const serviceRef = useRef<any>();
    const suppliersRef = useRef<any>();
    const customersRef = useRef<any>();
    const projectsRef = useRef<any>();
    const distributionRef = useRef<any>();

    const [preloadedEntities, setPreloadedEntities] = useState(initialPreloadState);
    const searchSameValueCount = useSelector(searchSameValueSelector);

    const preload = useCallback(
        (preloadAgain: boolean = false) => {
            // base state of preloaded items
            const preloaded: typeof preloadedEntities = preloadAgain
                ? initialPreloadState
                : preloadedEntities;

            // variable for change merge with base state
            const p: Partial<typeof preloadedEntities> = {};

            if (
                !preloaded[EntityType.Employee] &&
                isInViewport(peopleRef.current, layoutRef.current)
            ) {
                dispatch(resetPeopleState());
                dispatch(searchPeople(searchQuery));
                p[EntityType.Employee] = true;
            }
            if (
                !preloaded[EntityType.Document] &&
                isInViewport(documentRef.current, layoutRef.current)
            ) {
                dispatch(resetDocumentsState());
                dispatch(searchDocuments(searchQuery));
                p[EntityType.Document] = true;
            }
            if (!preloaded[EntityType.Email] && isInViewport(mailRef.current, layoutRef.current)) {
                dispatch(resetMailState());
                dispatch(searchMail(searchQuery));
                p[EntityType.Email] = true;
            }
            if (
                !preloaded[EntityType.Training] &&
                isInViewport(trainingRef.current, layoutRef.current)
            ) {
                dispatch(resetTrainingsState());
                dispatch(searchTrainings(searchQuery));
                p[EntityType.Training] = true;
            }
            if (
                !preloaded[EntityType.Correspondence] &&
                isInViewport(correspondenceRef.current, layoutRef.current)
            ) {
                dispatch(resetCorrespondenceState());
                dispatch(searchCorrespondence(searchQuery));
                p[EntityType.Correspondence] = true;
            }
            if (!preloaded[EntityType.News] && isInViewport(newsRef.current, layoutRef.current)) {
                dispatch(resetNewsState());
                dispatch(searchNews(searchQuery));
                p[EntityType.News] = true;
            }
            if (
                !preloaded[EntityType.Tender] &&
                isInViewport(tenderRef.current, layoutRef.current)
            ) {
                dispatch(resetTendersState());
                dispatch(searchTenders(searchQuery));
                p[EntityType.Tender] = true;
            }
            if (
                !preloaded[EntityType.Service] &&
                isInViewport(serviceRef.current, layoutRef.current)
            ) {
                dispatch(resetServicesState());
                dispatch(searchServices(searchQuery));
                p[EntityType.Service] = true;
            }
            if (
                !preloaded[EntityType.Suppliers] &&
                isInViewport(suppliersRef.current, layoutRef.current)
            ) {
                dispatch(resetSuppliersState());
                dispatch(searchSuppliers(searchQuery));
                p[EntityType.Suppliers] = true;
            }
            if (
                !preloaded[EntityType.Customers] &&
                isInViewport(customersRef.current, layoutRef.current)
            ) {
                dispatch(resetCustomersState());
                dispatch(searchCustomers(searchQuery));
                p[EntityType.Customers] = true;
            }
            if (
                !preloaded[EntityType.Projects] &&
                isInViewport(projectsRef.current, layoutRef.current)
            ) {
                dispatch(resetProjectsState());
                dispatch(searchProjects(searchQuery));
                p[EntityType.Projects] = true;
            }
            if (
                !preloaded[EntityType.Distribution] &&
                isInViewport(distributionRef.current, layoutRef.current)
            ) {
                dispatch(resetDistributionsState());
                dispatch(searchDistributions(searchQuery));
                p[EntityType.Distribution] = true;
            }

            if (preloadAgain || Object.keys(p).length) {
                setPreloadedEntities({...preloaded, ...p});
            }
        },
        [dispatch, preloadedEntities, searchQuery]
    );

    useEffect(() => {
        preload(true);
    }, [searchQuery, searchSameValueCount]); // eslint-disable-line react-hooks/exhaustive-deps

    const people = useSelector(peopleItemsSelector).slice(0, 3).slice(0, 3);
    const arePeopleLoading =
        useSelector(arePeopleLoadingSelector) || !preloadedEntities[EntityType.Employee];
    const showPeople =
        isEntityEnabled(EntityType.Employee) && (arePeopleLoading || people.length > 0);
    const pushPeopleTab = useCallback(
        (item?: IPeopleItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetPeopleState());
            dispatch(push(routes.search_employee + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const documents = useSelector(documentsListSelector).slice(0, 3);
    const areDocumentsLoading =
        useSelector(isDocumentLoadingSelector) || !preloadedEntities[EntityType.Document];
    const showDocuments =
        isEntityEnabled(EntityType.Document) && (areDocumentsLoading || documents.length > 0);
    const pushDocumentTab = useCallback(
        (item?: IDocumentItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetDocumentsState());
            dispatch(push(routes.search_document + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const mail = useSelector(mailItemsSelector).slice(0, 3);
    const isMailLoading =
        useSelector(isMailLoadingSelector) || !preloadedEntities[EntityType.Email];
    const showMail = isEntityEnabled(EntityType.Email) && (isMailLoading || mail.length > 0);
    const pushMailTab = useCallback(
        (item?: IMailItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetMailState());
            dispatch(push(routes.search_email + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const trainings = useSelector(trainingsSelector).slice(0, 3);
    const areTrainingsLoading =
        useSelector(areTrainingsLoadingSelector) || !preloadedEntities[EntityType.Training];
    const showTrainings =
        isEntityEnabled(EntityType.Training) && (areTrainingsLoading || trainings.length > 0);
    const pushTrainingTab = useCallback(
        (item?: ITrainingItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetTrainingsState());
            dispatch(push(routes.search_training + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const correspondence = useSelector(correspondenceItemsSelector).slice(0, 3);
    const isCorrespondenceLoading =
        useSelector(isCorrespondenceLoadingSelector) ||
        !preloadedEntities[EntityType.Correspondence];
    const showCorrespondence =
        isEntityEnabled(EntityType.Correspondence) &&
        (isCorrespondenceLoading || correspondence.length > 0);
    const pushCorrespondenceTab = useCallback(
        (item?: ICorrespondenceItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetCorrespondenceState());
            dispatch(push(routes.search_correspondence + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const news = useSelector(newsSelector).slice(0, 3);
    const areNewsLoading =
        useSelector(areNewsLoadingSelector) || !preloadedEntities[EntityType.News];
    const showNews = isEntityEnabled(EntityType.News) && (areNewsLoading || news.length > 0);
    const pushNewsTab = useCallback(
        (item?: INewsItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetNewsState());
            dispatch(push(routes.search_news + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const tenders = useSelector(tendersSelector).slice(0, 3);
    const areTendersLoading =
        useSelector(areTendersLoadingSelector) || !preloadedEntities[EntityType.Tender];
    const showTenders =
        isEntityEnabled(EntityType.Tender) && (areTendersLoading || tenders.length > 0);
    const pushTenderBoardTab = useCallback(
        (item?: ITenderItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetTendersState());
            dispatch(push(routes.search_tender + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const services = useSelector(serviceItemsSelector).slice(0, 3);
    const areServicesLoading =
        useSelector(areServiceItemsLoadingSelector) || !preloadedEntities[EntityType.Service];
    const showServices =
        isEntityEnabled(EntityType.Service) && (areServicesLoading || services.length > 0);
    const pushServiceNowTab = useCallback(
        (item?: IServiceItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetServicesState());
            dispatch(push(routes.search_service + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const suppliers = useSelector(suppliersSelector).slice(0, 3);
    const areSuppliersLoading =
        useSelector(areSuppliersLoadingSelector) || !preloadedEntities[EntityType.Suppliers];
    const showSuppliers =
        isEntityEnabled(EntityType.Suppliers) && (areSuppliersLoading || suppliers.length > 0);
    const pushSupplierTab = useCallback(
        (item?: ISupplierItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetServicesState());
            dispatch(push(routes.search_suppliers + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const customers = useSelector(customersSelector).slice(0, 3);
    const areCustomersLoading =
        useSelector(areCustomersLoadingSelector) || !preloadedEntities[EntityType.Customers];
    const showCustomers =
        isEntityEnabled(EntityType.Customers) && (areCustomersLoading || customers.length > 0);
    const pushCustomersTab = useCallback(
        (item?: ICustomerItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetCustomersState());
            dispatch(push(routes.search_customers + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const projects = useSelector(projectsSelector).slice(0, 3);
    const areProjectsLoading =
        useSelector(areProjectsLoadingSelector) || !preloadedEntities[EntityType.Projects];
    const showProjects =
        isEntityEnabled(EntityType.Projects) && (areProjectsLoading || projects.length > 0);
    const pushProjectsTab = useCallback(
        (item?: IProjectItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetProjectsState());
            dispatch(push(routes.search_projects + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const distributions = useSelector(distributionsSelector).slice(0, 3);
    const areDistributionsLoading =
        useSelector(areDistributionsLoadingSelector) || !preloadedEntities[EntityType.Distribution];
    const showDistributions =
        isEntityEnabled(EntityType.Distribution) &&
        (areDistributionsLoading || distributions.length > 0);
    const pushDistributionsTab = useCallback(
        (item?: IDistributionItem) => {
            const query = {...parse(location.search.substring(1))};
            if (item) query[routeParams.SELECTED_ID] = item.id;
            dispatch(resetDistributionsState());
            dispatch(push(routes.search_distribution + '?' + stringify(query)));
        },
        [dispatch, location.search]
    );

    const onColumnScroll = useCallback(() => preload(), [preload]);

    const showNoResults = ![
        showCorrespondence,
        showMail,
        showTrainings,
        showPeople,
        showDocuments,
        showNews,
        showTenders,
        showServices,
        showSuppliers,
        showCustomers,
        showProjects,
    ].some(entityExists => entityExists);

    // dispatch preload after each loading change
    useEffect(() => {
        preload();
    }, [
        preload,
        areCustomersLoading,
        isMailLoading,
        areTrainingsLoading,
        arePeopleLoading,
        areDocumentsLoading,
        areNewsLoading,
        areTendersLoading,
        areServicesLoading,
        areSuppliersLoading,
        areCustomersLoading,
        areProjectsLoading,
    ]);

    if (showNoResults) {
        return (
            <NoResults
                imageSrc={noResultsSvg}
                imageClasses={styles.noResultsImage}
                title={t('containers.all.noResultsTitle', {query: searchQuery})}
                description={t('containers.all.noResultsDesc')}
            />
        );
    }

    return (
        <OneColumnLayout ref={layoutRef} onScroll={onColumnScroll} key={searchQuery}>
            <>
                {showPeople && (
                    <Portal
                        title={t('containers.all.peopleTitle')}
                        isLoading={arePeopleLoading}
                        onViewAll={pushPeopleTab}
                        ref={peopleRef}
                    >
                        <PeopleList
                            onSelect={pushPeopleTab}
                            items={people}
                            searchWords={searchWords}
                            isLoading={areDocumentsLoading}
                        />
                    </Portal>
                )}

                {showDocuments && (
                    <Portal
                        title={t('containers.all.documentsTitle')}
                        isLoading={areDocumentsLoading}
                        onViewAll={pushDocumentTab}
                        ref={documentRef}
                    >
                        <DocumentList
                            onSelect={pushDocumentTab}
                            documents={documents}
                            searchWords={searchWords}
                            isLoading={areDocumentsLoading}
                        />
                    </Portal>
                )}

                {showCorrespondence && (
                    <Portal
                        title={t('containers.all.correspondenceTitle')}
                        isLoading={isCorrespondenceLoading}
                        onViewAll={pushCorrespondenceTab}
                        ref={correspondenceRef}
                    >
                        <CorrespondenceList
                            items={correspondence}
                            searchWords={searchWords}
                            isLoading={isCorrespondenceLoading}
                            onSelect={pushCorrespondenceTab}
                        />
                    </Portal>
                )}

                {showMail && (
                    <Portal
                        title={t('containers.all.emailsTitle')}
                        isLoading={isMailLoading}
                        onViewAll={pushMailTab}
                        ref={mailRef}
                    >
                        <MailList
                            mail={mail}
                            searchWords={searchWords}
                            isLoading={isMailLoading}
                            onSelect={pushMailTab}
                        />
                    </Portal>
                )}

                {showTrainings && (
                    <Portal
                        title={t('containers.all.trainingsTitle')}
                        isLoading={areTrainingsLoading}
                        onViewAll={pushTrainingTab}
                        ref={trainingRef}
                    >
                        <TrainingsList
                            trainings={trainings}
                            searchWords={searchWords}
                            isLoading={areTrainingsLoading}
                            onSelect={pushTrainingTab}
                        />
                    </Portal>
                )}

                {showNews && (
                    <Portal
                        title={t('containers.all.newsTitle')}
                        isLoading={areNewsLoading}
                        onViewAll={pushNewsTab}
                        ref={newsRef}
                    >
                        <NewsList
                            news={news}
                            onSelect={pushNewsTab}
                            isLoading={areNewsLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}

                {showProjects && (
                    <Portal
                        title={t('containers.all.projectsTitle')}
                        isLoading={areProjectsLoading}
                        onViewAll={pushProjectsTab}
                        ref={projectsRef}
                    >
                        <ProjectsList
                            projects={projects}
                            onSelect={pushProjectsTab}
                            isLoading={areProjectsLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}

                {showTenders && (
                    <Portal
                        title={t('containers.all.tenderBoardTitle')}
                        isLoading={areTendersLoading}
                        onViewAll={pushTenderBoardTab}
                        ref={tenderRef}
                    >
                        <TenderBoardList
                            tenders={tenders}
                            onSelect={pushTenderBoardTab}
                            isLoading={areTendersLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}

                {showServices && (
                    <Portal
                        title={t('containers.all.servicesTitle')}
                        isLoading={areServicesLoading}
                        onViewAll={pushServiceNowTab}
                        ref={serviceRef}
                    >
                        <ServiceList
                            services={services}
                            onSelect={pushServiceNowTab}
                            isLoading={areServicesLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}

                {showSuppliers && (
                    <Portal
                        title={t('containers.all.suppliersTitle')}
                        isLoading={areSuppliersLoading}
                        onViewAll={pushSupplierTab}
                        ref={suppliersRef}
                    >
                        <SuppliersList
                            items={suppliers}
                            onSelect={pushSupplierTab}
                            isLoading={areSuppliersLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}

                {showCustomers && (
                    <Portal
                        title={t('containers.all.customersTitle')}
                        isLoading={areCustomersLoading}
                        onViewAll={pushCustomersTab}
                        ref={customersRef}
                    >
                        <CustomersList
                            items={customers}
                            onSelect={pushCustomersTab}
                            isLoading={areCustomersLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}
                {showDistributions && (
                    <Portal
                        title={t('containers.all.distributionTitle')}
                        isLoading={areDistributionsLoading}
                        onViewAll={pushDistributionsTab}
                        ref={distributionRef}
                    >
                        <DistributionList
                            items={distributions}
                            onSelect={pushDistributionsTab}
                            isLoading={areDistributionsLoading}
                            searchWords={searchWords}
                        />
                    </Portal>
                )}
            </>
        </OneColumnLayout>
    );
};
