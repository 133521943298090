export const urlToPath = (url: string) => url.replace(/https?:\/\/[^/]+/, '');

export const urlAppendParam = (url: string, paramName: string, paramValue: string) => {
    return `${url}${url.indexOf('?') === -1 ? '?' : '&'}${paramName}=${paramValue}`;
};

export const urlToOneHubProxy = (domain: string, url: string, application: string): string => {
    return `${domain}/api/proxy/v0/${application}/direct?uri=${encodeURIComponent(url)}`;
};

export const generateOneHubServicesInnerPageUrl = (
    url: string,
    path: string,
    token: string,
    queries = ''
): string => {
    const encoded = encodeURIComponent(
        url + (token ? `${url.includes('?') ? '&' : '?'}token=${token}${queries}` : '')
    );

    return `${path}/${encoded}`;
};
