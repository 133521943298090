import {IFilterRefiner} from 'app/views/components/filterPanel/filterPanel.types';
import {PeopleFiltersOptions} from 'app/containers/people';

export const createPeopleFilter = (
    exactMatch: [
        {
            RefinementName: PeopleFiltersOptions;
            RefinementValue: PeopleFiltersOptions;
            isChecked: boolean;
        }
    ],
    companies: string[],
    jobTitles: string[]
) => {
    const exactMatchFilter: IFilterRefiner = {
        Name: PeopleFiltersOptions.ExactMatch,
        Entries: exactMatch,
    };
    const companyFilter: IFilterRefiner = {
        Name: PeopleFiltersOptions.Companies,
        Entries: companies.map(company => ({
            RefinementName: company,
            RefinementValue: company,
            isChecked: false,
        })),
    };
    const jobTitleFilter: IFilterRefiner = {
        Name: PeopleFiltersOptions.JobTitles,
        Entries: jobTitles.map(title => ({
            RefinementName: title,
            RefinementValue: title,
            isChecked: false,
        })),
    };

    return [exactMatchFilter, companyFilter, jobTitleFilter];
};

export const mapFiltersToPeopleOptions = (
    filters: IFilterRefiner[]
): {companies: string[]; exactMatch: (boolean | undefined)[] | boolean; jobTitles: string[]} => {
    const companyFilter = filters.find(f => f.Name === PeopleFiltersOptions.Companies);
    const companies =
        companyFilter?.Entries.filter(e => e.isChecked).map(e => e.RefinementValue) || [];

    const jobTitleFilter = filters.find(f => f.Name === PeopleFiltersOptions.JobTitles);
    const jobTitles =
        jobTitleFilter?.Entries.filter(e => e.isChecked).map(e => e.RefinementValue) || [];

    const ExactMatchFilter = filters.find(f => f.Name === PeopleFiltersOptions.ExactMatch);
    const exactMatch = ExactMatchFilter?.Entries[0]?.isChecked || false;

    return {
        companies,
        jobTitles,
        exactMatch,
    };
};
