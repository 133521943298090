import {
    AbortControllerSuppliers,
    IGetSuppliersSubItems,
    ISearchSuppliersOptions,
} from './searchAPICallers.suppliers.types';
import {fetchData} from 'app/utils';
import {SuppliersRoutes} from './routes.suppliers';

export const abortControllers: AbortControllerSuppliers = {
    SUPPLIERS: null,
};

export abstract class SearchAPICallerSuppliers {
    public static async searchSuppliers(
        body: ISearchSuppliersOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.SUPPLIERS?.abort();
        abortControllers.SUPPLIERS = new AbortController();

        return fetchData(
            SuppliersRoutes.suppliers(searchAPIURL),
            accessToken,
            'POST',
            body,
            null,
            null,
            abortControllers.SUPPLIERS.signal
        );
    }

    public static async getSuppliersDetails(
        {
            supplierId,
            companyId,
        }: {
            supplierId: string;
            companyId: string;
        },
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${SuppliersRoutes.suppliers(searchAPIURL)}/${supplierId}?companyId=${companyId}`
        );

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }

    public static async getSuppliersCountryDetails(
        {
            country,
            companyId,
            share,
        }: {
            country: string;
            companyId: string;
            share: string;
        },
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${SuppliersRoutes.suppliersCountry(
                searchAPIURL,
                country
            )}?companyId=${companyId}&share=${share}`
        );

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }

    public static async searchSuppliersCompanies(searchAPIURL: string, accessToken: string) {
        return fetchData(
            SuppliersRoutes.suppliersCompanies(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }

    public static async getSuppliersRankDetails(
        {pgCode, topGuyCode, companyId}: IGetSuppliersSubItems,
        searchAPIURL: string,
        accessToken: string
    ) {
        const url = new URL(
            `${SuppliersRoutes.suppliersSubItems(
                searchAPIURL
            )}/${pgCode}/?topGuyCode=${topGuyCode}&companyId=${companyId}`
        );

        return fetchData(String(url), accessToken, 'GET', null, null, null);
    }

    public static async getDocumentBlob(searchAPIURL: string, accessToken: string, id: string) {
        const url: string = SuppliersRoutes.supplierDocumentDownloadURL(searchAPIURL, id);

        return await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response: any) => response);
    }

    public static getPreviewDocumentUrl(searchAPIURL: string, id: string) {
        return SuppliersRoutes.supplierDocumentPreviewURL(searchAPIURL, id);
    }

    public static getDownloadDocumentUrl(searchAPIURL: string, id: string) {
        return SuppliersRoutes.supplierDocumentDownloadURL(searchAPIURL, id);
    }
}
