import {ILightAppWithQueryParts} from '../state/lightApps';
import {ISuggestionCategory} from '../infrastructure/suggestions/suggestionsService.types';

export interface ISelectedResult {
    lightAppsSelectedI?: number;
    suggestionsSelectedI?: number;
    suggestionsSelectedItemI?: number;
    allLightAppsSelected?: boolean;
    allSuggestionsSelected?: boolean;
}

export function calcMainSearchSelected(
    selectedI: number,
    lightAppsSuggestions: ILightAppWithQueryParts[],
    suggestions: ISuggestionCategory[],
    isHistorySearch: boolean
): ISelectedResult {
    let offsetI = 0;

    if (selectedI < 0) {
        return {};
    }

    if (lightAppsSuggestions.length > 0) {
        if (selectedI < lightAppsSuggestions.length) {
            return {lightAppsSelectedI: selectedI};
        }
        offsetI += lightAppsSuggestions.length;

        if (selectedI < offsetI + 1) {
            return {allLightAppsSelected: true};
        }
        offsetI += 1;
    }

    for (let i = 0; i < suggestions.length; i++) {
        const suggestion = suggestions[i];

        if (selectedI < offsetI + suggestion.items.length) {
            return {suggestionsSelectedI: i, suggestionsSelectedItemI: selectedI - offsetI};
        }
        offsetI += suggestion.items.length;
    }

    if (!isHistorySearch && selectedI < offsetI + 1) {
        return {allSuggestionsSelected: true};
    }

    return {};
}

export function calcMainSearchItemsCount(
    lightAppsSuggestions: ILightAppWithQueryParts[],
    suggestions: ISuggestionCategory[],
    isHistorySearch: boolean
): number {
    let count = lightAppsSuggestions.length;

    if (lightAppsSuggestions.length) {
        count++; // all light apps button
    }

    for (let suggestion of suggestions) {
        count += suggestion.items.length;
    }

    if (!isHistorySearch) {
        count++; // all suggestions button
    }

    return count;
}
