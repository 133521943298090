import React, {useRef, useState} from 'react';
import {PreviewContentLayout} from '../../../../layouts';
import classNames from 'classnames';
import styles from './distributionPreviewPanel.module.scss';
import {RevenueCard} from './components/revenueCard';
import {ComplaintsCard} from './components/complaintsCard';
import {RevenueTrend} from './components/revenueTrend';
import {TopCStoreProducts} from './components/topCStoreProducts';
import {BasketSizeTrend} from './components/basketSizeTrend';
import {CustomerInteractionsTrend} from './components/customerInteractionsTrend';
import {DistributionMap} from './components/distributionMap/distributionMap';
import useDimensions from 'react-cool-dimensions';
import {PeriodToggler} from './components/periodToggler';
import {PeakHours} from './components/peakHours';
import {IDistributionPreviewProps, ISearchEnginesKpi} from './distributionPreviewPanel.types';
import {has, map, size, sortBy} from 'lodash';

const transformDate = (monthYearValue: string) => {
    const [month, year] = monthYearValue.split('-');
    return `${month}, 20${year}`;
};

const transformQuarter = (quarterLabel: string) => {
    const [year, quarter] = quarterLabel.split(' Q ');
    return `Q${quarter}-${year.substring(2)}`;
};

const getPeriodStartTime = (monthYearValue: string): number => {
    const [month, year] = monthYearValue.split('-');
    return new Date(`1 ${month} 20${year}`).getTime();
};

export const DistributionPreviewPanel = ({data}: {data: IDistributionPreviewProps}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const {width} = useDimensions({ref: containerRef});
    const [period, setPeriod] = useState('quarter');

    // TODO Check what must be show
    if (!data.details || has(data, 'details.hasErrors')) return null;

    const {
        name,
        cityName,
        id,
        rating,
        status,
        placeId,
        phoneNumber,
        averageComplaints,
        totalComplaints,
        averageMonthFuelValues,
        averageMonthCStoreValues,
        averageMonthCarWashValues,
        averageMonthLubeValues,
        managerName,
        managerEmailAddress,
        searchEnginesKpis,
        totalStaff,
        stationTrafics,
        top10CsItems,
    } = data.details;

    const searchEnginesKpisSortedByMonth: ISearchEnginesKpi[] = sortBy(
        searchEnginesKpis,
        (o: ISearchEnginesKpi) => getPeriodStartTime(o.monthYearValue)
    );
    const monthKpis: ISearchEnginesKpi[] = map(
        searchEnginesKpisSortedByMonth,
        (item: ISearchEnginesKpi): ISearchEnginesKpi => ({
            ...item,
            monthYearValue: transformDate(item.monthYearValue),
            quarterLabel: transformQuarter(item.quarterLabel),
        })
    );

    const currentMonthKpi = monthKpis[size(monthKpis) - 1];
    const previousMonthKpi = monthKpis[size(monthKpis) - 2];

    const quarterKpis = monthKpis.reduce((quarters: ISearchEnginesKpi[], month) => {
        const quarter: ISearchEnginesKpi = quarters[quarters.length - 1];
        if (quarter && quarter.quarterLabel === month.quarterLabel) {
            quarter.totalRevenue += month.totalRevenue;
            quarter.fuelRevenue += month.fuelRevenue;
            quarter.lpgRevenue += month.lpgRevenue;
            quarter.openComplaints += month.openComplaints;
            quarter.cStoreRevenue += month.cStoreRevenue;
            quarter.lubeRevenue += month.lubeRevenue;
            quarter.enquiresCount += month.enquiresCount;
            quarter.basketSizeForRange += month.basketSizeForRange;
            quarter.carWashRevenue += month.carWashRevenue;
            quarter.totalComplaints += month.totalComplaints;
            quarter.totalSuggestions += month.totalSuggestions;
        } else {
            quarters.push(month);
        }
        return quarters;
    }, []);

    const currentQuarterKpi = quarterKpis[size(quarterKpis) - 1];
    const previousQuarterKpi = quarterKpis[size(quarterKpis) - 2];

    const periodLabel =
        period === 'month' ? currentMonthKpi?.monthYearValue : currentQuarterKpi?.quarterLabel;

    const currentPeriodKpi = period === 'month' ? currentMonthKpi : currentQuarterKpi;

    const previousPeriodKpi = period === 'month' ? previousMonthKpi : previousQuarterKpi;

    const kpiData: ISearchEnginesKpi[] = period === 'month' ? monthKpis : quarterKpis;

    const labels =
        period === 'month'
            ? monthKpis.map(p => p.monthYearValue)
            : quarterKpis.map(p => p.quarterLabel);

    const isContainerWidthSmall = width <= 576;

    const togglePeriod = (e: React.MouseEvent<HTMLElement>, value: string) => {
        setPeriod(value);
    };

    const averageValues = (avg: Map<string, number>): any[][] => {
        if (!avg) return [];
        return Object.entries(avg)
            .map(el => [Date.parse(`1 ${transformDate(el[0])}`), el[1]])
            .sort((a, b) => a[0] - b[0])
            .map(el => [
                transformQuarter(
                    `${new Date(el[0]).getFullYear()} Q ${Math.ceil(
                        (new Date(el[0]).getMonth() + 1) / 3
                    )}`
                ),
                el[1],
            ]);
    };

    const monthAvg = (monthAvg: Map<string, number>) => averageValues(monthAvg).map(val => val[1]);
    const quarterAvg = (monthAvg: Map<string, number>) =>
        averageValues(monthAvg)
            .reduce((quarters, month, i) => {
                if (i === 0) {
                    quarters.push(month);
                }
                if (month[0] === quarters[quarters.length - 1][0]) {
                    quarters[quarters.length - 1][1] = quarters[quarters.length - 1][1] + month[1];
                } else {
                    quarters.push(month);
                }
                return quarters;
            }, [])
            .map(q => q[1]);

    const monthAvgRevenue = {
        fuelRevenue: monthAvg(averageMonthFuelValues),
        cStoreRevenue: monthAvg(averageMonthCStoreValues),
        carWashRevenue: monthAvg(averageMonthCarWashValues),
        lubeRevenue: monthAvg(averageMonthLubeValues),
    };

    const quarterAvgRevenue = {
        fuelRevenue: quarterAvg(averageMonthFuelValues),
        cStoreRevenue: quarterAvg(averageMonthCStoreValues),
        carWashRevenue: quarterAvg(averageMonthCarWashValues),
        lubeRevenue: quarterAvg(averageMonthLubeValues),
    };

    const avgData = period === 'month' ? monthAvgRevenue : quarterAvgRevenue;
    const revenueData = {
        kpiData: kpiData,
        avgData: avgData,
    };

    return (
        <PreviewContentLayout
            layoutAttributes={{className: classNames(styles.container)}}
            ref={containerRef}
        >
            <div className={styles.header}>
                <h2 className={styles.title}>{name}</h2>
                <div className={styles.date}>
                    <PeriodToggler
                        className={styles.periodToggler}
                        period={period}
                        togglePeriod={togglePeriod}
                    />
                    <div className={styles.currentDateInfo}>{periodLabel}</div>
                </div>
            </div>
            <DistributionMap
                isSmall={isContainerWidthSmall}
                data={{
                    name,
                    status,
                    rating,
                    id,
                    placeId,
                    phoneNumber,
                    cityName,
                    totalRevenue: currentPeriodKpi?.totalRevenue,
                    totalStaff,
                    managerName,
                    managerEmailAddress,
                }}
            />
            <div className={styles.cardsContainer}>
                {[
                    'fuelRevenue',
                    'cStoreRevenue',
                    'carWashRevenue',
                    'lubeRevenue',
                    'lpgRevenue',
                ].map(type => (
                    <RevenueCard
                        key={`revenue-card-${type}`}
                        className={styles.cardContainer}
                        type={type}
                        period={period}
                        currentPeriod={currentPeriodKpi}
                        previousPeriod={previousPeriodKpi}
                    />
                ))}
                <ComplaintsCard
                    className={styles.cardContainer}
                    averageComplaints={averageComplaints}
                    totalComplaints={totalComplaints}
                    openComplaints={currentPeriodKpi?.openComplaints}
                />
            </div>
            <RevenueTrend
                className={classNames(styles.itemContainer, styles.revenue)}
                width={width}
                labels={labels}
                data={revenueData}
            />
            <PeakHours className={styles.itemContainer} stationTrafics={stationTrafics} />
            <div className={styles.twoColumns}>
                <BasketSizeTrend
                    className={classNames(styles.itemContainer, styles.basketSize)}
                    width={width}
                    labels={labels}
                    data={kpiData}
                />
                <CustomerInteractionsTrend
                    className={classNames(styles.itemContainer, styles.customerInteraction)}
                    width={width}
                    labels={labels}
                    data={kpiData}
                />
            </div>
            <TopCStoreProducts
                className={styles.itemContainer}
                products={top10CsItems}
                isSmallWidth={isContainerWidthSmall}
            />
        </PreviewContentLayout>
    );
};
