import {useEffect, useState} from 'react';
import {useInjection} from './index';
import {ISearchService} from '../infrastructure/search';

import {IoC} from '../ioc';

export function useUserAvatar(email: string | null) {
    const [avatar, setAvatar] = useState('');
    const searchService = useInjection<ISearchService>(IoC.SearchService);

    useEffect(() => {
        if (email) {
            searchService
                .searchOneHubEmployeesPhotos(email.toLocaleLowerCase())
                .then((blob: Blob) => {
                    setAvatar(blob && URL.createObjectURL(blob));
                });
        }
    }, [email]);

    return avatar;
}
