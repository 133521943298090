import React from 'react';
import {useLocalizationContext} from 'app/hooks';
import {DetailsList} from '../detailsList';

import styles from '../../servicePreviewPanel.module.scss';
import {CommonDetails} from '../commonDetails';
import {PriorityLabel} from '../../../label/label';
import {userInfoRenderer} from '../userInfoRenderer';

export const HrCase = ({data}: any) => {
    const {t} = useLocalizationContext();

    return (
        <>
            <div className={styles.twoColumnContainer}>
                <DetailsList
                    data={data}
                    fields={[
                        'number',
                        {
                            name: 'priority',
                            renderer: (value: string) =>
                                value ? (
                                    <PriorityLabel>{value}</PriorityLabel>
                                ) : (
                                    t('serviceNow.preview.NA')
                                ),
                        },
                    ]}
                />
                <DetailsList
                    data={data}
                    fields={[
                        {
                            name: 'openedFor',
                            renderer: userInfoRenderer,
                        },
                        {
                            name: 'subjectPerson',
                            renderer: userInfoRenderer,
                        },
                    ]}
                />
            </div>
            <hr />
            <div className={styles.twoColumnContainer}>
                <CommonDetails data={data} />
                <DetailsList
                    data={data}
                    fields={[
                        {
                            name: 'assignedTo',
                            renderer: userInfoRenderer,
                        },
                        'hcService',
                        'contactType',
                    ]}
                />
            </div>
        </>
    );
};
