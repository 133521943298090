import React from 'react';
import {SmallCard} from './smallCard';
import {useLocalizationContext} from '../../../../../hooks';

export const ComplaintsCard = ({
    className,
    averageComplaints,
    totalComplaints,
    openComplaints,
}: {
    className?: string;
    averageComplaints: number;
    totalComplaints: number;
    openComplaints: number;
}) => {
    const {t} = useLocalizationContext();
    const title = t('distribution.preview.customerComplaints');
    const period = t('distribution.preview.twelveMonthsComplaints');

    const additionalData = [
        {
            name: t('distribution.preview.avgComplaintsPerStation'),
            value: averageComplaints || 0,
        },
        {
            name: t('distribution.preview.openComplaints'),
            value: openComplaints || 0,
        },
    ];

    return (
        <SmallCard
            className={className}
            title={title}
            subtitle={period}
            total={totalComplaints || 0}
            data={additionalData}
            type="complaints"
        />
    );
};
