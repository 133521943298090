import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash/debounce';
import {get, trim} from 'lodash';
import {goBack, push} from 'connected-react-router';
import {parse, stringify} from 'querystring';
import {routeParams, routes} from 'app/routes';
import {
    ClientType,
    EventType,
    IChannelContext,
    IEventDescriptor,
    IMessageDescriptor,
    IOnDocumentPreviewMessage,
    IOnDownloadLinkMessage,
    IOnEmployeeHintShow,
    IOnMobileMailClientOpen,
    IOnOpenEmployeeProfileMessage,
    IOnOpenLinkMessage,
    IOnOpenOrgChartMessage,
    IOnOpenProfileCompletnessMessage,
    IOnPreviewItemMessage,
    IOnPreviewLinkMessage,
    NavigationType,
    NodeType,
} from './channelContext.types';
import {AppMode, IAppContext} from 'app/contexts';
import {useAppContext, useInjection} from 'app/hooks';
import {IHistoryService} from 'app/infrastructure/history';
import {IoC} from 'app/ioc';
import {transformEntityToNotification} from 'app/infrastructure/history/historyService.transformer';
import {sendMessage} from 'app/infrastructure/onehub/messageProtocol';
import {
    sendDocumentPreviewMessage,
    sendDocumentPreviewMessageMobile,
} from 'app/infrastructure/onehub/previewMessageProtocol';
import {locationSelector} from 'app/state/router';
import {generateUniqueString} from '../../utils/generateUniqueString';
import {UPDATE_TOKEN_MAX_ATTEMPTS} from '../../constants';

export const ChannelContext = React.createContext({} as IChannelContext);

export const Provider = (props: any) => {
    const appContext: IAppContext = useAppContext();
    const dispatch = useDispatch();
    const location = useSelector(locationSelector);
    const historyService = useInjection<IHistoryService>(IoC.HistoryService);

    const handleSearch = useCallback(
        (event: IEventDescriptor): void => {
            const query = {
                ...parse(location.search.replace('?', '')),
            };
            const pushState = {
                pathname: routes.search,
                search: '',
                state: {isCalledByWeb: true},
            };

            query[routeParams.QUERY] = event.eventData;

            if (!!event.entityType) {
                query[routeParams.SELECTED_ID] = event.entityId;
                pushState.pathname = get(routes, `search_${event.entityType}`, routes.search);
            } else {
                delete query[routeParams.SELECTED_ID];
            }

            pushState.search = stringify(query);

            dispatch(push(pushState));
        },
        [dispatch, location]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(debounce(handleSearch, 800), [handleSearch]);
    const handleNavigation = useCallback(
        (event: IEventDescriptor) => {
            switch (event.eventData) {
                case NavigationType.Back: {
                    dispatch(goBack());
                    break;
                }
            }
        },
        [dispatch]
    );

    const handleUpdateToken = useCallback(
        (event: IEventDescriptor) => {
            if (event?.eventType === EventType.TokenRotation) {
                appContext.updateToken(event.eventData, () => {
                    const reloadKey = generateUniqueString();
                    let updatedSearchQuery = location.query.q;

                    // Add or remove last space for change location and init react-router to reload current page
                    switch (true) {
                        case updatedSearchQuery && updatedSearchQuery.endsWith(' '): {
                            updatedSearchQuery = trim(updatedSearchQuery);

                            break;
                        }
                        case updatedSearchQuery && !updatedSearchQuery.endsWith(' '): {
                            updatedSearchQuery = `${updatedSearchQuery} `;

                            break;
                        }
                        default:
                            updatedSearchQuery = ' ';
                    }

                    const _query = {
                        ...location.query,
                        q: updatedSearchQuery,
                        attempts: location.query.attempts ? +location.query.attempts + 1 : 1,
                        token: event.eventData,
                    };
                    const pushState = {
                        pathname: `${location.pathname}`,
                        query: _query,
                        search: `?${stringify(_query)}`,
                        state: {reloadKey},
                    };

                    if (
                        !location.query.attempts ||
                        +location.query.attempts <= UPDATE_TOKEN_MAX_ATTEMPTS
                    ) {
                        dispatch(push(pushState));
                    }
                });
            }
        },
        [appContext, dispatch, location.pathname, location.query]
    );

    const handle = useCallback(
        (event: IEventDescriptor): void => {
            const type = event.eventType;

            if (type === EventType.Search) return debouncedSearch(event);
            if (type === EventType.Nav) return handleNavigation(event);
            if (type === EventType.TokenRotation) return handleUpdateToken(event);
        },
        [debouncedSearch, handleNavigation, handleUpdateToken]
    );

    const usePreviewItem = (msg: IOnPreviewItemMessage) => {
        const isValidMessage = !!msg;
        const entity = msg?.entity;
        const entityType = msg?.entityType;

        const notification = useMemo(() => {
            if (entityType && entity) {
                return transformEntityToNotification(entityType, entity);
            }
            return null;
        }, [entityType, entity]);

        useEffect(() => {
            if (!isValidMessage) return;
            if (appContext.mode !== AppMode.FullScreen) {
                const data = notification ? NodeType.Leaf : NodeType.Root;
                sendMessage({eventType: EventType.Nav, eventData: data});
            }
            if (notification && entityType) {
                if (!location.query[routeParams.DISABLE_HISTORY_ONCE]) {
                    historyService.sendNotification(entityType, notification);
                }
            }
        }, [isValidMessage, entityType, notification?.id]); // eslint-disable-line
    };

    const onOpenLink = (msg: IOnOpenLinkMessage) => {
        if (!msg) return;
        sendMessage({eventType: EventType.OpenLink, eventData: msg.absoluteURL}, ClientType.RN);
    };

    const onDownloadLink = (msg: IOnDownloadLinkMessage) => {
        if (!msg) return;
        let _eventData = msg.absoluteURL;

        if (typeof msg.absoluteURL !== 'string') {
            // TODO Check if we need absoluteURL like a full URL object
            _eventData = `${msg.absoluteURL?.origin}${msg.absoluteURL?.pathname}${msg.absoluteURL?.search}`;
        }

        sendMessage({eventType: EventType.DownloadLink, eventData: _eventData}, ClientType.RN);
    };

    const onDocumentPreview = (msg: IOnDocumentPreviewMessage) => {
        if (!msg) return;
        sendDocumentPreviewMessage({
            action: EventType.ShowPdf,
            payload: {
                downloadUrl: msg.downloadUrl,
                url: msg.previewUrl,
                extension: 'xod',
            },
        });
    };

    const onDocumentPreviewMobile = (msg: IOnDocumentPreviewMessage) => {
        if (!msg) return;
        sendDocumentPreviewMessageMobile({
            downloadUrl: msg.downloadUrl,
            previewUrl: msg.previewUrl,
        });
    };

    const onOpenProfile = (msg: IOnOpenEmployeeProfileMessage) => {
        if (!msg) return;
        sendMessage({eventType: EventType.OpenProfile, eventData: msg.email});
    };

    const onGoToEServiceProfile = (msg: IOnOpenProfileCompletnessMessage) => {
        if (!msg) return;
        sendMessage({eventType: EventType.OpenProfileCompleteness, eventData: msg.url});
    };

    const onPreviewLink = (msg: IOnPreviewLinkMessage) => {
        if (!msg) return;
        sendMessage({eventType: EventType.PreviewLink, eventData: msg.absoluteURL}, ClientType.RN);
    };

    const onOpenOrgChart = (msg: IOnOpenOrgChartMessage) => {
        if (!msg) return;
        sendMessage({eventType: EventType.OpenOrgChart, eventData: msg.email});
    };

    const onMobileMailClientOpen = (msg: IOnMobileMailClientOpen) => {
        if (!msg) return;

        sendMessage(
            {
                eventType: EventType.OpenMailClient,
                eventData: {to: msg.email, subject: '', body: ''},
            },
            ClientType.RN
        );
    };

    const onEmployeeHintShow = (msg: IOnEmployeeHintShow) => {
        if (!msg) return;
        sendMessage({
            eventType: EventType.Hint,
            eventData: msg.isVisible,
        });
    };

    const onUpdateTokenFromParent = useCallback(() => {
        if (!location.query.attempts || +location.query.attempts <= UPDATE_TOKEN_MAX_ATTEMPTS) {
            sendMessage({
                eventType: EventType.TokenRotation,
                eventData: '',
            });
        }
        // Example
        // window.postMessage({"client":"search","author":"oneHub","msg":{"eventType":"token-rotation","eventData":""}})
        // window.postMessage({"client":"search","author":"oneHub","msg":{"eventType":"token-rotation","eventData":"123"}})
    }, [location.query.attempts]);

    const onMessage = useCallback(
        (e: MessageEvent) => {
            const msgDescriptor: IMessageDescriptor = e.data;
            if (
                msgDescriptor.client === ClientType.Search &&
                msgDescriptor.author === ClientType.OneHub &&
                msgDescriptor.msg
            ) {
                handle(msgDescriptor.msg as IEventDescriptor);
            }
        },
        [handle]
    );

    useEffect(() => {
        if (appContext.mode !== AppMode.FullScreen) {
            window.addEventListener('message', onMessage);
            return () => window.removeEventListener('message', onMessage);
        }
    }, [appContext.mode, onMessage]);
    const context: IChannelContext = {
        onOpenLink,
        onOpenProfile,
        onDownloadLink,
        onPreviewLink,
        onOpenOrgChart,
        onMobileMailClientOpen,
        usePreviewItem,
        onDocumentPreview,
        onDocumentPreviewMobile,
        onGoToEServiceProfile,
        onEmployeeHintShow,
        onUpdateTokenFromParent,
    };
    return <ChannelContext.Provider value={context}>{props?.children}</ChannelContext.Provider>;
};
