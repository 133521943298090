import React, {FC} from 'react';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import styles from './trainingsListItem.module.scss';
import {TrainingsThumbnail} from 'app/views/trainings/components/trainingsThumbnail/trainingsThumbnail';
import {ITrainingItem} from 'app/state/trainings';

export const TrainingsListItem: FC<{item: ITrainingItem; searchWords: string[]}> = ({
    item: {courseIcon_Base64, courseFullName, courseDuration, courseCategory, courseShortName},
    searchWords = [],
}) => (
    <div className={styles.trainingsListItem}>
        <TrainingsThumbnail
            alt={courseFullName}
            src={courseIcon_Base64}
            className={styles.thumbnail}
        />
        <div className={styles.info}>
            <Highlighter
                className={styles.name}
                highlightClassName="highlight"
                searchWords={searchWords}
                textToHighlight={courseFullName || ''}
            />
            <div className={styles.extraInfo}>
                {courseDuration && <span className={styles.length}>{courseDuration}</span>}
                <div className={styles.fieldOfStudyWrapper}>
                    <Highlighter
                        className={styles.fieldOfStudy}
                        highlightClassName="highlight"
                        searchWords={searchWords}
                        textToHighlight={courseShortName || ''}
                        autoEscape={false}
                    />
                    <span className={styles.fieldOfStudy}>{courseCategory}</span>
                </div>
            </div>
        </div>
    </div>
);
