import React from 'react';
import {CorrespondenceListItem} from '../correspondenceListItem';
import {CorrespondenceListItemSkeleton} from '../correspondenceListItemSkeleton';
import {List} from 'app/views/components/list';
import {ICorrespondenceListProps} from './correspondenceList.types';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const CorrespondenceList = ({
    items,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}: ICorrespondenceListProps) => {
    if (isLoading) {
        return (
            <List
                itemComponentRender={CorrespondenceListItemSkeleton}
                itemsData={skeletons}
                searchWords={[]}
            />
        );
    }

    return (
        <List
            itemComponentRender={CorrespondenceListItem}
            itemsData={items}
            selectedItemId={selectedItemId}
            onSelect={onSelect}
            loadMoreHandler={loadMoreHandler}
            searchWords={searchWords}
        />
    );
};
