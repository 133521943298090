import React, {FC} from 'react';
import styles from './peopleListItem.module.scss';
import {HighlighterComponent as Highlighter} from 'app/views/components/highlighter';
import {useLocalizationContext} from 'app/hooks';
import {IPeopleItem} from 'app/state/people';
import {TextAvatar} from 'app/views/components/textAvatar';
import {useUserAvatar} from '../../../../hooks/useUserAvatar';

export const PeopleListItem: FC<{item: IPeopleItem; searchWords: string[]}> = ({
    item: {givenName, surname, jobtitle, pictureurl, department, companyName, workEmail},
    searchWords,
}) => {
    const {t} = useLocalizationContext();
    const hasCompanyNameOrDepartment = Boolean(companyName || department);
    const location =
        companyName && department && t('people.peopleListItem.location', {department, companyName});
    const name = `${givenName} ${surname || ''}`;
    const avatar = useUserAvatar(workEmail);

    return (
        <div className={styles.peopleListItem}>
            <TextAvatar title={name} avatarProps={{src: avatar, className: styles.avatar}} />
            <div className={styles.info}>
                <Highlighter
                    className={styles.name}
                    highlightClassName="highlight"
                    searchWords={searchWords}
                    textToHighlight={name}
                />
                {jobtitle && (
                    <div className={styles.jobTitle}>
                        <Highlighter
                            highlightClassName="highlight"
                            searchWords={searchWords}
                            textToHighlight={jobtitle}
                        />
                    </div>
                )}
                <div className={styles.location}>
                    {hasCompanyNameOrDepartment && (
                        <Highlighter
                            highlightClassName="highlight"
                            searchWords={searchWords}
                            textToHighlight={(location || companyName || department) as string}
                            autoEscape
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
