import React, {FC} from 'react';
import {MailListItem} from 'app/views/mail/components/mailListItem';
import {List} from 'app/views/components/list';
import {IMailListProps} from 'app/views/mail/components/mailList/mailList.types';
import {MailListItemSkeleton} from 'app/views/mail/components/mailListItemSkeleton/mailListItemSkeleton';

const skeletons = [...Array(3)].map((item, i) => ({id: String(i)}));

export const MailList: FC<IMailListProps> = ({
    mail,
    loadMoreHandler,
    onSelect,
    selectedItemId,
    searchWords,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <List
                key="skeleton"
                itemComponentRender={MailListItemSkeleton}
                itemsData={skeletons}
                searchWords={[]}
            />
        );
    }
    return (
        <List
            key="list"
            itemComponentRender={MailListItem}
            itemsData={mail}
            selectedItemId={selectedItemId}
            onSelect={onSelect}
            loadMoreHandler={loadMoreHandler}
            searchWords={searchWords}
        />
    );
};
