import partition from 'lodash/partition';
import {ILightApp} from '../state/lightApps';

export const filterLightApps = (query: string, categories: ILightApp[]) => {
    if (!query) {
        return [];
    }

    let atStartRegEx = new RegExp(`^${escapeRegExp(query)}`, 'i');
    let singleRegEx = new RegExp(`${escapeRegExp(query)}`, 'i');
    let multipleRegEx = new RegExp(`(.*?)(${escapeRegExp(query)})`, 'gi');

    let [matchedItems, arr] = partition(categories, item => item.title.match(atStartRegEx));

    if (matchedItems.length < 3) {
        let [matchedArr, arr2] = partition(arr, item => item.title.match(singleRegEx));
        matchedItems = matchedItems.concat(matchedArr);

        if (matchedItems.length < 3) {
            let matchedDescriptionItems = arr2.filter(item =>
                item['description'].match(singleRegEx)
            );
            matchedItems = matchedItems.concat(matchedDescriptionItems);
        }
    }

    return matchedItems.slice(0, 3).map(item => ({
        ...item,
        titleQueryParts: item.title.match(singleRegEx)
            ? splitByQuery(item.title, multipleRegEx)
            : [item.title],
        descriptionQueryParts: [item.description],
    }));
};

function splitByQuery(str: string, regEx: RegExp) {
    let match;
    let prevMatch;
    let res = [];
    while ((match = regEx.exec(str)) !== null) {
        res.push(match[1], match[2]);
        prevMatch = match;
    }
    if (prevMatch) {
        let tail = str.substr(prevMatch.index + prevMatch[0].length);
        res.push(tail);
    }
    return res;
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& - whole matched string
}
