import React, {FC, useLayoutEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router';

import {useLocalizationContext} from 'app/hooks';
import {SearchPage, WelcomePage} from 'app/pages';
import {routes} from 'app/routes';

import {AllContainer} from 'app/containers/all';
import {PeopleContainer} from 'app/containers/people';
import {DocumentsContainer} from 'app/containers/documents';
import {CorrespondenceContainer} from 'app/containers/correspondence';
import {MailContainer} from 'app/containers/mail';
import {TrainingsContainer} from 'app/containers/trainings';
import {ProjectsContainer} from 'app/containers/projects';
import {NewsContainer} from 'app/containers/news';
import {TenderBoardContainer} from 'app/containers/tenderBoard';
import {ServiceNowContainer} from 'app/containers/serviceNow';
import {SuppliersContainer} from 'app/containers/suppliers';
import {CustomersContainer} from 'app/containers/customers';
import {DistributionContainer} from './containers/distribution';
import {Error as ErrorScreen} from 'app/views/components/errors/error';

const Head: FC<{path: keyof typeof routes}> = ({path, children}) => {
    const {t} = useLocalizationContext();

    useLayoutEffect(() => {
        const oldTitle = document.title;
        document.title = t(`routeTitles.${path}`);
        return () => {
            document.title = oldTitle;
        };
    }, [path, t]);

    return <>{children}</>;
};

// @ts-ignore
function RedirectWithStatus({from, to, status}) {
    return (
        <Route
            render={({staticContext}) => {
                // there is no `staticContext` on the client, so
                // we need to guard against that here

                // @ts-ignore
                if (staticContext) staticContext.status = status;
                return <Redirect from={from} to={to} />;
            }}
        />
    );
}

export const RouterView: FC = () => (
    <Switch>
        <Route exact path={routes.welcome}>
            <Head path="welcome">
                <WelcomePage />
            </Head>
        </Route>
        <Route path={routes.search_employee}>
            <Head path="search_employee">
                <SearchPage>
                    <PeopleContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_document}>
            <Head path="search_document">
                <SearchPage>
                    <DocumentsContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_correspondence}>
            <Head path="search_correspondence">
                <SearchPage>
                    <CorrespondenceContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_email}>
            <Head path="search_email">
                <SearchPage>
                    <MailContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_training}>
            <Head path="search_training">
                <SearchPage>
                    <TrainingsContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_news}>
            <Head path="search_news">
                <SearchPage>
                    <NewsContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_tender}>
            <Head path="search_tender">
                <SearchPage>
                    <TenderBoardContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_projects}>
            <Head path="search_projects">
                <SearchPage>
                    <ProjectsContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_service}>
            <Head path="search_service">
                <SearchPage>
                    <ServiceNowContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_suppliers}>
            <Head path="search_suppliers">
                <SearchPage>
                    <SuppliersContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path={routes.search_customers}>
            <Head path="search_customers">
                <SearchPage>
                    <CustomersContainer />
                </SearchPage>
            </Head>
        </Route>
        {window.SEARCH_APP.feature.skey_59 && (
            <Route path={routes.search_distribution} status={408}>
                <Head path="search_distribution">
                    <SearchPage>
                        <DistributionContainer />
                    </SearchPage>
                </Head>
            </Route>
        )}
        <Route path={routes.search}>
            <Head path="search">
                <SearchPage>
                    <AllContainer />
                </SearchPage>
            </Head>
        </Route>
        <Route path="*">
            <Head path="welcome">
                <ErrorScreen errorCode={404} fetchErrors={false} />
            </Head>
        </Route>
    </Switch>
);
