import {IDetailsFilter} from 'app/state/suppliers';

export enum SuppliersFiltersOptions {}

export enum SuppliersDetailsFilters {
    Company = 'Company',
    Share = 'Share',
}

/**
 * const [values, toggleValue] = useThisCustomContext();
 *
 * where:
 *
 * values       ->  IDetailsFilter['items']
 * toggleValue  ->  (id: string) => void
 *
 * */

export type DetailsFilterContextValue = [IDetailsFilter['items'], (id: string) => void];
