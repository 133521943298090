import {
    ISendNotificationRequest,
    SupportedEntityTypes,
} from 'app/api/searchAPI/history/searchAPI.history.types';
import {IAuthProvider} from 'app/infrastructure/auth';
import {ILogger} from 'app/infrastructure/logging';
import {IoC} from 'app/ioc';
import {inject, injectable} from 'inversify';
import {EntityType} from 'app/contexts';
import {IHistoryService} from './historyService.types';
import {SearchAPICallerHistory} from 'app/api/searchAPI/history/searchAPICaller.history';
import {appConfig} from 'app/config';

@injectable()
export class HistoryService implements IHistoryService {
    @inject(IoC.AuthProvider) private readonly authProvider!: IAuthProvider;
    @inject(IoC.Logger) private readonly logger!: ILogger;

    public async sendNotification(entityType: EntityType, notification: SupportedEntityTypes) {
        const accessToken = await this.authProvider.getAccessToken();

        const sendNotificationRequest: ISendNotificationRequest = {
            options: {url: appConfig.skey_38, accessToken: accessToken},
            data: notification,
        };
        SearchAPICallerHistory.sendNotification(sendNotificationRequest, entityType).catch(e =>
            this.logger.error(e)
        );
    }
}
