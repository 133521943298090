import React from 'react';
import styles from './filterSearchEntry.module.scss';
import {IFilterSearchEntryProps} from './filterSearchEntry.types';

export const FilterSearchEntry = ({
    entry: {RefinementName, RefinementValue},
    onChange,
    getFilterIsCheckedValue,
}: IFilterSearchEntryProps) => (
    <li className={styles.searchOption}>
        <input
            type="checkbox"
            name="refinersCheckbox"
            id={`${RefinementName}${RefinementValue}`}
            value={RefinementValue}
            onChange={() => onChange(RefinementName)}
            className={styles.customCheckbox}
            checked={getFilterIsCheckedValue(RefinementName)}
        />
        <label htmlFor={RefinementName + RefinementValue} className={styles.entry}>
            <span className={styles.entryName}>{RefinementName}</span>
        </label>
    </li>
);
