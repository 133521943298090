import {appConfig} from 'app/config';

interface IObjectKeys {
    [key: string]: number;
}

const loggingLevels: IObjectKeys = {
    All: 0,
    Verbose: 0,
    Information: 1,
    Warning: 2,
    Error: 3,
    Critical: 4,
    Off: 5,
};

export const isLoggingOff = (severityLevel: number): boolean => {
    const isDevEnv = process.env.NODE_ENV === 'development';
    const loggingLevel = loggingLevels[appConfig.skey_49];

    return loggingLevel > severityLevel || isDevEnv;
};
