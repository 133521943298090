import {
    AbortControllerDistributions,
    IGetDistributionDetailsOptions,
    ISearchDistributionsOptions,
} from './searchAPICaller.distributions.types';
import {capitalizeFirstLetter} from 'app/utils/capitalizeFirstLetter';
import {fetchData} from 'app/utils';
import {DistributionRoutes} from './routes.distributions';

const queryString = require('query-string');

export const abortControllers: AbortControllerDistributions = {
    DISTRIBUTIONS: null,
};

export abstract class SearchAPICallerDistributions {
    public static async searchDistributions(
        options: ISearchDistributionsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        abortControllers.DISTRIBUTIONS?.abort();
        abortControllers.DISTRIBUTIONS = new AbortController();

        const {key, pageNumber, pageSize, filters, order, orderBy} = options;
        let convertedFilters = Object.fromEntries(
            filters.map(f => [capitalizeFirstLetter(f.field), f.values])
        );

        const url = new URL(
            `${DistributionRoutes.distributions(
                searchAPIURL
            )}?key=${key}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}&${queryString.stringify(
                convertedFilters
            )}`
        );

        return fetchData(
            String(url),
            accessToken,
            'GET',
            null,
            null,
            null,
            abortControllers.DISTRIBUTIONS.signal
        );
    }

    public static async getDistributionDetails(
        options: IGetDistributionDetailsOptions,
        searchAPIURL: string,
        accessToken: string
    ) {
        return fetchData(
            `${DistributionRoutes.distributions(searchAPIURL)}/${options.distributionId}`,
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }

    public static async searchDistributionsFilters(searchAPIURL: string, accessToken: string) {
        return fetchData(
            DistributionRoutes.distributionsFilters(searchAPIURL),
            accessToken,
            'GET',
            null,
            null,
            null
        );
    }
}
