import {appConfig} from 'app/config';
import {EntityType} from 'app/contexts/common.types';

const isFeatureEnabled = (feature: string): boolean =>
    typeof appConfig.feature[feature] === 'boolean' ? appConfig.feature[feature] : true;

const featuresMap = {
    [EntityType.Employee]: 'skey_60',
    [EntityType.Document]: 'skey_61',
    [EntityType.Correspondence]: 'skey_63',
    [EntityType.Email]: 'skey_62',
    [EntityType.Training]: 'skey_50',
    [EntityType.News]: 'skey_51',
    [EntityType.Tender]: 'skey_52',
    [EntityType.Projects]: 'skey_53',
    [EntityType.Service]: 'skey_55',
    [EntityType.Suppliers]: 'skey_57',
    [EntityType.Customers]: 'skey_58',
    [EntityType.Distribution]: 'skey_59',
};

export const isEntityEnabled = (entity: EntityType): boolean =>
    isFeatureEnabled(featuresMap[entity]);
