import React, {FC} from 'react';
import {routes} from 'app/routes';
import {ISuggestionComponent} from '../suggestions.types';
import styles from '../styles/suggestionItem.module.scss';
import {TextAvatar} from 'app/views/components/textAvatar';
import {IEmployee} from 'app/api/suggestionsAPI/suggestionsAPICaller.types';
import classNames from 'classnames';
import {useUserAvatar} from 'app/hooks/useUserAvatar';

export const Employee: FC<ISuggestionComponent<IEmployee>> = ({
    item,
    onPush,
    selected,
    itemRef,
    categoryI,
    itemI,
}) => {
    const {id, name, title, city, unit, searchString, historyDate, email} = item;
    const subTitle = [title, city, unit].filter(item => !!item).join(', ');
    const avatar = useUserAvatar(email);

    return (
        <div
            className={classNames(styles.suggestionItem, selected ? styles.selected : undefined)}
            onClick={() => onPush(routes.search_employee, id, searchString, historyDate)}
            ref={el => {
                itemRef.current[categoryI] = itemRef.current[categoryI] || [];
                itemRef.current[categoryI][itemI] = el;
            }}
        >
            <TextAvatar title={name} avatarProps={{className: styles.employeeIcon, src: avatar}} />
            <div className={styles.info}>
                <div className={styles.title}>{name}</div>
                <div className={styles.subTitle}>{subTitle}</div>
            </div>
        </div>
    );
};
