import {combineReducers} from 'redux';
import {History} from 'history';
import {connectRouter} from 'connected-react-router';

import filterReducer from './filter/filter.reducer';
import documentsReducer from './documents/documents.reducer';
import mailReducer from './mail/mail.reducer';
import peopleReducer from './people/people.reducer';
import searchAllReducer from './searchAll/searchAll.reducer';
import searchReducer from './search/search.reducer';
import trainingsReducer from './trainings/trainings.reducer';
import correspondenceReducer from './correspondence/correspondence.reducer';
import suggestionsReducer from './suggestions/suggestions.reducer';
import newsReducer from './news/news.reducer';
import tendersReducer from './tenders/tenders.reducer';
import projectsReducer from './projects/projects.reducer';
import serviceNowReducer from './serviceNow/serviceNow.reducer';
import suppliersReducer from './suppliers/suppliers.reducer';
import customersReducer from './customers/customers.reducer';
import distributionsReducer from './distribution/distribution.reducer';
import lightAppsReducer from './lightApps/lightApps.reducer';
import myProfileReducer from './myProfile/myProfile.reducer';
import {reducer as toastrReducer} from 'react-redux-toastr';

export const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        toastr: toastrReducer,
        searchAll: searchAllReducer,
        search: searchReducer,
        filter: filterReducer,
        documents: documentsReducer,
        mail: mailReducer,
        people: peopleReducer,
        trainings: trainingsReducer,
        correspondence: correspondenceReducer,
        suggestions: suggestionsReducer,
        news: newsReducer,
        tenders: tendersReducer,
        projects: projectsReducer,
        serviceNow: serviceNowReducer,
        suppliers: suppliersReducer,
        customers: customersReducer,
        distributions: distributionsReducer,
        lightAppsByCategories: lightAppsReducer,
        myProfile: myProfileReducer,
    });
