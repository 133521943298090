import React, {FC, Fragment, useEffect, useState} from 'react';
import classNames from 'classnames';
import {RectSkeleton} from 'app/views/components/skeleton';

import {useCurrency, useInjection, useLocalizationContext} from 'app/hooks';
import {IoC} from 'app/ioc';
import {numberFormatter} from 'app/utils';

import styles from './rankTable.module.scss';
import {ColorLabel} from 'app/views/components/colorLabel/colorLabel';
import {ISearchServiceSuppliers} from '../../../../../../infrastructure/search/suppliers';

const renderTr = (data: any, columns: string[]) => {
    const renderTd = (cName: string) => {
        switch (cName) {
            case 'pgDescription':
            case 'topGuyName':
                return <span className={styles.text}>{data[cName]}</span>;
            case 'amount':
                return <span className={styles.amount}>{numberFormatter(data[cName])} AED</span>;
            case 'amountUsd':
                return <span className={styles.amount}>$ {numberFormatter(data[cName])}</span>;
            default:
                return data[cName];
        }
    };

    return columns.map(c => (
        <td key={c} title={data[c]}>
            {renderTd(c)}
        </td>
    ));
};

export const RankTable: FC<{
    rankTableItems: any[];
    companyId: string | undefined;
    supplierId: string | number;
}> = ({rankTableItems, companyId = '', supplierId}) => {
    const {t} = useLocalizationContext();
    const searchService = useInjection<ISearchServiceSuppliers>(IoC.SearchServiceSuppliers);
    const [selectedCurrency] = useCurrency();
    const [expandedRows, setExpandedRows] = useState<{[key: string]: boolean}>({});
    const [subItems, setSubItems] = useState<{[key: string]: {loading: boolean; data?: any}}>({});

    useEffect(() => {
        setExpandedRows({});
        setSubItems({});
    }, [rankTableItems]);

    const toggleRow = (pgCode: string, topGuyCode: string) => {
        if (!subItems[pgCode]) {
            setSubItems({
                ...subItems,
                [pgCode]: {
                    loading: true,
                },
            });
            searchService
                .getSuppliersRankDetails({pgCode, topGuyCode: String(supplierId), companyId})
                .then(data => {
                    setSubItems({
                        ...subItems,
                        [pgCode]: {
                            loading: false,
                            data: data.length ? data : [],
                        },
                    });
                });
        }
        setExpandedRows({
            ...expandedRows,
            [pgCode]: !expandedRows[pgCode],
        });
    };

    const t_ = (s: string, options?: any) => t(`suppliers.previewPanel.rankTable.${s}`, options);

    const tableColumns = [
        'pgCode',
        'pgDescription',
        'company',
        'share',
        selectedCurrency === 'usd' ? 'amountUsd' : 'amount',
        'rank',
        'topGuyCode',
        'topGuyName',
    ];
    const subItemColumns = tableColumns.slice(2);

    const renderSubItems = (pgCode: string) => {
        if (!subItems[pgCode]) return null;
        if (subItems[pgCode].loading)
            return (
                <tr>
                    <td colSpan={2} className={styles.clear} />
                    <td>
                        <RectSkeleton />
                    </td>
                    <td>
                        <RectSkeleton />
                    </td>
                    <td>
                        <RectSkeleton />
                    </td>
                    <td>
                        <RectSkeleton />
                    </td>
                    <td>
                        <RectSkeleton />
                    </td>
                    <td>
                        <RectSkeleton />
                    </td>
                </tr>
            );
        return subItems[pgCode].data && subItems[pgCode].data.length ? (
            <>
                {subItems[pgCode].data.map((subItem: any, i: number) => (
                    <tr key={`tr-${pgCode}-${i}`}>
                        <td colSpan={2} className={styles.clear} />
                        {renderTr(subItem, subItemColumns)}
                    </tr>
                ))}
            </>
        ) : (
            <tr>
                <td colSpan={2} className={styles.clear} />
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </tr>
        );
    };

    return (
        <div>
            <div className={styles.header}>
                <h3>{t_('title')}</h3>
                <div className={styles.labelsContainer}>
                    <ColorLabel color={'gray'}>
                        {selectedCurrency === 'usd' ? 'USD' : 'AED'}
                    </ColorLabel>
                    <ColorLabel color={'gray'}>{t_('label')}</ColorLabel>
                    <ColorLabel color={'gray'}>{t_('yearsLabel', {years: 3})}</ColorLabel>
                    <ColorLabel color={'gray'}>
                        {t_('countLabel', {count: rankTableItems.length})}
                    </ColorLabel>
                </div>
            </div>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            {tableColumns.map(c => (
                                <th key={c}>{t_(c)}</th>
                            ))}
                        </tr>
                        {rankTableItems.map(item => (
                            <Fragment key={item.pgCode}>
                                <tr
                                    key={`tr-${item.pgCode}`}
                                    className={classNames(styles.row, {
                                        [styles.expand]: expandedRows[item.pgCode],
                                    })}
                                    onClick={() => {
                                        toggleRow(item.pgCode, item.topGuyCode);
                                    }}
                                >
                                    {renderTr(item, tableColumns)}
                                </tr>
                                {expandedRows[item.pgCode] && renderSubItems(item.pgCode)}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
