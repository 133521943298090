import React, {FC} from 'react';
import classNames from 'classnames';
import styles from './select.module.scss';
import FormControl from '@material-ui/core/FormControl';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {IPanelProps, IOption} from './select.types';
import {useLocalizationContext} from 'app/hooks';

export const Select: FC<IPanelProps> = ({items = [], onChange, value, className}) => {
    const {t} = useLocalizationContext();

    const handleChange = ({target: {value}}: any) => {
        onChange(value);
    };

    const containerClassName = classNames(styles.container, className);

    return (
        <div className={containerClassName}>
            <FormControl>
                <MUISelect
                    value={value}
                    onChange={handleChange}
                    disableUnderline
                    displayEmpty
                    classes={{
                        root: styles.selectRoot,
                    }}
                    IconComponent={ExpandMoreIcon}
                    className={styles.selectMenu}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    inputProps={{
                        classes: {
                            icon: styles.icon,
                        },
                    }}
                >
                    {items.map(({name, value}: IOption) => (
                        <MenuItem className={styles.menuItem} key={value} value={value}>
                            {t(name)}
                        </MenuItem>
                    ))}
                </MUISelect>
            </FormControl>
        </div>
    );
};
